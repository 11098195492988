/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Drawer,
    Form,
    Icon,
    Input,
    message,
    Modal,
    Row,
    Select,
    Table,
    Tag,
    Tooltip
} from 'antd'
import { Link } from 'react-router-dom'
import * as XLSX from 'xlsx'
import { Space } from '../../common-components/Space'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { FilterAsset } from './model'
import { getAssetFilter, getAssetList, searchFetch, getAssetFilterNew } from './service'
import { FormComponentProps } from 'antd/lib/form'
import ReactHtmlParser from 'react-html-parser'
import { decryptDataVspace, nullOrEmptyObject } from '../../common-misc'
import Scanner from '../Relate/Asset/BarcodeScanner'
import BarcodeScannerWithZoom from '../Relate/Asset/BarcodeScannerWithZoom'

const mapStateToProps = (state: StoreState) => {
    return {
        assetOwnerLocationList: state.assetOwnerLocationList,
        filterAsset: state.filterAsset
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAssetList: (search: string | undefined, filter: FilterAsset | undefined, sortBy?, orderBy?, skip?, limit?) => Promise<number>
    getAssetFilter: () => Promise<number>
    getAssetFilterNew: () => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

const { Search } = Input
const { Option } = Select

const AssetList: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue } = props.form
    const [isOnLoadingData, setIsOnLoadingData] = useState<boolean>(false)
    const [isOnLoadingFilter, setIsOnLoadingFilter] = useState<boolean>(true)
    const [isSearchChange, setIsSearchChange] = useState<boolean>(true)
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [barcodeVisible, setBarcodeVisible] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')
    const [searchFilter, setSearchFilter] = useState<FilterAsset>()
    const [page, setPage] = useState<number>(1)
    const [vSpaceName, setvSpaceName] = useState<string>('')
    const [pageSize, setPageSize] = useState<number>(10)
    const [totalWO, setTotalWO] = useState<number>()
    const [isAssetCenter, setIsAssetCenter] = useState<boolean>(true)
    const [isChangeSize, setIsChangeSize] = useState<boolean>(true)
    const params = new URLSearchParams(window.location.search)
    const incidentNo = params.get('incidentNo') || ''
    const [location, setLocation] = useState<string[]>(props.filterAsset.location?.slice(0, 20) || [])
    const [deviceType, setDeviceType] = useState<string[]>(props.filterAsset.deviceType?.slice(0, 20) || [])
    const [brand, setBrand] = useState<string[]>(props.filterAsset.brand?.slice(0, 20) || [])
    const [model, setModel] = useState<string[]>(props.filterAsset.model?.slice(0, 20) || [])
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [sortBy, setSortBy] = useState<string>('number')
    let email: string = ''
    let token: string = ''
    let roleID: string = ''
    const dataVspaceInfo = decryptDataVspace()
    if (typeof dataVspaceInfo === 'object') {
        email = dataVspaceInfo.email
        token = dataVspaceInfo.token
        roleID = dataVspaceInfo.roleId.toString()
    }
    useEffect(() => {
        if (roleID) {
            setIsAssetCenter(roleID === '444' || roleID === '351' || roleID === '473' || roleID === '474' || roleID === '475')
        }
        setvSpaceName(email)

    }, [])

    const loadData = async () => {
        // if (Object.keys(props.filterAsset).length === 0) {
        //     props.getAssetFilterNew().then(() => {
        //         setIsOnLoadingFilter(false)
        //     })
        // } else {
        //     setIsOnLoadingFilter(false)
        // }
        props.getAssetFilterNew().then(() => {
            setIsOnLoadingFilter(false)
        })
    }

    useEffect(() => {
        setFieldsValue({
            Location: searchFilter?.location,
            Status: searchFilter?.status,
            Device_Type: searchFilter?.deviceType,
            Brand: searchFilter?.brand,
            Model: searchFilter?.model
        })
        if (!openFilter) {
            search(searchText !== '' ? searchText : undefined, true)
        } else {
            // search()
            loadData()
        }
    }, [openFilter])

    useEffect(() => {
        setPage(1)
        setTotalWO(props.assetOwnerLocationList.total)
    }, [props.assetOwnerLocationList.total])
    const search = (value?: string, forceSearch?: boolean) => {
        var filter: FilterAsset | undefined
        if (!nullOrEmptyObject(searchFilter)) {
            filter = searchFilter
        }
        if (isSearchChange || forceSearch) {
            setIsOnLoadingData(true)
            setIsSearchChange(false)
            props.getAssetList(value, filter, sortBy, orderBy, (page - 1) * pageSize, pageSize).then(() => {
                setIsOnLoadingData(false)
            }).catch((err) => {
                setIsOnLoadingData(false)
                message.error(`Failed fetching data. ${err}`)
            })
        }
    }

    useEffect(() => {
        setIsOnLoadingData(true)
        setIsSearchChange(false)
        props.getAssetList(searchText, searchFilter, sortBy, orderBy, (page - 1) * pageSize, pageSize).then(() => {
            setIsOnLoadingData(false)
        }).catch((err) => {
            setIsOnLoadingData(false)
            message.error(`Failed fetching data. ${err}`)
        })
    }, [props.getAssetList, searchText, searchFilter, sortBy, orderBy, page, pageSize])

    useEffect(() => {
        if (window.innerWidth > 1024) {
            setIsChangeSize(true)
        } else {
            setIsChangeSize(false)
        }
    }, [window.innerWidth])

    useEffect(() => {
        if (props.filterAsset) {
            setLocation(props.filterAsset.location?.slice(0, 20) || [])
            setDeviceType(props.filterAsset.deviceType?.slice(0, 20) || [])
            setBrand(props.filterAsset.brand?.slice(0, 20) || [])
            setModel(props.filterAsset.model?.slice(0, 20) || [])
        }
    }, [props.filterAsset.location])

    // const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : (a === null ? '' : a).localeCompare(b === null ? '' : b))

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order === 'ascend') {
            setOrderBy('ASC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('number')
            }
        } else if (sorter.order === 'descend') {
            setOrderBy('DESC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('number')
            }
        } else {
            setSortBy('number')
        }
        setPage(pagination.current)
        setPageSize(pagination.pageSize)
    }

    const handleSearchFilter = (value, id) => {
        const filter: FilterAsset = searchFilter || {}
        switch (id) {
            case 'location':
                filter.location = value
                break
            case 'status':
                filter.status = value
                break
            case 'deviceType':
                filter.deviceType = value
                break
            case 'brand':
                filter.brand = value
                break
            case 'model':
                filter.model = value
                break
        }
        setSearchFilter(filter)
        setIsSearchChange(true)
    }
    const handleSearchAsset = (value) => {
        search(value)
        setSearchText(value)
        setIsSearchChange(true)
    }

    const createOption = (dataSource: string[]) => {
        const options = dataSource.map((data) => {
            return (<Option key={data} title={data}>{data}</Option>)
        })
        return options
    }

    const handleOnclickPageNumber = (page: number) => {
        setPage(page)
    }

    const Filter = (
        <Form>
            <Row>
                <Col md={24}>
                    <div style={{ marginBottom: 4, marginTop: 10 }}>
                        Location
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator('Location')(
                        <Select
                            onSearch={(value) => { searchFetch(value, props.filterAsset.location || [], setLocation!!) }}
                            mode="multiple"
                            placeholder='select location'
                            style={{ width: '100%' }}
                            onChange={(value) => { handleSearchFilter(value, 'location') }}
                            loading={isOnLoadingFilter}
                        >{createOption(location || [])}
                        </Select>
                    )}
                </Col>
                <Col md={24}>
                    <div style={{ marginBottom: 4, marginTop: 10 }}>
                        Asset Status
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator('Status')(
                        <Select
                            mode="multiple"
                            placeholder='select status'
                            style={{ width: '100%' }}
                            onChange={(value) => { handleSearchFilter(value, 'status') }}
                            loading={isOnLoadingFilter}
                        >{createOption(props.filterAsset.status || [])}
                        </Select>
                    )}
                </Col>
                <Col md={24}>
                    <div style={{ marginBottom: 4, marginTop: 10 }}>
                        Device Type
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator('Device_Type')(
                        <Select
                            onSearch={(value) => { searchFetch(value, props.filterAsset.deviceType || [], setDeviceType!!) }}
                            mode="multiple"
                            placeholder='select device type'
                            style={{ width: '100%' }}
                            onChange={(value) => { handleSearchFilter(value, 'deviceType') }}
                            loading={isOnLoadingFilter}
                        >{createOption(deviceType || [])}
                        </Select>
                    )}
                </Col>
                <Col md={24}>
                    <div style={{ marginBottom: 4, marginTop: 10 }}>
                        Brand
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator('Brand')(
                        <Select
                            onSearch={(value) => { searchFetch(value, props.filterAsset.brand || [], setBrand!!) }}
                            mode="multiple"
                            placeholder='select brand'
                            style={{ width: '100%' }}
                            onChange={(value) => { handleSearchFilter(value, 'brand') }}
                            loading={isOnLoadingFilter}
                        >{createOption(brand || [])}
                        </Select>
                    )}
                </Col>
                <Col md={24}>
                    <div style={{ marginBottom: 4, marginTop: 10 }}>
                        Model
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator('Model')(
                        <Select
                            onSearch={(value) => { searchFetch(value, props.filterAsset.model || [], setModel!!) }}
                            mode="multiple"
                            placeholder='select model'
                            style={{ width: '100%' }}
                            onChange={(value) => { handleSearchFilter(value, 'model') }}
                            loading={isOnLoadingFilter}
                        >{createOption(model || [])}
                        </Select>
                    )}
                </Col>
            </Row>
        </Form>
    )

    const searchFilterTag = (title: string, searchFilter: string[], onClose: Function) => {
        let tags: JSX.Element = (<></>)
        if (searchFilter.length > 0) {
            tags = (
                <Tooltip placement="bottom" title={ReactHtmlParser(searchFilter.map((filter: string) => { return `- ${filter}` }).join('</br>'))}>
                    <Tag style={{ margin: 10 }} closable onClose={onClose}>{title}</Tag>
                </Tooltip>)
        }
        return tags
    }

    const columns = [
        {
            title: 'Serial No',
            dataIndex: 'serialNo',
            key: 'serialNo',
            sorter: true,
            render: (record, row) => {
                return (
                    <>{<Link to={`/assetPreview/${row.id}/${record}`}>{record}</Link>}</>
                )
            }
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            sorter: true
        },
        {
            title: 'Asset Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true
        },
        {
            title: 'Kbank Barcode',
            dataIndex: 'kbankBarcode',
            key: 'kbankBarcode',
            sorter: true
        },
        {
            title: 'Device Type',
            dataIndex: 'deviceType',
            key: 'deviceType',
            sorter: true
        },
        // {
        //     title: 'Assignment group',
        //     dataIndex: 'group',
        //     key: 'group',
        //     sorter: (a, b) => sorter(a.group, b.group)
        // },
        {
            title: 'Computer Name',
            dataIndex: 'computerName',
            key: 'computerName',
            sorter: true

        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
            sorter: true
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            sorter: true
        },
        {
            title: 'Employee ID',
            dataIndex: 'employeeId',
            key: 'employeeId',
            sorter: true
        }
    ]

    const exportData = () => {
        if (props.assetOwnerLocationList) {
            const mainData = props.assetOwnerLocationList.data
            const ws1 = XLSX.utils.json_to_sheet(mainData)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws1, 'AssetOwnerLoaction')
            XLSX.writeFile(wb, 'AssetList.xlsx')
        }
    }

    const barcodeScanner = (value) => {
        if (value) {
            setBarcodeVisible(false)
            setFieldsValue({
                Search: value
            })
            search(value, true)
        }
    }

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>Asset List</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Drawer
                title="Filter"
                placement="left"
                closable={true}
                onClose={() => { setOpenFilter(false) }}
                visible={openFilter}
                width={window.innerWidth < 768 ? '100%' : 720}
                bodyStyle={{ paddingBottom: 80, overflow: 'auto' }}
            >
                {Filter}
            </Drawer>
            <Card >
                <div>
                    <h3 className='main-title'>Asset List</h3>
                </div>
                <Row gutter={8}>
                    <Col xl={8} span={8} xs={22} sm={22}>
                        <Form>
                            <Form.Item>
                                {getFieldDecorator('Search')(
                                    <Search
                                        placeholder="Can search Serial No , Kbank Barcode , Employee ID ,and Computer name"
                                        onSearch={handleSearchAsset}
                                    />
                                )}
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={2}>
                        {isChangeSize
                            ? null
                            : <Icon className="scan_icon" type="barcode" onClick={() => { setBarcodeVisible(!barcodeVisible) }} style={{ display: 'block', marginTop: 5 }} />
                        }
                        <Modal
                            title="Scan Barcode"
                            visible={barcodeVisible}
                            onOk={() => { setBarcodeVisible(false) }}
                            onCancel={() => { setBarcodeVisible(false) }}
                            width={700}
                            footer={null}
                        >
                            {barcodeVisible
                                ? <div style={{ textAlign: 'center' }}>
                                    {/* <Scanner barcodeScanner={barcodeScanner} /> */}
                                    <BarcodeScannerWithZoom barcodeScanner={barcodeScanner}/>
                                </div>
                                : null}
                        </Modal>
                    </Col>
                    <Col xl={16} lg={16} xs={24} sm={24} md={24}>
                        <Link to={'/assetForm'} ><Button type="primary" style={{ float: isChangeSize ? 'right' : 'left', marginLeft: isChangeSize ? 8 : 0, marginBottom: 5, width: 100 }} icon="plus" disabled={!isAssetCenter}>Add New</Button></Link>
                        <Button loading={isOnLoadingData} onClick={exportData} style={{ float:isChangeSize ? 'right' : 'left', width: 100, marginLeft: 8 }} disabled={!isAssetCenter}><Icon type="export" rotate={270} /> Export</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Button size="large" style={{ border: 0, marginTop: 10 }} onClick={() => { setOpenFilter(true) }} loading={isOnLoadingData} ><Icon type="filter" theme="filled" /> Filter</Button>
                        {searchFilter?.location ? searchFilterTag('Location', searchFilter.location, () => { delete searchFilter.location; search(searchText !== '' ? searchText : undefined, true) }) : null}
                        {searchFilter?.status ? searchFilterTag('Asset Status', searchFilter.status, () => { delete searchFilter.status; search(searchText !== '' ? searchText : undefined, true) }) : null}
                        {searchFilter?.deviceType ? searchFilterTag('Device Type', searchFilter.deviceType, () => { delete searchFilter.deviceType; search(searchText !== '' ? searchText : undefined, true) }) : null}
                        {searchFilter?.brand ? searchFilterTag('Brand', searchFilter.brand, () => { delete searchFilter.brand; search(searchText !== '' ? searchText : undefined, true) }) : null}
                        {searchFilter?.model ? searchFilterTag('Model', searchFilter.model, () => { delete searchFilter.model; search(searchText !== '' ? searchText : undefined, true) }) : null}
                    </Col>
                </Row>
                <br />
                <div style={{ overflow: 'auto' }}>
                    <Table
                        rowKey="serialNo"
                        columns={columns}
                        dataSource={props.assetOwnerLocationList.data!!}
                        onChange={handleTableChange}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: props.assetOwnerLocationList.total!!,
                            onChange: (event) => { handleOnclickPageNumber(event) },
                            showSizeChanger: true
                        }}
                        loading={isOnLoadingData}
                    />
                </div>
            </Card>
        </>
    )
}

const MyAssetList = Form.create({ name: 'AssetList' })(AssetList)

export default connect(
    mapStateToProps,
    { getAssetList, getAssetFilter, getAssetFilterNew }
)(MyAssetList)
