import {buildStateEvent, GenericStateEvent} from '../common-redux'
import {UserRolesAndPermissions} from "./model";

export interface CommonMiscStateEvent<T> extends GenericStateEvent<T> {}

export const UpdateSlaPolicyTableCurrentPageEvent = {
    type: 'UpdateSlaPolicyTableCurrentPageEvent',
    build: (payload: number) => buildStateEvent(UpdateSlaPolicyTableCurrentPageEvent.type, payload)
}

export const UpdateSlaPolicyTableTotalDataEvent = {
    type: 'UpdateSlaPolicyTableTotalDataEvent',
    build: (payload: number) => buildStateEvent(UpdateSlaPolicyTableTotalDataEvent.type, payload)
}

export const UpdateBusinessHourTableCurrentPageEvent = {
    type: 'UpdateBusinessHourTableCurrentPageEvent',
    build: (payload: number) => buildStateEvent(UpdateBusinessHourTableCurrentPageEvent.type, payload)
}

export const UpdateBusinessHourTableTotalDataEvent = {
    type: 'UpdateBusinessHourTableTotalDataEvent',
    build: (payload: number) => buildStateEvent(UpdateBusinessHourTableTotalDataEvent.type, payload)
}

export const IncidentTableTotalDataEvent = {
    type: 'IncidentTableTotalDataEvent',
    build: (payload: number) => buildStateEvent(IncidentTableTotalDataEvent.type, payload)
}

export const UpdateUserRolesAndPermissionsEvent = {
    type: 'UpdateUserRolesAndPermissionsEvent',
    build: (payload: UserRolesAndPermissions) => buildStateEvent(UpdateUserRolesAndPermissionsEvent.type, payload)
}
