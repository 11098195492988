/* eslint-disable react/prop-types */
import React, {useState} from 'react'
import {Button, Col, Dropdown, Form, Icon, Input, Menu, message, Modal, Row} from 'antd'
import {Knowledge, KnowledgeStatus} from './model'
import {ClickParam} from 'antd/lib/menu'
import {updateKnowledge} from './service'
import {Folder} from '../folder'
import {updateKnowledgeStatus} from '../approval'
import {FormComponentProps} from 'antd/lib/form'
import moment from 'moment'

interface Params {
    knowledge: Knowledge
    folder: Folder
    isCanEditKM: boolean
    redirectFunction: Function
    deleteKM: Function,
    cloneKnowledge: Function,
    isCanCheckOut: boolean
}

type Props = Params & FormComponentProps
const MenuDropDown: React.FC<Props> = (props: Props) => {
    const pathname = window.location.pathname
    const [isVisibleRejectModal, setIsVisibleRejectModal] = useState<boolean>(false)
    const [rejectReason, setRejectReason] = useState<string>('')
    const [knowledgeId, setKnowledgeId] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingReject, setIsLoadingReject] = useState<boolean>(false)
    const username = localStorage.getItem('username')

    const { getFieldDecorator, validateFields, resetFields } = props.form

    const stringSaveTo = (status: KnowledgeStatus) => {
        if (status === KnowledgeStatus.MyNote) {
            return 'Save to Draft'
        } else {
            return 'Save and Publish'
        }
    }

    const selectMenu = (e: ClickParam) => {
        if (e.key === '0') {
            // edit
            props.redirectFunction(`/KnowledgeFormEdit/${props.knowledge.id!!}`)
        } else if (e.key === '1') {
            const status = KnowledgeStatus[props.knowledge.status!!]
            if (status === KnowledgeStatus.MyNote) {
                if (!props.knowledge.categoryId || !props.knowledge.folderId) {
                    props.redirectFunction(`/KnowledgeFormEdit/${props.knowledge.id!!}?validation=1`)
                } else {
                    updateKnowledge({ id: props.knowledge.id, status: KnowledgeStatus.Draft, lastModifiedBy: username!!}).then(() => {
                        window.location.reload()
                        // TODO:add redux
                    })
                }
            } else {
                if (props.folder.publicWorkflow === '0') {
                    updateKnowledge({ id: props.knowledge.id, status: KnowledgeStatus.Published, lastModifiedBy: username!!}).then(() => {
                        window.location.reload()
                    })
                } else {
                    updateKnowledge({ id: props.knowledge.id, status: KnowledgeStatus.AwaitingApproval, lastModifiedBy: username!!}).then(() => {
                        window.location.reload()
                    })
                }
            }
        }
    }

    const menu = (status: KnowledgeStatus) => (
        props.knowledge.status !== KnowledgeStatus.Published
            ? (<Menu onClick={selectMenu}>
                <Menu.Item key="0" disabled={props.isCanEditKM}>
                    Edit
                </Menu.Item>
                <Menu.Item key="1" disabled={props.isCanEditKM}>
                    {stringSaveTo(status)}
                </Menu.Item>
                <Menu.Item key="2" onClick={() => props.deleteKM(props.knowledge.ticketNumber, props.knowledge.status, props.knowledge.id)} disabled={props.isCanEditKM}>
                    Delete
                </Menu.Item>
            </Menu>) : (
                <Menu onClick={selectMenuTypePublic}>
                    <Menu.Item key="3" disabled={props.isCanEditKM || !props.isCanCheckOut}>
                    Retire
                    </Menu.Item>
                    <Menu.Item key="4" disabled={props.isCanEditKM || !props.isCanCheckOut}>
                    Check Out
                    </Menu.Item>
                </Menu>)
    )

    const menuDelete = () => (
        <Menu>
            <Menu.Item key="1" onClick={() => props.deleteKM(props.knowledge.ticketNumber, props.knowledge.status, props.knowledge.id)} disabled={props.isCanEditKM}>
                    Delete
            </Menu.Item>
        </Menu>
    )

    const selectMenuTypePublic = (e: ClickParam) => {
        if (e.key === '3') {
            /* Retire work flow = 0 is Instant Retire -> update status to retire
               Retire work flow = 1 is Approval Retire -> update status to awaiting retire */
            if (props.folder?.retireWorkflow === '0') {
                updateKnowledge({ id: props.knowledge.id, status: KnowledgeStatus.Retired }).then(() => {
                    window.location.reload()
                })
            } else {
                updateKnowledge({ id: props.knowledge.id, status: KnowledgeStatus.AwaitingRetire }).then(() => {
                    window.location.reload()
                })
            }
        } else if (e.key === '4') {
            const ConvertKM = {
                ...props.knowledge,
                id: undefined,
                helpful: undefined,
                notHelpful: undefined,
                status: KnowledgeStatus.Draft,
                createdDate: moment(props.knowledge.createdDate).format('YYYY-MM-DD HH:mm:ss'),
                lastModifiedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
                lastModifiedBy: username
            }
            props.cloneKnowledge(ConvertKM)
        }
    }

    const selectApproval = (e: ClickParam) => {
        console.log(e)
        if (e.key === '0') {
            onApprove()
        } else if (e.key === '1') {
            onReject()
        }
    }

    const onApprove = () => {
        setIsLoading(true)
        updateKnowledgeStatus(props.knowledge.id!!, true).then(() => {
            setIsLoading(false)
            message.success('You have Successfully Approved the data.')
            window.location.reload()
        })
    }

    const onReject = () => {
        setKnowledgeId(props.knowledge.id!!)
        setIsVisibleRejectModal(true)
    }

    const onSubmitReject = () => {
        validateFields((error, row) => {
            if (!error) {
                setIsLoadingReject(true)
                updateKnowledgeStatus(knowledgeId, false, row.RejectReason).then(() => {
                    message.success('You have Successfully Rejected the data.')
                    setIsLoadingReject(false)
                    setIsVisibleRejectModal(false)
                    window.location.reload()
                })
            }
        })
    }

    const menuApproval = () => (
        <Menu onClick={selectApproval}>
            <Menu.Item key="0">
                Approve
            </Menu.Item>
            <Menu.Item key="1">
                Reject
            </Menu.Item>
        </Menu>
    )

    const onCancel = () => {
        setIsVisibleRejectModal(false)
        resetFields()
    }

    if (!(props.knowledge.status === KnowledgeStatus.AwaitingApproval || props.knowledge.status === KnowledgeStatus.AwaitingRetire || props.knowledge.status === KnowledgeStatus.Retired || props.knowledge.status === KnowledgeStatus.Outdated) && !pathname.includes('/ApprovalPreview')) {
        /* it's knowledge preview page and status isn't equal awaiting approve, awaiting retire, retired and out date */
        return (
            <Dropdown overlay={menu(KnowledgeStatus[props.knowledge.status!!])} trigger={['click']} >
                <a style={{ float: 'right' }} className="ant-dropdown-link" onClick={ e => (e.preventDefault())}>
                    <Icon type="down" />
                </a>
            </Dropdown>
        )
    } else if (pathname.includes('/ApprovalPreview') && props.knowledge.status === KnowledgeStatus.AwaitingApproval) {
        /* it's approval preview page and status is awaiting approve -> show button reject and published */
        return (
            <div style={{ float: 'right' }}>
                <Row gutter={[16, 16]}>
                    <span className='approval-button-group'>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="danger" onClick={onReject}>Reject</Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" onClick={onApprove} loading={isLoading}>Publish</Button>
                        </Col>
                    </span>
                </Row>
                <Modal
                    title="Reject Reason"
                    visible={isVisibleRejectModal}
                    onCancel={onCancel}
                    footer={[
                        <Button key="back" onClick={onCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" loading={isLoadingReject} type="primary" onClick={onSubmitReject}>
                            Save
                        </Button>
                    ]}>
                    <Form>
                        <Form.Item label='Reject reason'>
                            {getFieldDecorator('RejectReason', {
                                rules: [{ required: true, message: 'Reject reason is required' },
                                    { min: 3, message: 'Enter more than 3 characters' }]
                            })(
                                <Input.TextArea rows={4} placeholder="please enter description" minLength={3} value={rejectReason} onChange={(e) => setRejectReason(e.target.value)} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    } else if (props.knowledge.status === KnowledgeStatus.Retired) {
        return (
            <Dropdown overlay={menuDelete()} trigger={['click']} >
                <a style={{ float: 'right' }} className="ant-dropdown-link" onClick={ e => (e.preventDefault())}>
                    <Icon type="down" />
                </a>
            </Dropdown>
        )
    } else if (pathname.includes('/ApprovalPreview') && props.knowledge.status === KnowledgeStatus.AwaitingRetire) {
        /* it's approval preview page and status is awaiting retire -> show button reject and retire */
        return (
            <div style={{ float: 'right' }}>
                <Row gutter={[16, 16]}>
                    <span className='approval-button-group'>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="danger" onClick={onReject}>Reject</Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" onClick={onApprove} loading={isLoading}>Retire</Button>
                        </Col>
                    </span>
                </Row>
                <Modal
                    title="Reject Reason"
                    visible={isVisibleRejectModal}
                    onCancel={onCancel}
                    footer={[
                        <Button key="back" onClick={onCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" loading={isLoadingReject} type="primary" onClick={onSubmitReject}>
                            Save
                        </Button>
                    ]}>
                    <Form>
                        <Form.Item label='Reject reason'>
                            {getFieldDecorator('RejectReason', {
                                initialValue: rejectReason,
                                rules: [{ required: true, message: 'Reject reason is required' },
                                    { min: 3, message: 'Enter more than 3 characters' }]
                            })(
                                <Input.TextArea rows={4} placeholder="please enter description" minLength={3} onChange={(e) => setRejectReason(e.target.value)} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    } else {
        return null
    }
}

const MenuInDropDown = Form.create<Props>()(MenuDropDown)

export default MenuInDropDown
