import {buildStateEvent, GenericStateEvent} from '../common-redux'
import {Dashboard, IncidentBacklog} from './model'

export interface DashboardStateEvent<T> extends GenericStateEvent<T> {}
export interface IncidentBacklogStateEvent<T> extends GenericStateEvent<T> {}

// Total Group
// OpenTicket TotalGroup Event
export const GetOpenTicketTotalGroupSuccessEvent = {
    type: 'GetOpenTicketTotalGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetOpenTicketTotalGroupSuccessEvent.type, payload)
}

export const UpdateOpenTicketTotalGroupSuccessEvent = {
    type: 'UpdateOpenTicketTotalGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateOpenTicketTotalGroupSuccessEvent.type, payload)
}

// OpenbyStatusTicket TotalGroup Event
export const GetOpenbyStatusTicketTotalGroupSuccessEvent = {
    type: 'GetOpenbyStatusTicketTotalGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetOpenbyStatusTicketTotalGroupSuccessEvent.type, payload)
}

export const UpdateOpenbyStatusTicketTotalGroupSuccessEvent = {
    type: 'UpdateOpenbyStatusTicketTotalGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateOpenbyStatusTicketTotalGroupSuccessEvent.type, payload)
}

// OpenbyPriorityTicket TotalGroup Event
export const GetOpenbyPriorityTicketTotalGroupSuccessEvent = {
    type: 'GetOpenbyPriorityTicketTotalGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetOpenbyPriorityTicketTotalGroupSuccessEvent.type, payload)
}

export const UpdateOpenbyPriorityTicketTotalGroupSuccessEvent = {
    type: 'UpdateOpenbyPriorityTicketTotalGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateOpenbyPriorityTicketTotalGroupSuccessEvent.type, payload)
}

// OpenSLA TotalGroup Event
export const GetOpenSLATotalGroupSuccessEvent = {
    type: 'GetOpenSLATotalGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetOpenSLATotalGroupSuccessEvent.type, payload)
}

export const UpdateOpenSLATotalGroupSuccessEvent = {
    type: 'UpdateOpenSLATotalGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateOpenSLATotalGroupSuccessEvent.type, payload)
}
// End Total Group ------------------------------------------------------------------------------------

// My Group
// OpenTicket MyGroup Event
export const GetOpenTicketMyGroupSuccessEvent = {
    type: 'GetOpenTicketMyGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetOpenTicketMyGroupSuccessEvent.type, payload)
}

export const UpdateOpenTicketMyGroupSuccessEvent = {
    type: 'UpdateOpenTicketMyGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateOpenTicketMyGroupSuccessEvent.type, payload)
}

// OpenbyStatusTicket MyGroup Event
export const GetOpenbyStatusTicketMyGroupSuccessEvent = {
    type: 'GetOpenbyStatusTicketMyGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetOpenbyStatusTicketMyGroupSuccessEvent.type, payload)
}

export const UpdateOpenbyStatusTicketMyGroupSuccessEvent = {
    type: 'UpdateOpenbyStatusTicketMyGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateOpenbyStatusTicketMyGroupSuccessEvent.type, payload)
}

// OpenbyPriorityTicket MyGroup Event
export const GetOpenbyPriorityTicketMyGroupSuccessEvent = {
    type: 'GetOpenbyPriorityTicketMyGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetOpenbyPriorityTicketMyGroupSuccessEvent.type, payload)
}

export const UpdateOpenbyPriorityTicketMyGroupSuccessEvent = {
    type: 'UpdateOpenbyPriorityTicketMyGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateOpenbyPriorityTicketMyGroupSuccessEvent.type, payload)
}

// OpenSLA MyGroup Event
export const GetOpenSLAMyGroupSuccessEvent = {
    type: 'GetOpenSLAMyGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetOpenSLAMyGroupSuccessEvent.type, payload)
}

export const UpdateOpenSLAMyGroupSuccessEvent = {
    type: 'UpdateOpenSLAMyGroupSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateOpenSLAMyGroupSuccessEvent.type, payload)
}
// End My Group ------------------------------------------------------------------------------------

// My ticket
// OpenbyStatusTicket Myticket Event
export const GetOpenbyStatusTicketMyticketSuccessEvent = {
    type: 'GetOpenbyStatusTicketMyticketSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetOpenbyStatusTicketMyticketSuccessEvent.type, payload)
}

export const UpdateOpenbyStatusTicketMyticketSuccessEvent = {
    type: 'UpdateOpenbyStatusTicketMyticketSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateOpenbyStatusTicketMyticketSuccessEvent.type, payload)
}

// OpenbyPriorityTicket Myticket Event
export const GetOpenbyPriorityTicketMyticketSuccessEvent = {
    type: 'GetOpenbyPriorityTicketMyticketSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetOpenbyPriorityTicketMyticketSuccessEvent.type, payload)
}

export const UpdateOpenbyPriorityTicketMyticketSuccessEvent = {
    type: 'UpdateOpenbyPriorityTicketMyticketSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateOpenbyPriorityTicketMyticketSuccessEvent.type, payload)
}

// OpenSLA Myticket Event
export const GetOpenSLAMyticketSuccessEvent = {
    type: 'GetOpenSLAMyticketSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetOpenSLAMyticketSuccessEvent.type, payload)
}

export const UpdateOpenSLAMyticketSuccessEvent = {
    type: 'UpdateOpenSLAMyticketSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateOpenSLAMyticketSuccessEvent.type, payload)
}

// Incident Backlog Myticket Event
export const GetIncidentBacklogMyticketSuccessEvent = {
    type: 'GetIncidentBacklogMyticketSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(GetIncidentBacklogMyticketSuccessEvent.type, payload)
}

export const UpdateIncidentBacklogMyticketSuccessEvent = {
    type: 'UpdateIncidentBacklogMyticketSuccessEvent',
    build: (payload: Dashboard) => buildStateEvent(UpdateIncidentBacklogMyticketSuccessEvent.type, payload)
}
// End Myticket
