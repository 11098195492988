import React, { useState, useEffect } from 'react'
import { Col, Icon, Row, Form, Input, Button, Spin, Modal, message } from 'antd'
import Logo from '../../assets/images/vspace-sky.png'
import { FormComponentProps } from 'antd/es/form'
import Footer from '../../common-components/DefaultFooter'
import { getIp, loginVspace } from './service'
import { LoginInfo, LoginVspaceRequestBody } from './model'
import md5 from 'md5'
import { UserRole } from '../permissions/model'
import { loadRolesState } from '../permissions'
import { encrypt } from '../../common-misc'

type Props = FormComponentProps

let mspUser = ''
let result: LoginInfo
let userRoles: UserRole[]

const VspaceLogin: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form
    const params = new URLSearchParams(window.location.search)
    const redirect = params.get('redirect')
    const truePath = redirect || 'WoList'
    const [isFetchData, setIsFetchData] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [isShowConfirmLogin, setIsShowConfirmLogin] = useState<boolean>(false)

    useEffect(() => {
        mspUser = localStorage.getItem('username') || ''
    }, [localStorage.getItem('username')])

    const handleOK = () => {
        localStorage.setItem('username', getFieldValue('username'))
        localStorage.setItem('userRoles', JSON.stringify(userRoles))
        loadRolesState()
        const convertResult = {
            email: result.email,
            roleId: result.roleId,
            token: result.token
        } as LoginInfo
        window.location.href = `/${truePath}?data=${JSON.stringify(encrypt(JSON.stringify(convertResult)))}`
    }

    const handleCancel = () => {
        setIsShowConfirmLogin(false)
    }

    const handleSubmit = e => {
        setIsFetchData(true)
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                getIp().then((ip) => {
                    const body: LoginVspaceRequestBody = {
                        username: getFieldValue('username'),
                        password: md5(getFieldValue('password')),
                        ip: ip
                    }
                    loginVspace(body).then((response) => {
                        result = response
                        userRoles = response.userRole
                        if (!mspUser || mspUser === getFieldValue('username')) {
                            console.log('if')
                            handleOK()
                        } else {
                            console.log('else')
                            setAlertMessage(`Do you want to replace ${mspUser} to ${getFieldValue('username')} ?`)
                            setIsShowConfirmLogin(true)
                        }
                        localStorage.setItem('userId', response.userId.toString()!!)
                    }).catch((err) => {
                        if (err?.status === 401) {
                            message.error(err.data.message)
                        }
                    })
                        .finally(() => setIsFetchData(false))
                }).catch(() => {
                    setIsFetchData(false)
                })
            }
        }
        )
    }

    return (
        <div>
            <Modal
                title="Confirm Login"
                visible={isShowConfirmLogin}
                onOk={handleOK}
                okText={'Accept'}
                onCancel={handleCancel}
            >
                <p>{alertMessage}</p>
            </Modal>
            <Row style={{marginTop: '10%'}}>
                <Col md={24}>
                    <Spin spinning={isFetchData}>
                        <div style={{ textAlign: 'center' }}>
                            <img src={Logo} />
                        </div>
                        <Form onSubmit={handleSubmit} style={{marginTop: 10}} className="login-form">
                            <Form.Item>
                                {getFieldDecorator('username', {
                                    rules: [{ required: true, message: 'Please input your username!' }],
                                    initialValue: mspUser
                                })(
                                    <Input
                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder="Username"
                                    />
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Please input your Password!' }]
                                })(
                                    <Input
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password"
                                        placeholder="Password"
                                    />
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" >
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Col>
            </Row>
            <Footer />
        </div>
    )
}
const VspaceLoginForm = Form.create({ name: 'vspace_login' })(VspaceLogin)

export default VspaceLoginForm
