/* eslint-disable react/display-name */
import {StoreState} from '../../store'
import React, {useCallback, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {
    addSupportTeam,
    editSupportTeam,
    getAllSupportTeam,
    getSupportTeamById,
    getSupportTeamsWithPagination,
    getUser
} from './service'
import {AutoComplete, Breadcrumb, Button, Card, Col, Form, Icon, Input, message, Row, Table, Tooltip} from 'antd'
import {Link, Redirect, useParams} from 'react-router-dom'
import {FormComponentProps} from 'antd/es/form'
import {Assignee, SupportTeamManagement} from './model'
import {Modal} from 'antd/es'

const Option = AutoComplete.Option

const mapStateToProps = (state: StoreState) => {
    return {
        supportTeamManagement: state.supportTeamManagement
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllSupportTeam: () => Promise<number>
    addSupportTeam: (s: SupportTeamManagement) => Promise<number>
    editSupportTeam: (id: string, s: any) => Promise<number>
}

interface Params {
    id: string
    action: string
}

type Props = StateProps & DispatchProps & FormComponentProps & Params

const SupportTeamForm: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator} = props.form
    const [assignees, setAssignees] = useState<any[]>([])
    const [userRoles, setUserRoles] = useState<Assignee[]>([])
    const {id, action} = useParams<Params>() // action - View/Edit/Create
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isRedirect, setRedirect] = useState<boolean>(false)
    const [isSelectAssignee, setIsSelectAssignee] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isActive, setIsActive] = useState<boolean>(true)

    useEffect(() => {
        if (id !== undefined && id !== 'action') {
            getSupportTeamById(id)
                .then((result) => {
                    setFieldValue(result)
                    setIsEdit(true)
                    setIsActive(result.active!!)
                })
                .catch((err) => message.error(`Failed loading support team ID ${id}. ${err}`))
        }
    }, [])

    const setFieldValue = (result: SupportTeamManagement) => {
        setAssignees(result.assignees || [])
        // if (result.assignees) {
        //     result.assignees?.map((it) => {
        //         const convertData = {
        //             name: it
        //         }
        //     })
        // }
        props.form.setFieldsValue({
            name: result.name,
            email: result.email
        })
    }

    const columns = [
        {
            title: '',
            dataIndex: 'actionDelete',
            key: 'actionDelete',
            width: '5px',
            render: (_text, record, _index) => (
                <>
                    {action === 'View' ? null : <Tooltip placement="bottom" title={'Delete'}>
                        <Icon className="del_icon" type="delete" onClick={() => handleOnDeleteAssignee(record)}/>
                    </Tooltip>}
                    {/* {index === assignees.length - 1 ? setIsLastIndex(true) : setIsLastIndex(false)} */}
                </>
            )
        }, {
            title: 'Name',
            dataIndex: '',
            key: '',
            width: '45%',
            render: (row) => (
                <>
                    {row.name || row.user.people.fullName}
                </>
            )
        }, {
            title: 'Email',
            dataIndex: '',
            key: '',
            width: '45%',
            render: (row) => (
                <>
                    {row.email || row.user.username}
                </>
            )
        }
    ]

    const handleOnDeleteAssignee = e => {
        setIsSelectAssignee(false)
        setAssignees(assignees.filter(assignees => !(assignees.id === e.id)))
    }

    const renderOption = (item: Assignee) => {
        return (
            <Option key={item.email} value={item.email}>
                {item.name}
            </Option>
        )
    }

    const handleSearchOnSelect = (_value: any, option: any) => {
        const tempAssignee: Assignee[] = []
        let exitAssignee: boolean = false
        assignees.map(a => {
            if (isEdit) {
                if (a.email === option.props.value || (a.user && a.user.username === option.props.value)) exitAssignee = true
                if (a.name === option.props.children) exitAssignee = true
            } else {
                if (a.email === option.props.value) exitAssignee = true
                if (a.name === option.props.children) exitAssignee = true
            }
        })
        if (exitAssignee) {
            message.warning('Assignee already exit')
            props.form.setFieldsValue({
                search: ''
            })
        } else {
            const user = userRoles.find(it => it.email === option.props.value)
            if (user) {
                tempAssignee.push(user, ...assignees)
            }
            props.form.setFieldsValue({
                search: ''
            })
            setIsSelectAssignee(true)
            setAssignees(tempAssignee)
        }
    }

    const handleSearchOnSearch = (s: string) => {
        setUserRoles([])
        getUser(s)
            .then(result => {
                setUserRoles(result)
            })
            .catch()
    }

    const handleOnSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (err) {
                console.log('Received err of form: ', err)
                setIsLoading(false)
            } else {
                setIsLoading(true)
                // const tempAssignees = userRoles.filter(({name: id1}) => assignees.some(({name: id2}) => id2 === id1))
                switch (action) {
                case 'Create': {
                    const objSupportTeam: SupportTeamManagement = {
                        name: values.name,
                        email: values.email,
                        tempAssignees: assignees,
                        activate: assignees.length !== 0
                    }
                    props.addSupportTeam(objSupportTeam)
                        .then(_result => {
                            message.success('You have Successfully save the data')
                            setRedirect(true)
                            setIsLoading(false)
                            // setTimeout(window.location.href = '/SupportTeamSettingList', 3000)
                        })
                        .catch(err => {
                            setIsLoading(false)
                            message.error(`You have Unsuccessfully save the data. ${err}`)
                        })

                    break
                }
                case 'Edit': {
                    const newAssignees: any[] = []
                    const existAssignee: any[] = []
                    // assignees.forEach((it) => {
                    //     if (!it.supportTeamId) {
                    //         newAssignees.push(it)
                    //     } else {
                    //         existAssignee.push(it)
                    //     }
                    // })
                    const objSupportTeam: SupportTeamManagement = {
                        id: Number(id),
                        name: values.name,
                        email: values.email,
                        assignees: assignees,//newAssignees.length !== 0 ? newAssignees : existAssignee,
                        activate: assignees.length !== 0,
                        active: isActive
                    }
                    props.editSupportTeam(id, objSupportTeam)
                        .then((result) => {
                            message.success('The Update has finished successfully.')
                            setIsLoading(false)
                            setRedirect(true)
                        })
                        .catch(err => {
                            setIsLoading(false)
                            message.error(`You have Unsuccessfully save the data. ${err}`)
                        })

                    break
                }
                }
            }
        })
    }

    const isRepeatValue = (arr: any[], value: string, id: string) => {
        let isRepeat: boolean = false
        if (action === 'Create') {
            arr.map(d => {
                if (d.name === value) isRepeat = true
            })
        }
        if (action === 'Edit') {
            arr.map(d => {
                if (d.name === value && d.id !== id) isRepeat = true
            })
        }
        return isRepeat
    }

    const validateSupportGroupName = (_rule: any, value: any, callback) => {
        value = value.trim()
        if (value.length > 250) {
            props.form.setFieldsValue({
                name: value.substring(0, 250)
            })
        }
        const supportTeamNameList: any[] = []
        getAllSupportTeam().then(result => {
            (result || []).map(result => {
                if (result.active) {
                    supportTeamNameList.push({name: result.name, id: result.id})
                }
            })
        }).then(() => {
            if (isRepeatValue(supportTeamNameList, value, id)) {
                callback('The Support Team name is already exists. It must be unique. Please try another one.')
            } else {
                callback()
            }
        })
    }

    return (

        <>
            <div>
                {isRedirect ? (<Redirect to="/SupportTeamSettingList"/>) : null}
                <Breadcrumb separator=">" className={'content'}>
                    <Breadcrumb.Item>
                        <Link to="/Setting">Setting</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={'/SupportTeamSettingList'}>Group</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{action === 'Create' ? 'New Group' : `${action} Group`}</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Card headStyle={{borderBottom: 'none', paddingTop: '23px'}}>
                    <Form onSubmit={handleOnSubmit}>
                        <Row>
                            <Col span={23}>
                                {action === 'Create'
                                    ? <p className="main-title">New Group</p>
                                    : <p className="main-title">{action} Group</p>}
                            </Col>
                            {action === 'View' ? (
                                <Col span={1}>
                                    <span style={{float: 'right', marginRight: '8px'}}>
                                        <Tooltip placement="bottom" title={'Edit'}><Link to={`/SupportTeamSettingForm/${id}/Edit`}><Icon
                                            className="edit_icon" type="edit"/></Link></Tooltip>
                                    </span>
                                </Col>
                            ) : (null)}
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label={'Name'}>
                                    {getFieldDecorator('name',
                                        {
                                            initialValue: '',
                                            rules: [{required: true, message: 'Name is Required'},
                                                {min: 3, message: 'Enter more than 3 characters'},
                                                {max: 250, message: 'message max 250 characters'},
                                                {validator: !isEdit ? validateSupportGroupName : undefined}]
                                        })(
                                        <Input
                                            disabled={!!(action === 'View' || action === 'Edit')}
                                            placeholder="Support Team Name"
                                            prefix={<Icon type="team" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'Email'}>
                                    {getFieldDecorator('email', {
                                        rules: [{
                                            type: 'email',
                                            message: 'The input is not valid E-mail'
                                        }]
                                    })(
                                        <Input
                                            disabled={action === 'View'}
                                            placeholder="Support Team Email"
                                            prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label={'Assignee'}>
                                    {getFieldDecorator('search')(
                                        <AutoComplete
                                            disabled={action === 'View'}
                                            dataSource={userRoles.map(renderOption)}
                                            onSearch={e => handleSearchOnSearch(e)}
                                            onSelect={(value, option) => handleSearchOnSelect(value, option)}
                                        >
                                            <Input
                                                placeholder="Search Assignee"
                                                prefix={<Icon type="search" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                            />
                                        </AutoComplete>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={11}></Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Table
                                    columns={columns}
                                    rowKey="id"
                                    className={isSelectAssignee ? 'custom-highlight' : undefined}
                                    dataSource={assignees}
                                    pagination={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div style={{float: 'right', paddingTop: '30px'}}>
                                    {/* <div className={'custom-highlight'} ></div> */}
                                    <Form.Item>
                                        <Button type="default" htmlType="button" style={{marginRight: 10}}
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: 'Are you sure?',
                                                    content: 'You want to leave this page ?',
                                                    okText: 'Yes',
                                                    okType: 'danger',
                                                    autoFocusButton: 'cancel',
                                                    cancelText: 'No',
                                                    onCancel: () => {
                                                    },
                                                    onOk: () => {
                                                        setRedirect(true)
                                                    }
                                                })
                                            }}>
                                            Cancel
                                        </Button>
                                        <Button type="primary" htmlType="submit" disabled={action === 'View'}
                                            loading={isLoading} onClick={() => setIsLoading(true)}>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </>
    )
}
const wrappedForm = Form.create({name: 'supportTeamForm'})(SupportTeamForm)
export default connect(
    mapStateToProps,
    {getAllSupportTeam: getSupportTeamsWithPagination, addSupportTeam, editSupportTeam}
)(wrappedForm)
