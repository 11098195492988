import React from 'react'
import { ResponseUserRole } from '../duck/model'

interface EditData {
    userrole: ResponseUserRole
    error?: string[]
}

export default class UserRoleShareData {
    private static instance: UserRoleShareData
    editData: EditData = { userrole: { username: '', fullName: '', roles: [], active: true, peopleId: 0 } }
    Context!: React.Context<any>

    static getInstance(): UserRoleShareData {
        if (!UserRoleShareData.instance) {
            UserRoleShareData.instance = new UserRoleShareData()
            UserRoleShareData.instance.Context = React.createContext<any>({})
        }
        return UserRoleShareData.instance
    }
}
