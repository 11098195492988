import React, { useState, useEffect } from 'react'
import { Row, Col, Empty, Modal, Spin } from 'antd'
import SelectAsset from './SelectAsset'
import AssetContent from './AssetContent'
import { Asset } from './model'
import { getAssetForWOByWOId } from './service'
import { useLocation } from 'react-router-dom'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { getAssetFilter, getEditAssetFieldOption } from '../../asset-management/service'
import { message } from 'antd/es'
import { decryptDataVspace } from '../../../common-misc'

interface Param {
    getItemAsset: Function
    woId: string
}

const mapStateToProps = (state: StoreState) => {
    return {
        asset: state.asset,
        filterAsset: state.filterAsset,
        job: state.job
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAssetFilter: () => Promise<number>
    getEditAssetFieldOption: () => Promise<number>
}

type Props = Param & StateProps & DispatchProps

const RelateAsset: React.FC<Props> = (props: Props) => {
    const [visible, setVisible] = useState<boolean>(false)
    const [visibleSpare, setVisibleSpare] = useState<boolean>(false)
    const [dataAsset, setDataAsset] = useState<Asset[]>([])
    const [dataSpare, setDataSpare] = useState<Asset[]>([])
    const [loadData, setLoadData] = useState<boolean>(false)
    const [isDisableAsset, setIsDisableAsset] = useState<boolean>(false)
    const location = useLocation()
    const params = new URLSearchParams(window.location.search)
    const incidentNo = params.get('incidentNo') || ''

    useEffect(() => {
        loadFilter()
        if (incidentNo && !location.pathname.includes('/WoFormEdit')) {
            fetchDataAsset(incidentNo)
        }
        if (location.pathname.includes('/WoFormEdit')) {
            if (props.woId) {
                fetchDataAsset(props.woId)
            }
        }
    }, [])

    useEffect(() => {
        const mergeAsset = dataAsset.concat(dataSpare)
        if (checkForDuplicates(mergeAsset, 'id')) {
            message.error('This Asset is already exists.')
        }
        props.getItemAsset(mergeAsset)
    }, [dataAsset, dataSpare])

    useEffect(() => {
        if (props.job[0] && location.pathname.includes('/WoFormEdit')) {
            if (props.job[0].attachedSignatureReplace) {
                setIsDisableAsset(true)
            } else {
                setIsDisableAsset(false)
            }
        }
    }, [props.job])

    function checkForDuplicates(array, keyName) {
        return new Set(array.map(item => item[keyName])).size !== array.length
    }

    const loadFilter = async () => {
        // if (!props.filterAsset.employeeID) {
        //     if (Object.keys(props.filterAsset).length === 0) {
        //         props.getAssetFilter().then(() => {
        //             console.log('load filter success')
        //         })
        //     }
        // }
        props.getEditAssetFieldOption().then(() => {
            console.log('load filter success')
        })

    }

    const fetchDataAsset = async (id) => {
        setLoadData(true)
        const res = await getAssetForWOByWOId(id)
        if (res) {
            const tempAsset: any = []
            const tempSpare: any = [];
            (res || []).forEach((it) => {
                if (it.assetType === 'asset' || it.assetType === null) {
                    tempAsset.push(it)
                } else {
                    tempSpare.push(it)
                }
            })
            setDataAsset(tempAsset)
            setDataSpare(tempSpare)
            setLoadData(false)
        } else {
            setLoadData(false)
        }
    }

    const showModal = () => {
        setVisible(true)
    }

    const handleState = e => {
        setVisible(false)
    }

    const showModalSpare = () => {
        setVisibleSpare(true)
    }

    const handleStateSpare = e => {
        setVisibleSpare(false)
    }

    const getData = (data, check) => {
        if (data !== null) {
            if (check) {
                setDataAsset(data)
                setVisible(false)
            } else {
                setDataSpare(data)
                setVisibleSpare(false)
            }
        }
    }

    const deleteDataAsset = (id) => {
        const temp = (dataAsset || []).filter(res => res.id !== id)
        setDataAsset(temp)
    }

    const deleteDataSpare = (id) => {
        const temp = (dataSpare || []).filter(res => res.id !== id)
        setDataSpare(temp)
    }

    return (
        <>
            <Row>
                <Col span={24} style={{ textAlign: 'end' }}>
                    {!isDisableAsset ? <a href="#" onClick={showModal}>Select Asset </a> : null}
                </Col>
            </Row>
            <br />
            <Modal
                title="Asset List"
                visible={visible}
                onOk={handleState}
                onCancel={handleState}
                okButtonProps={{ disabled: true }}
                cancelButtonProps={{ disabled: true }}
                footer={null}
                width={1500}
            >
                <SelectAsset getData={getData} setData={dataAsset} isAsset={true} assetvisible={visible} assetSpare={visibleSpare} />
            </Modal>
            <Spin spinning={loadData}>
                {dataAsset.length !== 0 ? (dataAsset || []).map((it, index) => {
                    return (<div key={index}>
                        <AssetContent data={it} deleteData={deleteDataAsset} isDisableAsset={isDisableAsset} />
                    </div>)
                })
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Spin>

            <Row style={{ lineHeight: '500%' }}><Col>&nbsp;</Col></Row>
            <Row>
                <Col span={24} style={{ textAlign: 'end' }}>
                    {!isDisableAsset ? <a href="#" onClick={showModalSpare}>Select Spare</a> : null}
                </Col>
            </Row>
            <br />
            <Modal
                title="Asset List"
                visible={visibleSpare}
                onOk={handleStateSpare}
                onCancel={handleStateSpare}
                okButtonProps={{ disabled: true }}
                cancelButtonProps={{ disabled: true }}
                footer={null}
                width={1500}
            >
                <SelectAsset getData={getData} setData={dataSpare} isAsset={false} assetvisible={visible} assetSpare={visibleSpare} />
            </Modal>
            <Spin spinning={loadData}>
                {dataSpare.length !== 0 ? (dataSpare || []).map((it, index) => {
                    return (<div key={index}>
                        <AssetContent data={it} deleteData={deleteDataSpare} isDisableAsset={isDisableAsset} />
                    </div>)
                })
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Spin>
        </>
    )
}

export default connect(mapStateToProps, {
    getAssetFilter, getEditAssetFieldOption
})(RelateAsset)
