import {BaseDocument, BaseDocumentFunc, BaseEntity} from '../../common-model'
import {TicketType} from '../sla-global-setting'
import {cloneObject} from '../../common-misc'
import {bool} from "aws-sdk/clients/signer";
const arr: SlaCondition[] = []
export interface SlaPolicy extends BaseDocument, BaseHistory {
    name: string;
    description: string;
    ticketType: string;
    businessHourId?: string;
    conditionsMatchingRequirement: ConditionsMatchingRequirement;
    conditions: SlaCondition[];
    activate: boolean;
    slaGoals: SlaGoal[];
    version: number;
    active: boolean;

}

export interface BaseHistory {
    originId?: number
    versionTimestamp?: string
    validThrough?: string
}

export interface SlaGoal extends BaseEntity {
    slaPolicyId: number
    name?: string
    day: number
    hour: number
    minute: number,
    statusToStartClock?: string,
    statusToStopClock?: string,
    statusesToPauseClock: string,
    statusToCancel?: string
}

export interface SlaCondition extends BaseEntity{
    slaPolicyId?: number
    rule: string
    value: string
}

export enum ConditionsMatchingRequirement {
    MeetAllConditions = 'MeetAllConditions',
    MeetAnyConditions = 'MeetAnyConditions'
}

export interface Condition {
    id?: number
    name: string
}

export const SlaPolicyFunc = {
    construct: (ticketType: string) => {
        return {
            name: '',
            description: '',
            ticketType: ticketType,
            conditionsMatchingRequirement: ConditionsMatchingRequirement.MeetAllConditions,
            conditions: [],
            activate: true,
            slaGoals: [],
            version: 0,
            active: true
        } as SlaPolicy
    },
    proposeSlaGoalNamesAndCheckWhetherTheLastNameBreakTheRule: (slaPolicy: SlaPolicy, names: string[]): boolean => {
        let shouldPop = false
        if (names.length === 0) {
            slaPolicy.slaGoals = []
        } else {
            if (names.length > 8 || names[names.length - 1].length > 20) {
                shouldPop = true
            } else {
                slaPolicy.slaGoals = names.map((name) =>
                    slaPolicy.slaGoals.find((it) => it.name === name) ||
                    { name: name } as SlaGoal
                )
            }
        }
        return shouldPop
    },
    addConditionIfNotExists: (slaPolicy: SlaPolicy, key: string, value: string): boolean => {
        const exists = slaPolicy.conditions.find(it => it.rule === key && it.value.toString() === value.toString())
        if (!exists) {
            const condition: SlaCondition = {
                slaPolicyId: 0,
                rule: key,
                value: value
            }
            arr.push(condition)
            slaPolicy.conditions = [...slaPolicy.conditions, condition]
            return true
        } else {
            return false
        }
    },
    deleteConditionIfExist: (slaPolicy: SlaPolicy, key: string, value: string): boolean => {
        const allValueInRule = slaPolicy.conditions.filter(it => it.rule === key)
        slaPolicy.conditions.map((item, index) => {
            if (item.rule === key) {
                const isDeleteElement = item.value?.toString().includes(value.toString())
                if (isDeleteElement) {
                    slaPolicy.conditions.splice(index, 1)
                }
            }
        })
        if (allValueInRule.some(it => it.value?.toString() === value.toString())) {
            return true
        }
        return false
    },
    cloneForPatching: (slaPolicy: SlaPolicy): SlaPolicy => {
        const clone = cloneObject(slaPolicy)
        // BaseDocumentFunc.deleteFields(clone, true) //TODO
        return clone
    }
}
