import React from 'react'
import { Row, Tag, Icon, Tooltip, Col } from 'antd'
import { Link } from 'react-router-dom'
import { Knowledge, KnowledgeStatus } from './model'
import { convertCamelCaseToSentenceCase, statusColor } from '../util'

interface Props {
    rowData: Knowledge
    deleteKM: Function
    hasPermission: Function
}

export const KnowledgeListColumn: React.FC<Props> = (props: Props) => {
    return (
        <Row key={props.rowData.id}>
            <Col span={22}>
                <Link to={`/KnowledgePreview/${props.rowData.id}`} >
                    <div>
                        <h3 className={'ant-table-column-title'}>
                            {props.rowData.ticketNumber}
                            {(typeof (props.rowData?.major) === 'number' || props.rowData?.major === null) && typeof (props.rowData?.minor) === 'number' && props.rowData.status !== KnowledgeStatus.MyNote
                                ? <Tag style={{ marginLeft: 10, color: '4F4F4F' }}>
                                    {props.rowData.major || '0'}.{props.rowData?.minor < 10 ? '0' + props.rowData.minor : props.rowData.minor}
                                </Tag> : null}
                            <Tag color={statusColor(props.rowData.status || '')} style={{ marginLeft: 10 }}>{convertCamelCaseToSentenceCase(props.rowData.status || '')}</Tag>
                        </h3>
                        <p>{props.rowData.topic}</p>
                        <Tooltip title='Helpful'><Icon type='like' style={{ marginRight: 5 }} />{props.rowData.helpful ? props.rowData.helpful : 0}</Tooltip>
                        <Tooltip title='Not Helpful'><Icon type='dislike' style={{ marginRight: 5, marginLeft: 20 }} />{props.rowData.notHelpful ? props.rowData.notHelpful : 0}</Tooltip>
                        <Tooltip title='Views'><Icon type='check-square' style={{ marginRight: 5, marginLeft: 20 }} />{props.rowData.views ? props.rowData.views : 0}</Tooltip>
                        <Tooltip title='Used'><Icon type='read' style={{ marginRight: 5, marginLeft: 20 }} />{props.rowData.used ? props.rowData.used : 0}</Tooltip>
                        <Tooltip title='Created By'><Icon type='contacts' style={{ marginRight: 5, marginLeft: 20 }} />{props.rowData.createdBy}</Tooltip>
                        <Tooltip title='Created Date'><Icon type='calendar' style={{ marginRight: 5, marginLeft: 20 }} />{props.rowData.createdDate}</Tooltip>
                    </div>
                </Link>
            </Col>
            <Col span={2} style={{ textAlign: 'end' }}>
                {(props.rowData.status === 'MyNote' || props.rowData.status === 'Draft' || props.rowData.status === 'Rejected' || props.rowData.status === 'Retired') && props.hasPermission(props.rowData.folderId)
                    ? <Icon className="del_icon" type="delete" onClick={() => props.deleteKM(props.rowData.id, props.rowData.status, props.rowData.ticketNumber)} /> : null}
            </Col>
        </Row>
    )
}
