import { Dispatch } from 'redux'
import { ResponseDetails } from '../../common-rest'
import { GetAllSupportTeamSuccessEvent } from './state-event'
import {modelOfSupportTeam, setSupportTeam, SupportTeam} from './model'
import { axiosFactory as supportTeamAxiosFactory } from '../../authorization-module/rest'
import { SupportTeams } from './supportTeams'

export const getAllSupportTeam = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await supportTeamAxiosFactory().get<ResponseDetails<SupportTeam[]>>('/api/auth/supportTeams/getAllActiveTeam')
            // SupportTeams.getInstance().list = response.data.data!!
            dispatch(GetAllSupportTeamSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}

export const getAllEmailGroupByCurrentUser = async () => {
    const response = await supportTeamAxiosFactory().get('/api/auth/supportTeams/getAllEmailGroup')
    return response.data
}
