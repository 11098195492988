/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import {
    message,
    Table
} from 'antd'
import { workOrder } from '../wo-management'
import PreventiveForm from './PreventiveForm'
import {findExistPvm} from '../asset-management'

interface Param {
   data: workOrder[]
   isUpdatePM: boolean,
   setIsUpdatePM: Function
   serialNo: string,
   isNewData: boolean
}

type Props = Param
const TableRelatePM: React.FC<Props> = (props: Props) => {
    const [isRelate, setIsRelate] = useState<boolean>(false)
    const [dataRelate, setDataRelate] = useState<any>()

    useEffect(() => {
        if (isRelate) {
            setIsRelate(false)
        }
    }, [props.isNewData])

    const handleRelate = (data) => {
        findExistPvm(props.serialNo, data.id).then(res=>{
            setDataRelate(data)
            setIsRelate(true)
        }).catch(err=>{
            setIsRelate(false)
            message.error('This Order ID is already exists.')
        })

    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (row) => <a href='#' onClick={() => handleRelate(row)}>Relate</a>
        },
        {
            title: 'Order ID',
            dataIndex: 'number',
            key: 'number'
        },
        {
            title: 'Topic',
            dataIndex: 'topic',
            key: 'topic'
        },
        {
            title: 'Contact User',
            dataIndex: 'contactUserName',
            key: 'contactUserName'
        },
        {
            title: 'Location',
            key: 'contactUserLocation',
            dataIndex: 'contactUserLocation'
        },
        {
            title: 'Assignment Group',
            key: 'supportTeam',
            dataIndex: 'supportTeam'
        },
        {
            title: 'Assigned To',
            key: 'assignee',
            dataIndex: 'assignee'
        }]
    return (
        <div style={{ overflow: 'auto'}}>
            {!isRelate ? <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                <Table rowKey='number' columns={columns} dataSource={props.data} />
            </div> : <><PreventiveForm data={dataRelate} isUpdatePM={props.isUpdatePM} setIsUpdatePM={props.setIsUpdatePM} serialNo={props.serialNo}/></>}
        </div>
    )
}

export default TableRelatePM
