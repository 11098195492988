import { SlaPolicy } from './model'
import {
    AddSlaPolicySuccessEvent,
    DeleteSlaPolicySuccessEvent,
    GetAllSlaPolicySuccessEvent,
    UpdateSlaPolicySuccessEvent
} from './state-event'
import { axiosDelete, axiosPatch, axiosPost, axiosGet } from '../rest'
import { Dispatch } from 'redux'

export async function getSlaPolicyById(id: string): Promise<SlaPolicy> {
    try {
        const res = await axiosGet<SlaPolicy>(`/api/sla/slaPolicies/${id}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getAllSlaPolicy = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<SlaPolicy[]>('/api/sla/slaPolicies')
            dispatch(GetAllSlaPolicySuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const addSlaPolicy = (slaPolicy: SlaPolicy) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<SlaPolicy>('/api/sla/slaPolicies', slaPolicy)
            dispatch(AddSlaPolicySuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteSlaPolicy = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/sla/slaPolicies/${id}`)
            dispatch(DeleteSlaPolicySuccessEvent.build(id))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const updateSlaPolicy = (patch: SlaPolicy) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            delete patch.id
            const response = await axiosPatch<SlaPolicy>(`/api/sla/slaPolicies/${id}`, patch)
            dispatch(UpdateSlaPolicySuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            throw err.response
        }
    }
}
