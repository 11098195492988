import { GetAllGeneralIncidentSettingSuccessEvent, GeneralIncidentSettingStateEvent } from './state-event'
import { GeneralIncidentSetting } from './model'
import { stat } from 'fs'

export const GeneralIncidentSettingStateReducer = (state: GeneralIncidentSetting = {}, event: GeneralIncidentSettingStateEvent<any>) => {
    if (event.type === GetAllGeneralIncidentSettingSuccessEvent.type) {
        return event.payload || {}
    } else {
        return state
    }
}
