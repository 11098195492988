
import AWS, { S3 } from 'aws-sdk'
import { UploadFile } from 'antd/lib/upload/interface'
// import { postUploadFile } from '../../../file-server-storage'

const Bucket = 'msp-knowledge'

AWS.config.update({
    region: 'us-east-2',
    accessKeyId: 'AKIARUW4XWD6B3PNQYM7',
    secretAccessKey: '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'
})

export const getObjectS3 = (Key: string) => {
    const S3 = new AWS.S3()
    const objParams = {
        Bucket: Bucket,
        Key: Key
    }
    return S3.getObject(objParams)
}

// export const uploadFSS = (fileList: UploadFile[], path: String, callBack: Function) => {

//     function dataURItoBlob(dataURI) {
//         // convert base64/URLEncoded data component to raw binary data held in a string
//         var byteString;
//         if (dataURI.split(',')[0].indexOf('base64') >= 0)
//             byteString = atob(dataURI.split(',')[1]);
//         else
//             byteString = unescape(dataURI.split(',')[1]);
    
//         // separate out the mime component
//         var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
//         // write the bytes of the string to a typed array
//         var ia = new Uint8Array(byteString.length);
//         for (var i = 0; i < byteString.length; i++) {
//             ia[i] = byteString.charCodeAt(i);
//         }
    
//         return new Blob([ia], {type:mimeString});
//     }

//     let i: number = 0
//     fileList.map((file) => {
//         console.log(file)
//         if (file.status?.includes('done')) {
//             i += 1
//             if (i === fileList.length) {
//                 callBack(path)
//             }
//         } else {
//             var blob = dataURItoBlob(file.url)
//             var fd = new FormData(document.forms[0])
//             fd.append('path', `${path}${file.uid}.${file.name.split('.').pop()}`)
//             fd.append('file', blob)
            
//             postUploadFile(fd).then(() => {
//                 i += 1
//                 if (i === fileList.length) {
//                     callBack(path)
//                 }
//             }).catch((err) => {
//                 console.log('Something went wrong')
//                 console.log(err.code)
//                 console.log(err.message)
//             })
//         }
//     })
// }

export const uploadS3 = (fileList: UploadFile[], callBack: Function) => {
    const S3 = new AWS.S3()
    let i: number = 0
    fileList.map((file) => {
        console.log(file)
        if (file.url?.includes('https://msp-knowledge.s3.amazonaws.com')) {
            i += 1
            if (i === fileList.length) {
                callBack(i)
            }
        } else {
            const objParams = {
                Bucket: Bucket,
                Key: file.uid,
                Body: convertDataURIToBinary(file.url),
                ContentType: file.type
            }
            S3.putObject(objParams)
                .send(function (err: any) {
                    if (err) {
                        console.log('Something went wrong')
                        console.log(err.code)
                        console.log(err.message)
                    } else {
                        i += 1
                        if (i === fileList.length) {
                            callBack(i)
                        }
                    }
                })
        }
    })
}

export const deleteObjectInS3 = (Key: string) => {
    const S3 = new AWS.S3()
    const objParams = {
        Bucket: Bucket,
        Key: Key
    }
    const isDeleted = S3.deleteObject(objParams)
        .promise()
        .then((result) => {
            console.log(result)
            return true
        })
        .catch((error) => {
            console.log(error)
            return false
        })
    return isDeleted
}

const convertDataURIToBinary = (dataURI) => {
    var BASE64_MARKER = ';base64,'
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    var base64 = dataURI.substring(base64Index)
    var raw = window.atob(base64)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (var i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i)
    }
    return array
}
