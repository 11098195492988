import {
    AddWorkOrderSuccessEvent,
    DeleteWorkOrderSuccessEvent,
    GetAllWorkOrderSuccessEvent, UpdateTotalContentWorkOrderEvent,
    UpdateTotalWorkOrderSuccessEvent, UpdateWorkOrderPageEvent,
    UpdateWorkOrderSuccessEvent,
    workOrderStateEvent
} from './state-event'
import {TotalWorkOrderContent, workOrder, WorkOrderList, WorkOrderWithPagination} from './model'

const closedJob = (workOrders: any[]) => {
    workOrders.map(a => {
        // const countTotalJobs = (a.related || []).filter(it => it.jobs.id === null).length
        // const totalJobs = countTotalJobs > 0 ? 0 : (a.related || []).length
        // const closedJobs = (a.related || []).filter(it => it.jobs.jobStatus === 'Closed' || it.jobs.jobStatus === 'Rejected' || it.jobs.jobStatus === 'Cancelled').length
        a.workOrder.closedJobs = `${a.related[0].closeJob}/${a.related[0].totalJob}`

        let jobTopic = ''
        let currentJobIndex = 0
        const relateJob = (a.related || []).filter(it => it.jobs.id !== null)
        for (const [index, item] of relateJob.entries()) {
            if (item.jobs.jobStatus !== 'Closed') {
                if (index === currentJobIndex) {
                    currentJobIndex = index
                    jobTopic = item.jobs.jobTopic
                    break
                }
            } else {
                const lastIndex = relateJob.length - 1
                const nextIndex = index + 1
                if (index === lastIndex) {
                    jobTopic = item.jobs.jobTopic
                    break
                } else {
                    if (relateJob[nextIndex].jobs.jobStatus !== 'Closed') {
                        currentJobIndex = nextIndex
                        jobTopic = relateJob[nextIndex].jobs.jobTopic
                    }
                }
            }
        }
        a.workOrder.jobTopic = jobTopic
    })

    return workOrders
}

export const workOrderStateReducer = (state: workOrder[] = [], event: workOrderStateEvent<any>) => {
    if (event.type === AddWorkOrderSuccessEvent.type) {
        return [...state, event.payload]
    } else if (event.type === DeleteWorkOrderSuccessEvent.type) {
        return state.filter((it) => it.id !== event.payload)
    } else if (event.type === UpdateWorkOrderSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}

export const workOrderListStateReducer = (state: WorkOrderList[] = [], event: workOrderStateEvent<any>) => {
    if (event.type === GetAllWorkOrderSuccessEvent.type) {
        console.log(event.payload)
        return closedJob(event.payload)
    } else {
        return state
    }
}

export const totalWorkOrderContentStateReducer = (state: TotalWorkOrderContent = {}, event: workOrderStateEvent<any>) => {
    if (event.type === UpdateTotalWorkOrderSuccessEvent.type) {
        return event.payload
    } else {
        return state
    }
}

export const workOrderPaginationStateReducer = (state: WorkOrderWithPagination = {}, event: workOrderStateEvent<any>) => {
    if (event.type === UpdateTotalContentWorkOrderEvent.type) {
        state.total = event.payload
    } else if (event.type === UpdateWorkOrderPageEvent.type) {
        state.page = event.payload
    } else {
        return state
    }
    return state
}
