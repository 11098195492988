import {Charging, ChargingGroup} from './Model'
import {costStateEvent} from './state-event'

export const chargingStateReducer = (state: ChargingGroup[] = [], event: costStateEvent<any>) => {
    if (event.type === 'GetChargingSuccessEvent') {
        return event.payload
    } else {
        return state
    }
}
