/* eslint-disable quote-props */
import React, {useEffect, useState} from 'react'
import {FormComponentProps} from 'antd/es/form'
import {Button, Card, Form, Icon, Input, message, Row, Tooltip} from 'antd'
import BannerConfirmation from '../../assets/images/BannerConfirmation.png'
import {useParams} from 'react-router-dom'
import {confirmUser, getUsername} from './duck/action'
import {UserConfirm} from './duck/model'
import * as sha256 from "fast-sha256";
import {newUserView} from '../../incident-management/incident-view';

interface Params {
    secretCode: string
}

type Props = FormComponentProps & Params

const UserConfirmation: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form
    const {secretCode} = useParams<Params>()
    const [confirm, setConfirm] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        getUsername(secretCode)
            .then((res) => {
                props.form.setFieldsValue({
                    'username': res
                })
            }).catch(_error => {
                message.error('You have Unsuccessfully get the data.')
            })
    }, [])

    const handleOnSubmit = e => {
        setIsLoading(true)
        const {form} = props
        e.preventDefault()
        form.validateFields((err, values) => {
            if (err) {
                setIsLoading(false)
                console.log('Received err of form: ', err)
            } else {
                const user: UserConfirm = {
                    secretCode: secretCode,
                    // password: values.password
                    password: new TextDecoder("ascii").decode(sha256.pbkdf2(values.password, new TextEncoder().encode("MSPTeamWuhoo"), 10, 100))
                }

                confirmUser(user)
                    .then(res => {
                        setIsLoading(false)
                        newUserView(user.secretCode).then(res => {
                            window.location.href = '/'
                        }).catch(_err => {
                            message.error('You have Unsuccessfully create current views')
                        })
                    }).catch(_err => {
                        setIsLoading(false)
                        message.error('You have Unsuccessfully save the data.')
                    })
            }
        })
    }

    const validateNewPassword = (rule: any, value: string, callback) => {
        const regularExpression = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*_?])[a-zA-Z\d!@#$%&*_?]{8,20}$/
        if (value.length < 1) {
            callback('Password is require.')
        } else if (!regularExpression.test(value)) {
            setConfirm('')
            props.form.validateFields(['confirmPassword'])
            callback('Password does not meet password policy requirement.')
        } else {
            setConfirm('')
            props.form.validateFields(['confirmPassword'])
            callback()
        }
    }

    const validateConfirmPassword = (rule: any, value: string, callback) => {
        const password = props.form.getFieldValue('password')
        if (password !== value) {
            setConfirm('')
            callback('Your password not matched.')
        } else {
            if (value.length > 1) {
                setConfirm('OK, Confirm password matched.')
            }
            callback()
        }
    }

    return (
        <>
            <div style={{minHeight: '100%', marginBottom: '-30px', display: 'flex', alignItems: 'center'}}>
                <Card
                    hoverable
                    style={{ width: 350, height: '100%', margin: 'auto'}}
                    cover={
                        <div>
                            <img style={{width: '100%'}} src={BannerConfirmation}/>
                            <div style={{
                                backgroundColor: '#323276',
                                color: '#ffffff',
                                paddingLeft: '15px',
                                paddingBottom: '15px'
                            }}>
                                <h4 style={{color: '#ffffff', textAlign: 'center', fontSize: '16px'}}>Please confirm your account</h4>
                            </div>
                        </div>
                    }
                >
                    <Form layout="vertical" onSubmit={handleOnSubmit}>
                        <Form.Item label={'Username'}>
                            {getFieldDecorator('username',
                                {
                                    initialValue: '',
                                    rules: []
                                })(
                                <Input disabled={true} />
                            )}
                        </Form.Item>
                        <Form.Item label={<span>
                            New Password&nbsp;
                            <Tooltip title={<span>
                                <Row>At least one upper case character (A-Z)</Row>
                                <Row>At least one lower case character (a-z)</Row>
                                <Row>At least one numeric case character (1-9)</Row>
                                <Row>At least one symbol case character(!@#$%&*_?)</Row>
                                <Row>A minimum of 8 characters</Row>
                            </span>}>
                                <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                        </span>} >
                            {getFieldDecorator('password',
                                {
                                    initialValue: undefined,
                                    rules: [{validator: validateNewPassword}]
                                })(
                                <Input.Password />
                            )}
                        </Form.Item>
                        <Form.Item label={'Confirm New Password'}>
                            {getFieldDecorator('confirmPassword',
                                {
                                    initialValue: undefined,
                                    rules: [{validator: validateConfirmPassword}]
                                })(
                                <div>
                                    <Input.Password/>
                                    <span style={{color: '#4DC977'}}>{confirm}</span>
                                </div>

                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>

            </div>
            <footer style={{
                backgroundColor: '#323276',
                textAlign: 'center',
                height: '30px',
                color: '#ffffff',
                fontSize: 11
            }}>
                G-Able Company Limited
            </footer>
        </>
    )
}
const wrappedForm = Form.create({ name: 'UserConfirmation' })(UserConfirmation)
export default wrappedForm
