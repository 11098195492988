import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'

import {StoreState} from '../../store'

import {Breadcrumb, Button, Card, Col, Form, Icon, message, Modal, Row, Table, Tooltip} from 'antd'
import {FormComponentProps} from 'antd/lib/form'

import {RoleAndPermissions} from './model'
import {deleteRoleAndPermissions, getAllRoleAndPermissions, getAllRoleAndPermissionsWithPaging} from './service'

import {Link} from 'react-router-dom'
import {GetAllRoleAndPermissionsSuccessEvent, UpdatePageRoleAndPermissionsEvent} from './state-event'

const mapStateToProps = (state: StoreState) => {
    return {
        roleAndPermissions: state.roleAndPermissions,
        roleAndPermissionsPaging: state.roleAndPermissionsPaging
    }
}

interface DispatchProps {
    roleAndPermissions: RoleAndPermissions
    getAllRoleAndPermissions: () => Promise<number>
    getAllRoleAndPermissionsWithPaging: (page: number, pageSize: number, sortBy?: string, orderBy?: string) => Promise<number>
    deleteRoleAndPermissions: (id: string) => Promise<number>
}

type StateProps = ReturnType<typeof mapStateToProps>

interface OwnProps {
    getAllRoleAndPermissions: () => void
}

type Props = StateProps & OwnProps & FormComponentProps & DispatchProps

const RoleAndPermissionsList: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch()
    const { confirm } = Modal
    const [page, setPage] = useState<number>(1)

    useEffect(() => {
        fetchPaging()
    }, [])

    useEffect(() => {
        fetchPaging()
    }, [page])

    const fetchPaging = () => {
        const currentPage = props.roleAndPermissionsPaging.page !== undefined && props.roleAndPermissionsPaging.page > 1 ? props.roleAndPermissionsPaging.page : page
        props.getAllRoleAndPermissionsWithPaging(currentPage, 10).then((res) => { setPage(currentPage) })
            .catch(() => dispatch(GetAllRoleAndPermissionsSuccessEvent.build([])))
    }

    const handleOnClickPageNumber = (p: number) => {
        dispatch(UpdatePageRoleAndPermissionsEvent.build(p))
        setPage(p)
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'} ><Icon className="del_icon" type="delete" onClick={() => deleteRole(row.id)} /></Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Edit'}><Link to={`/RoleAndPermissionsSettingEditForm/${row.id}`} ><Icon className="edit_icon" type="edit" /></Link></Tooltip>
                </>
            )
        },
        {
            title: 'Name',
            dataIndex: '',
            key: '',
            width: '20%',
            // eslint-disable-next-line react/display-name
            render: (role: RoleAndPermissions) => (
                <>
                    <Link to={`/RoleAndPermissionsSettingPreview/${role.id}`}>{role.name?.length!! > 30 ? (<>{role.name?.substring(0, 30) + ' ...'}</>) : (<>{role.name}</>)}</Link>
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        }
    ]

    const deleteRole = (id): void => {
        try {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete this data?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                onOk() {
                    props.deleteRoleAndPermissions(id).then(() => {
                        message.success('This role was deleted')
                        fetchPaging()
                    }).catch(() => {
                        message.warning('This role and permission are being used by User Role.')
                    })
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className='fix_scroll_md'>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Role and Permission</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Card style={{ width: '100%', height: '100%' }} >
                <Row>
                    <Col span={12}><h3 className={'main-title'}>Role and Permission</h3></Col>
                    <Col span={12}><Button type='primary' style={{ float: 'right' }}><Link to="/RoleAndPermissionsSettingForm">Create</Link></Button></Col>
                </Row>
                <Row style={{overflow: 'visible', minWidth: 768}}>
                    <Table
                        dataSource={props.roleAndPermissions}
                        columns={columns}
                        rowKey='id'
                        pagination={{
                            pageSize: 10,
                            current: page,
                            total: props.roleAndPermissionsPaging.totalContent,
                            onChange: (event) => { handleOnClickPageNumber(event) }
                        }}
                    />
                </Row>
            </Card>
        </div>
    )
}

const MyRoleAndPermissionsList = Form.create<Props>()(RoleAndPermissionsList)

export default connect(mapStateToProps, { getAllRoleAndPermissions, deleteRoleAndPermissions, getAllRoleAndPermissionsWithPaging })(MyRoleAndPermissionsList)
