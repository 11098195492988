import { Dispatch } from 'redux'
import {
    DeleteRoleAndPermissionsEvent,
    GetAllRoleAndPermissionsOptionsSuccessEvent,
    GetAllRoleAndPermissionsSuccessEvent,
    UpdatePageRoleAndPermissionsEvent,
    UpdateTotalContentRoleAndPermissionsEvent,
    GetAllRoleKMSuccessEvent
} from './state-event'
import { axiosDelete, axiosGet, axiosGetWithPagination, axiosPatch, axiosPost } from '../rest'
import { RoleAndPermissions, LineNotify } from './model'

export const getAllRoleAndPermissionsOptions = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<RoleAndPermissions>('/api/authority/roles/allPermissions')
            dispatch(GetAllRoleAndPermissionsOptionsSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            throw err.response
        }
    }
}

export const getAllRoleAndPermissions = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<RoleAndPermissions[]>('/api/authority/roles')
            dispatch(GetAllRoleAndPermissionsSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            throw err.response
        }
    }
}

export const getAllRoleAndPermissionsWithPaging = (page: number, pageSize: number, sortBy?: string, orderBy?: string) => {
    sortBy = sortBy === undefined ? 'createdDate' : sortBy
    orderBy = orderBy === undefined ? 'asc' : orderBy
    return async (dispatch: Dispatch) => {
        try {
            const url = `/api/authority/roles/data?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&orderBy=${orderBy}`
            const response = await axiosGetWithPagination<RoleAndPermissions[]>(url)
            dispatch(UpdatePageRoleAndPermissionsEvent.build(response.data.data.number + 1))
            dispatch(UpdateTotalContentRoleAndPermissionsEvent.build(response.data.data.numberOfElements || 0))
            dispatch(GetAllRoleAndPermissionsSuccessEvent.build(response.data.data.content!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getRoleAndPermissionsById = async (RoleAndPermissionsId: number) => {
    try {
        const response = await axiosGet<RoleAndPermissions>(`/api/authority/roles/${RoleAndPermissionsId}`)
        return response.data.data!!
    } catch (err) {
        throw err.response
    }
}

export const createRoleAndPermissions = async (data: RoleAndPermissions) => {
    try {
        return await axiosPost<RoleAndPermissions>('/api/authority/roles', data)
    } catch (err) {
        throw err.response
    }
}

export const updateRoleAndPermissions = async (data: RoleAndPermissions, id: string) => {
    try {
        return await axiosPatch<RoleAndPermissions>(`/api/authority/roles/${id}`, data)
    } catch (err) {
        throw err.response
    }
}

export const deleteRoleAndPermissions = (RoleAndPermissionsId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const respone = await axiosDelete<RoleAndPermissions>(`/api/authority/roles/${RoleAndPermissionsId}`)
            dispatch(DeleteRoleAndPermissionsEvent.build(RoleAndPermissionsId))
            return respone.status
        } catch (err) {
            throw err.response
        }
    }
}

export async function sendLineNotify(message: string, group: string) {
    try {
        var form_data = new FormData();
        const obj = { msg: message, group: group }
        for (var key in obj) {
            form_data.append(key, obj[key]);
        }
        const response = await axiosPost<LineNotify>(`/lineNotify/sendMessages`, form_data)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getKmMapRole = (email: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<string[]>(`/api/auth/kmMapRole/groups/${email}`)
            dispatch(GetAllRoleKMSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            throw err.response
        }
    }
}
