import { buildStateEvent, GenericStateEvent } from '../../common-redux'
import { BusinessHour } from './model'

export interface BusinessHourStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllBusinessHourSuccessEvent = {
    type: 'GetAllBusinessHourSuccessEvent',
    build: (payload: BusinessHour[]) => buildStateEvent(GetAllBusinessHourSuccessEvent.type, payload)
}

export const AddBusinessHourSuccessEvent = {
    type: 'AddBusinessHourSuccessEvent',
    build: (payload: BusinessHour) => buildStateEvent(AddBusinessHourSuccessEvent.type, payload)
}

export const DeleteBusinessHourSuccessEvent = {
    type: 'DeleteBusinessHourSuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteBusinessHourSuccessEvent.type, payload)
}

export const UpdateBusinessHourSuccessEvent = {
    type: 'UpdateBusinessHourSuccessEvent',
    build: (payload: BusinessHour) => buildStateEvent(UpdateBusinessHourSuccessEvent.type, payload)
}
