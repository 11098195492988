import React, { useEffect, useState } from 'react'
import { BrowserMultiFormatReader, BarcodeFormat, DecodeHintType } from '@zxing/library'
import { Select } from 'antd'

interface Param {
    barcodeScanner?: Function
}

type Props = Param

const { Option } = Select
const formats = [
    BarcodeFormat.AZTEC,
    BarcodeFormat.CODABAR,
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_128,
    BarcodeFormat.EAN_8,
    BarcodeFormat.EAN_13,
    BarcodeFormat.ITF,
    BarcodeFormat.PDF_417,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.RSS_14,
    BarcodeFormat.DATA_MATRIX
]
const hints = new Map()
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats)
const codeReader = new BrowserMultiFormatReader(hints)

const BarcodeScanner: React.FC<Props> = (props: Props) => {
    const [chooseCamera, setChooseCamera] = useState<string>('')
    const [listCamera, setListCamera] = useState<MediaDeviceInfo[]>([])
    let firstDeviceId = ''

    useEffect(() => {
        codeReader
            .listVideoInputDevices()
            .then(videoInputDevices => {
                setListCamera(videoInputDevices)
                videoInputDevices.forEach(() => {
                    firstDeviceId = videoInputDevices[videoInputDevices.length - 1].deviceId
                    codeReader
                        .decodeOnceFromVideoDevice(firstDeviceId, 'video')
                        .then(result => {
                            if (props.barcodeScanner) {
                                props.barcodeScanner(result.getText())
                                codeReader.reset()
                            }
                        })
                        .catch(() => null)
                }
                )
            })
            .catch(err => console.error(err))
    }, [])

    useEffect(() => {
        codeReader
            .listVideoInputDevices()
            .then(videoInputDevices => {
                videoInputDevices.forEach(() => {
                    codeReader
                        .decodeOnceFromVideoDevice(chooseCamera, 'video')
                        .then(result => {
                            if (props.barcodeScanner) {
                                props.barcodeScanner(result.getText())
                                codeReader.reset()
                            }
                        })
                        .catch(() => null)
                }
                )
            })
            .catch(err => console.error(err))
    }, [chooseCamera])

    const handleChange = (value) => {
        setChooseCamera(value)
    }

    return (
        <div>
            Choose Camera : <Select placeholder="-- Select a Camera --" defaultValue={firstDeviceId || undefined} style={{ width: 200 }} onChange={handleChange}>
                {listCamera.map((it, index) => {
                    return <Option value={it.deviceId} key={index}>{it.label}</Option>
                })}
            </Select>
            <br /><br />

            <video
                id="video"
                width="300"
                height="200"
                style={{ border: '1px solid gray' }}
            >
            </video>
        </div>
    )
}

export default BarcodeScanner
