import { Dispatch } from 'redux'
import { GetAllKnowledgeSuccessEvent, DeleteKnowledgeSuccessEvent } from './state-event'
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from '../rest'
import { Knowledge, KnowledgeList, KnowledgeStatus } from './model'
import {Permissions} from '../../authorization-module/permissions'

const pageSize = require('./config.json').pageSize
export const checkKmAdminPermission = () => {
    const localRoles = localStorage.getItem('role')
    let roleState = Permissions.getInstance().roleState
    if (localRoles) {
        roleState = JSON.parse(localRoles)
    }
    for (const role of roleState.knowledge || []) {
        return (role.permissions || []).includes('CreateKnowledge') && (role.permissions || []).includes('UpdateKnowledge') && (role.permissions || []).includes('DeleteKnowledge')
    }
}

export const checkDeletePermission = () => {
    const localRoles = localStorage.getItem('role')
    let roleState = Permissions.getInstance().roleState
    if (localRoles) {
        roleState = JSON.parse(localRoles)
    }
    for (const role of roleState.knowledge || []) {
        return (role.permissions || []).includes('DeleteKnowledge')
    }
}

export const getAllKnowledge = (currentPage: number = 1, size: number = pageSize, groupUser?: string[]) => {
    const isAdmin = checkKmAdminPermission()
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<KnowledgeList>(`/api/knowledge/knowledges/list?currentPage=${currentPage}&size=${size}&isAdmin=${isAdmin || false}`, groupUser)
            dispatch(GetAllKnowledgeSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getKnowledgeById = async (id: string) => {
    try {
        const response = await axiosGet<Knowledge>(`/api/knowledge/knowledges/${id}`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getKnowledgeBySearch = async (currentPage: number, size: number, folderId?: string, categoryId?: string, status?: KnowledgeStatus, input?: string, groupUser?: string[]) => {
    const isAdmin = checkKmAdminPermission()
    try {
        // clear folder id cause it use to fillter all category in another function
        if (folderId === 'all') { folderId = '' }
        const response = await axiosPost<KnowledgeList>(`/api/knowledge/knowledges/search?categoryId=${categoryId || ''}&folderId=${folderId || ''}&status=${status || ''}&input=${input || ''}&currentPage=${currentPage}&size=${size}&isAdmin=${isAdmin}&sortBy=createdDate&orderBy=desc`, groupUser)
        localStorage.setItem('km_current_page', currentPage.toString())
        return response.data.data || {}
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const createKnowledge = async (data: Knowledge) => {
    try {
        const response = await axiosPost<Knowledge>('/api/knowledge/knowledges', data)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const updateKnowledge = async (data: Knowledge) => {
    try {
        const response = await axiosPatch<Knowledge>(`/api/knowledge/knowledges/${data.id}`, data)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const deleteKnowledgeByTicketNumber = (ticketNumber: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<Knowledge>(`/api/knowledge/knowledges/ticketNumber/${ticketNumber}`)
            dispatch(DeleteKnowledgeSuccessEvent.build(ticketNumber))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const deleteKnowledgeById = (ticketId: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<Knowledge>(`/api/knowledge/knowledges/${ticketId}`)
            dispatch(DeleteKnowledgeSuccessEvent.build(ticketId.toString()))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const historyByTicketNumberAndStatus = async (ticketNumber: string, Status: string) => {
    try {
        const response = await axiosGet<Knowledge[]>(`/api/knowledge/knowledges/historyTicketNumber/${ticketNumber}/${Status}`)
        return response.data.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getTicketByTicketNumber = async (ticketNumber: string) => {
    try {
        const response = await axiosGet<Knowledge[]>(`/api/knowledge/knowledges/getTicketByTicketNumber/${ticketNumber}`)
        return response.data.data || []
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getDataExportKM = async (start, end) => {
    try {
        const response = await axiosGet<Knowledge[]>(`/api/knowledge/knowledges/report?start=${start}&end=${end}`)
        return response.data.data || []
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const deleteFolderById = (folderId: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<Knowledge>(`/api/knowledge/folders/${folderId}`)
            // dispatch(DeleteKnowledgeSuccessEvent.build(ticketId.toString()))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const deleteCategoryById = (CategoryId: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<Knowledge>(`/api/knowledge/categories/${CategoryId}`)
            // dispatch(DeleteKnowledgeSuccessEvent.build(ticketId.toString()))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}
