import { axiosGet, axiosPatch, axiosPost } from '../rest'
import { Knowledge, KnowledgeList, KnowledgeStatus } from '../knowledge'
const pageSize = require('../knowledge/config.json').pageSize

export const getAllKnowledgeWithApprovalStatus = async (params) => {
    try {
        const response = await axiosPost<KnowledgeList>(`/api/knowledge/knowledges/getAwaitingApprove?currentPage=${params.currentPage}&size=${pageSize}`, params.listStatus)
        return response.data.data || {}
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const updateKnowledgeStatus = async (knowledgeId: number, isApproved: boolean, rejectReason?: string) => {
    try {
        const response = await axiosPatch<Knowledge>(`/api/knowledge/knowledges/${knowledgeId}/status?isApproved=${isApproved}&rejectReason=${rejectReason}`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}
