import React, {useEffect, useRef, useState} from 'react'
import {Link, Redirect, useParams} from 'react-router-dom'
import {Breadcrumb, Card, Col, Collapse, Icon, Menu, Modal, Row, Tag, Tooltip, Upload} from 'antd'
import moment from 'moment'

import {defaultKnowledge, dropdownDataType} from './mock'
import {Knowledge, KnowledgeStatus} from './model'
import {Category, getAllCategory, getCetagoryById} from '../category'
import {Folder} from '../folder'
import {Space} from '../../common-components/Space'
import {
    createKnowledge,
    deleteKnowledgeById,
    deleteKnowledgeByTicketNumber,
    getKnowledgeById,
    getTicketByTicketNumber
} from './service'
import {statusColor} from '../util'
import MenuDropDown from './MenuDropDown'
import CommentList from './comments/CommentList'
import {likeComment, LikeType} from './comments'
import {UploadLink} from './uploads'
import {UploadFile} from 'antd/lib/upload/interface'
import 'react-quill/dist/quill.snow.css'
import {message} from 'antd/es'
import {connect} from 'react-redux'
import {StoreState} from '../../store'
import exportIcon from '../../common-file/icon-file'
import {decryptDataVspace} from '../../common-misc'
import {assignmentGroups, getAssignmentUserWithRole} from '../../msp-wo/wo-management'
import {checkRolesState, RoleType} from '../../authorization-module/permissions'
import {getKmMapRole} from '../../authorization-module/role-and-permissions/service'
import KnowledgeHistory from './KnowledgeHistory'

import {FormComponentProps} from 'antd/es/form'

const { SubMenu } = Menu
const { Panel } = Collapse

const mapStateToProps = (state: StoreState) => {
    return {
        supportTeam: state.supportTeams,
        permission: state.currentUserRole,
        kmMapRole: state.kmMapRole
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCategory: () => Promise<number>
    deleteKnowledgeByTicketNumber: (ticketNumber: string) => Promise<number>
    deleteKnowledgeById: (ticketId: number) => Promise<number>
    getKmMapRole: (email: string) => Promise<number>
}
interface Params {
    id: string
}

type Props = StateProps & DispatchProps & FormComponentProps & Params
const { confirm } = Modal
let isCanCheckOut: boolean = false
let redirectPath: string = ''
const KnowledgePreview: React.FC<Props> = (props: Props) => {
    const bodyRef = useRef<HTMLSpanElement>(null)
    const [knowledge, setKnowledge] = useState<Knowledge>(defaultKnowledge)
    const [bodyHTML, setBodyHTML] = useState('')
    const [category, setCategory] = useState<Category>()
    const [folder, setFolder] = useState<Folder>()
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [roleVspace, setRoleVspace] = useState<string>()
    const [dataRoleVspace, setDataRoleVspace] = useState<assignmentGroups>()
    const [isCanEditKM, setIsCanEditKM] = useState<boolean>(false)
    const { id } = useParams<Params>()
    const [isRedirect, setIsRedirect] = useState<boolean>(false)
    const username = localStorage.getItem('username')
    const pathname = window.location.pathname

    useEffect(() => {
        let role: string | undefined
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            role = dataVspace.roleId.toString()
            setRoleVspace(role)
            fetchAssignmentUserEngineer(role)
            if (props.kmMapRole) {
                props.getKmMapRole(dataVspace.email)
            }
        }
        props.getKmMapRole(username || '')
    }, [])

    useEffect(() => {
        if (props.kmMapRole) {
            getKnowledge()
        }
    }, [props.kmMapRole])

    useEffect(() => {
        if (category) {
            const folder = category.folderList?.find((folder) => {
                return folder.id === knowledge.folderId
            })
            setFolder(folder)
        }
    }, [category, knowledge])

    useEffect(() => {
        if (knowledge.ticketNumber) {
            getTicketByTicketNumber(knowledge.ticketNumber).then((res) => {
                if (res.length <= 1) {
                    isCanCheckOut = true
                } else {
                    const filterStatusDisableCheckOut = res.filter(it => it.status === KnowledgeStatus.Draft || it.status === KnowledgeStatus.AwaitingApproval || it.status === KnowledgeStatus.Rejected)
                    isCanCheckOut = filterStatusDisableCheckOut.length < 1;
                }
            })
        }
    }, [knowledge])

    useEffect(() => {
        if (bodyRef.current) {
            bodyRef.current.innerHTML = bodyHTML
        }
    }, [bodyRef.current, bodyHTML])

    useEffect(() => {
        if (category) {
            const filterFolder = category?.folderList?.find(res => res.id === knowledge.folderId)
            setIsCanEditKM(checkPermissionEdit(filterFolder!!))
        }
    }, [category, dataRoleVspace])

    const getKnowledge = () => {
        getKnowledgeById(id).then(knowledge => {
            if (knowledge) {
                setKnowledge(knowledge)
                setBodyHTML(knowledge.detail || '')
                if (knowledge.categoryId) {
                    getCetagoryById(knowledge.categoryId, props.kmMapRole).then((category) => {
                        setCategory(category)
                    }).catch(e => {
                        message.error(e.message)
                    })
                }
            }
        })
    }
    const checkPermissionEdit = (folder: Folder): boolean => {
        if (!checkRolesState(RoleType.Knowledge, 'UpdateKnowledge')) {
            return true
        }
        if (folder?.managedBy.length !== 0 && folder) {
            // return !folder?.managedBy.some(it => it.groupName === dataRoleVspace?.name!!)
            return !folder?.managedBy?.some(it => props.kmMapRole.includes(it.groupName) || knowledge.createdBy === username)
        } else {
            return false
        }
    }

    const fetchAssignmentUserEngineer = (role: string) => {
        getAssignmentUserWithRole(role).then((res) => {
            (res || []).map((data) => {
                if (role === data.roleId.toString()) {
                    setDataRoleVspace(data)
                }
                return { id: data.name, value: data.name }
            })
        }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
    }

    const fileListView = () => {
        const fileList = knowledge.uploadLink?.map((file: UploadLink) => {
            const uploadFile: UploadFile = {
                uid: file.name,
                name: file.name,
                status: 'done',
                url: file.url,
                type: file.type,
                thumbUrl: exportIcon(file.type!!),
                size: 0
            }
            return uploadFile
        })
        return (<Upload
            listType="picture"
            className={'upload-list-inline'}
            fileList={fileList}
            showUploadList={{ showRemoveIcon: false }}
            onPreview={(e: any) => handlePreview(e)}
        />)
    }

    const handleHelpful = (isHelpFul: boolean) => {
        const value = isHelpFul ? 1 : 0
        likeComment({
            type: LikeType.Knowledge,
            idOfType: id,
            username: username || '',
            value: value,
            lastModifiedBy: username || '',
            createdBy: username || ''
        }).then(() => {
            getKnowledge()
        })
    }

    // const handlePreview = async (file: UploadFile<any>) => {
    //     setPreviewImage(file.url || file.preview)
    //     setPreviewVisible(true)
    // }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
            setPreviewVisible(true)
        } else {
            setPreviewVisible(false)
        }
        setPreviewImage(file.url || file.preview)
    }

    const handleCancel = () => setPreviewVisible(false)

    const reDirect = (path: string) => {
        redirectPath = path
        setIsRedirect(true)
    }

    const deleteKM = (ticketNumber, status, ticketId) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete of this data ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                // รอ delete km
                if (status === KnowledgeStatus.Retired) {
                    console.log(ticketNumber)
                    props.deleteKnowledgeByTicketNumber(ticketNumber).then(() => {
                        message.success('Delete Knowledge Success')
                        reDirect('/Knowledge')
                    }).catch(() => {
                        message.error('Failed to delete Knowledge')
                    })
                } else {
                    props.deleteKnowledgeById(ticketId).then(() => {
                        message.success('Delete Knowledge Success')
                        reDirect('/Knowledge')
                    }).catch(() => {
                        message.error('Failed to delete Knowledge')
                    })
                }
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const cloneKnowledge = (knowledge: Knowledge) => {
        createKnowledge(knowledge).then((res) => {
            if (res.id) {
                window.location.href = '/KnowledgePreview/' + res.id
            }
        }).catch((error) => {
            message.error(error.toString())
        })
    }

    // @ts-ignore
    return (
        <Row gutter={20}>
            {isRedirect ? <Redirect to={redirectPath} /> : null}

            <Col>
                <Breadcrumb separator=">">
                    {pathname.includes('/ApprovalPreview')
                        ? <Breadcrumb.Item><Link to='/ApprovalCenter'>Approval Center</Link></Breadcrumb.Item>
                        : <Breadcrumb.Item><Link to='/Knowledge'>Knowledge</Link></Breadcrumb.Item>
                    }
                    <Breadcrumb.Item>{knowledge.ticketNumber || knowledge.topic}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Space size={20} />
            <Col span={18} sm={24} xs={24} md={18} lg={18}>
                <Card>
                    <Row gutter={8}>
                        <Col span={24} style={{float: 'right'}}>
                            <MenuDropDown knowledge={knowledge}
                                folder={folder!!}
                                isCanEditKM={isCanEditKM}
                                redirectFunction={reDirect}
                                deleteKM={deleteKM}
                                cloneKnowledge={cloneKnowledge}
                                isCanCheckOut={isCanCheckOut}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} >
                            <span className='km-header'>{knowledge.ticketNumber} {knowledge.topic}</span>
                        </Col>
                    </Row>
                    <Row>
                        <span className='ql-editor'><span ref={bodyRef} /></span>
                    </Row>
                    <Row>
                        {fileListView()}
                        <Modal visible={previewVisible} footer={null} onCancel={() => handleCancel()} >
                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </Row>
                    <span key="comment-basic-like" style={{ marginRight: 20 }}>
                        <Tooltip title="Helpful">
                            <Icon
                                type="like"
                                theme={knowledge.liked === 1 ? 'filled' : 'outlined'}
                                onClick={() => { handleHelpful(true) }}
                            />
                        </Tooltip>
                        <span style={{ paddingLeft: 8, cursor: 'auto' }}>{knowledge.helpful ? knowledge.helpful : 0}</span>
                    </span>
                    <span key="comment-basic-dislike">
                        <Tooltip title="Not Helpful">
                            <Icon
                                type="dislike"
                                theme={knowledge.liked === 0 ? 'filled' : 'outlined'}
                                onClick={() => { handleHelpful(false) }}
                            />
                        </Tooltip>
                        <span style={{ paddingLeft: 8, cursor: 'auto' }}>{knowledge.notHelpful ? knowledge.notHelpful : 0}</span>
                    </span>
                    <div style={{margin: 15}}>
                        <Collapse>
                            <Panel header="Knowledge History" key="1">
                                <KnowledgeHistory Knowledge={knowledge}/>
                            </Panel>
                        </Collapse>
                    </div>
                    {(knowledge.disableComment || knowledge.status !== KnowledgeStatus.Published) ? null : <CommentList />}
                </Card>
            </Col>
            <Col span={6} sm={24} xs={24} md={6} lg={6}>
                <Card>
                    <Tag color={statusColor(knowledge.status || '')} style={{ marginLeft: 10 }}>{knowledge.status}</Tag>
                    {knowledge.status === KnowledgeStatus.Rejected ? (<Tooltip title={knowledge.rejectReason}><Tag>...</Tag></Tooltip>) : ''}
                    <Space size={10} />
                    <Col>Version :  {(typeof (knowledge?.major) === 'number' || knowledge?.major === null) && typeof (knowledge?.minor) === 'number' ? <>
                        {knowledge.major || '0'}.{knowledge.minor < 10 ? '0' + knowledge.minor : knowledge.minor}
                    </> : '-'}</Col>
                    <Space size={10} />
                    <Col>Last modified by : <Tooltip title={knowledge.lastModifiedBy}>{knowledge.lastModifiedByDisplay || knowledge.lastModifiedBy}</Tooltip></Col>
                    <Col>on : {moment(knowledge.lastModifiedDate).format('YYYY-MM-DD HH:mm:ss')}</Col>
                    <Space size={10} />
                    <Col>
                        <Menu
                            mode="inline"
                            // style={{ minWidth: 256 }}
                            selectable={false}
                            className="km-category-list"
                        >
                            <SubMenu key="sub1" title='Knowledge Properties'>
                                <Menu.ItemGroup key="1" title='Category' className='km-font-menu'>
                                    <Menu.Item>
                                        <Tooltip placement="bottom" title={category?.categoryName || '-'}>
                                            <span>{category?.categoryName || '-'}</span>
                                        </Tooltip>
                                    </Menu.Item>
                                </Menu.ItemGroup>
                                <Menu.ItemGroup key="2" title='Folder'>
                                    <Menu.Item>
                                        <Tooltip placement="bottom" title={folder?.folderName || '-'}>
                                            <span>{folder?.folderName || '-'}</span>
                                        </Tooltip>
                                    </Menu.Item>
                                </Menu.ItemGroup>
                                <Menu.ItemGroup key="3" title='Author'>
                                    <Menu.Item>
                                        <Tooltip placement="bottom" title={knowledge.createdBy || '-'}>
                                            <span>{knowledge.createdBy || '-'}</span>
                                        </Tooltip>
                                    </Menu.Item>
                                </Menu.ItemGroup>
                                <Menu.ItemGroup key="4" title='Type'>
                                    <Menu.Item>
                                        <Tooltip placement="bottom" title={dropdownDataType.getValueById(knowledge.type) || '-'}>
                                            <span>{dropdownDataType.getValueById(knowledge.type) || '-'}</span>
                                        </Tooltip>
                                    </Menu.Item>
                                </Menu.ItemGroup>
                                <Menu.ItemGroup key="5" title='Tags'>
                                    <Menu.Item>
                                        <Tooltip placement="bottom" title={knowledge.tagsList && knowledge.tagsList.length > 0 ? knowledge.tagsList?.map((tag, index) => (<span key={index}>{tag.tag}</span>)) : '-'}>
                                            <span>{knowledge.tagsList && knowledge.tagsList.length > 0 ? knowledge.tagsList?.map((tag, index) => (<Tag key={index}>{tag.tag.length > 20 ? `${tag.tag.substring(0, 20)}...` : tag.tag}</Tag>)) : '-'}</span>
                                        </Tooltip>
                                    </Menu.Item>
                                </Menu.ItemGroup>
                            </SubMenu>
                            <SubMenu key="sub2" title='Analytic'>
                                <Menu.ItemGroup key="1" title='Helpful'>
                                    <Menu.Item>{knowledge.helpful || '-'}</Menu.Item>
                                </Menu.ItemGroup>
                                <Menu.ItemGroup key="2" title='Not Helpful'>
                                    <Menu.Item>{knowledge.notHelpful || '-'}</Menu.Item>
                                </Menu.ItemGroup>
                                <Menu.ItemGroup key="3" title='Views'>
                                    <Menu.Item>{knowledge.views || '-'}</Menu.Item>
                                </Menu.ItemGroup>
                                <Menu.ItemGroup key="4" title='Used'>
                                    <Menu.Item>{knowledge.used || '-'}</Menu.Item>
                                </Menu.ItemGroup>
                            </SubMenu>
                        </Menu>
                    </Col>
                </Card>
            </Col>
        </Row>
    )
}

export default connect(mapStateToProps, { getAllCategory, getKmMapRole, deleteKnowledgeByTicketNumber, deleteKnowledgeById })(KnowledgePreview)
