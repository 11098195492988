import React, {useEffect, useState} from 'react'
import {AutoComplete, Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Upload} from 'antd'
import {FormComponentProps} from 'antd/lib/form'
import {UploadLink} from '../../file-server-storage/model'
import {UploadFile} from 'antd/lib/upload/interface'
import moment from 'moment'
import exportIcon from '../../common-file/icon-file'
import {
    AssetOwnerLocation,
    findByAssetId,
    findPreventiveHistoriesBySerialNo,
    getAssetFilter,
    getChangeHistoryBySerialNo,
    getTicketAffectedAssetBySerialNo,
    searchFetch,
    status,
    TicketAffectedAsset,
    TicketChangeHistory,
    updateAssetOwnerLocation
} from '../asset-management'
import {StoreState} from '../../store'
import {connect} from 'react-redux'
import {PMHistory} from './model'
import TablePreventiveHistory from './TablePreventiveHistory'
import {decryptDataVspace} from '../../common-misc'

interface Param {
    assetOwnerLocation: AssetOwnerLocation,
    isEdit: boolean
    getDataEdit: Function
    setIsEdit: Function
}

const mapStateToProps = (state: StoreState) => {
    return {
        filterAsset: state.filterAsset
    }
}

interface DispatchProps {
    getAssetFilter: () => Promise<number>
}

type StateProps = ReturnType<typeof mapStateToProps>

const { Option } = Select

type Props = Param & FormComponentProps & DispatchProps & StateProps

const AssetPreventive: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue } = props.form
    const [isLoading, setIsLoading] = useState<boolean>()
    const [isEdit, setIsEdit] = useState<boolean>(props.isEdit)
    const [vSpaceName, setVspaceName] = useState<string>('')
    const [assetOwnerLocation, setAssetOwnerLocation] = useState<AssetOwnerLocation>(props.assetOwnerLocation)
    // const [fileList, setFileList] = useState<UploadFile[]>()
    // const [loading, setLoading] = useState<boolean>(false)
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [ticketAffectedAsset, setTicketAffectedAsset] = useState<TicketAffectedAsset[]>([])
    const [ticketChangeHistory, setTicketChangeHistory] = useState<TicketChangeHistory[]>([])
    const [location, setLocation] = useState<string[]>(props.filterAsset.location?.slice(0, 20) || [])
    const [employeeID, setEmployeeID] = useState<string[]>(props.filterAsset.employeeID?.slice(0, 20) || [])
    const [historiesPreventive, setHistoriesPreventive] = useState<PMHistory[]>([])
    const [serialNo, setSerialNo] = useState<string>(props.assetOwnerLocation.serialNo!!)
    const [id, setID] = useState<string>(props.assetOwnerLocation.id!!)

    useEffect(() => {
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            const email = dataVspace.email
            setVspaceName(email)
        }
        loadFilter()
    }, [])

    useEffect(() => {
        setIsEdit(props.isEdit)
    }, [props.isEdit])

    useEffect(() => {
        if (props.assetOwnerLocation) {
            setFieldsValue({
                Serial_No: props.assetOwnerLocation?.serialNo,
                Kbank_Barcode: props.assetOwnerLocation?.kbankBarcode,
                Device_Type: props.assetOwnerLocation?.deviceType,
                Computer_Name: props.assetOwnerLocation?.computerName,
                Brand: props.assetOwnerLocation?.brand,
                Model: props.assetOwnerLocation?.model,
                Employee_ID: props.assetOwnerLocation?.employeeId,
                Location: props.assetOwnerLocation?.location,
                Asset_Status: props.assetOwnerLocation?.status,
                Dept_Name: props.assetOwnerLocation?.deptName,
                City: props.assetOwnerLocation?.city,
                Zone: props.assetOwnerLocation?.zone,
                Monitor_Brand: props.assetOwnerLocation?.monitorBrand,
                Monitor_Serial: props.assetOwnerLocation?.monitorSerial,
                Monitor_Type: props.assetOwnerLocation?.monitorType,
                Company: props.assetOwnerLocation?.company,
                Country: props.assetOwnerLocation?.country,
                Regional: props.assetOwnerLocation?.regional,
                Group: props.assetOwnerLocation?.group,
                Phone: props.assetOwnerLocation?.phone,
                PO_Number: props.assetOwnerLocation?.poNumber,
                Date_in_service: props.assetOwnerLocation?.dateInService ? moment(props.assetOwnerLocation?.dateInService) : null,
                Expiry_date: props.assetOwnerLocation?.expiryDate ? moment(props.assetOwnerLocation?.expiryDate) : null,
                Invoice_date: props.assetOwnerLocation?.invoiceDate ? moment(props.assetOwnerLocation?.invoiceDate) : null,
                Description: props.assetOwnerLocation?.description
            })
            getHistoriesPreventive(props.assetOwnerLocation?.serialNo)
        }
    }, [assetOwnerLocation, props.assetOwnerLocation])

    const getHistoriesPreventive = (serialNo) => {
        findPreventiveHistoriesBySerialNo(serialNo).then((res) => {
            setHistoriesPreventive(res)
        }).catch((err) => {
            message.error(err)
        })
    }

    const loadFilter = async () => {
        if (Object.keys(props.filterAsset).length === 0) {
            props.getAssetFilter().then(() => {
                setEmployeeID(props.filterAsset.employeeID?.slice(0, 20) || [])
                setLocation(props.filterAsset.location?.slice(0, 20) || [])
                console.log('load filter success')
            })
        }
    }

    const fileListView = () => {
        if (props.assetOwnerLocation?.attachFile) {
            const attachFile = JSON.parse(props.assetOwnerLocation?.attachFile)
            const fileList = attachFile?.map((file: UploadLink) => {
                const uploadFile: UploadFile = {
                    uid: file.name,
                    name: file.name,
                    status: 'done',
                    url: file.url,
                    type: file.type,
                    thumbUrl: exportIcon(file.type!!),
                    size: 0
                }
                return uploadFile
            })
            return (<div><Upload
                listType="picture-card"
                fileList={fileList}
                showUploadList={{ showRemoveIcon: false }}
                onPreview={(e: any) => handlePreview(e)}
                style={{ height: '200 px', width: '200 px' }}
            />
            <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)} >
                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
            </Modal>
            </div>)
        }
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
            setPreviewVisible(true)
        } else {
            setPreviewVisible(false)
        }
        setPreviewImage(file.url || file.preview)
    }

    // const searchFetch = (value: SelectValue, allData: string[], setState: Function) => {
    //     if (typeof value === 'string' && value.length >= 3) {
    //         const filterData = allData.filter((data: string) => data.toUpperCase().includes(value.toUpperCase()))
    //         if (filterData.length > 0) {
    //             if (filterData.length < 20) {
    //                 setState(filterData)
    //             } else {
    //                 setState(filterData.slice(0, 20))
    //             }
    //         }
    //     } else {
    //         setState(allData.slice(0, 20))
    //     }
    // }

    const customInput = (name: string,
        editable: boolean = false,
        autoCompleteData: string[] = [],
        fullData: string[] = [],
        required: boolean = false,
        setState?: Function,
        maxInput?: number) => {
        return (
            <Form.Item>
                <Col md={24}>
                    <div style={{ marginRight: 8, marginTop: 4 }} className={required ? 'ant-form-item-required' : ''}>
                        {name}
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator(replaceName(name), {
                        rules: [
                            { required: required, message: `${name} is required` },
                            { max: maxInput, message: `${name} max ${maxInput} characters` }
                        ]
                    })(
                        autoCompleteData.length > 0 ? (
                            <AutoComplete
                                dataSource={autoCompleteData}
                                placeholder={`${name}`}
                                id={`${name}`}
                                onChange={(value) => { searchFetch(value, fullData || [], setState!!) }}
                                disabled={!editable}
                            />
                        ) : (
                            <Input placeholder={`${name}`} disabled={!editable} maxLength={maxInput}/>
                        )
                    )}
                </Col>
            </Form.Item>
        )
    }

    const inputDate = (name: string) => {
        return (
            <Form.Item>
                <Col md={24}>
                    <div style={{ marginRight: 8, marginTop: 4 }}>
                        {name}
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator(replaceName(name))(
                        <DatePicker placeholder={`${name}`} style={{ width: '100%' }} disabled={true} />
                    )}
                </Col>
            </Form.Item>
        )
    }

    const customSelect = (name: string, editable: boolean = false, options: string[]) => {
        return (
            <Form.Item>
                <Col md={24}>
                    <div style={{ marginRight: 8, marginTop: 4 }}>
                        {name}
                    </div>
                </Col>
                <Col md={24}>
                    {getFieldDecorator(replaceName(name), {
                        initialValue: options[5]
                    })(
                        <Select
                            placeholder='select status'
                            style={{ width: '100%' }}
                            disabled={!editable}
                        >{createOption(options)}
                        </Select>
                    )}
                </Col>
            </Form.Item>
        )
    }

    const createOption = (dataSource: string[]) => {
        return dataSource.map((data) => {
            return (<Option key={data}>{data}</Option>)
        })
    }

    const replaceName = (name: string) => {
        return name.replace(/ /g, '_')
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        props.form.validateFields(async (_err: any, values: any) => {
            if (!_err) {
                setIsLoading(true)
                const newAssetOwnerLocation: AssetOwnerLocation = {
                    ...assetOwnerLocation,
                    employeeId: values.Employee_ID,
                    location: values.Location,
                    status: values.Asset_Status,
                    lastModifiedBy: vSpaceName
                }
                updateAssetOwnerLocation(assetOwnerLocation?.id!!, newAssetOwnerLocation).then(() => {
                    getTicketAffectedAssetBySerialNo(serialNo).then((result) => {
                        if (result) {
                            setTicketAffectedAsset(result)
                        }
                    })
                    getChangeHistoryBySerialNo(serialNo).then((result) => {
                        if (result) {
                            setTicketChangeHistory(result)
                        }
                    })
                    message.success('The update has finished successfully.')
                    setIsLoading(false)
                    setIsEdit(!isEdit)
                    props.setIsEdit(!props.isEdit)
                }).catch((err) => {
                    message.error(`You have unSuccessfully save the data. ${err}`)
                    setIsLoading(false)
                    setIsEdit(!isEdit)
                    props.setIsEdit(!props.isEdit)
                })
            }
        })
    }

    const fetchData = () => {
        findByAssetId(id).then((result) => {
            setAssetOwnerLocation(result)
        })
    }

    const getDataEdit = (data) => {
        props.getDataEdit(data)
    }

    const handleCancel = () => {
        setIsEdit(!isEdit)
        props.setIsEdit(!props.isEdit)
        setFieldsValue({
            Employee_ID: assetOwnerLocation.employeeId,
            Location: assetOwnerLocation.location,
            Asset_Status: assetOwnerLocation.status
        })
    }

    return (
        <div>
            <Row gutter={10} style={{ padding: 24 }}>
                <Col span={24}>
                    {fileListView()}
                </Col>
                <Form onSubmit={handleSubmit}>
                    <Col sm={24} md={12} xl={8} style={{ marginTop: 10 }}>
                        {customInput('Serial No')}
                        {customInput('Kbank Barcode')}
                        {customInput('Device Type')}
                        {customInput('Computer Name')}
                        {customInput('Brand')}
                        {customInput('Model')}
                        {customInput('Employee ID', isEdit, employeeID, props.filterAsset.employeeID, true, setEmployeeID, 15)}
                        {customInput('Location', isEdit, location, props.filterAsset.location, true, setLocation, 255)}
                        {customSelect('Asset_Status', isEdit, status)}

                    </Col>
                    <Col sm={24} md={12} xl={8} style={{ marginTop: 10 }}>
                        {customInput('Dept Name')}
                        {customInput('City')}
                        {customInput('Zone')}
                        {customInput('Monitor Brand')}
                        {customInput('Monitor Serial')}
                        {customInput('Monitor Type')}
                        {customInput('Company')}
                        {customInput('Country')}
                        {customInput('Regional')}
                    </Col>
                    <Col sm={24} md={12} xl={8} style={{ marginTop: 10 }}>
                        {customInput('PO Number')}
                        {customInput('Group')}
                        {customInput('Phone')}
                        {inputDate('Date in service')}
                        {inputDate('Expiry date')}
                        {inputDate('Invoice date')}
                        {customInput('Description')}
                    </Col>
                    <Col span={24} style={{ padding: 30, backgroundColor: '#F2F2F2' }}>
                        <Col sm={24} md={12} xl={8}>
                            <Col span={24}>
                                <span style={{ color: '#828282' }}>Data origin : </span>
                                <span style={{ color: '#4f4f4f' }}>{props.assetOwnerLocation?.dataOrigin}</span>
                            </Col>
                        </Col>
                        <Col sm={24} md={12} xl={8}>
                            <Col span={24}>
                                <span style={{ color: '#828282' }}>Created by : </span>
                                <span style={{ color: '#4f4f4f' }}>{props.assetOwnerLocation?.createdBy}</span>
                            </Col>
                            <Col span={24}>
                                <span style={{ color: '#828282' }}>Created datetime : </span>
                                <span style={{ color: '#4f4f4f' }}>{props.assetOwnerLocation?.createdDatetime}</span>
                            </Col>
                        </Col>
                        <Col sm={24} md={12} xl={8}>
                            <Col span={24}>
                                <span style={{ color: '#828282' }}>Last modified by : </span>
                                <span style={{ color: '#4f4f4f' }}>{props.assetOwnerLocation?.lastModifiedBy}</span>
                            </Col>
                            <Col span={24}>
                                <span style={{ color: '#828282' }}>Last modified datetime : </span>
                                <span style={{ color: '#4f4f4f' }}>{props.assetOwnerLocation?.lastModifiedDatetime}</span>
                            </Col>
                        </Col>
                    </Col>

                    {historiesPreventive.length > 0 ? <Col span={24}>
                        <TablePreventiveHistory dataHistory={historiesPreventive} getDataEdit={getDataEdit}/>
                    </Col> : null}
                    {
                        isEdit ? (
                            <Col span={24} style={{ marginTop: 10 }}>
                                <Button type="primary" htmlType="submit" loading={isLoading} style={{ float: 'right', marginLeft: 15 }} >Submit</Button>
                                <Button style={{ float: 'right' }} onClick={() => handleCancel()}>Cancel</Button>
                            </Col>
                        ) : null
                    }
                </Form>
            </Row>
        </div>
    )
}

const AssetPreventiveWithFrom = Form.create<Props>({})(AssetPreventive)

export default connect(
    mapStateToProps,
    { getAssetFilter }
)(AssetPreventiveWithFrom)
