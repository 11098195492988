/* eslint-disable no-unused-expressions */
/* eslint-disable no-prototype-builtins */
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {StoreState} from '../../store'

import {
    Breadcrumb,
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Icon,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Tabs,
    Tooltip
} from 'antd'
import {FormComponentProps} from 'antd/lib/form'

import {PermissionsAndScope, RoleAndPermissions} from './model'
import {
    createRoleAndPermissions,
    getAllRoleAndPermissions,
    getAllRoleAndPermissionsOptions,
    getRoleAndPermissionsById,
    updateRoleAndPermissions
} from './service'
import {getAllSupportTeam} from '../../incident-management/support-team/service'
import {Link, Redirect, useParams} from 'react-router-dom'
import {CheckboxChangeEvent} from 'antd/es/checkbox'

const mapStateToProps = (state: StoreState) => {
    return {
        roleAndPermissionsOptions: state.roleAndPermissionsOptions,
        roleAndPermissions: state.roleAndPermissions,
        supportTeams: state.supportTeams
    }
}

interface DispatchProps {
    getAllRoleAndPermissionsOptions: () => Promise<number>
    getRoleAndPermissionsById: (id: string) => Promise<number>
    getAllSupportTeam: () => Promise<number>
    getAllRoleAndPermissions: () => Promise<number>

}

type StateProps = ReturnType<typeof mapStateToProps>

interface OwnProps {
    editData?: any
    getAllRoleAndPermissionsOptions: () => void
}

type Props = StateProps & OwnProps & FormComponentProps & DispatchProps

interface TabsContent {
    title: string
    content: JSX.Element[]
    key: string
}

const { confirm } = Modal
const { TabPane } = Tabs
const { Option } = Select

const RoleAndPermissionsFrom: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, getFieldValue, resetFields, validateFields, setFields } = props.form

    const { id } = useParams()

    const [roleAndPermissions, setRoleAndPermissions] = useState<RoleAndPermissions>()
    const [selectedGroup, setSelectedGroup] = useState<String[]>([])
    const [selectedGroupName, setSelectedGroupName] = useState<String[]>([])
    const [preview, setPreview] = useState<boolean>(false)
    const isEdit: boolean = window.location.pathname.includes('RoleAndPermissionsSettingEditForm')
    const [isRedirect, setIsRedirect] = useState(false)
    const [defaultRoleAndPermissionName, setDefaultRoleAndPermissionName] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const pathname = window.location.pathname
        props.getAllRoleAndPermissions().catch((err) => message.error(`Failed getting all role and permissions. ${err}`))
        if (pathname.includes('/RoleAndPermissionsSettingPreview')) {
            setPreview(true)
        }
        if (id) {
            // console.log('Have Data')
            // console.log(id)
            initRoleAndPermissionsOptions()
            initRoleAndPermissionsById(id)
        } else {
            // console.log('clean data')
            cleanData()
            initRoleAndPermissionsOptions()
            props.getAllSupportTeam()
        }
    }, [])

    useEffect(() => {
        const groups: string[] = []
        if (props.supportTeams !== [] && props.supportTeams) {
            props.supportTeams.map((supportTeam) => {
                selectedGroup.map((group) => {
                    if (group === supportTeam.id?.toString()) {
                        groups.push(supportTeam.name)
                    }
                })
            })
        }
        setSelectedGroupName(groups || selectedGroup)
    }, [props.supportTeams, selectedGroup])

    useEffect(() => {
        if (roleAndPermissions) {
            // console.log(roleAndPermissions, '<<<THis Here')
            setDefaultRoleAndPermissionName(roleAndPermissions.name!!)
            setFieldsValue({ RoleName: roleAndPermissions.name })
            setFieldsValue({ Description: roleAndPermissions.description })
            setFieldsValue({ Scope: roleAndPermissions.incident?.scope })
            const groups = roleAndPermissions.selectedGroup?.split(',')
            if (groups) {
                setSelectedGroup(groups || [groups])
            }
            // Set Incident
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'CreateIncident')) {
                setFieldsValue({ CreateIncident: true })
            }
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'UpdateIncident')) {
                setFieldsValue({ UpdateIncident: true })
            }
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'DeleteIncident')) {
                setFieldsValue({ DeleteIncident: true })
            }
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'ViewIncident')) {
                setFieldsValue({ ViewIncident: true })
            }
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'CloseIncident')) {
                setFieldsValue({ CloseIncident: true })
            }

            // Set Security
            if (roleAndPermissions.security?.permissions?.find(rp => rp === 'ManageRoleAndPermission')) {
                setFieldsValue({ ManageRoleAndPermission: true })
            }
            if (roleAndPermissions.security?.permissions?.find(rp => rp === 'ManageUserRole')) {
                setFieldsValue({ ManageUserRole: true })
            }

            // Set SlaPolicy
            if (roleAndPermissions.slaPolicy?.permissions?.find(rp => rp === 'ManageSlaGlobalSettings')) {
                setFieldsValue({ ManageSlaGlobalSettings: true })
            }
            if (roleAndPermissions.slaPolicy?.permissions?.find(rp => rp === 'ManageBusinessHours')) {
                setFieldsValue({ ManageBusinessHours: true })
            }
            if (roleAndPermissions.slaPolicy?.permissions?.find(rp => rp === 'ManageSlaPolicy')) {
                setFieldsValue({ ManageSlaPolicy: true })
            }

            // Set Knowledge
            if (roleAndPermissions.knowledge?.permissions?.find(rp => rp === 'CreateKnowledge')) {
                setFieldsValue({ CreateKnowledge: true })
            }
            if (roleAndPermissions.knowledge?.permissions?.find(rp => rp === 'UpdateKnowledge')) {
                setFieldsValue({ UpdateKnowledge: true })
            }
            if (roleAndPermissions.knowledge?.permissions?.find(rp => rp === 'DeleteKnowledge')) {
                setFieldsValue({ DeleteKnowledge: true })
            }
            if (roleAndPermissions.knowledge?.permissions?.find(rp => rp === 'ViewKnowledge')) {
                setFieldsValue({ ViewKnowledge: true })
            }
            if (roleAndPermissions.knowledge?.permissions?.find(rp => rp === 'ApproveOrRejectKnowledge')) {
                setFieldsValue({ ApproveOrRejectKnowledge: true })
            }
        }
    }, [roleAndPermissions])

    const initRoleAndPermissionsById = (id: string) => {
        getRoleAndPermissionsById(Number(id)).then((role) => {
            setRoleAndPermissions(role)
        })
    }

    const initRoleAndPermissionsOptions = () => {
        props.getAllRoleAndPermissionsOptions()
    }

    const cleanData = () => {
        resetFields()
    }

    const submitFrom = () => {
        validateFields(
            (error, value) => {
                if (error) {
                    console.log(error)
                    return
                }
                setIsLoading(true)
                // console.log(value)
                const security: PermissionsAndScope = {
                    permissions: [],
                    scope: 'GlobalAccess'
                }
                const incident: PermissionsAndScope = {
                    permissions: [],
                    scope: getFieldValue('Scope')
                }
                const knowledge: PermissionsAndScope = {
                    permissions: [],
                    scope: 'GlobalAccess'
                }
                const slaPolicy: PermissionsAndScope = {
                    permissions: [],
                    scope: 'GlobalAccess'
                }

                // Security value
                if (value.ManageRoleAndPermission === true) {
                    security.permissions?.push('ManageRoleAndPermission')
                }
                if (value.ManageUserRole) {
                    security.permissions?.push('ManageUserRole')
                }

                // Incident value
                if (value.CreateIncident === true) {
                    incident.permissions?.push('CreateIncident')
                }
                if (value.UpdateIncident) {
                    incident.permissions?.push('UpdateIncident')
                }
                if (value.DeleteIncident) {
                    incident.permissions?.push('DeleteIncident')
                }
                if (value.ViewIncident === true) {
                    incident.permissions?.push('ViewIncident')
                }
                if (value.CloseIncident) {
                    incident.permissions?.push('CloseIncident')
                }

                // Knowledge value
                if (value.CreateKnowledge === true) {
                    knowledge.permissions?.push('CreateKnowledge')
                }
                if (value.UpdateKnowledge) {
                    knowledge.permissions?.push('UpdateKnowledge')
                }
                if (value.DeleteKnowledge) {
                    knowledge.permissions?.push('DeleteKnowledge')
                }
                if (value.ViewKnowledge === true) {
                    knowledge.permissions?.push('ViewKnowledge')
                }
                if (value.ApproveOrRejectKnowledge === true) {
                    knowledge.permissions?.push('ApproveOrRejectKnowledge')
                }

                // SlaPolicy value
                if (value.ManageSlaGlobalSettings === true) {
                    slaPolicy.permissions?.push('ManageSlaGlobalSettings')
                }
                if (value.ManageBusinessHours === true) {
                    slaPolicy.permissions?.push('ManageBusinessHours')
                }
                if (value.ManageSlaPolicy === true) {
                    slaPolicy.permissions?.push('ManageSlaPolicy')
                }

                const groups: string[] = []
                if (value.supportTeam) {
                    props.supportTeams.map((supportTeam) => {
                        value.supportTeam.map((group) => {
                            if (group === supportTeam.name?.toString()) {
                                groups.push(supportTeam.id!!.toString())
                            }
                        })
                    })
                }

                const roleAndPermissions: RoleAndPermissions = {
                    name: getFieldValue('RoleName').trim(),
                    description: getFieldValue('Description'),
                    security: security,
                    incident: incident,
                    knowledge: knowledge,
                    slaPolicy: slaPolicy,
                    selectedGroup: groups.join(',')
                }
                if (id) {
                    setDefaultRoleAndPermissionName(getFieldValue('RoleName'))
                    updateRoleAndPermissions(roleAndPermissions, id).then(() => {
                        // console.log('update', response)
                        props.getAllRoleAndPermissionsOptions()
                        message.success('The Update has finished successfully.')
                        setIsRedirect(true)
                        setIsLoading(false)
                    }).catch((error) => {
                        console.log(error)
                        setIsLoading(false)
                        if (error.status === 409) {
                            setFields({ RoleName: { errors: [new Error(error.data.message)] } })
                        }
                        message.error(error.data.message)
                    })
                } else {
                    createRoleAndPermissions(roleAndPermissions).then(() => {
                        // console.log('Created', response)
                        props.getAllRoleAndPermissionsOptions()
                        message.success('You have Successfully save the data.')
                        setIsRedirect(true)
                        setIsLoading(false)
                    }).catch((error) => {
                        console.log(error)
                        setIsLoading(false)
                        if (error.status === 409) {
                            setFields({ RoleName: { errors: [new Error(error.data.message)] } })
                        }
                        message.error(error.data.message)
                    })
                }
            }
        )
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const contentOfTab = (contentData: string[]) => {
        return contentData.map((data) => {
            return (
                <Row style={{ marginLeft: 50, marginTop: 14 }} key={data}>
                    <Form.Item>{getFieldDecorator(data, {
                        valuePropName: 'checked'
                    })(
                        <Checkbox key={data} disabled={preview} onChange={(e) => onChangeCheckBox(e)}>{convertCamelCaseToSentenceCase(data)}</Checkbox>
                    )}</Form.Item>
                </Row>
            )
        })
    }

    const tabs = () => {
        const tabsContent: (TabsContent | undefined)[] = []
        if (props.roleAndPermissionsOptions) {
            for (const key in props.roleAndPermissionsOptions) {
                if (props.roleAndPermissionsOptions.hasOwnProperty(key)) {
                    const element = props.roleAndPermissionsOptions[key]
                    const content: TabsContent = {
                        title: key,
                        content: contentOfTab(element),
                        key: key
                    }
                    tabsContent.push(content)
                }
            }
        }
        return tabsContent || []
    }

    const convertCamelCaseToSentenceCase = (fieldValue: string) => {
        if (fieldValue) {
            // adding space between strings
            const result = fieldValue.replace(/([A-Z])/g, ' $1')

            // converting first character to uppercase and join it to the final string
            return result.charAt(0).toUpperCase() + result.slice(1).replace('Or',
                'or').replace('Sla', 'SLA')
        }
    }

    const onChangeCheckBox = (checkedValue: CheckboxChangeEvent) => {
        const obj = {}
        let incident = []
        let knowledge = []
        for (const key in props.roleAndPermissionsOptions) {
            if (key === 'Incident') incident = props.roleAndPermissionsOptions[key]
            if (key === 'Knowledge') knowledge = props.roleAndPermissionsOptions[key]
        }
        const itemsInc = ['CreateIncident', 'DeleteIncident', 'UpdateIncident', 'CloseIncident']
        if ((itemsInc.includes(checkedValue.target.id as string))) {
            setFieldsValue({ ViewIncident: true })
        }
        if (checkedValue.target.id === 'ViewIncident' && !checkedValue.target.checked) {
            incident.map((item) => {
                obj[item as string] = false
            })
            setFieldsValue(obj)
        }

        const itemsKm = ['CreateKnowledge', 'DeleteKnowledge', 'UpdateKnowledge']
        if ((itemsKm.includes(checkedValue.target.id as string))) {
            setFieldsValue({ ViewKnowledge: true })
        }
        if (checkedValue.target.id === 'ViewKnowledge' && !checkedValue.target.checked) {
            knowledge.map((item) => {
                obj[item as string] = false
            })
            setFieldsValue(obj)
        }
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        if (props.form.getFieldValue('RoleName')) {
            if (props.roleAndPermissions) {
                const roleAndPermissionsList = props.roleAndPermissions.map((value) => {
                    return value.name
                })
                if (isEdit && value.trim() === defaultRoleAndPermissionName) {
                    callback()
                } else if (roleAndPermissionsList.includes(value.trim())) {
                    callback('The Role Name is already exists. It must be unique. Please try another one.')
                } else {
                    callback()
                }
            }
        } else {
            callback()
        }
    }

    return (
        <>
            {isRedirect ? (<Redirect to="/RoleAndPermissionsSettingList" />) : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={'/RoleAndPermissionsSettingList'} >Role and Permission</Link>
                </Breadcrumb.Item>
                {preview ? (
                    <Breadcrumb.Item>View Role and Permission</Breadcrumb.Item>
                ) : (
                    isEdit ? (
                        <Breadcrumb.Item>Edit Role and Permission</Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item>New Role and Permission</Breadcrumb.Item>
                    )
                )}
            </Breadcrumb>
            <br />
            <Card className={'custom-card'}>
                <Form layout="vertical">
                    <Row>
                        <Col span={23}>
                            {preview ? (
                                <h2 className={'main-title'}>View Role and Permission</h2>
                            ) : (
                                isEdit ? (
                                    <h2 className={'main-title'}>Edit Role and Permission</h2>
                                ) : (
                                    <h2 className={'main-title'}>New Role and Permission</h2>
                                )
                            )}
                        </Col>
                        {preview ? (<Col span={1} >
                            <span style={{float: 'right', marginRight: '8px'}}>
                                <Tooltip placement="bottom" title={'Edit'} ><Link to={`/RoleAndPermissionsSettingEditForm/${id}`} ><Icon className="edit_icon" type="edit"/></Link></Tooltip>
                            </span>
                        </Col>
                        ) : null}
                    </Row>

                    <Row gutter={32}>
                        <Col span={10}>
                            <Form.Item label={'Role Name'}>
                                {getFieldDecorator('RoleName', {
                                    rules: [{ required: true, message: 'Role Name is required'},
                                        { min: 3, message: 'Enter more than 3 characters'},
                                        { validator: validateNameUniqueness }
                                    ]
                                })(
                                    <Input disabled={preview} placeholder="Role name" maxLength={250} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={14}>
                            <Form.Item label={'Description'}>
                                {getFieldDecorator('Description')(
                                    <Input disabled={preview} placeholder="Description" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20}}>
                        <h2 className={'sub-title'}>Permission and Access</h2>
                        <Tabs defaultActiveKey="1">
                            {tabs().map((pane) => {
                                if (pane) {
                                    return (
                                        <TabPane tab={convertCamelCaseToSentenceCase(pane?.title!!)} key={pane.key}>
                                            <h3 style={{ marginLeft: 30 }} className={'sub-title'}>Agent can</h3>
                                            {pane.content}
                                            {pane.title === 'Incident'
                                                ? <Row style={{ marginTop: 14 }}>
                                                    <p style={{ marginLeft: 30 }} className={'sub-title'}>Access</p>
                                                    <Form.Item style={{ marginLeft: 50, overflow: 'auto' }}>
                                                        {getFieldDecorator('Scope', {
                                                            initialValue: 'GlobalAccess'
                                                        })(
                                                            <Radio.Group disabled={preview}>
                                                                <Row style={{ marginTop: 14 }}>
                                                                    <Radio value="GlobalAccess">Global Access - Can access all Incidents</Radio>
                                                                </Row>
                                                                <Row style={{ marginTop: 14 }}>
                                                                    <Radio value="SelectedGroupAccess">Group Access and Selected Group Access -  Can access incidents in their Group(s) and their selected Group(s). Also, Incidents assigned to them.</Radio>
                                                                </Row>
                                                                <Row style={{marginLeft: 25, marginTop: 10}}>
                                                                    {getFieldValue('Scope') === 'SelectedGroupAccess'
                                                                        ? <Form.Item label={'Group Access'}>
                                                                            {getFieldDecorator('supportTeam', {
                                                                                rules: [
                                                                                    { required: true, message: 'Support Team is required', type: 'array' }
                                                                                ],
                                                                                initialValue: selectedGroupName
                                                                            })(
                                                                                <Select disabled={preview} mode="tags"
                                                                                    placeholder="Select a Group Access">
                                                                                    {props.supportTeams.map((supportTeam) => {
                                                                                        return (<Option key={supportTeam.name} value={supportTeam.name}>{supportTeam.name}</Option>)
                                                                                    })}
                                                                                </Select>
                                                                            )}
                                                                        </Form.Item>
                                                                        : null}
                                                                </Row>
                                                                <Row style={{ marginTop: 14 }}>
                                                                    <Radio value="GroupAccess">Group Access -  Can access Incidents in their Group(s) and Incidents assigned to them</Radio>
                                                                </Row>
                                                                <Row style={{ marginTop: 14 }}>
                                                                    <Radio value="RestrictedAccess">Restricted Access -  Can only access Incidents assigned to them</Radio>
                                                                </Row>
                                                            </Radio.Group>
                                                        )}
                                                    </Form.Item>
                                                </Row> : null
                                            }
                                        </TabPane>
                                    )
                                }
                            })}
                        </Tabs>
                    </Row>
                    <Row className={'custom-position-button'}>
                        <Col span={24}>
                            <Button disabled={preview} type="primary"
                                loading={isLoading}
                                htmlType="submit"
                                onClick={submitFrom}
                                style={{ float: 'right', marginRight: 10, marginBottom: '5px' }}>Submit</Button>
                            <Button onClick={leavePageModal} style={{ float: 'right', marginRight: 10 }}>Cancel</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

const MyRoleAndPermissionsFrom = Form.create<Props>()(RoleAndPermissionsFrom)

export default connect(mapStateToProps, { getAllRoleAndPermissionsOptions, getAllSupportTeam, getAllRoleAndPermissions })(MyRoleAndPermissionsFrom)
