import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'

import { StoreState } from '../../store'

import { Breadcrumb, Button, Checkbox, Col, Form, Input, message, Modal, Row, Select, Tag, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { defaultKnowledge, dropdownDataType } from './mock'
import { convertCamelCaseToSentenceCase, statusColor } from '../util'
import { Category } from '../category'
import { Folder, getFolderById } from '../folder'
import { Knowledge, KnowledgeList, KnowledgeStatus, MyUploadLink, TagsList } from './model'
import KnowledgeFormUpload from './uploads/KnowledgeFormUpload'
import { Space } from '../../common-components/Space'
import { Link, useParams } from 'react-router-dom'
import { UploadFile } from 'antd/lib/upload/interface'
import { createKnowledge, getKnowledgeById, updateKnowledge } from './service'
import { getAllCategory } from '../category/service'
import { UploadLink } from './uploads'
import FSS from '../../file-server-storage'
import { getAssignmentUserWithRole } from '../../msp-wo/wo-management/service'
import { assignmentGroups } from '../../msp-wo/wo-management/model'
import { decryptDataVspace } from '../../common-misc'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { getKmMapRole } from '../../authorization-module/role-and-permissions/service'

const { Option } = Select

interface Props extends FormComponentProps {
    categories: Category[]
    Knowledge: Knowledge
    getAllCategory: (groupUser?: string[]) => void
    kmMapRole: string[]
    getKmMapRole: (email: string) => Promise<number>
}

interface dropdownData {
    id: string
    value: string
}

enum FormType {
    Topic = 'Topic',
    Detail = 'Details',
    Category = 'Category',
    Folder = 'Folder',
    Type = 'Type',
    Tags = 'Tags',
}

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }, { color: [] }],
        ['link', 'image'],
        ['clean']
    ]
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'align', 'color',
    'link', 'image'
]

const KnowledgeForm: React.FC<Props> = (props: Props) => {
    const [knowledge, setKnowledge] = useState<Knowledge>(defaultKnowledge)
    const [textError, setTextError] = useState<string>('')
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [loadingSaveButton, setLoadingSaveButton] = useState<boolean>(false)
    const [loadingSaveToDraftButton, setLoadingSaveToDraftButton] = useState<boolean>(false)

    const [isSelectCategory, setIsSelectCategory] = useState<boolean>(false)

    const [knowledgeStatus, setKnowledgeStatus] = useState<KnowledgeStatus>(KnowledgeStatus.MyNote)

    const [isDisableComment, setIsDisableComment] = useState<boolean>(false)
    const [detail, setDetail] = useState<string>('')
    const [dataRoleVspace, setDataRoleVspace] = useState<assignmentGroups>()
    const [isCanEditKM, setIsCanEditKM] = useState<boolean>(false)
    const [roleVspace, setRoleVspace] = useState<string>()
    const username = localStorage.getItem('username')

    const params = new URLSearchParams(window.location.search)

    const { id, categoryId, folderId } = useParams()

    const { confirm } = Modal

    const { getFieldDecorator, setFieldsValue, getFieldValue, validateFields, resetFields } = props.form

    useEffect(() => {
        let role: string | undefined
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            role = dataVspace.roleId.toString()
            setRoleVspace(role)
            if (props.kmMapRole) {
                props.getKmMapRole(dataVspace.email)
            }
            fetchAssignmentUserEngineer(role)
        }
        if (!id) {
            setKnowledgeStatus(KnowledgeStatus.MyNote)
            return
        }
        getKnowledgeById(id).then(knowledge => {
            if (knowledge) {
                knowledge.createdBy = undefined
                knowledge.createdDate = undefined
                knowledge.createdByDisplay = undefined
                knowledge.lastModifiedBy = undefined
                knowledge.lastModifiedByDisplay = undefined
                knowledge.lastModifiedDate = undefined
                setKnowledge(knowledge)
                setKnowledgeStatus(KnowledgeStatus[knowledge.status || KnowledgeStatus.MyNote])
                initKnowledgeData(knowledge)
                const uploadFile = knowledge.uploadLink?.map((file) => {
                    const uploadFile: UploadFile = {
                        uid: file.name,
                        name: file.name,
                        status: 'done',
                        url: file.url,
                        type: file.type,
                        size: 0
                    }
                    return uploadFile
                })
                setFileList(uploadFile || [])
            }
        })
    }, [])

    useEffect(() => {
        if (props.kmMapRole) {
            props.getAllCategory(props.kmMapRole)
        }
    }, [props.kmMapRole])

    useEffect(() => {
        if (isSelectCategory || !getFieldValue(FormType.Category)) {
            resetFields([FormType.Folder])
        }
    }, [getFieldValue(FormType.Category)])

    useEffect(() => {
        if (categoryId) {
            setField({ Category: categoryId.toString() }, categoryId.toString())
        }
    }, [categoryId])

    useEffect(() => {
        if (folderId) {
            setField({ Folder: folderId.toString() }, folderId.toString())
        }
    }, [folderId])

    useEffect(() => {
        if (params.get('validation') === '1') {
            validateFields([FormType.Category, FormType.Folder])
        }
    }, [params.get('validation')])

    useEffect(() => {
        if (id) {
            if (props.categories && knowledge) {
                const filterCategory = props.categories.find(res => res.id === String(knowledge.categoryId))
                const filterFolder = filterCategory?.folderList?.find(res => res.id === knowledge.folderId)
                if (filterFolder) {
                    if (knowledge.folderId) {
                        setField({ Folder: knowledge.folderId.toString() }, knowledge.folderId.toString())
                    }
                    setIsCanEditKM(checkPermissionEdit(filterFolder!!))
                } else {
                    resetFields([FormType.Folder])
                }
            }
        } else {
            const categoryId = getFieldValue('Category')
            const folderId = getFieldValue('Folder')
            if (folderId) {
                const filterCategory = props.categories.find(res => res.id === String(categoryId))
                const filterFolder = filterCategory?.folderList?.find(res => res.id === folderId)
                if (filterFolder) {
                    setIsCanEditKM(checkPermissionEdit(filterFolder!!))
                }
            }
        }
    }, [props.categories, dataRoleVspace, knowledge])

    const fetchAssignmentUserEngineer = (role: string) => {
        getAssignmentUserWithRole(role).then((res) => {
            (res || []).map((data) => {
                if (role === data.roleId.toString()) {
                    setDataRoleVspace(data)
                }
                return { id: data.name, value: data.name }
            })
        }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
    }

    const checkPermissionEdit = (folder: Folder): boolean => {
        if (!checkRolesState(RoleType.Knowledge, 'UpdateKnowledge')) {
            return true
        }
        if (folder?.managedBy.length !== 0 && folder?.managedBy) {
            // if (!folder?.managedBy.some(it => it.groupName === dataRoleVspace?.name!!)) {
            //     return true
            // } else {
            //     return false
            // }
            if (!folder?.managedBy?.some(it => props.kmMapRole.includes(it.groupName)) || knowledge.createdBy === username) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const initKnowledgeData = (knowledge: Knowledge) => {
        const tagList = knowledge.tagsList?.map((res) => { return res.tag })
        if (knowledge.categoryId) {
            setField({ Category: knowledge.categoryId.toString() }, knowledge.categoryId.toString())
        }
        if (knowledge.folderId) {
            setField({ Folder: knowledge.folderId.toString() }, knowledge.folderId.toString())
        }
        setField({ Type: knowledge.type }, knowledge.type)
        setField({ Tags: tagList }, tagList)
        setIsDisableComment(knowledge.disableComment || false)
        setFieldsValue({ Topic: knowledge.topic })
        setDetail(knowledge.detail || '')
    }

    const setField = (object: Object, value?: any) => {
        if (value) {
            setFieldsValue(object)
        }
    }

    const CategoriesList = props.categories.map((category) => {
        return {
            id: category.id!,
            value: category.categoryName!
        }
    })

    const FolderList = (categoryID: string) => {
        const category = props.categories.find((category) => category.id === categoryID)
        if (category) {
            return category.folderList!.map((folder) => {
                return {
                    id: folder.id!,
                    value: folder.folderName!
                }
            })
        } else {
            return []
        }
    }

    const createMenu = (data: dropdownData[], id: FormType, isRequire = true) => {
        return getFieldDecorator(id.toString(), { rules: [{ required: isRequire }] })(
            <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select"
                onChange={(selectItem: string) => {
                    setFieldsValue({ id: selectItem })
                }}
                onSelect={() => {
                    setIsSelectCategory(true)
                }}
                allowClear={true}
            >
                {data.map((data) => {
                    return (
                        <Option value={`${data.id}`} key={data.id}>
                            {data.value}
                        </Option>
                    )
                })}
            </Select>
        )
    }
    const stringSaveTo = (status: KnowledgeStatus) => {
        if (status === KnowledgeStatus.MyNote) {
            return 'Save to Draft'
        } else {
            return 'Save and Publish'
        }
    }

    const validateKnowledge = (e) => {
        validateFields(async (err: any) => {
            const detailValue = detail || ''
            const CheckValue = (detailValue).replace(/<[^>]*>/g, '')

            if (err || CheckValue === '' || CheckValue.length <= 2) {
                setLoadingSaveButton(false)
                setLoadingSaveToDraftButton(false)
                if (CheckValue === '') {
                    setTextError('Detail is required')
                } else {
                    if (CheckValue.length <= 2) {
                        setTextError('Enter more than 3 characters')
                    } else {
                        setTextError('')
                    }
                }
                return
            }

            const newKnowledge = knowledge
            newKnowledge.detailText = CheckValue
            let tempStatus = ''
            const promiseAll: Promise<any>[] = []
            switch (e.target.value) {
            case 'Save':
                setLoadingSaveButton(true)
                if (!newKnowledge.status) {
                    tempStatus = KnowledgeStatus.MyNote
                } else {
                    tempStatus = newKnowledge.status
                }
                break
            case 'Save to Draft':
                setLoadingSaveToDraftButton(true)
                tempStatus = KnowledgeStatus.Draft
                break
            case 'Save and Publish':
                setLoadingSaveToDraftButton(true)
                // eslint-disable-next-line no-case-declarations
                const getFolder = getFolderById(getFieldValue(FormType.Folder)).then((folder) => {
                    if (folder.publicWorkflow === '0') {
                        tempStatus = KnowledgeStatus.Published
                    } else {
                        tempStatus = KnowledgeStatus.AwaitingApproval
                    }
                })
                promiseAll.push(getFolder)
                break
            default:
                tempStatus = knowledge.status || KnowledgeStatus.MyNote
                break
            }
            await Promise.all(promiseAll)

            if (tempStatus !== newKnowledge.status && tempStatus !== KnowledgeStatus.MyNote) {
                validateFields([FormType.Category, FormType.Folder], (error) => {
                    if (error) {
                        setLoadingSaveButton(false)
                        setLoadingSaveToDraftButton(false)
                        return
                    }
                    newKnowledge.status = tempStatus
                    saveKnowledge(newKnowledge)
                })
            } else {
                if (!newKnowledge.status) {
                    newKnowledge.status = tempStatus
                }
                saveKnowledge(newKnowledge)
            }
        })
    }

    const saveKnowledge = (knowledge: Knowledge) => {
        uploadImageInTextArea(detail, (newDetail) => {
            knowledge.detail = newDetail
            if (fileList.length > 0 && fileList.length <= 5) {
                FSS.putFile(fileList, '/knowledge/', (uploadLink: UploadLink[]) => {
                    console.debug(uploadLink)
                    if (knowledge.id) {
                        knowledge.uploadLink = uploadLink.map((it) => {
                            return {
                                knowledgeId: knowledge.id,
                                name: it.name,
                                url: it.url,
                                type: it.type
                            } as MyUploadLink
                        })
                    } else {
                        knowledge.uploadLink = uploadLink
                    }
                    submitKnowledge(knowledge)
                })
            } else if (fileList.length > 5) {
                message.error('Upload File Max 5')
                setLoadingSaveButton(false)
                setLoadingSaveToDraftButton(false)
            } else {
                knowledge.uploadLink = []
                submitKnowledge(knowledge)
            }
        })
    }

    const submitKnowledge = (knowledge: Knowledge) => {
        knowledge.categoryId = getFieldValue(FormType.Category)
        knowledge.folderId = getFieldValue(FormType.Folder)
        knowledge.type = getFieldValue(FormType.Type) || null
        // knowledge.tagsList = getFieldValue(FormType.Tags)
        knowledge.disableComment = isDisableComment
        knowledge.topic = getFieldValue(FormType.Topic)
        // knowledge.detail = detail
        knowledge.used = 0
        knowledge.createdBy = username || ''
        knowledge.lastModifiedBy = username || ''
        // knowledge.isKmVspace = !!roleVspace
        knowledge.tagsList = (getFieldValue(FormType.Tags) || []).map((it) => {
            return {
                id: undefined,
                knowledgeId: undefined,
                tag: it
            } as unknown as TagsList
        })
        setKnowledge(knowledge)
        if (knowledge.id) {
            updateKnowledge(knowledge).then(response => {
                message.success('The update has finished successfully.')
                setLoadingSaveButton(false)
                setLoadingSaveToDraftButton(false)
                window.location.href = `/KnowledgePreview/${response.id!}`
            }).catch((error) => {
                setLoadingSaveButton(false)
                setLoadingSaveToDraftButton(false)
                message.error(error.toString())
            })
        } else {
            createKnowledge(knowledge).then(response => {
                message.success('You have successfully saved the data.')
                setLoadingSaveButton(false)
                setLoadingSaveToDraftButton(false)
                window.location.href = `/KnowledgePreview/${response.id!}`
            }).catch((error) => {
                setLoadingSaveButton(false)
                setLoadingSaveToDraftButton(false)
                message.error(error.toString())
            })
        }
    }

    const uploadImageInTextArea = (value: string, callBack: Function) => {
        if (value.search('<img')) {
            const MyFileList: UploadFile[] = []
            const result: string[] = value.split('<img src="data:')
            const newValue = [result[0]]
            result.map((it, index) => {
                if (it.startsWith('image')) {
                    const fileType = it.split(';')[0]
                    const fileExtention = fileType.split('/')[1] || ''
                    const deleteTagHtml = 'data:' + it.split('">')[0]
                    const ts = `${Math.round((new Date()).getTime() / 1000)}-${index}`
                    const ConvertUploadLink: UploadFile = {
                        uid: `${id}-${ts}`,
                        name: `${id}-${ts}.${fileExtention}`,
                        type: fileType,
                        url: deleteTagHtml,
                        size: deleteTagHtml.length
                    }
                    MyFileList.push(ConvertUploadLink)
                }
            })

            if (MyFileList.length === 0) callBack(value)
            FSS.putFile(MyFileList, '/knowledgeEditor/', (uploadLink: UploadLink[]) => {
                result.forEach((it, index) => {
                    if (index !== 0) {
                        var replaceImageValue = it.split('">')
                        replaceImageValue[0] = `<img src="${uploadLink[index - 1].url}`
                        replaceImageValue = replaceImageValue.map((it, index) => {
                            if (it.startsWith('<img src="')) {
                                return it + '">'
                            }
                            return it
                        })
                        newValue.push(...replaceImageValue)
                    }
                })
                const joinV = newValue.join('')
                setDetail(joinV)
                callBack(joinV)
            })
        }
    }

    const onTextEditorChange = (value: string) => {
        const CheckValue = (value).replace(/<[^>]*>/g, '')
        const element = document.getElementsByClassName('ql-editor')?.item(0) as HTMLElement
        if (value) {
            setDetail(value)
            if (CheckValue === '') {
                element.style.border = '1px solid red'
                setTextError('Detail is required')
            } else {
                if (CheckValue.length <= 2) {
                    element.style.border = '1px solid red'
                    setTextError('Enter more than 3 characters')
                } else {
                    element.style.border = '0px solid red'
                    setTextError('')
                }
            }
        }
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page?',
            okText: 'Yes',
            onOk() {
                if (id) {
                    window.location.href = `/KnowledgePreview/${id}`
                } else {
                    window.location.href = '/Knowledge'
                }
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/Knowledge'}>Knowledge</Link></Breadcrumb.Item>
                {id ? (<Breadcrumb.Item><Link to={`/KnowledgePreview/${id}`}>{getFieldValue('Topic')}</Link></Breadcrumb.Item>) : null}
                <Breadcrumb.Item>{id ? 'Edit Knowledge' : 'Create Knowledge'}</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Row>
                <Col>
                    <h3 className={'main-title'}>
                        {id ? 'Edit Knowledge' : 'Create Knowledge'}
                        <Tag color={statusColor(knowledgeStatus)} style={{ marginLeft: 10 }}>{convertCamelCaseToSentenceCase(knowledgeStatus)}</Tag>
                        {knowledgeStatus === KnowledgeStatus.Rejected ? (<Tooltip title={knowledge.rejectReason}><Tag>...</Tag></Tooltip>) : ''}
                    </h3>
                </Col>
            </Row>
            <Space size={10} />
            <Form className={!knowledge.status || knowledge.status === KnowledgeStatus.MyNote ? 'km-form' : ''}>
                <Row gutter={[20, 10]}>
                    {knowledge.ticketNumber ? (<Col>
                        {knowledge.ticketNumber}
                    </Col>) : null}
                    <Col span={16} xs={24} sm={24} md={16} lg={8}>
                        <Form.Item label={FormType.Category} className='conditional-required'>
                            {createMenu(CategoriesList, FormType.Category)}
                        </Form.Item>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={8}>
                        <Form.Item label={FormType.Folder} className='conditional-required'>
                            {createMenu(FolderList(getFieldValue(FormType.Category)), FormType.Folder)}
                        </Form.Item>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={8}>
                        <Form.Item label={FormType.Type} >
                            {createMenu(dropdownDataType.dataList, FormType.Type, false)}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={FormType.Tags}>
                            {getFieldDecorator(FormType.Tags)(
                                <Select mode="tags" style={{ width: '100%' }} placeholder="Type Tags" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Checkbox checked={isDisableComment} onChange={(e) => {
                            setIsDisableComment(e.target.checked)
                        }}>Disable Comment</Checkbox>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={FormType.Topic} >
                            {getFieldDecorator(FormType.Topic, { rules: [{ required: true }, { min: 3, message: 'Enter more than 3 characters' }] })(
                                <Input placeholder='input topic' maxLength={255} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={FormType.Detail} required>
                            <ReactQuill modules={modules} formats={formats} value={detail} onChange={(e: any) => { onTextEditorChange(e) }} />
                            <span style={{ color: 'red' }}>{textError}</span>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <KnowledgeFormUpload fileList={fileList} setFileList={(fileList: UploadFile[]) => { setFileList(fileList) }} />
                    </Col>
                    <Col span={24}>
                        <Button htmlType="submit" disabled={false} type="primary" loading={loadingSaveToDraftButton} onClick={validateKnowledge} value={stringSaveTo(knowledgeStatus)} style={{ float: 'right', marginRight: 10 }}>{stringSaveTo(knowledgeStatus)}</Button>
                        <Button htmlType="submit" disabled={isCanEditKM} type="primary" loading={loadingSaveButton} onClick={validateKnowledge} value='Save' style={{ float: 'right', marginRight: 10 }}>Save</Button>
                        <Button onClick={leavePageModal} style={{ float: 'right', marginRight: 10 }}>Cancel</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.knowledgeCategories,
        kmMapRole: state.kmMapRole
    }
}

const MyKnowledgeForm = Form.create<Props>()(KnowledgeForm)

export default connect(mapStateToProps, { getAllCategory, getKmMapRole })(MyKnowledgeForm)
