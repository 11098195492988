import React from 'react'
import moment from 'moment'
import {IncidentWithSlaCalculations, TicketStatus} from './index'
import {getLeastTargetTimeOfAllInProgressSlaCalculations} from '../misc'
import {Status} from '../status'
import TimeLeft from '../sla/TimeLeft'
import {Col, Row} from 'antd'

interface Params {
    incident: IncidentWithSlaCalculations
}

type Props = Params

const IncidentTimeIndex: React.FC<Props> = (props: Props) => {
    const getLastTimeStatus = (status: string) => {
        // let time = ''
        // for (const history of props.incident.statusHistories!!) {
        //     if (history.status === status && time < history.timestamp) {
        //         time = history.timestamp
        //     }
        // }
        // return time
    }

    const findSlaTargetTimeAndPendingUntil = (slaTarget: string, pendingUntil?: string) => {
        if (pendingUntil) {
            return <span className='TextFrontDesc'>Pending remaining : <TimeLeft until={pendingUntil} /></span>
        } else if (slaTarget) {
            return <span className='TextFrontDesc'>Due in : <TimeLeft until={slaTarget} /></span>
        }
    }

    const showStatusWithStampTime = (status: TicketStatus) => {
        if (status.status === 'New' || status.status === 'InProgress' || status.status === 'Assigned') {
            return (
                <Row>
                    <Col span={3}>
                        <span className='TextFrontDesc'>Created : </span>
                    </Col>
                    <Col span={5}>
                        <span>{moment(props.incident.createdDate!!).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </Col>
                    <Col span={10}>
                        {findSlaTargetTimeAndPendingUntil(getLeastTargetTimeOfAllInProgressSlaCalculations(props.incident.slaCalculations), props.incident.pendingUntil)}
                    </Col>
                </Row>
            )
            // return <span>Created :
            //     <span style={{ marginLeft: 39 }}>{moment(props.incident.createdDate!!).format('YYYY-MM-DD HH:mm:ss')}</span>
            //     <span style={{ marginLeft: 39 }}></span>
            // </span>
        } else {
            return (
                <Row>
                    <Col span={3}>
                        <span className='TextFrontDesc'>{status.status} : </span>
                    </Col>
                    {/*<Col span={5}>*/}
                    {/*    <span>{moment(getLastTimeStatus(props.incident.ticketStatus.status)).format('YYYY-MM-DD HH:mm:ss')}</span>*/}
                    {/*</Col>*/}
                    <Col span={10}>
                        {findSlaTargetTimeAndPendingUntil(getLeastTargetTimeOfAllInProgressSlaCalculations(props.incident.slaCalculations), props.incident.pendingUntil)}
                    </Col>
                </Row>
            )
            // return <div>{status} :
            //     <span style={{ marginLeft: status === 'Closed' ? 45 : 36 }}>{moment(getLastTimeStatus(props.incident.status)).format('YYYY-MM-DD HH:mm:ss')}</span>
            //     <span style={{ marginLeft: status === 'Closed' ? 45 : 36 }}></span>
            // </div>
        }
    }

    return (
        <>
            {showStatusWithStampTime(props.incident.ticketStatus)}
        </>
    )
}
export default IncidentTimeIndex
