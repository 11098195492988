import { buildStateEvent, GenericStateEvent } from '../../common-redux'
import { SlaCalculationDataSuit } from './service'
import moment from 'moment'

export interface SlaCalculationStateEvent<T> extends GenericStateEvent<T> {}

export const SlaCalculationDataSuitReceivedEvent = {
    type: 'SlaCalculationDataSuitReceivedEvent',
    build: (payload: SlaCalculationDataSuit) => buildStateEvent(SlaCalculationDataSuitReceivedEvent.type, {...payload, versionTimestamp: moment()})
}
