/* eslint-disable react/display-name */
import React from 'react'
import { Table } from 'antd'
import { ChargingGroup } from '../../Relate/Charging/Model'

interface Param {
    data: ChargingGroup
}

type Props = Param

const TableCharging: React.FC<Props> = (props: Props) => {
    const columns = [
        {
            title: <b>No</b>,
            dataIndex: '',
            width: '10%',
            render: (text, record, index) => (
                <>
                    {index + 1}
                </>
            )
        },
        {
            title: <b>Description</b>,
            dataIndex: 'chargeDescription',
            width: '80%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        },
        {
            title: <b>Cost</b>,
            dataIndex: 'chargeCost',
            width: '10%',
            render: (data: string) => (
                <>
                    <div>
                        <p>{data}</p>
                    </div>
                </>
            )
        }
    ]

    return (
        <>
            <Table
                dataSource={props.data?.chargingList}
                columns={columns}
                pagination={false}
                rowKey='id'
            />
        </>
    )
}

export default TableCharging
