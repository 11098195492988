import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { connect } from 'react-redux'
import { nullOrEmptyObject } from '../../common-misc'
import { StoreState } from '../../store'
import { DataForChart } from '../model'
import { Empty } from 'antd'

const mapStateToProps = (state: StoreState) => {
    return {
    }
}

interface DispatchProps {
}

type StateProps = ReturnType<typeof mapStateToProps>

interface Params {
    Height?: number
    minHeight?:number
    myData: DataForChart
    marginTop: String
    showLabels: boolean
    fixPosition?: String
}

type Props = Params & StateProps & DispatchProps

const DoughnutChart: React.FC<Props> = (props: Props) => {
    const data = {
        labels: [
            'Red',
            'Green',
            'Yellow'
        ],
        datasets: [{
            data: [90, 50, 100],
            backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56'
            ],
            hoverBackgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56'
            ]
        }]
    }

    return (
        <div
            style={{ marginTop: `${props.marginTop}` }}
        >
            {!nullOrEmptyObject(props.myData?.labels)
                ? <Doughnut
                    data={props.myData?.labels ? props.myData : data}
                    height={props.Height !== undefined ? props.Height!! : 100}
                    // width={100}
                    // height={50}
                    // options={{ maintainAspectRatio: false }}
                    // width={100}
                    // options={{
                    //     rotation: 1 * Math.PI,
                    //     circumference: 1 * Math.PI,
                    //     // legend: {
                    //     //     display: false
                    //     // },
                    //     // tooltip: {
                    //     //     enabled: false
                    //     // },
                    //     cutoutPercentage: 120
                    // }}
                    options={{
                        legend: {
                            position: props.fixPosition ? props.fixPosition : 'bottom',
                            display: props.showLabels
                        },
                        tooltips: {
                            enabled: true
                        }
                        // tooltips: {
                        //     callbacks: {
                        //         label: function(tooltipItem) {
                        //             return tooltipItem
                        //         }
                        //     }
                        // }
                    }}
                />
                : <div style={{height: props.minHeight!!}}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            }
        </div>
    )
}

export default connect(mapStateToProps, {

})(DoughnutChart)
