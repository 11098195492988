import React, {useEffect, useState} from 'react'
import {
    AutoComplete,
    Avatar,
    Breadcrumb,
    Button,
    Card,
    Col,
    Form,
    Icon,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Select
} from 'antd'
import './style.css'
import {connect} from 'react-redux'
import {StoreState} from '../../store'
import {addUserRole, editUser, resend, searchPeople, showActiveRoleList} from './duck/action'
import {Roles, ResponseUserRole} from './duck/model'
import {FormComponentProps} from 'antd/lib/form'
import {DataSourceItemObject} from 'antd/lib/auto-complete'
import {Link, Redirect, useParams} from 'react-router-dom'

const mapStateToProps = (state: StoreState) => ({
    roles: (state.roles || [])
})

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    showUserList: (roles: String[]) => Promise<number>
    showActiveRoleList: () => Promise<number>
    addUserRole: (userRoles: ResponseUserRole) => Promise<number>
    editUser: (userRoles: ResponseUserRole, id: string) => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

const {confirm} = Modal

const UserRoleFrom: React.FC<Props> = (props: Props) => {
    const {Option} = Select

    const {id, peopleIdParam, usernameParam, role}: any = useParams()

    const [valueRoles, setValueRoles] = useState<Array<Roles>>([])
    const [isRedirect, setIsRedirect] = useState(false)
    const [selectedMenu, setSelectedMenu] = useState<string>('personal')
    const [firstName, setFirstName] = useState<string>('-')
    const [lastName, setLastName] = useState<string>('-')
    const [fullName, setFullName] = useState<string>('-')
    const [phoneNumber, setPhoneNumber] = useState<string>('-')
    const [companyName, setCompanyName] = useState<string>('-')
    const [companyAddress, setCompanyAddress] = useState<string>('-')
    const [siteName, setSiteName] = useState<string>('-')
    const [siteAddress, setSiteAddress] = useState<string>('-')
    const [dataSource, setDatasource] = useState<DataSourceItemObject[]>([])
    const [email, setEmail] = useState<string>('')
    const [peopleId, setPeopleId] = useState<any>('')
    const [editMode, setEditMode] = useState<boolean>(false)
    const [disableMenu, setDisableMenu] = useState<boolean>(true)
    const [preview, setPreview] = useState<boolean>(false)
    const [loading, setLoadind] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [existEmail, setExistEmail] = useState<boolean>(false)
    const [selectedRoles, setSelectedRoles] = useState<Array<number>>([])

    useEffect(() => {
        const pathname = window.location.pathname
        if (pathname.includes('/UserSettingPreview')) {
            setPreview(true)
            setEditMode(false)
        }
        props.showActiveRoleList().then(null)
    }, [])

    useEffect(() => {
        // const defaultSelectedRole: number[] = []
        setValueRoles(props.roles)
        // if (role && props.roles) {
        //     const roleParams: string[] = role?.split(',')
        //     roleParams.map((param) => {
        //         const roleId = props.roles.find((role) => role.name === param)?.id
        //         if (roleId != null) {
        //             defaultSelectedRole.push(roleId)
        //         }
        //     })
        //     setSelectedRoles(defaultSelectedRole)
        // }
    }, [props.roles])

    useEffect(() => {
        if (id) {
            onSelect(peopleIdParam)
            setEditMode(true)
        }
    }, [peopleIdParam])

    useEffect(() => {
        if (role) {
            setSelectedRoles(role?.split(','))
        }
    }, [role])

    const {getFieldDecorator} = props.form

    const convertSelectedRole = (selectedRole): number[] => {
        const arr: number[] = []
        let roleId: number | undefined = 0
        selectedRole.map((item) => {
            if (typeof item === 'string') {
                roleId = props.roles.find(role => role.name === item)?.id
                if (roleId != null) {
                    arr.push(roleId)
                }
            } else {
                arr.push(item)
            }
        })
        return arr
    }

    const handleSubmit = (e) => {
        setLoadind(true)
        e.preventDefault()
        props.form.validateFieldsAndScroll((error, value) => {
            if (!error) {
                const packData = {
                    username: value.username,
                    fullName: fullName,
                    roles: convertSelectedRole(value.roles).map((roleId) => {
                        return {
                            id: roleId
                        }
                    }
                    ),
                    peopleId: peopleId,
                    active: true
                } as ResponseUserRole
                if (id) {
                    props.editUser(packData, id).then(() => {
                        setExistEmail(false)
                        message.success('The Update has finished successfully.')
                        setIsRedirect(true)
                    }).catch((error) => {
                        setLoadind(false)
                        message.error(error.message)
                    })
                } else {
                    props.addUserRole(packData).then(() => {
                        setExistEmail(false)
                        message.success('You have Successfully save the data.')
                        props.form.setFieldsValue({
                            username: undefined,
                            fullName: undefined,
                            roles: undefined
                        })
                        setIsRedirect(true)
                    }).catch((error) => {
                        if (error.response.status === 409) {
                            setLoadind(false)
                            setExistEmail(true)
                            // message.error('This email is already in the system.')
                        } else {
                            setLoadind(false)
                            message.error(error.message)
                        }
                    })
                }
            } else {
                setLoadind(false)
            }
        })
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const handleChangeMenu = e => {
        setSelectedMenu(e.key)
    }

    const uploadNewProfile = () => {

    }

    const changeToEdit = () => {
        setEditMode(true)
        setPreview(false)
    }

    const onSelect = (value) => {
        console.log(value)
        if (value === '') {
            setDisableMenu(true)
        } else {
            setDisableMenu(false)
        }
        searchPeople('').then((raw) => {
            // eslint-disable-next-line no-unused-expressions
            raw?.map((temp) => {
                if (temp.id?.toString() === value.toString()) {
                    setFirstName(temp.firstName)
                    setLastName(temp.lastName)
                    setPhoneNumber(temp.tel)
                    setCompanyName(temp.company)
                    setEmail(temp.email)
                    setFullName(temp.fullName)
                    setCompanyAddress(temp.companyAddress)
                    setSiteAddress(temp.siteAddress)
                    setSiteName(temp.site)
                    setPeopleId(value)
                }
            })
            if (id) {
                setEmail(usernameParam!!)
            }
        })
    }

    // const handleSelectRoles = (value: any[]) => {
    //     setSelectedRoles(value)
    // }

    const onSearch = (searchText) => {
        searchPeople(searchText).then((data) => {
            if (data) {
                const result = data.map((people) => {
                    return {
                        value: people.id?.toString(),
                        text: people.fullName
                    } as DataSourceItemObject
                })
                setDatasource(result)
            } else {
                setDatasource([])
            }
        })
    }

    const handleResend = () => {
        setIsLoading(true)
        const username = props.form.getFieldValue('username')
        resend(username, 'resend')
            .then(() => {
                setIsLoading(false)
                message.success('Account Verification Link has been send')
            }).catch(error => {
                setIsLoading(false)
                if (error.response.status === 404) {
                    message.error('User already confirmed account.')
                } else {
                    message.error('You have Unsuccessfully save the data.')
                }
            }
            )
    }

    return (
        <>
            {isRedirect ? (<Redirect to="/UserSetting"/>) : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/UserSetting">User</Link>
                </Breadcrumb.Item>
                {
                    preview ? <Breadcrumb.Item>{firstName}</Breadcrumb.Item>
                        : (id) ? <Breadcrumb.Item>Edit User</Breadcrumb.Item>
                            : <Breadcrumb.Item>New User</Breadcrumb.Item>
                }
            </Breadcrumb>
            <br/>
            <Card className={'custom-card'}>
                <Row>
                    <Col style={{textAlign: 'right'}}>{preview
                        ? <Icon className="edit_icon" type="edit" onClick={changeToEdit}/> : null}</Col>
                    <Col>{preview ? <h2 className={'main-title'}>User</h2> : id
                        ? <h2 className={'main-title'}>Edit User</h2>
                        : <h2 className={'main-title'}>New User</h2>}</Col>
                </Row>

                <Form onSubmit={handleSubmit}>
                    {/* Left Part */}
                    <Row>
                        <Col span={5} lg={5} md={5} sm={24} xs={24}>
                            <Row>
                                <AutoComplete
                                    disabled={editMode}
                                    dataSource={dataSource}
                                    onSelect={onSelect}
                                    onSearch={onSearch}
                                    placeholder="Can search name"
                                >
                                    <Input suffix={<Icon type="search" className="certain-category-icon"/>}/>
                                </AutoComplete>
                            </Row>
                            <Row style={{marginTop: '10%', textAlign: 'center'}}>
                                <Avatar shape="square" size={170} icon="user"/>
                            </Row>
                            <Row style={{marginTop: 10, textAlign: 'center'}}>
                                <Button disabled={true} onClick={uploadNewProfile}><Icon type="upload"/>Upload New
                                    Profile</Button>
                            </Row>
                            <Row style={{marginTop: '20%'}}>
                                <Menu onClick={handleChangeMenu} style={{width: 256}} mode="inline">
                                    <Menu.Item key="personal"
                                        style={selectedMenu === 'personal' ? {color: '#FA541C'} : {color: '#595959'}}>
                                        <span>Personal information <Icon type="right"/></span>
                                    </Menu.Item>
                                    <Menu.Item key="security" disabled={disableMenu}>
                                        <span>Security information <Icon type="right"/></span>
                                    </Menu.Item>
                                </Menu>
                            </Row>
                        </Col>

                        {/* Right Part */}
                        <Col span={18} style={{marginLeft: '3%'}} lg={18} md={18} sm={24} xs={24}>
                            {(selectedMenu === 'personal')
                                ? <Row style={{marginTop: '8%'}}>
                                    <h4 style={{color: '#323276', fontSize: 14}}>Personal information</h4>
                                    <Row style={{marginTop: '2%'}}>
                                        <Col span={3} lg={3} md={3} sm={24} xs={24}>FirstName :</Col>
                                        <Col span={4} lg={4} md={4} sm={24} xs={24}>{firstName}</Col>
                                        <Col span={2} lg={2} md={2} sm={24} xs={24}>LastName :</Col>
                                        <Col span={10} lg={10} md={10} sm={24} xs={24}>{lastName}</Col>
                                    </Row>
                                    <Row style={{marginTop: '1%'}}>
                                        <Col span={3} lg={3} md={3} sm={24} xs={24}>Phone Number :</Col>
                                        <Col span={21} lg={21} md={21} sm={24} xs={24}>{phoneNumber}</Col>
                                    </Row>
                                    <h4 style={{marginTop: '3%', color: '#323276', fontSize: 14}}>Company</h4>
                                    <Row style={{marginTop: '2%'}}>
                                        <Col span={3} lg={3} md={3} sm={24} xs={24}>Name :</Col>
                                        <Col span={10} lg={10} md={10} sm={24} xs={24}>{companyName}</Col>
                                    </Row>
                                    <Row style={{marginTop: '1%'}}>
                                        <Col span={3} lg={3} md={3} sm={24} xs={24}>Address :</Col>
                                        <Col span={21} lg={21} md={21} sm={24} xs={24}>{companyAddress}</Col>
                                    </Row>
                                    <h4 style={{marginTop: '3%', color: '#323276', fontSize: 14}}>Site</h4>
                                    <Row style={{marginTop: '2%'}}>
                                        <Col span={3} lg={3} md={3} sm={24} xs={24}>Name :</Col>
                                        <Col span={10} lg={21} md={21} sm={24} xs={24}>{siteName}</Col>
                                    </Row>
                                    <Row style={{marginTop: '1%'}}>
                                        <Col span={3} lg={3} md={3} sm={24} xs={24}>Address :</Col>
                                        <Col span={21} lg={21} md={21} sm={24} xs={24}>{siteAddress}</Col>
                                    </Row>
                                </Row>
                                : <Row style={{marginTop: '8%'}}>
                                    <h4 style={{color: '#323276', fontSize: 14}}>Security information</h4>
                                    <Row>
                                        <Form.Item label={'Username'} style={{width: '80%'}}>
                                            {getFieldDecorator('username',
                                                {
                                                    initialValue: email,
                                                    rules: [
                                                        {type: 'email'},
                                                        {required: true, message: 'Username is required'}
                                                    ]
                                                })(
                                                <Input
                                                    placeholder="E-mail"
                                                    disabled={preview}
                                                    style={{borderColor: existEmail ? 'red' : undefined}}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            )}
                                        </Form.Item>
                                        {existEmail ? <span style={{color: 'red'}}>
                                            This user or email is already in the system.
                                        </span> : null}
                                        <h4 style={{color: '#323276', marginTop: '5%', fontSize: 14}}>Setting Role</h4>
                                        <Form.Item label={'Role'} style={{width: '80%'}}>
                                            {getFieldDecorator('roles',
                                                {
                                                    initialValue: selectedRoles,
                                                    rules: [{required: true, message: 'Role is required'}]
                                                })(
                                                <Select
                                                    mode="multiple"
                                                    placeholder={'Role'}
                                                    disabled={preview}
                                                    // onChange={handleSelectRoles}
                                                >
                                                    {valueRoles.map((roles: Roles) => {
                                                        return (<Option value={roles.name}
                                                            key={roles.id}>{roles.name}</Option>)
                                                    })}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Row>
                                    {(preview || editMode)
                                        ? <div>
                                            <Row style={{marginTop: '8%'}}>
                                                <Button style={{borderColor: '#FA541C', color: '#FA541C'}}
                                                    onClick={handleResend} loading={isLoading}>Resend</Button>
                                            </Row>
                                            <Row style={{marginTop: '2%'}}>
                                                <span>Please click &quot;Resend&quot; to resend account verification link</span>
                                            </Row>
                                        </div>
                                        : null}
                                    <Row style={{marginTop: '20%'}}>
                                        {!preview
                                            ? <div>
                                                <Button type="primary" htmlType="submit" loading={loading} style={{
                                                    float: 'right',
                                                    marginRight: 10,
                                                    marginBottom: '5px'
                                                }}>Submit</Button>
                                                <Button onClick={leavePageModal}
                                                    style={{float: 'right', marginRight: 10}}>Cancel</Button>
                                            </div>
                                            : null}

                                    </Row>
                                </Row>}
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}
const MyUserRolesForm = Form.create<Props>()(UserRoleFrom)

export default connect(
    mapStateToProps, {
        addUserRole,
        showActiveRoleList,
        editUser
    }
)(MyUserRolesForm)
