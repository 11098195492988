import { Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Knowledge } from './model'
import { getDataExportKM } from './service'

interface Params {
    defaultRangeCreatedDate: any[]
}

type Props = Params
const KnowledgeListPreview: React.FC<Props> = (props: Props) => {
    const [data, setData] = useState<Knowledge[]>([])
    useEffect(() => {
        if (props.defaultRangeCreatedDate) {
            getDataExportKM(moment(props.defaultRangeCreatedDate[0]).format('YYYY-MM-DD'), moment(props.defaultRangeCreatedDate[1]).format('YYYY-MM-DD')).then((response) => {
                if (response) {
                    console.log(response)
                    setData(response)
                }
            })
        }
    }, [props.defaultRangeCreatedDate])

    const columns = [
        {
            title: 'knowledgeNumber',
            dataIndex: 'knowledgeNumber',
            key: 'knowledgeNumber'
        },
        {
            title: 'topic',
            dataIndex: 'topic',
            key: 'topic',
            width: 200
        },
        {
            title: 'knowledgeStatus',
            dataIndex: 'knowledgeStatus',
            key: 'knowledgeStatus'
        },
        {
            title: 'categoryName',
            dataIndex: 'categoryName',
            key: 'categoryName'
        },
        {
            title: 'folderName',
            dataIndex: 'folderName',
            key: 'folderName'
        },
        {
            title: 'knowledgeType',
            dataIndex: 'knowledgeType',
            key: 'knowledgeType'
        },
        {
            title: 'knowledgeTags',
            dataIndex: 'knowledgeTags',
            key: 'knowledgeTags'
        },
        {
            title: 'version',
            dataIndex: 'version',
            key: 'version'
        },
        {
            title: 'helpful',
            dataIndex: 'helpful',
            key: 'helpful'
        },
        {
            title: 'totalComment',
            dataIndex: 'totalComment',
            key: 'totalComment'
        },
        {
            title: 'createdBy',
            dataIndex: 'createdBy',
            key: 'createdBy'
        },
        {
            title: 'createdDate',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'lastModifiedBy',
            dataIndex: 'lastModifiedBy',
            key: 'lastModifiedBy'
        },
        {
            title: 'lastModifiedDate',
            dataIndex: 'lastModifiedDate',
            key: 'lastModifiedDate'
        }
    ]

    return (
        <>
            <Table
                dataSource={data}
                columns={columns}
                style={{ overflow: 'auto' }}
                pagination={false}
            />
        </>
    )
}

export default KnowledgeListPreview
