import React, {useEffect, useState} from 'react'
import DetailCharging from './DetailCharging'
interface Param {
    woId: string,
    getItemCharging: Function
    key: string
}

type Props = Param
const RelateCharging: React.FC<Props> = (props: Props) => {
    const [tempLogistic, setTempLogistic] = useState<Array<any>>([])
    const [tempRepair, setTempRepair] = useState<Array<any>>([])

    const getDataLogistic = (data) => {
        if (data) {
            setTempLogistic(data)
            if (tempLogistic.length === 0) {
                props.getItemCharging(tempRepair.concat(data))
            } else {
                props.getItemCharging(tempLogistic.concat(tempRepair))
            }
        }
    }

    const getDataRepair = (data) => {
        if (data) {
            setTempRepair(data)
            if (tempRepair.length === 0) {
                props.getItemCharging(tempLogistic.concat(data))
            } else {
                props.getItemCharging(tempLogistic.concat(tempRepair))
            }
        }
    }

    const deleteDataLogistic = (key) => {
        const tempData = tempLogistic.filter(data => data.chargeDescription !== key.chargeDescription)
        setTempLogistic(tempData)
        props.getItemCharging(tempData.concat(tempRepair))
    }

    const deleteDataRepair = (key) => {
        const tempData = tempRepair.filter(data => data.chargeDescription !== key.chargeDescription)
        setTempRepair(tempData)
        props.getItemCharging(tempData.concat(tempLogistic))
    }

    return (
        <>
            <DetailCharging topic="Logistic" woId={props.woId} getDataLogistic={getDataLogistic} deleteDataLogistic={deleteDataLogistic} key={props.key} />
            <br /><br />
            <DetailCharging topic='Repair' woId={props.woId} getDataRepair={getDataRepair} deleteDataRepair={deleteDataRepair} key={props.key} />
        </>
    )
}

export default RelateCharging
