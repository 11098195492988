/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, {useState, useCallback, useRef, useEffect} from 'react'
import {
    Table,
    Card,
    Breadcrumb,
    Menu,
    Dropdown,
    Icon,
    Button,
    Tooltip,
    Switch,
    Input,
    Select,
    Form,
    message,
    Modal,
    InputNumber,
    Spin
} from 'antd'
import {DndProvider} from 'react-dnd'
import update from 'immutability-helper'
import {RNDContext, DragableBodyRow} from './DragableBodyRow'
import {StoreState} from '../store'
import {FormComponentProps} from 'antd/lib/form'
import {connect} from 'react-redux'
import {getAllGeneralIncidentSetting} from '../incident-management/general-incident-setting/service'
import {Link, Redirect} from 'react-router-dom'
import {CustomFiled, CustomFileds} from './model'
import {getAllCustomFields, addCustomFields, updateCustomFields} from './service'
import {SelectValue} from 'antd/lib/select'
import {emit} from 'cluster'

const mapStateToProps = (state: StoreState) => {
    return {
        generalIncidentSetting: state.generalIncidentSetting,
        customFields: state.customFields
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllGeneralIncidentSetting: () => Promise<number>
    getAllCustomFields: (value: String) => Promise<number>
    addCustomFields: (customField: CustomFileds) => Promise<number>
    updateCustomFields: (customField: CustomFileds) => Promise<number>
}
let arr: CustomFiled[] = []
type Props = StateProps & DispatchProps & FormComponentProps
const DragSortingTable: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, getFieldValue, validateFields, setFieldsValue, resetFields} = props.form
    const {Option} = Select
    const {confirm} = Modal
    const [data, setData] = useState<CustomFiled[]>([])
    const [selectTicketType, setSelectTicketType] = useState<String>('')
    const [isRedirect, setIsRedirect] = useState<Boolean>(false)
    const [checkIsUpdate, setCheckIsUpdate] = useState<Boolean>(false)
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState({}), [])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isRender, setIsRender] = useState<Boolean>(false)
    const [isSpinning, setIsSpinning] = useState(false)

    useEffect(() => {
        initGeneralIncidentSetting()
    }, [selectTicketType])

    useEffect(() => {
        // if (props.customFields.fields) {
        //     props.customFields.fields.forEach((obj, index) => {
        //         obj.id = index
        //     })
        // }
        const fetchCustomFieldData = props.customFields?.fields?.map(it => {
            it.createdDate = undefined
            it.createdBy = undefined
            it.lastModifiedBy = undefined
            it.lastModifiedDate = undefined
            return it
        })
        setData(fetchCustomFieldData)
        setIsSpinning(false)
    }, [props.customFields])

    useEffect(() => {
    }, [data, checkIsUpdate])

    useEffect(() => {
        if (props.generalIncidentSetting.ticketTypes && props.generalIncidentSetting.ticketTypes?.length > 0 && !isRender) {
            setIsRender(true)
            setSelectTicketType(props.generalIncidentSetting.ticketTypes[0].value)
        }
    }, [props.generalIncidentSetting.ticketTypes])

    const initGeneralIncidentSetting = () => {
        props.getAllGeneralIncidentSetting()
        setIsSpinning(true)
        if (selectTicketType) {
            props.getAllCustomFields(selectTicketType).then((res) => {
                setCheckIsUpdate(false)
                // setIsSpinning(false)
            }).catch((_err) => {
                setCheckIsUpdate(true)
                setData([])
                setIsSpinning(false)
            })
        }
    }

    const AddData = () => {
        arr = []
        const initData = {
            // id: data.length,
            dataType: undefined,
            defaultValue: undefined,
            description: undefined,
            inputType: undefined,
            isRequireFields: false,
            label: undefined,
            name: undefined,
            placeholder: undefined,
            selectOption: undefined
        } as unknown as CustomFiled
        arr.push(initData)
        // data.forEach((obj) => {
        //     if (obj.id === initData.id) {
        //         console.log('++++')
        //         console.log(obj.id)
        //         initData.id = (obj.id as number)++
        //     }
        // })
        setData((data || []).concat(arr))
        forceUpdate()
    }

    const handleChangeName = (row: CustomFiled, value: string) => {
        row.name = value
    }

    const handleChangeLabel = (row: CustomFiled, value: string) => {
        row.label = value
    }

    const handleChangeDescription = (row: CustomFiled, value: string) => {
        row.description = value
    }

    const handleChangeInputType = (row: CustomFiled, value, index: number) => {
        row.inputType = value
        const setValue = {}
        setValue['selectOption' + row.id + index] = undefined
        setValue['defaultValue' + row.id + index] = undefined
        setValue['placeholder' + row.id + index] = undefined
        row.defaultValue = ''
        row.selectOption = ''
        row.placeholder = ''
        if (value === 'Checkbox' || value === 'DropdownSelect' || value === 'RadioButton') {
            setValue['dataType' + row.id + index] = 'String'
            row.dataType = 'String'
            setFieldsValue(setValue)
        } else if (value === 'DatePicker') {
            setValue['dataType' + row.id + index] = 'Date'
            row.dataType = 'Date'
            setFieldsValue(setValue)
        } else if (value === 'InputNumber') {
            setValue['dataType' + row.id + index] = 'Number'
            row.dataType = 'Number'
            setValue['defaultValue' + row.id + index] = 0
            row.defaultValue = 0
            setFieldsValue(setValue)
        } else {
            setValue['dataType' + row.id + index] = undefined
            row.dataType = undefined
            setFieldsValue(setValue)
        }
    }

    const handleChangeDataType = (row: CustomFiled, value, index: number) => {
        const setValue = {}
        row.dataType = value
        row.defaultValue = ''
        row.selectOption = ''
        row.placeholder = ''
        setValue['selectOption' + row.id + index] = undefined
        setValue['defaultValue' + row.id + index] = undefined
        setValue['placeholder' + row.id + index] = undefined
        setFieldsValue(setValue)
    }

    const handleChangeSelectOption = (row: CustomFiled, value) => {
        row.selectOption = Array.isArray(value) ? value.join('|') : value
    }

    const handleChangeDefaultValue = (row: CustomFiled, value: string | number) => {
        row.defaultValue = value
    }

    const handleChangePlaceholder = (row: CustomFiled, value: string) => {
        row.placeholder = value
    }

    // const validateFieldName = (_: any, value: any, callback) => {
    //     // if (props.form.getFieldValue('RoleName')) {
    //     if (props.customFields.fields) {
    //         const fieldNameList = props.customFields.fields.map((value) => {
    //             return value.name
    //         })
    //         // if (isEdit && value.trim() === defaultRoleAndPermissionName) {
    //         //     callback()
    //         // } else
    //         if (fieldNameList.includes(value.trim())) {
    //             callback('The Field name must be unique. Please try another one.')
    //         } else {
    //             callback()
    //         }
    //     }
    // // } else {
    // //     callback()
    // // }
    // }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, data, index) => (
                <>
                    <Tooltip placement="bottom" title={'Add'}><Icon className="del_icon" type="plus-square"
                        onClick={() => addAfterRow(row.id, index)}/></Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, data, index) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'}><Icon className="edit_icon" type="minus-square"
                        onClick={() => deleteRow(index)}/></Tooltip>
                </>
            )
        },
        {
            title: <span><span className={'requiredIcon'}>* </span>Field name</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('name' + row.name + index, {
                            initialValue: row.name,
                            rules: [{required: true, message: 'Field name is required'}]
                        })(
                            <Input onChange={(e) => handleChangeName(row, e.target.value)}/>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: <span><span className={'requiredIcon'}>* </span>Field label</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('label' + row.id + index, {
                            initialValue: row.label,
                            rules: [{required: true, message: 'Field label is required'}]
                        })(
                            <Input onChange={(e) => handleChangeLabel(row, e.target.value)}/>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Field description',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('description' + row.id + index, {
                            initialValue: row.description,
                            rules: [{required: false}]
                        })(
                            <Input onChange={(e) => handleChangeDescription(row, e.target.value)}/>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: <span><span className={'requiredIcon'}>* </span>Input type</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('inputType' + row.id + index, {
                            initialValue: row.inputType,
                            rules: [{required: true, message: 'Input type is required'}]
                        })(
                            <Select style={{width: 135}} onChange={(e) => handleChangeInputType(row, e, index)} allowClear>
                                <Option value="Checkbox">Checkbox</Option>
                                <Option value="DatePicker">Date picker</Option>
                                <Option value="DropdownSelect">Dropdown Select</Option>
                                <Option value="InputNumber">Input number</Option>
                                <Option value="RadioButton">Radio button</Option>
                                <Option value="TextBox">Text box</Option>
                            </Select>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: <span><span className={'requiredIcon'}>* </span>Data type</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('dataType' + row.id + index, {
                            initialValue: row.dataType,
                            rules: [{required: true, message: 'Data type is required'}]
                        })(
                            <Select style={{width: 135}} onChange={(e) => handleChangeDataType(row, e, index)} allowClear>
                                {getFieldValue('inputType' + row.id + index) === 'DropdownSelect' || getFieldValue('inputType' + row.id + index) === 'RadioButton' || getFieldValue('inputType' + row.id + index) === 'TextBox' || getFieldValue('inputType' + row.id + index) === 'Checkbox'
                                    ? <Option value="String" key='1'>String</Option>
                                    : null}
                                {getFieldValue('inputType' + row.id + index) === 'InputNumber'
                                    ? <Option value="Number" key='2'>Number</Option>
                                    : null}
                                {getFieldValue('inputType' + row.id + index) === 'TextBox'
                                    ? <Option value="Email" key='3'>Email</Option> : null}
                                {/* {getFieldValue('inputType' + row.id) === 'Checkbox' ? <Option value="Boolean" key='4'>Boolean</Option> : null} */}
                                {getFieldValue('inputType' + row.id + index) === 'DatePicker'
                                    ? <Option value="Date" key='5'>Date</Option> : null}
                            </Select>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Select option',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <div key={index}>
                    <Form.Item>
                        {getFieldDecorator('selectOption' + row.id + index, {
                            initialValue: row.selectOption ? row.selectOption.split('|') : undefined,
                            rules: [{
                                required: (getFieldValue('inputType' + row.id + index) === 'DropdownSelect' || getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'RadioButton'),
                                message: 'Select option is required'
                            }]
                        })(
                            <Select
                                disabled={!(getFieldValue('inputType' + row.id + index) === 'DropdownSelect' || getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'RadioButton')}
                                mode="tags" style={{width: 135}} onChange={(e => handleChangeSelectOption(row, e))}/>
                        )}
                    </Form.Item>
                </div>
            )
        },
        {
            title: 'Default value',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('defaultValue' + row.id + index, {
                            initialValue: getFieldValue('dataType' + row.id + index) === 'Number' ? Number(row.defaultValue) : row.defaultValue,
                            rules: [
                                {
                                    type: getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'DatePicker' || getFieldValue('inputType' + row.id + index) === 'RadioButton'
                                        ? undefined
                                        : getFieldValue('dataType' + row.id + index)
                                            ? (getFieldValue('dataType' + row.id + index) as String).toLowerCase()
                                            : undefined,
                                    message: 'default value is not a valid'
                                }, {
                                    required: false
                                }
                            ]
                        })(
                            getFieldValue('dataType' + row.id + index) === 'Number'
                                ? <InputNumber min={0} onChange={(e) => handleChangeDefaultValue(row, e!!)}
                                    style={{width: '100%'}} defaultValue={0}
                                    disabled={getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'DatePicker' || getFieldValue('inputType' + row.id + index) === 'RadioButton'}/>
                                : <Input onChange={(e) => handleChangeDefaultValue(row, e.target.value)}
                                    disabled={getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'DatePicker' || getFieldValue('inputType' + row.id + index) === 'RadioButton'}
                                />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Placeholder',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('placeholder' + row.id + index, {
                            initialValue: row.placeholder,
                            rules: [{required: false}]
                        })(
                            <Input onChange={(e) => handleChangePlaceholder(row, e.target.value)}
                                disabled={getFieldValue('inputType' + row.id + index) === 'Checkbox' || getFieldValue('inputType' + row.id + index) === 'DatePicker' || getFieldValue('inputType' + row.id + index) === 'RadioButton'}
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Require field',
            dataIndex: '',
            key: '',
            render: (row) => (
                <>
                    <Tooltip placement="bottom" title={row.isRequireFields ? 'Deactivate' : 'Activate'}>
                        <Switch checked={row.isRequireFields} onChange={() => toggleActiveness(row)}/>
                    </Tooltip>
                </>
            )
        }
    ]

    const addAfterRow = (currentRow, index) => {
        data.forEach((obj) => {
            if (Number(obj.id) >= currentRow + 1) {
                obj.id = Number(obj.id) + 1
            }
        })
        data.splice(index + 1, 0, {
            id: currentRow + 1,
            dataType: undefined,
            defaultValue: undefined,
            description: undefined,
            inputType: undefined,
            isRequireFields: false,
            label: undefined,
            name: undefined,
            placeholder: undefined,
            selectOption: ''
        })
        resetFields()
        setData(data)
        forceUpdate()
    }

    const deleteRow = (index) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete this data?',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            async onOk() {
                const tempData = data.filter((obj, objIndex) => {
                    if (objIndex !== index) {
                        return obj
                    }
                })
                setData(tempData)
            },
            onCancel() {
            }
        })
    }

    const toggleActiveness = (data: CustomFiled) => {
        data.isRequireFields = !data.isRequireFields
        forceUpdate()
    }

    const menu = () => {
        return (
            <Menu>
                {props.generalIncidentSetting?.ticketTypes?.map((it, index) => {
                    return (<Menu.Item key={index} onClick={() => setTicketTypes(it.value)}>
                        <span>{it.value}</span>
                    </Menu.Item>)
                })}
            </Menu>
        )
    }

    const setTicketTypes = (text: string) => {
        setSelectTicketType(text!!)
    }

    const components = {
        body: {
            row: DragableBodyRow
        }
    }

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex]
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow]
                    ]
                })
            )
        },
        [data]
    )

    const manager = useRef(RNDContext)

    const renderTable = () => {
        return (
            <DndProvider manager={manager.current.dragDropManager!!}>
                <Spin spinning={isSpinning}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        components={components}
                        rowKey='id'
                        onRow={(record, index) => ({
                            index,
                            moveRow
                        })}
                    />
                </Spin>
            </DndProvider>
        )
    }

    const goBack = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const handleSubmit = (e: any): void => {
        e.preventDefault()
        validateFields((_err) => {
            if (_err) {
                console.log('err')
            } else {
                setIsLoading(true)
                data.map(it => {
                    it.id = undefined
                })
                const formData = {
                    ticketType: selectTicketType,
                    fields: data
                }
                // if (!checkIsUpdate) {
                // Update
                props.updateCustomFields(formData as CustomFileds).then((res) => {
                    if (res === 200) {
                        setIsRedirect(true)
                        message.success('The Update has finished successfully')
                        setIsLoading(false)
                    }
                }).catch((err) => {
                    setIsLoading(false)
                    message.error(err.response.data.message)
                })
                // } else {
                //     // Insert
                //     delete formData.id
                //     props.addCustomFields(formData).then((res) => {
                //         if (res === 201) {
                //             setIsRedirect(true)
                //             message.success('You have Successfully save the data.')
                //             setCheckIsUpdate(false)
                //             setIsLoading(false)
                //         }
                //     }).catch((_err) => {
                //         setIsLoading(false)
                //         message.error(_err.response.data.message)
                //     })
                // }
            }
        })
    }

    return (
        <>
            {isRedirect ? <Redirect to='Setting'/> : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Custom Field Setting</Breadcrumb.Item>
            </Breadcrumb>
            <br/>
            <Card style={{overflow: 'auto'}}>
                <h3 className={'main-title'}>Custom Field Setting</h3>
                <div style={{marginBottom: 10}}>
                    <span style={{color: 'red'}}>* <span style={{color: '#323276'}}>Ticket Type </span></span>
                    <Dropdown overlay={() => menu()} trigger={['click']}>
                        <span style={{color: '#323276', fontWeight: 'bold'}}>
                            {selectTicketType} <Icon type="down"/>
                        </span>
                    </Dropdown>
                </div>
                <Button onClick={() => AddData()} htmlType="button" className="addCriteriaButton">Add</Button>
                <br/><br/>
                <Form onSubmit={handleSubmit}>
                    {renderTable()}
                    <Form.Item style={{float: 'right', marginTop: 10}}>
                        <Button htmlType="button"
                            onClick={() => goBack()}
                            style={{marginRight: 10}}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    )
}

const MyFormDragSortingTable = Form.create<Props>()(DragSortingTable)

export default connect(mapStateToProps, {
    getAllGeneralIncidentSetting,
    getAllCustomFields: getAllCustomFields,
    addCustomFields: addCustomFields,
    updateCustomFields: updateCustomFields
})(MyFormDragSortingTable)
