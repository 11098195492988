import { Dispatch } from 'redux'
import { GetAllCategorySuccessEvent } from './state-event'
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from '../rest'
import { Category } from './model'

export const getAllCategory = (groupUser?: string[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<Category[]>('/api/knowledge/categories/findAll', groupUser)
            dispatch(GetAllCategorySuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            throw err.response
        }
    }
}

export const getCetagoryById = async (categoryId: number, groupUser?: string[]) => {
    try {
        const response = await axiosPost<Category>(`/api/knowledge/categories/findById/${categoryId}`, groupUser)
        return response.data.data!!
    } catch (err) {
        throw err.response
    }
}

export const createCategory = async (data: Category) => {
    try {
        return await axiosPost<Category>('/api/knowledge/categories', data)
    } catch (err) {
        throw err.response
    }
}

export const updateCategory = async (data: Category) => {
    try {
        return await axiosPatch<Category>(`/api/knowledge/categories/${data.id}`, data)
    } catch (err) {
        throw err.response
    }
}

export const deleteCategory = async (categoryId: string) => {
    try {
        return await axiosDelete<Category>(`/api/knowledge/categories/${categoryId}`)
    } catch (err) {
        throw err.response
    }
}
