import React, {useEffect, useState} from 'react'
import {Card, Col, Modal, Row, Upload} from 'antd'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getCommentListByIncidentId} from './service'
import moment from 'moment'
import {StoreState} from '../../store'
import {connect} from 'react-redux'
import {CommentType} from './model'
import {FileUpload} from '../../common-model'
import {UploadFile} from 'antd/es/upload/interface'
import {FetchingState, fetchWithState, SpinningFetcher} from '../../common-components'
import {CommentWebSocketChannel} from './web-socket-channel'
import AutoAvatar from '../../common-components/autoAvatar'
import CommentRender from './CommentRender'
import TimeRender from './TimeRender'
import exportIcon from '../../common-file/icon-file'

const mapStateToProps = (state: StoreState) => ({
    comments: (state.comments || [])
})

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getCommentListByIncidentId: (id: string) => Promise<number>
}

interface Params {
    incidentId: string
}

type Props = StateProps & DispatchProps & Params

const CommentSection: React.FC<Props> = (props: Props) => {
    library.add(fas)

    const [fileList, setFileList] = useState<any>([])
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [commentsFetchingState, setCommentFetchingState] = useState<FetchingState>(FetchingState.NotStarted)

    useEffect(() => {
        CommentWebSocketChannel.subscribe()
    }, [])

    useEffect(() => {
        if (props.incidentId !== null) {
            CommentWebSocketChannel.subscribeToIncidentIds([props.incidentId!!])
            reloadAllComments()
        }
    }, [props.incidentId])

    useEffect(() => {
        fetchWithState(commentsFetchingState, setCommentFetchingState, (): Promise<number> => {
            return props.getCommentListByIncidentId(props.incidentId)
        })
    }, [props.incidentId, commentsFetchingState])

    const reloadAllComments = () => {
        setCommentFetchingState(FetchingState.NotStarted)
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handleChange = (fileList: any) => setFileList(fileList)

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
            setPreviewVisible(true)
        } else {
            setPreviewVisible(false)
        }
        setPreviewImage(file.url || file.preview)
    }

    const convertFileListFormat = (fileList: FileUpload[]) => {
        return fileList.map((dataFile, index) => {
            return {
                uid: index,
                name: `${dataFile.name}`,
                status: 'done',
                url: dataFile.url,
                thumbUrl: exportIcon(dataFile.type!!)
            } as unknown as UploadFile
        })
    }

    const handleDownload = (file) => {
        const link = document.createElement('a')
        link.href = file.url
        link.download = file.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const renderComments = () => {
        return props.comments.map((comment) => {
            return <div key={comment.id!!} style={{ marginBottom: '5px' }}>
                {!comment.isResolution
                    ? <Card style={{ border: '1.5px solid #E0E0E0' }} >
                        <Row style={{ alignItems: 'center', display: 'flex' }}>
                            <Col span={2} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <AutoAvatar title={comment.createdBy || ''} broken={false}/>
                            </Col>
                            <Col span={22}>
                                <span><a>{comment.createdBy}</a></span>
                                <span style={{ color: 'gray', fontSize: '12px', fontStyle: 'italic', marginLeft: 3 }}>
                                    {comment?.type === CommentType.AddNote ? 'added a ' : null}
                                    {comment?.type === CommentType.Forward ? 'forwarded ' : null}
                                    {comment?.type === CommentType.Reply ? 'replied ' : null}
                                    {comment?.type === CommentType.AddNote ? comment.visibleToCustomer ? 'public note ' : 'private note ' : null}
                                    <TimeRender Date={comment.createdDate!!}/>
                                    {' '} ago ({moment(comment.createdDate).format('YYYY-MM-DD HH:mm:ss')})
                                </span>
                            </Col>
                        </Row>
                        <br />
                        <Row style={{ alignItems: 'center' }}>
                            <Col span={2} style={{ justifyContent: 'center', alignItems: 'top', display: 'flex' }} >
                                {comment.visibleToCustomer ? null : <FontAwesomeIcon style={{ color: '#FF4003' }} icon={['fas', 'lock']} />}
                            </Col >
                            <Col span={22} >
                                {comment?.type === CommentType.Reply || comment?.type === CommentType.Forward ? (<>
                                From : <span style={{color: '#6A6C8F'}}>{comment.from}</span><br />
                                Replied to : <span style={{color: '#6A6C8F'}}>{comment.to}</span><br /><br />
                                </>) : null}
                                <CommentRender text={comment.text!!} type={comment?.type} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={2}/>
                            <Col span={22}>
                                <Upload
                                    listType="picture"
                                    className={'upload-list-inline'}
                                    fileList={convertFileListFormat(comment.uploadLink ? comment.uploadLink!! : [])}
                                    onPreview={(e: any) => handlePreview(e)}
                                    onChange={(e: any) => handleChange(e)}
                                    onDownload={(e: any) => handleDownload(e)}
                                    showUploadList={{ showRemoveIcon: false }}
                                />
                            </Col>
                        </Row>
                        <Modal visible={previewVisible} footer={null} onCancel={ () => setPreviewVisible(false)} >
                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </Card>
                    : null }
            </div>
        })
    }

    return (
        <>
            <br />
            <SpinningFetcher fetchingState={commentsFetchingState}>
                {renderComments()}
            </SpinningFetcher>
        </>
    )
}

export default connect(mapStateToProps, {
    getCommentListByIncidentId
})(CommentSection)
