import {Dispatch} from 'redux'
import {ResponseUserRole} from './model'
import {UpdateUserRoleCurrentPageSuccessEvent, UpdateUserRoleTotalPageSuccessEvent, USER_ROLES_DATA_SHOW} from './type'
import {axiosPostWithPagination} from '../../rest'

export const BASE_URL = process.env.REACT_APP_BASE_URL_AUTH!!

export const getUserRoleWithPagination = (selectedRoles: string[], page: number, pageSize: number, searchValue: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const url = `/api/authority/user/searchUser?page=${page}&pageSize=${pageSize}&searchValue=${searchValue}`
            const response = await axiosPostWithPagination<ResponseUserRole[]>(url, selectedRoles)
            dispatch(UpdateUserRoleCurrentPageSuccessEvent.build(response.data.data.number + 1))
            dispatch(UpdateUserRoleTotalPageSuccessEvent.build(response.data.data.totalElements))
            dispatch(USER_ROLES_DATA_SHOW.build(response.data.data.content))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}
