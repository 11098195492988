import { FetchingState } from './fetching-with-state'
import { Spin } from 'antd'
import React, { PropsWithChildren } from 'react'
import { notEmpty } from '../common-misc'
import * as PropTypes from 'prop-types'

interface Params {
    fetchingState: FetchingState | FetchingState[]
    size?: 'small' | 'default' | 'large'
}

type Props = Params & PropsWithChildren<Params>

export const SpinningFetcher: React.FC<Props> = (props: Props) => {
    const arr = Array.isArray(props.fetchingState)
        ? (props.fetchingState as FetchingState[])
        : [props.fetchingState as FetchingState]
    const thisFetchingState = arr.shift()
    const moreFetchingStates = notEmpty(arr) ? arr : undefined

    let element: JSX.Element = <></>
    switch (thisFetchingState) {
    case FetchingState.NotStarted:
        element = <span>Fetching not started</span>
        break
    case FetchingState.Started:
        element = <span>Fetching data</span>
        break
    case FetchingState.Succeed:
        element = <div>{props.children}</div>
        break
    case FetchingState.Failed:
        element = <span>Failed to fetch data</span>
        break
    }

    if (notEmpty(moreFetchingStates)) {
        element = <SpinningFetcher fetchingState={moreFetchingStates!!}>{element}</SpinningFetcher>
    }

    return <Spin spinning={thisFetchingState === FetchingState.Started} size={props.size || 'large'}>{element}</Spin>
}
