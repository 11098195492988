import { UploadFile } from 'antd/lib/upload/interface'
import Compress from 'compress.js'
import { postUploadFile, getFileUrl } from './service'
import { UploadLink } from './model'
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)

const compress = new Compress()

export default class FSS {
    public static putFile = (fileList: UploadFile[], path: String, callBack: Function) => {
        const uploadedList: UploadLink[] = []

        function dataURItoBlob(dataURI) {
            let byteString
            if (dataURI.split(',')[0].indexOf('base64') >= 0) {
                const uri = dataURI.split(',')[1]
                byteString = atob(uri)
            } else {
                byteString = unescape(dataURI.split(',')[1])
            }

            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i)
            }
            return new Blob([ia], {type: mimeString})
        }

        function dataURLtoFile(dataUrl, filename) {
            const arr = dataUrl.split(',')
            const mime = arr[0].match(/:(.*?);/)[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], filename, {type: mime})
        }

        function uploadFile(formData: FormData, file: UploadFile) {
            postUploadFile(formData).then(() => {
                i += 1
                const newPath = `${getFileUrl(path)}${file.uid}.${file.name.split('.').pop()}`
                uploadedList.push(new UploadLink(file.name, newPath, file.type))
                if (i === fileList.length) {
                    callBack(uploadedList)
                }
            }).catch((err) => {
                console.log(err.code)
                console.log(err.message)
                callBack()
            })
        }

        let i: number = 0
        fileList.map((file: UploadFile) => {
            if (file.url && !file.url.includes('data:')) {
                i += 1
                uploadedList.push(new UploadLink(file.name, file.url!!, file.type))
                if (i === fileList.length) {
                    callBack(uploadedList)
                }
            } else {
                if (file.size <= fileSize || path === '/signature/') {
                    const blob = dataURItoBlob(file.url)
                    const fd = new FormData(document.forms[0])
                    fd.append('path', `${path}${file.uid}.${file.name.split('.').pop()}`)
                    fd.append('file', blob)
                    uploadFile(fd, file)
                } else {
                    const originalFile = dataURLtoFile(file.url, file.fileName)
                    compress.compress([originalFile], {
                        size: 1, // the max size in MB, defaults to 2MB
                        quality: 0.75, // the quality of the image, max is 1,
                        maxWidth: 1280, // the max width of the output image, defaults to 1920px
                        maxHeight: 1280, // the max height of the output image, defaults to 1920px
                        resize: true // defaults to true, set false if you do not want to resize the image width and height
                    }).then((data) => {
                        const img = data[0]
                        const base64str = img.data
                        const imgExt = img.ext
                        const resizedFile = Compress.convertBase64ToFile(base64str, imgExt)
                        const fd = new FormData(document.forms[0])
                        fd.append('path', `${path}${file.uid}.${file.name.split('.').pop()}`)
                        fd.append('file', resizedFile)
                        uploadFile(fd, file)
                    })
                }
            }
        })
    }
}
