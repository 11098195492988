import {
    CustomFiledsStateEvent,
    GetAllCustomFiledsSuccessEvent
} from './state-event'

export const CustomFiledsStateReducer = (state = {}, event: CustomFiledsStateEvent<any>) => {
    if (event.type === GetAllCustomFiledsSuccessEvent.type) {
        return event.payload || {}
    } else {
        return state
    }
}
