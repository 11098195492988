import moment from 'moment'
import {
    AddIncidentViewSuccessEvent, CurrentViewCriteriaChangedEvent,
    GetCurrentIncidentViewSuccessEvent,
    IncidentViewStateEvent, UserChangeOrderByEvent, UserChangeSortByEvent,
    SetCurrentIncidentViewSuccessEvent,
    UpdateIncidentViewSuccessEvent
} from './state-event'
import { IncidentViewState } from './state-model'

export const incidentViewStateReducer = (state: IncidentViewState = {}, event: IncidentViewStateEvent<any>) => {
    if (event.type === GetCurrentIncidentViewSuccessEvent.type ||
        event.type === SetCurrentIncidentViewSuccessEvent.type
    ) {
        state = event.payload
    } else if (event.type === AddIncidentViewSuccessEvent.type) {
        state.currentView = event.payload
        state.allVisibleViewNames = Array.from([...(state.allVisibleViewNames || []), event.payload.name]).sort()
    }else if (event.type === UpdateIncidentViewSuccessEvent.type) {
        state.currentView = event.payload
        state.allVisibleViewNames = Array.from([...(state.allVisibleViewNames || [])]).sort()
    }  else if (event.type === CurrentViewCriteriaChangedEvent.type) {
        state.currentView!!.criteria = event.payload
        state.currentView!!.isCriteriaDirty = true
    } else if (event.type === UserChangeSortByEvent.type) {
        state.sortBy = event.payload
    } else if (event.type === UserChangeOrderByEvent.type) {
        state.orderBy = event.payload
    }
    else {
        return state
    }
    return {...state, version: moment()}
}
