import { Dispatch } from 'redux'
import { GetAllGeneralIncidentSettingSuccessEvent } from './state-event'
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from '../../incident-management/rest'
import {GeneralIncidentSetting, RequestBodyGeneralIncidentSetting} from './model'

export const getAllGeneralIncidentSetting = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<GeneralIncidentSetting>('/api/incident/generalIncidentSetting')

            const setting: GeneralIncidentSetting = response.data.data || {}
            dispatch(GetAllGeneralIncidentSettingSuccessEvent.build(setting))
            // dispatch(GetAllGeneralIncidentSettingSuccessEvent.build(response.data.data[0] || []))
            return response.status
        } catch (err) {
            throw err.response
        }
    }
}

export const createGeneralIncidentSetting = async (data: RequestBodyGeneralIncidentSetting[]) => {
    try {
        return await axiosPost<GeneralIncidentSetting>('/api/incident/generalIncidentSetting/create', data)
    } catch (err) {
        throw err.response
    }
}

// export const updateGeneralIncidentSetting = async (data: RequestBodyGeneralIncidentSetting[]) => {
//     try {
//         return await axiosPatch<GeneralIncidentSetting>(`/api/incident/generalIncidentSetting/${id}`, data)
//     } catch (err) {
//         throw err.response
//     }
// }
