import { IncidentWebSocket } from '../web-socket'
import { message } from 'antd'
import { Frame } from 'stompjs'
import { IncidentWebSocketEvent, IncidentWebSocketEventType } from './web-socket-model'
import store from '../../store'
import { IncidentDeletedEvent, IncidentUpdatedEvent, SlaCalculationsUpdatedEvent } from './state-event'

function onIncidentEvent(frame: Frame) {
    const event: IncidentWebSocketEvent = JSON.parse(frame.body)
    console.log(event)
    if (event.eventType === IncidentWebSocketEventType.SlaCalculationsUpdated) {
        store.dispatch(SlaCalculationsUpdatedEvent.build(event))
    } else if (event.eventType === IncidentWebSocketEventType.Updated) {
        store.dispatch(IncidentUpdatedEvent.build(event.incident!!))
    } else if (event.eventType === IncidentWebSocketEventType.Deleted) {
        store.dispatch(IncidentDeletedEvent.build(event.incidentId!!))
    }
}

export const IncidentWebSocketChannel = {
    subscribe: () => {
        IncidentWebSocket.getClient()
            .then((client) => {
                // client.subscribe(IncidentWebSocket.buildDestination(client, '/incidents/event'), onIncidentEvent)
                // client.subscribe(IncidentWebSocket.buildDestination(client, '/sla/event'), onIncidentEvent)
            })
            .catch((_err) => {
                // message.error(`Failed web socket connection. ${err}`)
            })
    },
    subscribeToIncidentIds: (incidentIds: number[], unsubscribeAll: boolean = true) => {
        IncidentWebSocket.getClient()
            .then((client) => {
                if (unsubscribeAll) {
                    client.send('/app/incidents.unsubscribeAll', {})
                    client.send('/app/sla.unsubscribeAll', {})
                }
                // client.send('/app/incidents.subscribe', {}, JSON.stringify(incidentIds))
                // client.send('/app/sla.subscribe', {}, JSON.stringify(incidentIds))
            })
            .catch((_err) => {
                // message.error(`Failed web socket connection. ${err}`)
            })
    },
    subscribeDashboard: (onDashboard) => {
        IncidentWebSocket.getClient()
            .then((client) => {
                // client.subscribe(IncidentWebSocket.buildDestination(client, '/dashboard/event'), onDashboard)
            })
            .catch((_err) => {
                // message.error(`Failed web socket connection. ${err}`)
            })
    },
    subscribeTodashboard: (unsubscribeAll: boolean = true) => {
        IncidentWebSocket.getClient()
            .then((client) => {
                if (unsubscribeAll) {
                    client.send('/app/dashboard.unsubscribeAll', {})
                }
                // client.send('/app/dashboard.subscribe', {}, JSON.stringify([]))
                // client.send('/app/dashboard.subscribe', {}, JSON.stringify([localStorage.getItem('username')!!]))
            })
            .catch((_err) => {
                // message.error(`Failed web socket connection. ${err}`)
            })
    },
    subscribeNotify: (onNotify) => {
        IncidentWebSocket.getClient()
            .then((client) => {
                // client.subscribe(IncidentWebSocket.buildDestination(client, '/dashboard/report/event'), onNotify)
            })
            .catch((_err) => {
                // message.error(`Failed web socket connection. ${err}`)
            })
    },
    subscribeToNotify: (unsubscribeAll: boolean = true) => {
        IncidentWebSocket.getClient()
            .then((client) => {
                if (unsubscribeAll) {
                    client.send('/app/dashboard.unsubscribeAll', {})
                }
                // client.send('/app/dashboard.subscribe', {}, JSON.stringify([localStorage.getItem('username')!!]))
            })
            .catch((_err) => {
                // message.error(`Failed web socket connection. ${err}`)
            })
    }
}
