import React, {useEffect, useState} from 'react'
import {
    AutoComplete,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Radio,
    Row,
    Select
} from 'antd'
import {connect} from 'react-redux'
import {StoreState} from '../../store'
import {useParams} from 'react-router'
import {getAllImpact} from '../impact'
import {getAllUrgency} from '../urgency'
import {Category, getAllCategory} from '../category'
import {getAllSupportTeam} from '../support-team'
import {FieldDetail, SupportTeam, TicketStatus} from '../incident/model'
import {getIncidentById, searchIncidents, updateIncident} from './service'
import {IncidentFilter} from '../incident-view'
import {Incident} from './index'
import {getAllPriority, PriorityLevel, PriorityMatrix, resolvePriority} from '../priority'
import {FormComponentProps} from 'antd/lib/form'
import {SelectValue} from 'antd/lib/select'
import {checkRolesState, RoleType} from '../../authorization-module/permissions'
import {CustomFiled, getAllCustomFields} from '../../configurable-fields'
import moment from 'moment'
import {User} from '../../authorization-module/user-role/duck/model'
import {UserSupportTeam} from '../../authorization-module/support-team-management/model'
import {getTicketStatus} from '../status'
import {disableByStatus} from '../../common-misc/index'

const mapStateToProps = (state: StoreState) => {
    return {
        impacts: state.impacts,
        urgencies: state.urgencies,
        categories: state.categories,
        supportTeams: state.supportTeams,
        incidents: state.incidentState?.incidents || [],
        incident: state.incidentState.incident,
        customFields: state.customFields,
        priorities: state.priorities,
        statuses: state.statuses,
        generalIncidentSetting: state.generalIncidentSetting
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllImpact: () => Promise<number>
    getAllUrgency: () => Promise<number>
    getAllCategory: () => Promise<number>
    getAllSupportTeam: () => Promise<number>
    searchIncidents: (criteria: IncidentFilter, sortBy: string, orderBy: string) => Promise<number>
    updateIncident: (incident: Incident) => Promise<number>
    getAllCustomFields: (value: String) => Promise<number>
    getAllPriority: () => Promise<number>
}

interface Params {
    currentStatus?: TicketStatus
    changeOldStatus: (boolean) => void
    oldStatus?: string
}

type Props = StateProps & DispatchProps & FormComponentProps & Params

const IncidentStatusUpdater: React.FC<Props> = (props: Props) => {
    const {id} = useParams()
    const {Option} = Select

    const [isFetch, setFetch] = useState(false)
    const [myPriority, setMyPriority] = useState<PriorityMatrix>()
    const [subcategoryName, setSubcategoryName] = useState<string[]>([])
    const myCategory: string[] = props.categories.map(it => it.category)
    const [items, setItems] = useState<string[]>([])
    let mySupportTeamName: string[] = []
    const [supportTeam, setSupportTeam] = useState<SupportTeam>()
    const [user, setUser] = useState<User>()
    const [assignee, setAssignee] = useState<UserSupportTeam[]>([])
    const [impact, setImpact] = useState<PriorityLevel>()
    const [urgency, setUrgency] = useState<PriorityLevel>()
    const [isRequireAssignGroup, setIsRequireAssignGroup] = useState<boolean>(false)
    const [isRequireAssignee, setIsRequireAssignee] = useState<boolean>(false)
    const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [inc, setInc] = useState<Incident>()

    useEffect(() => {
        if (!isFetch) {
            fetchData().catch((err) => message.error(`Failed fetching all data 12345678. ${err}`))
            setFetch(true)
            props.getAllImpact().catch((err) => message.error(`Failed fetching all impact. ${err}`))
            props.getAllUrgency().catch((err) => message.error(`Failed fetching all urgency. ${err}`))

            props.getAllCategory().catch((err) => message.error(`Failed fetching all category. ${err}`))
            props.getAllSupportTeam().catch((err) => message.error(`Failed fetching all support team. ${err}`))
        }
        if (props.currentStatus !== undefined) {
            checkCurrentStatusToSetRequireFieldAndDisable()
        }
    }, [props.currentStatus])

    const fetchData = async () => {
        if (id) {
            if (props.incidents?.length !== 0) {
                const incidentsTarget = props.incidents?.filter((target: Incident) => {
                    return target.id === Number(id)
                })
                setIncident(incidentsTarget)
                const settingType = incidentsTarget[0].incidentSetting.find(it => it.incGeneralSetting.settingType === 'ticketType')!!
                props.getAllCustomFields(settingType.incGeneralSetting.value!!)
            } else {
                const target = getIncidentById(Number(id)).then((res: Incident) => {
                    const temp: Incident[] = []
                    if (res) {
                        temp.push(res!!)
                    }
                    return temp
                })
                await target.then((res) => {
                    setIncident(res)
                    const settingType = res[0].incidentSetting.find(it => it.incGeneralSetting.settingType === 'ticketType')!!
                    props.getAllCustomFields(settingType.incGeneralSetting.value!!)
                })
            }
        }
    }

    const setIncident = (incidentsTarget: Incident[]) => {
        incidentsTarget.forEach((res) => {
            setInc(res)
            props.form.setFieldsValue({
                Assignment_group: res.supportTeam?.name,
                Assigned_to: res.user?.people.fullName,
                Impact: res.priority?.impact?.name,
                Urgency: res.priority?.urgency?.name,
                Category: res.category === null ? undefined : res.category,
                subcategory: res.subcategory === null ? undefined : res.subcategory,
                Item: res.item === null ? undefined : res.item
            })
            FilterMyCategory(res.category || '')
        })
        callApiGetPriorities().catch((err) => message.error(`Failed fetching priority. ${err}`))
    }

    const callApiGetPriorities = async () => {
        const myImpact = props.form.getFieldValue('Impact')
        const myUrgency = props.form.getFieldValue('Urgency')
        const impact = props.impacts?.find(it => it.id?.toString() === myImpact)?.id!!
        const urgency = props.urgencies?.find(it => it.id?.toString() === myUrgency)?.id!!
        if (impact !== undefined && urgency !== undefined) {
            resolvePriority(impact, urgency, Number(id)).then((res) => {
                setMyPriority(res)
            }).catch((err) => {
                message.error(`${err}`)
            })
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()

        props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                setIsLoading(true)
                const fieldDetails: FieldDetail[] = (inc?.customField || []).map((obj: FieldDetail) => {
                    const fieldDetail: FieldDetail = {
                        id: obj.id,
                        value: props.form.getFieldValue(obj.name!!)
                    }
                    return fieldDetail
                })
                const patch = {
                    id: id,
                    supportTeam: supportTeam,
                    user: user,
                    priority: myPriority,
                    category: values.Category === undefined ? null : values.Category,
                    subcategory: values.subcategory === undefined ? null : values.subcategory,
                    item: values.Item === undefined ? null : values.Item,
                    status: '',
                    customField: fieldDetails
                } as unknown as Incident
                if (values.Assignment_group && values.Assigned_to) {
                    patch.ticketStatus = getTicketStatus(props.statuses, 'InProgress')
                } else if (values.Assignment_group) {
                    patch.ticketStatus = getTicketStatus(props.statuses, 'Assigned')
                } else {
                    delete patch.ticketStatus
                }
                props.updateIncident((patch as unknown as Incident))
                    .then(() => {
                        message.success('The update has finished successfully.')
                        props.changeOldStatus(true)
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        message.error(`${err}`)
                        setIsLoading(false)
                    })
            }
        })
    }

    const FilterMyCategory = (e: SelectValue | string) => {
        const mySubcategories = props.categories.filter((category: Category) => {
            return category.category === e
        })
        // filter subcategories
        const tempSubcategory: string[] = []
        // const tempItem: string[] = []
        mySubcategories.forEach((subCat: Category) => {
            if (subCat.subCategory && subCat.active !== false) {
                tempSubcategory.push(subCat.subCategory)
                // if (subCat.item) {
                //     tempItem.push(subCat.item)
                // }
                setSubcategoryName(tempSubcategory)
                props.form.setFieldsValue({
                    subcategory: undefined,
                    Item: undefined
                })
                // setItems(tempItem)
            }
        })
    }

    const checkCategoryField = (valueInCategory: SelectValue) => {
        if (valueInCategory) {
            FilterMyCategory(valueInCategory)
        } else {
            props.form.setFieldsValue({
                subcategory: undefined,
                Item: undefined
            })
            setItems([])
        }
    }

    const checkSubcategoryField = (valueInSubcategory: SelectValue) => {
        if (valueInSubcategory) {
            FilterMyItems(valueInSubcategory)
        } else {
            props.form.setFieldsValue({
                Item: undefined
            })
            setItems([])
        }
    }

    const fetchSubcategory = () => {
        const currentCategory = props.form.getFieldValue('Category')
        const filterCategory = props.categories.filter((category: Category) => {
            return category.category === currentCategory
        })
        const fetchSubcategory: string[] = []
        filterCategory.forEach((category: Category) => {
            if (category.subCategory && category.active) {
                fetchSubcategory.push(category.subCategory!!)
            }
        })
        setSubcategoryName(fetchSubcategory)
    }

    const fetchItems = () => {
        const currentSubcategory = props.form.getFieldValue('subcategory')
        const ListItem: string[] = []
        props.categories.forEach((category: Category) => {
            if (category.category && category.active) {
                if (category.subCategory === currentSubcategory) {
                    if (category.item) {
                        ListItem.push(category.item)
                    }
                }
            }
        })
        setItems(ListItem)
    }

    const FilterMyItems = (e: SelectValue) => {
        const categories = props.categories.filter((category: Category) => {
            return category.subCategory === e
        })
        const tempItem: string[] = []
        categories.forEach((cat: Category) => {
            if (cat.subCategory && cat.subCategory === e && cat.active !== false) {
                if (cat.item) {
                    tempItem.push(cat.item)
                }
                props.form.setFieldsValue({
                    Item: undefined
                })
            }
        })
        tempItem.length > 0 ? setItems(tempItem) : setItems([])
    }

    const FilterSupportTeam = (e: SelectValue) => {
        props.form.setFieldsValue({
            Assigned_to: null
        })
        const targetSupportTeams = props.supportTeams.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === e
        })
        if (targetSupportTeams.length !== 0) {
            setSupportTeam(targetSupportTeams[0])
            targetSupportTeams.forEach((res: SupportTeam) => {
                setAssignee(res.assignees)
            })
        } else {
            setAssignee([])
        }
    }

    const FilterAssignee = () => {
        const valAssignee = props.form.getFieldValue('Assignment_group')
        const targetSupportTeams = props.supportTeams.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === valAssignee
        })
        targetSupportTeams.forEach((res) => {
            setAssignee(res.assignees)
        })
    }

    const checkAssignee = (e: SelectValue) => {
        if (e) {
            FilterSupportTeam(e)
        } else {
            props.form.setFieldsValue({
                Assigned_to: null
            })
        }
    }

    const {getFieldDecorator} = props.form

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16}
        }
    }

    const onBlurTeam = (value: SelectValue) => {
        if (mySupportTeamName) {
            const isTeam = mySupportTeamName.includes((value as string))
            if (!isTeam) {
                props.form.setFieldsValue({
                    Assignment_group: null
                })
            }
        }
    }

    const onBlurAssignee = (value: SelectValue) => {
        if (assignee) {
            const isAssignee = assignee.find(it => it.id?.toString() === value || it.user.people.fullName === value)
            if (!isAssignee) {
                props.form.setFieldsValue({
                    Assigned_to: null
                })
            } else {
                setUser(isAssignee.user)
            }
        }
    }

    const checkCurrentStatusToSetRequireFieldAndDisable = () => {
        disableByStatus(props.currentStatus?.status, setDisabledSaveButton, props.incident?.user?.username, props?.incident?.supportTeam)

        if (props.currentStatus?.status === 'InProgress' || (props.currentStatus?.status === 'Pending' && props.oldStatus === 'InProgress')) {
            setIsRequireAssignGroup(true)
            setIsRequireAssignee(true)
        } else if (props.currentStatus?.status === 'Assigned' || (props.currentStatus?.status === 'Pending' && props.oldStatus === 'Assigned')) {
            setIsRequireAssignGroup(true)
            setIsRequireAssignee(false)
        } else if ((props.currentStatus?.status === 'Resolved' || props.currentStatus?.status === 'Closed') &&
            !(props.incident?.user?.username && props.incident.supportTeam)) {
            setIsRequireAssignGroup(true)
            setIsRequireAssignee(true)
        } else {
            setIsRequireAssignGroup(false)
            setIsRequireAssignee(false)
        }
    }

    // eslint-disable-next-line no-return-assign
    return (
        <Form {...formItemLayout} onSubmit={handleSubmit} layout="vertical">
            {
                <>
                    {props.supportTeams.length !== 0 ? (<span style={{display: 'none'}}>{
                        mySupportTeamName = props.supportTeams.map((supportTeam: SupportTeam) => {
                            return supportTeam.name
                        })
                    }</span>
                    ) : null}

                    {props.categories.length !== 0 ? (<span style={{display: 'none'}}>{
                        props.categories.map((category: Category) => {
                            if (category.active) {
                                myCategory.push(category.category)
                            }
                        })
                    }</span>
                    ) : null}

                    <Row>
                        <p>{isRequireAssignGroup ? <span className='requiredIcon'>* </span> : null}<b
                            className="MyText-form">Assignment group</b></p>
                        <Form.Item>
                            {getFieldDecorator('Assignment_group',
                                {
                                    rules: [{
                                        required: isRequireAssignGroup,
                                        whitespace: true,
                                        message: 'Assignment group is required'
                                    }]
                                })(
                                <AutoComplete
                                    style={{width: '250px'}}
                                    dataSource={(mySupportTeamName || []).map((it) => {
                                        return it
                                    })}
                                    onChange={(e) => checkAssignee(e)}
                                    onSelect={(e) => FilterSupportTeam(e)}
                                    onBlur={(e) => onBlurTeam(e)}
                                    placeholder={'Select an assignment group'}
                                    disabled={disabledSaveButton}
                                />
                            )}
                        </Form.Item>
                    </Row>
                    <Row>
                        <p>{isRequireAssignee ? <span className='requiredIcon'>* </span> : null}<b
                            className="MyText-form">Assigned to</b></p>
                        <Form.Item>
                            {getFieldDecorator('Assigned_to',
                                {
                                    rules: [{
                                        required: isRequireAssignee,
                                        whitespace: true,
                                        message: 'Assignee is required'
                                    }]
                                })(
                                <AutoComplete style={{width: '250px'}}
                                    dataSource={assignee.map((item, index: number) => {
                                        return <Option value={item.id?.toString()}
                                            key={index}>{item.user.people.fullName}</Option>
                                    })}
                                    onFocus={() => FilterAssignee()}
                                    onBlur={(e) => onBlurAssignee(e)}
                                    placeholder={'Select an assignee'}
                                    disabled={disabledSaveButton}/>
                            )}
                        </Form.Item>
                    </Row>

                    <Row gutter={8} style={{marginRight: 30}}>
                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                            <p className="MyText-form"><span className={'requiredIcon'}>* </span>Impact</p>
                            <Form.Item>
                                {getFieldDecorator('Impact',
                                    {
                                        rules: [{required: true, whitespace: true, message: 'Impact is required'}]
                                    })(
                                    <Select style={{minWidth: '120px'}} placeholder="-Select-"
                                        onSelect={() => callApiGetPriorities()} disabled={disabledSaveButton}>
                                        {props.impacts.length === 0 ? null : (props.impacts.map((item: PriorityLevel, index: number) => {
                                            return <Option value={item.id?.toString()} key={index}>{item.name}</Option>
                                        }))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>

                        <Col span={12} lg={12} md={12} sm={24} xs={24}>
                            <p className="MyText-form"><span className={'requiredIcon'}>* </span>Urgency</p>
                            <Form.Item>
                                {getFieldDecorator('Urgency',
                                    {
                                        rules: [{required: true, whitespace: true, message: 'Required'}]
                                    })(
                                    <Select style={{minWidth: '120px'}} placeholder="-Select-"
                                        onSelect={() => callApiGetPriorities()} disabled={disabledSaveButton}>
                                        {props.urgencies.length === 0 ? null : (props.urgencies.map((item: PriorityLevel, index: number) => {
                                            return <Option value={item.id?.toString()} key={index}>{item.name}</Option>
                                        }))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <p><b className="MyText-form">Category</b></p>
                        <Form.Item>
                            {getFieldDecorator('Category')(
                                <Select
                                    style={{width: '250px'}}
                                    placeholder="Select a category"
                                    onSelect={(e) => FilterMyCategory(e)} key={0}
                                    onChange={(e) => checkCategoryField(e)}
                                    allowClear={true}
                                    disabled={disabledSaveButton}
                                >
                                    {[...new Set(myCategory)].map((item: string, index: number) => {
                                        return <Option value={item} key={index}>{item}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Row>
                    <Row>
                        <p><b className="MyText-form">Subcategory</b></p>
                        <Form.Item>
                            {getFieldDecorator('subcategory')(
                                <Select
                                    style={{width: '250px'}}
                                    placeholder='Select a subcategory'
                                    onSelect={(e) => FilterMyItems(e)} key={1}
                                    onChange={(e) => checkSubcategoryField(e)}
                                    onFocus={() => fetchSubcategory()}
                                    allowClear={true}
                                    disabled={disabledSaveButton}
                                >
                                    {[...new Set(subcategoryName)].map((item: string, index: number) => {
                                        return <Option value={item} key={index}>{item}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Row>
                    <Row>
                        <p><b className="MyText-form">Item</b></p>
                        <Form.Item>
                            {getFieldDecorator('Item')(
                                <Select
                                    style={{width: '250px'}}
                                    onFocus={() => fetchItems()}
                                    placeholder='Select an item'
                                    key={2}
                                    allowClear={true}
                                    disabled={disabledSaveButton}
                                >
                                    {items !== null ? ([...new Set(items)].map((item: string, index: number) => {
                                        return <Option value={item} key={index}>{item}</Option>
                                    })) : 'null'}
                                </Select>
                            )}
                        </Form.Item>
                    </Row>
                    {(inc?.customField || []).map((obj: FieldDetail, index) => {
                        // const tempData = Object.values(props.incident?.customField!![index]!!)[0]
                        // const tempData = inc?.customField!![index]
                        if (obj) {
                            return (
                                <Row>
                                    <Col span={24} lg={24} md={24} sm={24} xs={24} key={index}>
                                        {obj.inputType === 'Checkbox' ? (
                                            <>
                                                <p><b className="MyText-form">{obj.isRequireFields ? (
                                                    <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                </p>
                                                <Form.Item>
                                                    {getFieldDecorator(obj.name as string, {
                                                        initialValue: obj.value || obj.defaultValue,
                                                        rules: [{
                                                            required: obj.isRequireFields as boolean,
                                                            message: obj.label + ' is required'
                                                        }]
                                                    })(
                                                        <Checkbox.Group style={{width: '100%'}}
                                                            disabled={disabledSaveButton}>
                                                            <Row>
                                                                {obj.selectOption?.split('|').map((it, _index) => {
                                                                    return <Col span={12} key={_index}>
                                                                        <Checkbox value={it}>{it}</Checkbox>
                                                                    </Col>
                                                                })}
                                                            </Row>
                                                        </Checkbox.Group>
                                                    )}
                                                </Form.Item>
                                            </>
                                        ) : null}
                                        {obj.inputType === 'DatePicker' ? (
                                            <>
                                                <p><b className="MyText-form">{obj.isRequireFields ? (
                                                    <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                </p>
                                                <Form.Item>
                                                    {getFieldDecorator(obj.name as string, {
                                                        initialValue: moment(obj.value) || moment(obj.defaultValue as string),
                                                        rules: [{
                                                            required: obj.isRequireFields as boolean,
                                                            message: obj.label + ' is required'
                                                        }]
                                                    })(
                                                        <DatePicker style={{width: '250px'}}
                                                            disabled={disabledSaveButton}/>
                                                    )}
                                                </Form.Item>
                                            </>
                                        ) : null}
                                        {obj.inputType === 'DropdownSelect' ? (
                                            <>
                                                <p><b className="MyText-form">{obj.isRequireFields ? (
                                                    <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                </p>
                                                <Form.Item>
                                                    {getFieldDecorator(obj.name as string, {
                                                        initialValue: obj.value || obj.defaultValue,
                                                        rules: [{
                                                            required: obj.isRequireFields as boolean,
                                                            message: obj.label + ' is required'
                                                        }]
                                                    })(
                                                        <Select style={{width: '250px'}}
                                                            placeholder={obj.placeholder as string || undefined}
                                                            allowClear
                                                            disabled={disabledSaveButton}
                                                        >
                                                            {obj.selectOption?.split('|').map((it, index) => {
                                                                return (
                                                                    <Option value={it as string}
                                                                        key={index}>{it}</Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </>
                                        ) : null}
                                        {obj.inputType === 'InputNumber' ? (
                                            <>
                                                <p><b className="MyText-form">{obj.isRequireFields ? (
                                                    <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                </p>
                                                <Form.Item>
                                                    {getFieldDecorator(obj.name as string, {
                                                        initialValue: Number(obj.value) || Number(obj.defaultValue),
                                                        rules: [
                                                            {type: (obj.dataType as String).toLowerCase() || undefined},
                                                            {
                                                                required: obj.isRequireFields as boolean,
                                                                message: obj.label + ' is required'
                                                            }
                                                        ]
                                                    })(
                                                        <InputNumber min={0} style={{width: '250px'}}
                                                            disabled={disabledSaveButton}
                                                            placeholder={obj.placeholder as string || undefined}/>
                                                    )}
                                                </Form.Item>
                                            </>
                                        ) : null}
                                        {obj.inputType === 'RadioButton' ? (
                                            <>
                                                <p><b className="MyText-form">{obj.isRequireFields ? (
                                                    <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                </p>
                                                <Form.Item>
                                                    {getFieldDecorator(obj.name as string, {
                                                        initialValue: obj.value || obj.defaultValue,
                                                        rules: [{
                                                            required: obj.isRequireFields as boolean,
                                                            message: obj.label + ' is required'
                                                        }]
                                                    })(
                                                        <Radio.Group disabled={disabledSaveButton}>
                                                            {obj.selectOption?.split('|').map((it, _index) => {
                                                                return <Radio value={it} key={_index}>{it}</Radio>
                                                            })}
                                                        </Radio.Group>
                                                    )}
                                                </Form.Item>
                                            </>
                                        ) : null}
                                        {obj.inputType === 'TextBox' ? (
                                            <>
                                                <p><b className="MyText-form">{obj.isRequireFields ? (
                                                    <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                </p>
                                                <Form.Item>
                                                    {getFieldDecorator(obj.name as string, {
                                                        initialValue: obj.value || obj.defaultValue,
                                                        rules: [
                                                            {type: (obj.dataType as String).toLowerCase() || undefined},
                                                            {
                                                                required: obj.isRequireFields as boolean,
                                                                message: obj.label + ' is required'
                                                            }
                                                        ]
                                                    })(
                                                        <Input style={{width: '250px'}}
                                                            placeholder={obj.placeholder as string || undefined}
                                                            disabled={disabledSaveButton}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </>
                                        ) : null}
                                    </Col>
                                </Row>)
                        }
                    })}
                </>
            }

            <div style={{float: 'right', marginRight: 35, marginTop: 10}}>
                <Form.Item>
                    <Button type="primary" htmlType="submit"
                        loading={isLoading}
                        disabled={disabledSaveButton || !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy)}>
                        Save
                    </Button>
                </Form.Item>
            </div>
        </Form>
    )
}

const WrappedRegistrationForm = Form.create<Props>({name: 'register'})(IncidentStatusUpdater)

export default connect(
    mapStateToProps,
    {
        searchIncidents,
        getAllCategory,
        getAllSupportTeam,
        getAllImpact,
        getAllUrgency,
        updateIncident,
        getAllCustomFields: getAllCustomFields,
        getAllPriority
    }
)(WrappedRegistrationForm)
