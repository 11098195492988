import React, {useEffect} from 'react'
import './App.css'
import 'react-quill/dist/quill.snow.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {LastLocationProvider} from 'react-router-last-location'
// Layout
import Sidebar from './msp-portal/Sidebar'
// Page
import Login from './msp-portal/Login'
import Home from './msp-portal/Home'
import Setting from './sla-management/setting/settingDash'
// import Auth from './auth/Auth'
// import Callback from './Callback'
import {PrivateRoute, PrivateRouteForVspace, PrivateRouteKM} from './guard/route'
import IncidentForm from './incident-management/incident/IncidentForm'
import IncidentList from './incident-management/incident/IncidentList'
import SlaGlobalSettingPage from './sla-management/sla-global-setting/SlaGlobalSettingPage'
import SlaPolicyList from './sla-management/sla-policy/SlaPolicyList'
import SlaPolicyForm from './sla-management/sla-policy/SlaPolicyForm'
import BusinessHourList from './sla-management/business-hour/BusinessHourList'
import BusinessHourForm from './sla-management/business-hour/BusinessHourForm'
import IncidentDetailsView from './incident-management/incident/IncidentDetailsView'
import KnowledgeHome from './knowledge-management/KnowledgeHome'
import KnowledgeForm from './knowledge-management/knowledge/KnowledgeForm'
import KnowledgePreview from './knowledge-management/knowledge/KnowledgePreview'
import ApprovalHome from './knowledge-management/approval/ApprovalHome'
import UserRole from './authorization-module/user-role/UserRole'
import UserRoleForm from './authorization-module/user-role/UserRoleForm'
import SupportTeamList from './authorization-module/support-team-management/SupportTeamList'
import SupportTeamForm from './authorization-module/support-team-management/SupportTeamForm'
import SlaCategorySettingPage from './sla-management/sla-category-setting/SlaCategorySettingPage'
import PriorityConfigurationPage from './incident-management/priority/PriorityConfigurationPage'
import RoleAndPermissionsForm from './authorization-module/role-and-permissions/RoleAndPermissionsForm'
import RoleAndPermissionsList from './authorization-module/role-and-permissions/RoleAndPermissionsList'
import GeneralIncidentSettingForm from './incident-management/general-incident-setting/GeneralIncidentSettingForm'
import ConfigurationFiledList from './configurable-fields/ConfigurableFiledList'
import ForgotPassword from './authorization-module/user-role/ForgotPassword'
import UserConfirmation from './authorization-module/user-role/UserConfirmation'
import ReportDashBoard from './msp-portal/ReportDashBoard'
import WoList from './msp-wo/wo-management/WoList'
import WoForm from './msp-wo/wo-management/WoForm'
import Page403 from './msp-portal/Page403'
import AssetList from './msp-wo/asset-management/AssetList'
import AssetForm from './msp-wo/asset-management/AssetForm'
import AssetPreview from './msp-wo/asset-management/AssetPreview'
import SelectLocationForPrint from './msp-wo/preventive/SelectLocationForPrint'
import SearchPreventive from './msp-wo/preventive/SearchPreventive'
import AssetsForSign from './msp-wo/preventive/signature/AssetsForSign'
import ConfirmLogin from './authorization-module/vSpace-login/ConfirmLogin'
import VspaceLogin from './authorization-module/vSpace-login/VspaceLogin'
import JobMonitor from './msp-wo/wo-management/JobMonitor'

// PrivateRoute

const App = () => {
    useEffect(() => {
        // window.onbeforeunload = function() {
        //     if (localStorage.getItem('scrollHeightTo1000')) {
        //         localStorage.removeItem('scrollHeightTo1000')
        //     }
        //     return ''
        // }
    }, [])

    return (
        <Router>
            <Switch>
                <Route path="/account/forgotPassword" component={ForgotPassword} />
                <Route path="/account/confirmation/:secretCode" component={UserConfirmation} />
                <Route exact path="/" component={Login} />
                <Route path='/403' component={Page403} />
                <Route path="/vSpaceLogin/:id/:token/:incidentNo" component={ConfirmLogin} />
                <Route path="/vSpaceLogin/:id/:token" component={ConfirmLogin} />
                <Route path="/vSpaceLogin" component={VspaceLogin} />
                <Route >
                    <Switch>
                        <LastLocationProvider>
                            <Sidebar>
                                <PrivateRouteForVspace exact path="/WoList" component={WoList} />
                                <PrivateRouteForVspace path="/WoForm" component={WoForm} />
                                <PrivateRouteForVspace path="/WoFormEdit/:id/:openFrom?" component={WoForm} />
                                <PrivateRouteForVspace path="/assetList" component={AssetList} />
                                <PrivateRouteForVspace path="/assetForm" component={AssetForm} />
                                <PrivateRouteForVspace path="/assetPreview/:id/:serialNo" component={AssetPreview} />
                                <PrivateRouteForVspace path="/Preventive" component={SearchPreventive} />
                                <PrivateRouteForVspace path="/locationForSign" component={AssetsForSign} />
                                <PrivateRouteForVspace path="/SelectLocationForPrint" component={SelectLocationForPrint} />
                                <PrivateRouteForVspace path="/JobList" component={JobMonitor} />

                                <PrivateRoute path="/dashboard" component={Home} />
                                <PrivateRoute path="/IncidentList" component={IncidentList} />
                                <PrivateRoute path="/IncidentForm" component={IncidentForm} />
                                <PrivateRoute path="/Setting" component={Setting} />
                                <PrivateRoute path="/GlobalSetting" component={SlaGlobalSettingPage} />
                                <PrivateRoute path="/CategorySetting" component={SlaCategorySettingPage} />
                                <PrivateRoute path="/PrioritySetting" component={PriorityConfigurationPage} />
                                <PrivateRoute path="/SlaPolicySettingList" component={SlaPolicyList} />
                                <PrivateRoute path="/SlaPolicySettingForm" component={SlaPolicyForm} />
                                <PrivateRoute path="/SlaPolicySettingFormView/:id" component={SlaPolicyForm} />
                                <PrivateRoute path="/SlaPolicySettingFormEdit/:id" component={SlaPolicyForm} />
                                <PrivateRoute path="/SlaPolicySettingFormClone/:id" component={SlaPolicyForm} />
                                <PrivateRoute path="/BusinessHourSettingList" component={BusinessHourList} />
                                <PrivateRoute path="/BusinessHourSettingForm" component={BusinessHourForm} />
                                <PrivateRoute path="/BusinessHourSettingFormView/:id" component={BusinessHourForm} />
                                <PrivateRoute path="/BusinessHourSettingFormEdit/:id" component={BusinessHourForm} />
                                <PrivateRoute path="/BusinessHourSettingFormClone/:id" component={BusinessHourForm} />
                                <PrivateRoute path="/IncidentDetailsView/:id" component={IncidentDetailsView} />
                                <PrivateRouteKM exact path="/Knowledge" component={KnowledgeHome} />
                                <Switch>
                                    <PrivateRouteKM path="/KnowledgeForm/:categoryId/:folderId" component={KnowledgeForm} />
                                    <PrivateRouteKM path="/KnowledgeForm/:categoryId" component={KnowledgeForm} />
                                    <PrivateRouteKM path="/KnowledgeForm" component={KnowledgeForm} />
                                </Switch>
                                <PrivateRouteKM path="/KnowledgeFormEdit/:id" component={KnowledgeForm} />
                                <PrivateRouteKM path="/KnowledgePreview/:id" component={KnowledgePreview} />
                                <PrivateRouteKM path="/ApprovalCenter" component={ApprovalHome} />
                                <PrivateRouteKM path="/ApprovalPreview/:id" component={KnowledgePreview} />
                                <PrivateRoute path="/SupportTeamSettingList" component={SupportTeamList} />
                                <PrivateRoute path="/SupportTeamSettingForm/:id/:action" component={SupportTeamForm} />
                                <PrivateRoute path="/UserSetting" component={UserRole} />
                                <PrivateRoute path="/UserSettingForm" component={UserRoleForm} />
                                <PrivateRoute path="/UserSettingPreview/:id/:peopleIdParam/:usernameParam/:role" component={UserRoleForm} />
                                <PrivateRoute path="/UserSettingEditForm/:id/:peopleIdParam/:usernameParam/:role" component={UserRoleForm} />
                                <PrivateRoute path="/RoleAndPermissionsSettingList" component={RoleAndPermissionsList} />
                                <PrivateRoute path="/RoleAndPermissionsSettingForm" component={RoleAndPermissionsForm} />
                                <PrivateRoute path="/RoleAndPermissionsSettingEditForm/:id" component={RoleAndPermissionsForm} />
                                <PrivateRoute path="/RoleAndPermissionsSettingPreview/:id" component={RoleAndPermissionsForm} />
                                <PrivateRoute path="/GeneralIncidentSettingForm" component={GeneralIncidentSettingForm} />
                                <PrivateRoute path="/ConfigurationFiledSettingList" component={ConfigurationFiledList} />
                                <PrivateRoute path="/ReportDashBoard" component={ReportDashBoard} />
                                <PrivateRoute path="/ReportDashBoardNoneSLA" component={ReportDashBoard} />
                            </Sidebar>
                        </LastLocationProvider>
                    </Switch>
                </Route>
            </Switch>
        </Router>
    )
}

export default App
