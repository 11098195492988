import { Dispatch } from 'redux'
import { CommentAddedEvent, GetCommentListByIncidentIdSuccessEvent } from './state-event'
import { Comment } from './model'
import { axiosGet, axiosPost } from '../rest'

export const getCommentListByIncidentId = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Comment[]>(`/api/incident/incidents/${id}/comments`)
            dispatch(GetCommentListByIncidentIdSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const addComment = (comment: Comment) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<Comment>('/api/incident/comments', comment)
            dispatch(CommentAddedEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}
