/* eslint-disable indent */
import React, {useEffect, useState} from 'react'
import {
    AutoComplete,
    Breadcrumb,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Empty,
    Form,
    Icon,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Tag,
    Upload
} from 'antd'
import {Link, useLocation} from 'react-router-dom'
import {connect, useDispatch} from 'react-redux'
import {StoreState} from '../../store'

import {FormComponentProps} from 'antd/lib/form'
import {searchPeople} from '../requester'
import {FieldDetail, Incident, IncidentSetting, SupportTeam, TicketStatus} from './model'
import {Category, getAllCategory} from '../category'
import {addIncident, getPrefilledNewIncident, searchIncidents} from './service'
import {getAllImpact, Impact} from '../impact'
import {getAllUrgency, Urgency} from '../urgency'
import {getAllSupportTeam} from '../support-team'
import {getAllStatus, getTicketStatus} from '../status'
import {getAllPriority, PriorityLevel, PriorityMatrix, resolvePriority} from '../priority'
import {DataSourceItemObject} from 'antd/lib/auto-complete'
import {SelectValue} from 'antd/lib/select'
import {checkRolesState, RoleType} from '../../authorization-module/permissions'
import {getAllGeneralIncidentSetting} from '../general-incident-setting/service'
import {UploadFile} from 'antd/lib/upload/interface'
import {deleteObjectInS3, UploadLink} from '../../knowledge-management/knowledge/uploads'
import exportIcon from '../../common-file/icon-file'
import FSS from '../../file-server-storage'
import {CustomFiled, CustomFileds, getAllCustomFields, GetAllCustomFiledsSuccessEvent} from '../../configurable-fields'
import moment from 'moment'
import {UserSupportTeam} from '../../authorization-module/support-team-management/model'
import {User} from '../../authorization-module/user-role/duck/model'
import {People} from '../../authorization-module/people/model'
import {String} from 'aws-sdk/clients/cloudwatchevents'
import {GeneralIncidentSetting} from '../incident/model'

const mapStateToProps = (state: StoreState) => {
    return {
        incidents: state.incidentState?.incidents || [],
        impacts: state.impacts,
        urgencies: state.urgencies,
        priority: state.priorities,
        categories: state.categories,
        supportTeams: state.supportTeams,
        statuses: state.statuses,
        userRolesAndPermissions: state.userRolesAndPermissions,
        generalIncidentSetting: state.generalIncidentSetting,
        customFields: state.customFields
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    searchIncidents: () => Promise<number>
    getAllCategory: () => Promise<number>
    getAllSupportTeam: () => Promise<number>
    addIncident: (incident: Incident) => Promise<number>
    getAllStatus: () => Promise<number>
    getAllPriority: () => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
    getAllCustomFields: (value: String) => Promise<number>
    getAllImpact: ()=> Promise<number>
    getAllUrgency: () => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
const IncidentForm: React.FC<Props> = (props: Props) => {
    const { Option } = Select
    const { TextArea } = Input
    const dispatch = useDispatch()
    const [myToggleInput, setMyToggleInput] = useState<boolean>(false)
    const [myStatus, setMyStatus] = useState<TicketStatus | undefined>(getTicketStatus(props.statuses, 'New'))
    const [myPriority, setMyPriority] = useState<PriorityMatrix>()
    const [assignee, setAssignee] = useState<UserSupportTeam[]>([])
    const [subcategoryName, setSubcategoryName] = useState<string[]>([])
    const [items, setItems] = useState<string[] | undefined>([])
    const [ticketNumber, setTicketNumber] = useState<string>('')
    const location = useLocation()
    const [requester, setRequester] = useState<People | undefined>()
    const [requesterSuggestions, setRequesterSuggestions] = useState<People[]>([])
    const [tempCategory, setTempCategory] = useState<string | undefined>(undefined)
    const [tempSubcategory, setTempSubcategory] = useState<string | undefined>(undefined)
    const [tempItem, setTempItem] = useState<string | undefined>(undefined)
    const [tempAssignTo, setTempAssignTo] = useState<string | undefined>(undefined)
    const [user, setUser] = useState<User | undefined>()
    const [tempAssignGroup, setTempAssignGroup] = useState<SupportTeam>()
    const [loading, setLoading] = useState<boolean>(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [validateCustomField, setValidateCustomField] = useState<boolean>(false)

    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    let mySupportTeamName: string[] = []
    const myCategory: string[] = []
    const isCreate = location.pathname.substring(1, 13) === 'IncidentForm'
    const { getFieldDecorator, getFieldValue } = props.form
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        props.form.setFieldsValue({
            description: ''
        })
        fetchDataToImpact().catch((err) => message.error(`Failed loading initial impact data. ${err}`))
        fetchDataToUrgency().catch((err) => message.error(`Failed loading initial urgency data. ${err}`))
        fetchTicketIncident()
        fetchDataToStatus().catch((err) => message.error(`Failed loading initial status data. ${err}`))
        fetchPriority().catch((err) => message.error(`Failed loading initial priority data. ${err}`))
        fetchTicketTypeAndChannel()
    }, [])

    useEffect(() => {
        const ticketType = props.generalIncidentSetting.ticketTypes?.find(it => it.id === props.form.getFieldValue('Ticket Type'))
        if (ticketType) {
            fetchCustomFields(ticketType.value)
        }
    }, [props.form.getFieldValue('Ticket Type')])

    useEffect(() => {
        if (props.statuses) {
            setMyStatus(getTicketStatus(props.statuses, 'New'))
        }
    }, [props.statuses])

    const fetchDataToStatus = () => {
        return props.getAllStatus()
    }

    const fetchDataToImpact = () => {
        return props.getAllImpact()
    }

    const fetchDataToUrgency = () => {
        return props.getAllUrgency()
    }

    const fetchPriority = () => {
        return props.getAllPriority()
    }
    const fetchTicketIncident = () => {
        if (isCreate) {
            getPrefilledNewIncident()
                .then((res) => setTicketNumber(res.ticketNumber))
                .catch((err) => message.error(`Failed loading initial incident data. ${err}`))
        }
    }

    const fetchTicketTypeAndChannel = () => {
        props.getAllGeneralIncidentSetting().catch((err) => message.error(`Failed loading initial general incident setting data. ${err}`))
    }

    const fetchCustomFields = (value: String) => {
        props.getAllCustomFields(value).catch(() => {
            const CustomFieldEmpty: CustomFileds = {
                ticketType: props.form.getFieldValue('Ticket Type'),
                fields: []
            }
            dispatch(GetAllCustomFiledsSuccessEvent.build(CustomFieldEmpty))
        })
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const saveIncident = (incident: Incident) => {
        props.addIncident(incident)
            .then(() => {
                message.success('You have successfully saved the data.')
                window.location.href = '/IncidentList'
                setLoading(false)
                setIsLoading(false)
            })
            .catch((err) => {
                message.error(`You have unSuccessfully save the data. ${err}`)
                setLoading(false)
                setIsLoading(false)
            })
    }
    const handleSubmit = (e: any): void => {
        e.preventDefault()
        props.form.validateFields(async (err: any, values) => {
            if (err) {
                console.log(' err');
                (props.customFields.fields || []).map((obj: CustomFiled) => {
                    if (obj.isRequireFields === true && !obj.defaultValue) {
                        setValidateCustomField(true)
                    }
                })
            } else {
                setIsLoading(true)
                console.log('no err')
                const isStatusNew: boolean = false
                let check: boolean = false
                const channel = props.generalIncidentSetting.channels?.find(it => it.id === props.form.getFieldValue('Channel'))
                const ticketType = props.generalIncidentSetting.ticketTypes?.find(it => it.id === props.form.getFieldValue('Ticket Type'))
                const fieldDetails: FieldDetail[] = (props.customFields.fields || []).map((obj: CustomFiled) => {
                    if (obj.isRequireFields === true && !obj.defaultValue) {
                        setValidateCustomField(true)
                        check = true
                    }
                    const generalIncidentSetting: GeneralIncidentSetting = {
                        id: ticketType?.id
                    }
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox') {
                            value = (getFieldValue(obj.name) || []).join('|')
                        } else {
                            value = getFieldValue(obj.name)
                        }
                    }
                    const fieldDetail: FieldDetail = {
                            ticketId: undefined,
                            name: obj.name,
                            value: obj.name ? value : undefined,
                            ticketTypeId: generalIncidentSetting,
                            label: obj.label,
                            description: obj.description,
                            inputType: obj.inputType,
                            dataType: obj.dataType,
                            selectOption: obj.selectOption,
                            defaultValue: obj.defaultValue,
                            placeholder: obj.placeholder,
                            isRequireFields: Boolean(obj.isRequireFields)
                    }
                    return fieldDetail
                })
                const setting: IncidentSetting[] = [
                    {
                        incidentId: undefined,
                        incGeneralSetting: {
                            id: channel?.id
                        }
                     }, {
                        incidentId: undefined,
                        incGeneralSetting: {
                            id: ticketType?.id
                        }
                    }]
                const incident: Incident = {
                    ticketNumber: ticketNumber,
                    subject: values.topic,
                    description: values.description,
                    company: requester?.company!! || 'G-able',
                    category: tempCategory,
                    subcategory: tempSubcategory,
                    item: tempItem,
                    supportTeam: tempAssignGroup,
                    user: user,
                    priority: myPriority,
                    ticketStatus: myStatus!!,
                    pendingReason: undefined,
                    pendingUntil: undefined,
                    people: requester!!,
                    viewed: isStatusNew,
                    incidentSetting: setting,
                    customField: fieldDetails
                }
                if (check) {
                    if (!myToggleInput) {
                        setIsLoading(false)
                        message.error('Please fill in the required fields')
                    } else {
                        checkSaveIncident(incident)
                    }
                } else {
                    checkSaveIncident(incident)
                }
            }
        })
    }

    const checkSaveIncident = (incident) => {
        if (fileList.length > 0 && fileList.length <= 5) {
            FSS.putFile(fileList, '/incident/', (uploadLink: UploadLink[]) => {
                incident.uploadLink = uploadLink
                saveIncident(incident)
                setValidateCustomField(false)
            })
        } else if (fileList.length > 5) {
            setIsLoading(false)
            message.error('Upload File Max 5')
        } else {
            saveIncident(incident)
            setValidateCustomField(false)
        }
    }

    const toggleInput = async () => {
        setMyToggleInput(!myToggleInput)
        props.getAllCategory().catch((err) => message.error(`Failed loading all category data. ${err}`))
        props.getAllSupportTeam().catch((err) => message.error(`Failed loading all support team data. ${err}`))
        setValidateCustomField(false)
    }

    const changeMyStatus = (newStatus: TicketStatus): void => {
        setMyStatus(newStatus)
    }

    const callApiGetPriorities = async () => {
        const myImpact = props.form.getFieldValue('Impact')
        const myUrgency = props.form.getFieldValue('Urgency')
        const impact = props.impacts?.find(it => it.id === myImpact)?.id!!
        const urgency = props.urgencies?.find(it => it.id === myUrgency)?.id!!
        if (impact !== undefined && urgency !== undefined) {
            resolvePriority(impact, urgency).then((res) => {
                setMyPriority(res)
            }).catch((err) => { message.error(`${err}`) })
        }
    }

    const FilterSupportTeam = async (e: SelectValue) => {
        await props.form.setFieldsValue({
            Assigned_to: null
        })
        const target = props.supportTeams.find(it => it.name === e)
        setTempAssignGroup(target)
        await changeMyStatus(getTicketStatus(props.statuses, 'Assigned'))
        if (props.form.getFieldValue('Assignment_group') === '') {
            await changeMyStatus(getTicketStatus(props.statuses, 'New'))
            props.form.setFields({
                Assignment_group: null,
                Assigned_to: null
            })
        }
        const targetSupportTeams = props.supportTeams.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === e
        })
        if (targetSupportTeams.length !== 0) {
            targetSupportTeams.forEach((res) => {
                setAssignee(res.assignees)
            })
        } else {
            setAssignee([])
        }
    }
    const onBlurTeam = (value: SelectValue) => {
        if (mySupportTeamName) {
            const isTeam = mySupportTeamName.includes((value as string))
            if (!isTeam) {
                props.form.setFieldsValue({
                    Assignment_group: undefined
                })
                changeMyStatus(getTicketStatus(props.statuses, 'New'))
                setAssignee([])
            }
        }
    }

    const FilterAssignee = () => {
        const valAssignee = props.form.getFieldValue('Assignment_group')
        const targetSupportTeams = props.supportTeams.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === valAssignee
        })
        targetSupportTeams.forEach((res) => {
            setAssignee(res.assignees)
        })
    }

    const checkAssignee = (e: SelectValue) => {
        if (e) {
            FilterSupportTeam(e).catch((err) => message.error(`Failed filter support team. ${err}`))
        } else {
            props.form.setFieldsValue({
                Assigned_to: null
            })
            setTempAssignGroup(undefined)
            setTempAssignTo(undefined)
        }
    }

    const changeAssigned = (e: SelectValue) => {
        const currentAssignGroup = props.form.getFieldValue('Assignment_group')
        if (e === '') {
            props.form.setFieldsValue({
                Assigned_to: null
            })
            setTempAssignTo(undefined)
            setAssignee([])
        } else {
            if (currentAssignGroup) {
                changeMyStatus(getTicketStatus(props.statuses, 'InProgress'))
            } else {
                console.log('here')
            }
        }
    }

    const onBlurAssignee = (value: SelectValue) => {
        const currentAssignTo = props.form.getFieldValue('Assigned_to')
        const currentAssignGroup = props.form.getFieldValue('Assignment_group')
        const target = assignee.find(it => it.id?.toString() === value)
        setUser(target?.user)
        if (assignee) {
            const isAssignee = assignee.find(it => it.id?.toString() === value)
            if (!isAssignee && !currentAssignGroup) {
                props.form.setFieldsValue({
                    Assigned_to: undefined
                })
                setTempAssignTo(undefined)
                changeMyStatus(getTicketStatus(props.statuses, 'New'))
            } else if (!isAssignee) {
                props.form.setFieldsValue({
                    Assigned_to: undefined
                })
                setTempAssignTo(undefined)
                changeMyStatus(getTicketStatus(props.statuses, 'Assigned'))
            }
        }
    }

    const FilterMyCategory = (e: SelectValue) => {
        const mySubcategories = props.categories.filter((category: Category) => {
            return category.category === e
        })
        const currentCategory = props.form.getFieldValue('Category')
        setTempCategory(currentCategory)
        // filter subcategories
        setSubcategoryName([])
        setItems([])
        const tempSubcategory: string[] = []
        mySubcategories.forEach((subCat: Category) => {
            if (subCat.subCategory && subCat.active) {
                tempSubcategory.push(subCat.subCategory)
                setSubcategoryName(tempSubcategory)
                props.form.setFieldsValue({
                    subcategory: undefined,
                    Item: undefined
                })
            } else {
                setSubcategoryName([])
                setItems([])
            }
        })
    }

    const checkCategoryField = (valueInCategory: SelectValue) => {
        setTempSubcategory(undefined)
        setTempItem(undefined)
        if (valueInCategory) {
            FilterMyCategory(valueInCategory)
        } else {
            props.form.setFieldsValue({
                subcategory: undefined,
                Item: undefined
            })
            setTempCategory(undefined)
            setSubcategoryName([])
            setItems([])
        }
    }

    const checkSubcategoryField = (valueInSubcategory: SelectValue) => {
        setTempItem(undefined)
        if (valueInSubcategory) {
            FilterMyItems(valueInSubcategory)
        } else {
            props.form.setFieldsValue({
                Item: undefined
            })
            setItems([])
        }
    }

    const FilterMyItems = (e: SelectValue) => {
        const currentSubcategory = props.form.getFieldValue('subcategory')
        setTempSubcategory(currentSubcategory)
        const categoriesWithItems: Category[] = []
        const listItem: string[] = []
        props.categories.forEach((subCat: Category) => {
            if (subCat.item !== null && subCat.active) {
                categoriesWithItems.push(subCat)
            }
        })
        categoriesWithItems.forEach((tar) => {
            if (tar.subCategory === e && props.form.getFieldValue('Category') === tar.category) {
                if (tar.item && tar.subCategory === e) {
                    listItem.push(tar.item)
                }
                props.form.setFieldsValue({
                    Item: undefined
                })
            }
            listItem.length > 0 ? setItems(listItem) : setItems([])
        })
    }

    const selectedItem = () => {
        const currentItem = props.form.getFieldValue('Item')
        setTempItem(currentItem)
    }

    const onSelectRequest = (value: SelectValue) => {
        const requester = requesterSuggestions.find((it) => it.id?.toString() === value)
        setRequester(requester)
    }

    const onSearchRequester = (searchText: string) => {
        searchPeople(searchText).then((res: People[]) => {
            setRequesterSuggestions(res)
        })
    }

    const validateRequester = (_: any, value: any, callback) => {
        if (props.form.getFieldValue('requester')) {
            if (requesterSuggestions) {
                const isSuggested = requesterSuggestions.find((it) => it.id === value) !== undefined
                if (!isSuggested && value.length >= 3) {
                    // setRequester(undefined)
                    callback('Requester does not exist')
                } else {
                    // setRequester(undefined)
                    callback()
                }
            } else {
                if (value.length >= 3) {
                    // setRequester(undefined)
                    callback('Requester does not exist')
                }
            }
        } else {
            callback()
        }
    }

    // const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
    //     if (!((file.url) as string).includes('jpg')) {
    //         setPreviewVisible(false)
    //     } else {
    //         setPreviewVisible(true)
    //     }
    //     setPreviewImage(file.url || file.preview)
    // }
    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob; type: string}) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        // if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
        if (file.type.includes('image')) {
            setPreviewVisible(true)
        } else {
            setPreviewVisible(false)
        }
        setPreviewImage(file.url || file.preview)
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                deleteObjectInS3(file.uid).catch((err) => message.error(`Failed delete object in S3 data. ${err}`))
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                // fileList = fileList.slice(-2);
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
                console.log(fileList)
            }

            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const modalConfirmCancel = (): void => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                window.location.href = '/IncidentList'
            }
        })
    }

    const customFieldLayout = () => {
        let rowContents: JSX.Element[] = []
        // eslint-disable-next-line react/prop-types
        const contents = (props.customFields.fields || []).reduce((acc: JSX.Element[], obj, index) => {
            rowContents.push(
                <div>
                    <Col span={12} lg={12} md={12} sm={24} xs={24} key={index} style={{marginBottom: 10}}>
                        {obj.inputType === 'Checkbox' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Checkbox.Group style={{width: '100%', minHeight: 20}}>
                                        <Row>
                                            {obj.selectOption.split('|').map((it, _index) => {
                                                return <Col span={8} key={_index}>
                                                    <Checkbox value={it}>{it}</Checkbox>
                                                </Col>
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'DatePicker' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue ? moment(obj.defaultValue as string) : undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <DatePicker style={{width: '100%'}}/>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'DropdownSelect' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue ? (obj.defaultValue as string) : undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Select style={{width: '100%'}}
                                            placeholder={obj.placeholder as string || undefined} allowClear>
                                        {obj.selectOption.split('|').map((it, index) => {
                                            return (
                                                <Option value={it as string} key={index}>{it}</Option>
                                            )
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'InputNumber' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: Number(obj.defaultValue),
                                    rules: [
                                        {type: (obj.dataType as String).toLowerCase() || undefined},
                                        {
                                            required: obj.isRequireFields as boolean,
                                            message: obj.label + ' is required'
                                        }
                                    ]
                                })(
                                    <InputNumber min={0} style={{width: '100%'}}
                                                 placeholder={obj.placeholder as string || undefined}/>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'RadioButton' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Radio.Group style={{width: '100%', minHeight: 20}}>
                                        {obj.selectOption.split('|').map((it, _index) => {
                                            return <Radio value={it} key={_index}>{it}</Radio>
                                        })}
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'TextBox' ? (
                            <Form.Item label={obj.label}>
                                {getFieldDecorator(obj.name as string, {
                                    initialValue: obj.defaultValue,
                                    rules: [
                                        {type: (obj.dataType as String).toLowerCase() || undefined},
                                        {
                                            required: obj.isRequireFields as boolean,
                                            message: obj.label + ' is required'
                                        }
                                    ]
                                })(
                                    <Input placeholder={obj.placeholder as string || undefined}/>
                                )}
                            </Form.Item>
                        ) : null}
                    </Col>
                </div>
            )
            if (index % 2 === 1) {
                acc.push(
                    <>
                        <Row gutter={16}>{rowContents}</Row>
                    </>
                )
                rowContents = []
            }
            return acc
        }, [])
        contents.push(<Row>{rowContents}</Row>)

        return (
            <div>
                {contents}
            </div>
        )
    }

    const dynamicInputFields = <>
        {
            !myToggleInput ? (<></>) : (<>
                {props.supportTeams.length !== 0 ? (<span style={{ display: 'none' }}>{
                    mySupportTeamName = props.supportTeams.map((supportTeam: SupportTeam) => {
                        return supportTeam.name
                    })
                }</span>
                ) : null}

                {props.categories.length !== 0 ? (<span style={{ display: 'none' }}>{
                    props.categories.map((category: Category) => {
                        if (category.active) {
                            myCategory.push(category.category)
                        }
                    })
                }</span>
                ) : null}
                <Row gutter={16}>
                    <Col span={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item label="Assignment group">
                            {getFieldDecorator('Assignment_group', { initialValue: tempAssignGroup })(
                                <AutoComplete
                                    dataSource={(mySupportTeamName || []).map((it) => {
                                        return it
                                    })}
                                    onChange={(e) => checkAssignee(e)}
                                    onSelect={(e) => FilterSupportTeam(e)}
                                    onBlur={(e) => onBlurTeam(e)}
                                    placeholder={'Select an assignment group'}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item label="Assigned to">
                            {getFieldDecorator('Assigned_to', { initialValue: tempAssignTo })(
                                <AutoComplete
                                    dataSource={assignee.map((item, index: number) => {
                                        return <Option value={item.id?.toString()}
                                                       key={index}>{item.user.people.fullName}</Option>
                                    })}
                                    onChange={changeAssigned}
                                    onFocus={() => FilterAssignee()}
                                    onBlur={(e) => onBlurAssignee(e)}
                                    placeholder={'Select an assignee'}

                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8} lg={8} md={8} sm={24} xs={24} style={{ marginRight: 38 }}>
                        <Form.Item label={'Category'}>
                            {getFieldDecorator('Category', { initialValue: tempCategory })(
                                <Select
                                    placeholder="Select a category"
                                    onSelect={(e) => FilterMyCategory(e)} key={0}
                                    onChange={(e) => checkCategoryField(e)}
                                    value={tempCategory}
                                    allowClear={true}
                                >
                                    {[...new Set(myCategory)].map((item: string, index: number) => {
                                        return <Option value={item}
                                            key={index}>{item}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={7} lg={7} md={7} sm={24} xs={24} style={{ marginRight: 38 }}>
                        {subcategoryName.length !== 0 ? (<>
                            <Form.Item label={'Subcategory'}>
                                {getFieldDecorator('subcategory', { initialValue: tempSubcategory })(
                                    <Select
                                        placeholder="Select a subcategory"
                                        onSelect={(e) => FilterMyItems(e)} key={1}
                                        onChange={(e) => checkSubcategoryField(e)}
                                        allowClear={true}
                                    >
                                        {[...new Set(subcategoryName)].map((item: string, index: number) => {
                                            return <Option value={item}
                                                key={index}>{item}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        </>) : null}
                    </Col>
                    <Col span={7} lg={7} md={7} sm={24} xs={24}>{items !== null && items?.length !== 0 ? (<>
                        <Form.Item label={'Item'}>
                            {getFieldDecorator('Item', { initialValue: tempItem })(
                                <Select
                                    placeholder="Select an item"
                                    key={2}
                                    onSelect={() => selectedItem()}
                                    allowClear={true}
                                >
                                    {([...new Set(items)]?.map((item: string, index: number) => {
                                        if (item) {
                                            return <Option value={item}
                                                           key={index}>{item}</Option>
                                        }
                                    }))}
                                </Select>
                            )}
                        </Form.Item>
                    </>
                    ) : null}</Col>
                </Row>
                    {getFieldValue('Ticket Type')
                        ? customFieldLayout() : null}
                <br />
            </>)
        }
    </>

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to={'/IncidentList'} >Ticket Type</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>New Incident</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Spin spinning={loading}>
                <Row gutter={20}>
                    <Col span={18}>
                        <Card style={{ minHeight: 700, minWidth: 300 }} >
                            <div>
                                <span> Ticket Number : {ticketNumber}</span>
                                <span style={{ marginLeft: 20 }}>
                                    <span>{myStatus?.status}</span>
                                    {/* <Dropdown overlay={menuStatus}>
                                    <span className="ant-dropdown-link" >{myStatus}<Icon type="down" style={{ marginBottom: '5px' }} /></span>
                                </Dropdown> */}
                                </span>
                                <span style={{ marginLeft: 20 }}>
                                    {props.priority.map((priority: PriorityLevel, index) => {
                                        return (
                                            myPriority?.priority?.name === priority.name ? (
                                                <Tag style={{ backgroundColor: priority.tagColor, color: '#fff' }} key={index}>{myPriority.priority?.name}</Tag>
                                            ) : null
                                        )
                                    })}
                                </span>
                            </div>
                            <br />
                            <div style={{ padding: 20 }}>
                                <Form onSubmit={handleSubmit} layout="vertical">
                                    <Row>
                                        <Row gutter={16}>
                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label="Requester">
                                                    {getFieldDecorator('requester',
                                                        {
                                                            rules: [{
                                                                required: true,
                                                                whitespace: true,
                                                                message: 'Requester is required'
                                                            },
                                                            // , { min: 3, message: 'Enter more than 3 characters' },
                                                            { validator: validateRequester }
                                                            ]
                                                        })(
                                                            // <Input type="text" placeholder="Name" maxLength={255} />
                                                            <AutoComplete
                                                                dataSource={(requesterSuggestions || []).map((it: People) => {
                                                                    return { value: it.id?.toString(), text: it.firstName + ' ' + it.lastName } as DataSourceItemObject
                                                                })}
                                                                style={{ width: '100%' }}
                                                                onSelect={onSelectRequest}
                                                                onSearch={onSearchRequester}
                                                                onChange={() => setRequester(undefined)}
                                                                placeholder="Name"
                                                            />
                                                        )}
                                                </Form.Item>
                                                <div style={{ color: '#828282', display: requester ? undefined : 'none' }}>
                                                    <span style={{ marginRight: 5 }}><Icon type="mail" /> {requester?.email ? requester.email.length > 0 ? requester.email : '-' : '-'}</span>
                                                    <span style={{ marginRight: 5 }}><Icon type="phone" /> {requester?.tel ? requester.tel.length > 0 ? requester.tel : '-' : '-'}</span>
                                                    <span style={{ marginRight: 5 }}><Icon type="bank" /> {requester?.company ? requester.company.length > 0 ? requester.company : '-' : '-'} </span>
                                                </div>
                                            </Col>
                                            <Col span={6} lg={6} md={6} sm={24} xs={24}>
                                                <Form.Item label="Impact">
                                                    {getFieldDecorator('Impact', {
                                                        rules: [{
                                                            required: true
                                                        }]
                                                    })(
                                                        <Select placeholder="Select an impact"
                                                            onSelect={() => callApiGetPriorities()}>
                                                            {props.impacts.length === 0 ? null : (props.impacts.map((item: PriorityLevel, index: number) => {
                                                                return <Option value={item.id} key={index}>{item.name}</Option>
                                                            }))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} lg={6} md={6} sm={24} xs={24}>
                                                <Form.Item label="Urgency">
                                                    {getFieldDecorator('Urgency', {
                                                        rules: [{
                                                            required: true
                                                        }]
                                                    })(
                                                        <Select placeholder="Select an urgency"
                                                            onSelect={() => callApiGetPriorities()}>
                                                            {props.urgencies.length === 0 ? null : (props.urgencies.map((item: PriorityLevel, index: number) => {
                                                                return <Option value={item.id} key={index}>{item.name}</Option>
                                                            }))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label="Channel">
                                                    {getFieldDecorator('Channel', {
                                                        rules: [{
                                                            required: true
                                                        }]
                                                    })(
                                                        <Select placeholder="Select an channel">
                                                            {props.generalIncidentSetting.channels?.length === 0 ? null : (props.generalIncidentSetting.channels?.map((item, index: number) => {
                                                                return <Option value={item.id} key={index}>{item.value}</Option>
                                                            }))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label="Ticket Type">
                                                    {getFieldDecorator('Ticket Type', {
                                                        rules: [{
                                                            required: true
                                                        }]
                                                    })(
                                                        <Select placeholder="Select an Ticket Type">
                                                            {props.generalIncidentSetting.ticketTypes?.length === 0 ? null : (props.generalIncidentSetting.ticketTypes?.map((item, index: number) => {
                                                                return <Option value={item.id} key={index}>{item.value}</Option>
                                                            }))}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ marginTop: 5 }}>
                                                <Form.Item label="Topic">
                                                    {getFieldDecorator('topic',
                                                        {
                                                            rules: [{ required: true, whitespace: true, message: 'Topic is required' },
                                                            { min: 3, message: 'Enter more than 3 characters' }]
                                                        })(
                                                            <Input type="text" placeholder="Topic" maxLength={255} />
                                                        )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ height: 140 }}>
                                                <Form.Item label="Description">
                                                    {getFieldDecorator('description',
                                                        {
                                                            rules: [{
                                                                required: true, message: 'Description is required'
                                                            }, { min: 3, message: 'Enter more than 3 characters' }]
                                                        })(
                                                            // <ReactQuill modules={modules} formats={formats} style={{ height: 100 }} />
                                                            <TextArea rows={4} placeholder={'Description'} />
                                                        )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '20px' }}>
                                                <Upload {...uploadProps}
                                                    listType="picture"
                                                    className={'upload-list-inline'}
                                                    fileList={fileList}
                                                    onPreview={(e: any) => handlePreview(e)}
                                                >
                                                    <Button disabled={fileList.length >= 5}>
                                                        <Icon type="upload" /> Upload
                                                    </Button>
                                                </Upload>
                                            </Col>
                                        </Row>
                                        <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)} >
                                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Row>

                                    <Row>
                                        {dynamicInputFields}
                                    </Row>

                                    <Row>
                                        <Col span={24} lg={24} md={24} sm={24} xs={24}>
                                            {!myToggleInput ? (
                                                <Button onClick={toggleInput} style={{ width: '100%', borderColor: validateCustomField ? 'red' : undefined }}>Show More
                                                    <Icon type="down" style={{ marginBottom: '10px' }} /></Button>
                                            ) : (
                                                    <Button onClick={toggleInput} style={{ width: '100%' }}>Show Less
                                                        <Icon type="up" style={{ marginBottom: '5px' }} /></Button>
                                                )}
                                        </Col>
                                    </Row>

                                    <br />
                                    <Form.Item style={{ float: 'right', marginTop: 10 }}>
                                        <Button htmlType="button"
                                            onClick={() => modalConfirmCancel()}
                                            style={{ marginRight: 10 }}>
                                            Cancel
                                    </Button>
                                        <Button type="primary" htmlType="submit" disabled={!checkRolesState(RoleType.Incident, 'CreateIncident')} loading={isLoading}>
                                            Submit
                                    </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Card>
                    </Col>
                    <Col span={5} lg={5} md={5} sm={24} xs={24}>
                        <Card style={{ minHeight: 700 }}>
                            <h2 className="sub-title">Related Knowledge</h2>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </>
    )
}

const IncidentFormMain = Form.create({ name: 'IncidentForm' })(IncidentForm)

export default connect(mapStateToProps,
    {
        searchIncidents,
        getAllCategory,
        getAllSupportTeam,
        addIncident,
        getAllStatus,
        getAllPriority,
        getAllUrgency,
        getAllImpact,
        getAllGeneralIncidentSetting,
        getAllCustomFields: getAllCustomFields
    })(IncidentFormMain)
