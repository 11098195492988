/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
import React, {useEffect, useState} from 'react'
// Redux start
import {connect, useDispatch} from 'react-redux'
import {StoreState} from '../../store'
import ReactHtmlParser from 'react-html-parser'
// Redux End
import IncidentStatusUpdater from './IncidentStatusUpdater'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    DatePicker,
    Dropdown,
    Form,
    Icon,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Tag,
    Timeline,
    TimePicker,
    Tooltip,
    Upload
} from 'antd'
import 'react-quill/dist/quill.snow.css' // ES6
import CommentForm from '../comment/CommentForm'
import {useParams} from 'react-router'
import moment from 'moment'
// import SlaDisplay from '../sla/SlaDisplay'
import CommentSection from '../comment/CommentSection'
import {getIncidentById, updateIncident, updateIncidentStatus} from './service'
import {getAllWorkLogByIncidentId} from '../worklog/service'
import {Incident, TicketStatus} from './model'
import {FormComponentProps} from 'antd/es/form'
import {ForIncident} from '../../sla-management/sla-calculation/service'
import {getLeastTargetTimeOfAllInProgressSlaCalculations} from '../misc'
import {FetchingState, fetchWithState, SpinningFetcher} from '../../common-components'
import {WorklogChange} from '../worklog'
import {getAllStatus} from '../status'
import {IncidentWebSocketChannel} from './web-socket-channel'
import {GetIncidentByIdSuccessEvent} from './state-event'
import {checkRolesState, RoleType} from '../../authorization-module/permissions'
import {getAllPriority, PriorityLevel, PriorityMatrix} from '../priority'
import {SlaCalculation} from '../../sla-management/sla-calculation/model'
import {countLines, CutTextToReadMore, disableByStatus} from '../../common-misc'
import {UploadLink} from '../../knowledge-management/knowledge/uploads'
import {UploadFile} from 'antd/lib/upload/interface'
import exportIcon from '../../common-file/icon-file'
import SlaDisplay from '../sla/SlaDisplay'

const mapStateToProps = (state: StoreState) => {
    return {
        incident: state.incidentState?.incident,
        statuses: state.statuses,
        priority: state.priorities,
        workLog: state.workLog,
        slaCalculationDataSuit: state.incidentState?.slaCalculationDataSuit,
        comments: (state.comments || [])
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllStatus: () => Promise<number>
    updateIncident: (incident: Incident) => Promise<number>
    getAllWorkLogByIncidentId: (incidentId: string) => Promise<number>
    getAllSlaCalculationByTicketIds: (ticketIds: string[]) => Promise<number>
    getAllPriority: () => Promise<number>
}

interface Params {
    id: string
}

type Props = StateProps & DispatchProps & Params & FormComponentProps

const IncidentDetailsView: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch()
    const { id } = useParams<Params>()

    const [incidentFetchingState, setIncidentFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [workLogFetchingState, setWorkLogFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [slaCalculationsFetchingState, setSlaCalculationFetchingState] = useState<FetchingState>(FetchingState.NotStarted)

    const [ticketNumber, setTicketNumber] = useState<string>()
    const [createdDate, setCreatedDate] = useState<string>()
    const [topic, setTopic] = useState<string>()
    const [description, setDescription] = useState<string>('')
    const [requester, setRequester] = useState<string>()
    const [company, setCompany] = useState<string>()
    const [priority, setPriority] = useState<PriorityMatrix>()
    const [currentStatus, setCurrentStatus] = useState<TicketStatus>()
    const [pendingReason, setPendingReason] = useState<string | null>()
    const [pendingUntil, setPendingUntil] = useState<string | null>()
    const [SLACalDueDate, setSLACalDueDate] = useState<string>()
    const [initialPendingUntilDate, setInitialPendingUntilDate] = useState<string | null>()
    const [initialPendingUntilTime, setInitialPendingUntilTime] = useState<string | null>()
    const [initialPendingReason, setInitialPendingReason] = useState<string>()
    const [changeTitle, setChangeTitle] = useState<string>()
    const [changeDescription, setChangeDescription] = useState<string>()
    const [lastStatusTimestamp, setLastStatusTimestamp] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [phone, setPhone] = useState<string>()
    const [tempStatus, setTempStatus] = useState<TicketStatus>()

    const [showWorkLog, setShowWorkLog] = useState<boolean>(false)
    const [showSla, setShowSla] = useState<boolean>(false)
    const [oldStatus, setOldStatus] = useState<TicketStatus | undefined>(props.incident?.ticketStatus)
    const [commentText, setCommentText] = useState<string>('')
    const [isReadMoreTopic, setIsReadMoreTopic] = useState<boolean>(false)
    const [isReadMoreDesc, setIsReadMoreDesc] = useState<boolean>(false)
    const [isReadMoreResolution, setIsReadMoreResolution] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isEditIncidentLoading, setIsEditIncidentLoading] = useState<boolean>(false)
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(false)
    const [ticketType, setTicketType] = useState<string>()
    const [channel, setChannel] = useState<string>()

    useEffect(() => {
        IncidentWebSocketChannel.subscribe()
        IncidentWebSocketChannel.subscribeToIncidentIds([Number(id)!!])
        fetchWithState(incidentFetchingState, setIncidentFetchingState, fetchIncident, (incident) => {
            dispatch(GetIncidentByIdSuccessEvent.build(incident))
        })
        fetchWithState(workLogFetchingState, setWorkLogFetchingState, fetchAllWorkLog)
        fetchAllStatus().catch((err) => message.error(`Failed fetching statuses. ${err}`))
        fetchAllPriority().catch((err) => message.error(`Failed fetching priorities. ${err}`))
    }, [])

    useEffect(() => {
        if (props.incident !== null) {
            setIncident(props.incident!!)
        }
    }, [props.incident])

    useEffect(() => {
        console.log(currentStatus?.status)
        disableByStatus(currentStatus?.status, setDisabledSaveButton, props.incident?.user?.username, props?.incident?.supportTeam)
    }, [currentStatus?.status])

    useEffect(() => {
        fetchWithState(
            slaCalculationsFetchingState,
            setSlaCalculationFetchingState,
            fetchSlaCalculation,
            undefined,
            (err) => message.error(`Failed fetching SLA calculations. ${err}`)
        )
    }, [props.incident, showSla, slaCalculationsFetchingState])

    useEffect(() => {
        reCalculateDueDate()
        fetchAllWorkLog().catch((err) => message.error(`Failed fetching work log. ${err}`))
    }, [props.incident?.slaCalculations])

    useEffect(() => {
        props.comments.forEach((comment) => {
            if (comment.isResolution) {
                setCommentText(comment.text!!)
            }
        })
    }, [props.comments])

    const fetchAllStatus = () => props.getAllStatus()

    const fetchAllWorkLog = () => props.getAllWorkLogByIncidentId(id!!)

    const fetchSlaCalculation = () => props.getAllSlaCalculationByTicketIds([id!!])

    const fetchAllPriority = () => props.getAllPriority()

    const reCalculateDueDate = () => {
        const targetTime = getLeastTargetTimeOfAllInProgressSlaCalculations(props.incident?.slaCalculations || [])
        if (targetTime === '') {
            setSLACalDueDate('NoData')
        } else {
            setSLACalDueDate(moment(targetTime).format('YYYY-MM-DD HH:mm:ss'))
        }
    }

    const fetchIncident = () => getIncidentById(Number(id))

    const setIncident = (incident: Incident) => {
        if (incident.ticketNumber !== null) {
            setTicketNumber(incident.ticketNumber)
        }
        if (incident.createdDate !== null) {
            setCreatedDate(incident.createdDate)
        }
        if (incident.subject !== null) {
            setTopic(incident.subject)
        }
        if (incident.description !== null) {
            setDescription(incident.description)
        }
        if (incident.people.fullName !== null) {
            setRequester(incident.people.fullName)
        }
        if (incident.people.email) {
            setEmail(incident.people.email ? incident.people.email.length > 0 ? incident.people.email : '-' : '-')
        } else {
            setEmail(incident.people.email ? incident.people.email.length > 0 ? incident.people.email : '-' : '-')
        }
        if (incident.people.tel) {
            setPhone(incident.people.tel ? incident.people.tel.length > 0 ? incident.people.tel : '-' : '-')
        } else {
            setPhone(incident.people.tel ? incident.people.tel.length > 0 ? incident.people.tel : '-' : '-')
        }
        if (incident.company !== null) {
            setCompany(incident.company)
        }
        if (incident.priority !== null) {
            setPriority(incident.priority)
        }
        if (incident.ticketStatus !== null) {
            setCurrentStatus(incident.ticketStatus)
            setTempStatus(incident.ticketStatus)
        }
        if (incident.pendingReason !== null) {
            setPendingReason(incident.pendingReason)
        }
        if (incident.pendingUntil !== null) {
            setPendingUntil(incident.pendingUntil)
        }
        if (incident.lastStatusTimestamp !== null) {
            setLastStatusTimestamp(incident.lastStatusTimestamp)
        }
        if (incident.incidentSetting !== null) {
            incident.incidentSetting.map(it => {
                if (it.incGeneralSetting.settingType === 'channel') {
                    setChannel(it.incGeneralSetting.value)
                }
                if (it.incGeneralSetting.settingType === 'ticketType') {
                    setTicketType(it.incGeneralSetting.value)
                }
            })
        }
    }

    const setUpdatePending = async () => {
        if (pendingUntil && pendingReason) {
            const pendingDate = pendingUntil.split(' ')
            const pendingTime = pendingUntil.split(' ')
            const momentPendingDate = moment(pendingDate[0])
            const momentPendingTime = moment(pendingTime[1], 'HH:mm:ss')
            setInitialPendingUntilDate((momentPendingDate as unknown as string))
            setInitialPendingUntilTime((momentPendingTime as unknown as string))
            await setInitialPendingReason(pendingReason)
        } else {
            console.log(pendingUntil)
        }
    }

    // Data Menu for dropdown status
    const changeMyStatus = (newStatus: TicketStatus): void => {
        if (newStatus.status === 'Pending') {
            if (currentStatus?.status === 'Pending') {
                setOldStatus(oldStatus!!)
            } else {
                setOldStatus(currentStatus)
            }
            openModalPending()
        } else if (newStatus.status === 'InProgress' || newStatus.status === 'Resolved' || newStatus.status === 'Closed') {
            if (newStatus.status === 'Resolved' && commentText === '') {
                message.error('Unsuccessfully, Please input Resolution in Add note field.')
            } else {
                if (!(props.incident?.user && props.incident.supportTeam)) {
                    setTempStatus(newStatus)
                    message.error('Unsuccessfully, Please input require (*) field.')
                    setOldStatus(oldStatus)
                } else {
                    props.updateIncident(
                    {
                        id: id,
                        ticketStatus: newStatus,
                        resolution: commentText ? commentText.replace(/<[^>]*>/g, '') : null
                    } as unknown as Incident
                    ).catch((err) => {
                        message.error(`Failed saving changes. ${err}`)
                    })
                    setCurrentStatus(newStatus)
                    setTempStatus(newStatus)
                    setOldStatus(oldStatus)
                }
            }
        } else if (newStatus.status === 'Assigned') {
            if (!props.incident?.supportTeam) {
                setTempStatus(newStatus)
                message.error('Unsuccessfully, Please input require (*) field.')
                setOldStatus(oldStatus)
            } else {
                props.updateIncident(
                    {
                        id: id,
                        ticketStatus: newStatus
                    } as unknown as Incident
                ).catch((err) => {
                    message.error(`Failed update incident. ${err}`)
                })
                setCurrentStatus(newStatus)
                setTempStatus(newStatus)
                setOldStatus(oldStatus)
            }
        } else {
            setTempStatus(newStatus)
            setCurrentStatus(newStatus)
            props.updateIncident(
                {
                    id: id,
                    ticketStatus: newStatus
                } as unknown as Incident
            ).catch((err) => {
                message.error(`Failed update incident. ${err}`)
            })
            setOldStatus(oldStatus)
        }
    }

    // modal Pending
    const [visiblePending, setVisiblePending] = useState(false)
    const openModalPending = () => {
        setVisiblePending(true)
        props.form.setFieldsValue({
            datePending: '',
            timePending: null,
            PendingReason: null
        })
    }

    const openEditModalPending = () => {
        setUpdatePending().catch((err) => message.error(`Failed update pending. ${err}`))
        setVisiblePending(true)
    }

    const handlePendingOk = (e: any) => {
        e.preventDefault()

        props.form.validateFields((err: any, fieldsValue: any) => {
            if (err) {
                return
            }
            setIsLoading(true)
            // Should format date value before submit.
            const values = {
                ...fieldsValue,
                datePending: fieldsValue['datePending'].format('YYYY-MM-DD'),
                timePending: fieldsValue['timePending'].format('HH:mm:ss')
            }
            const newStatus: TicketStatus = props.statuses.find(it => it.status === 'Pending')!!
            const dueDatePending: string = values.datePending + ' ' + values.timePending + '.000'
            const reasonOfPending = values.PendingReason
            // eslint-disable-next-line prefer-const
            updateIncidentStatus(Number(id), newStatus, reasonOfPending, dueDatePending)
                .then((data) => {
                    message.success('Successfully put status to pending')
                    setVisiblePending(false)
                    setCurrentStatus(data.ticketStatus)
                    setPendingUntil(data.pendingUntil)
                    setPendingReason(data.pendingReason)
                    setTempStatus(data.ticketStatus)
                    setIsLoading(false)
                })
                .catch((err) => {
                    setIsLoading(false)
                    message.error(err.response.data.message)
                })
        })
        setInitialPendingReason('')
        setInitialPendingUntilDate(null)
        setInitialPendingUntilTime(null)
    }

    const handlePendingCancel = () => {
        props.form.setFieldsValue({
            datePending: initialPendingUntilDate,
            timePending: initialPendingUntilTime,
            PendingReason: initialPendingReason
        })
        setVisiblePending(false)
    }

    const changeOldStatus = (check) => {
        if (check) {
            setOldStatus(undefined)
        }
    }

    // eslint-disable-next-line camelcase
    const my_menu = () => {
        let currentKey: number
        return (
            <Menu>{
                // eslint-disable-next-line react/prop-types
                props.statuses.map((status, keys: number) => {
                    const currentStatus = status.status
                    if (currentStatus === props.incident?.ticketStatus.status) {
                        currentKey = keys
                        if (oldStatus === undefined) {
                            const workLog = (props.workLog || []).filter(value => value.type === 'Pending')[0]
                            if (workLog) {
                                const value = workLog.changes.filter(obj => obj.fieldName === 'ticketStatus' && obj.oldValue !== null)[0]
                                if (value) {
                                    const oldValue = props.statuses.find(it => it.status === value.oldValue)
                                    setOldStatus(oldValue)
                                }
                            }
                        }
                    }
                    if (currentKey || currentKey === 0) {
                        return checkRolesState(RoleType.Incident, 'CloseIncident', props.incident?.createdBy) && !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy)
                            // can close but can't update
                            ? (
                                // status = closed and current status = resolved
                                keys === 5 && currentKey === 4 ? (
                                    <Menu.Item key={keys} onClick={() => changeMyStatus(status)}>
                                        <span>{currentStatus}</span>
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item key={keys} onClick={() => changeMyStatus(status)} disabled={true}>
                                        <span>{currentStatus}</span>
                                    </Menu.Item>
                                )
                            ) : (
                                currentKey <= keys || keys === 3
                                    ? (
                                        props.incident?.ticketStatus.status === 'Resolved' && keys === 5 ? (
                                            <Menu.Item key={keys} onClick={() => changeMyStatus(status)}
                                                disabled={!checkRolesState(RoleType.Incident, 'CloseIncident', props.incident?.createdBy)}>
                                                <span>{currentStatus}</span>
                                            </Menu.Item>
                                        ) : (
                                            keys === 5 ? (
                                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)}
                                                    disabled={true}>
                                                    <span>{currentStatus}</span>
                                                </Menu.Item>
                                            ) : (
                                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)}>
                                                    <span>{currentStatus}</span>
                                                </Menu.Item>
                                            )
                                        )
                                    )
                                    : (
                                        <Menu.Item key={keys} onClick={() => changeMyStatus(status)}
                                            disabled={true}>
                                            <span>{currentStatus}</span>
                                        </Menu.Item>
                                    )
                            )
                    } else {
                        if (props.incident?.ticketStatus.status === 'Pending') {
                            return keys === 3 ? (
                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)}>
                                    <span>{currentStatus}</span>
                                </Menu.Item>
                            ) : (
                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)}
                                    disabled={oldStatus?.status !== currentStatus}>
                                    <span>{currentStatus}</span>
                                </Menu.Item>
                            )
                        } else if (props.incident?.ticketStatus.status === 'Resolved') {
                            return keys === 3 ? (
                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)}>
                                    <span>{currentStatus}</span>
                                </Menu.Item>
                            ) : (
                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)} disabled={true}>
                                    <span>{currentStatus}</span>
                                </Menu.Item>
                            )
                        } else {
                            return (
                                <Menu.Item key={keys} onClick={() => changeMyStatus(status)}
                                    disabled={true}>
                                    <span>{currentStatus}</span>
                                </Menu.Item>
                            )
                        }
                    }
                })
            }
            </Menu>
        )
    }

    // Modal Popup
    const [visible, setVisible] = useState(false)
    const showModal = () => {
        setChangeTitle(topic)
        setChangeDescription(description)
        setVisible(true)
    }
    const handleChangeTitle = (e) => {
        setChangeTitle(e.target.value)
        props.form.setFieldsValue({Topic: topic})
    }
    const handleChangeDescription = (e) => {
        setChangeDescription(e.target.value)
        props.form.setFieldsValue({Description: description})
    }
    const checkSaveButton = () => {
        let disableButton = true
        if (changeTitle && changeDescription) {
            disableButton = false
        }
        return disableButton
    }
    const onSubmitSubjectAndDescription = () => {
        setIsEditIncidentLoading(true)
        props.updateIncident(
            {
                id: Number(id),
                subject: changeTitle || '',
                description: changeDescription || ''
            } as Incident
        )
            .then(() => {
                message.success('The update has finished successfully.')
                setVisible(false)
                setIsEditIncidentLoading(false)
            })
            .catch((err) => {
                setIsEditIncidentLoading(false)
                message.error(`Failed saving changes. ${err}`)
            })
    }
    const handleCancel = () => {
        props.form.setFieldsValue({
            Topic: topic,
            Description: description
        })
        setVisible(false)
        setPreviewVisible(false)
    }

    // Button Show SLA and Table

    const handleShowWorkLog = () => {
        setShowWorkLog(!showWorkLog)
        fetchAllWorkLog().catch((err) => message.error(`Failed fetching work log. ${err}`))
        setShowSla(false)
    }
    const handleShowSLA = () => {
        setShowSla(!showSla)
        setShowWorkLog(false)
    }

    // Control Panel of Reply, Forward, AddNote, Discuss

    const controlStatusDetail = () => {
        if (currentStatus?.status === 'Pending') {
            return <div>
                <span style={{ color: '#EB5757' }}>Pending until {moment((pendingUntil as string)).format('YYYY-MM-DD HH:mm:ss')}</span>
                <Tooltip placement="bottom" title={pendingReason}>
                    <Icon style={{ marginLeft: '10px', backgroundColor: '#E0E0E0', borderRadius: '3px', width: '20px' }} type="ellipsis" />
                </Tooltip>
                <span onClick={openEditModalPending}><Icon type="edit" /></span>
            </div>
        } else {
            if (SLACalDueDate === 'NoData') {
                return <div/>
            } else {
                return <div>
                    <span style={{ color: '#323276' }}>Due date : </span><span style={{ color: '#323276' }}>{SLACalDueDate}</span>
                </div>
            }
        }
    }

    const { TextArea } = Input
    const { getFieldDecorator } = props.form

    const getSlaPolicy = (slaPolicyHistory) => {
        const slaHistories = props.slaCalculationDataSuit?.slaPolicyHistories.find((sla: any) => sla.id === slaPolicyHistory)
        return slaHistories
    }
    const displayWorkLog = () => {
        // eslint-disable-next-line prefer-const
        let workLogElements: JSX.Element[] = []
        let statusBeforePending: string | undefined, pendingUntil: string | undefined
        // eslint-disable-next-line react/prop-types
        // const slaCalculationsMap : SlaCalculation[] = props.slaCalculationDataSuit!!.slaCalculationsMap[id]

        // eslint-disable-next-line react/prop-types
        for (const wl of props.workLog) {
            const convertCamelCaseToSentenceCase = (fieldValue: string) => {
                // adding space between strings
                const result = fieldValue.replace(/([A-Z])/g, ' $1')

                // converting first character to uppercase and join it to the final string
                return result.charAt(0).toUpperCase() + result.slice(1)
            }
            const mappingWord = (wording: string) => {
                if (wording === 'supportTeam') {
                    return 'assignmentGroup'
                }
                if (wording === 'assignee') {
                    return 'assignedTo'
                } else {
                    return wording
                }
            }

            const getNewValue = (fieldName: string) => {
                return (wl.changes || []).find((change) => change.fieldName === fieldName)?.newValue
            }

            const getOldValue = (fieldName: string) => {
                return (wl.changes || []).find((change) => change.fieldName === fieldName)?.oldValue
            }
            // const slaCalculation = slaCalculationsMap.find((slaCal) => slaCal.id === wl.slaCalculationId)
            // let slaPolicyName, slaGoalName, slaStatus
            // if (slaCalculation) {
            //     const slaHistory = getSlaPolicy(slaCalculation.slaPolicyHistoryId)
            //     slaPolicyName = slaHistory?.name
            //     slaGoalName = slaCalculation.slaGoalName
            //     slaStatus = slaCalculation.slaStatus
            // }

            const getMoreValueOnCreateOfAssignGroup = (fieldNameMore: string) => {
                const result = (wl.changes || []).find((change) => change.fieldName === fieldNameMore)
                if (result) {
                    if (result.newValue !== '') {
                        return ', Assignment group as ' + (wl.changes || []).find((change) => change.fieldName === fieldNameMore)?.newValue
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            }

            const getMoreValueOnCreateOfAssignee = (fieldNameAssign: string) => {
                const result = (wl.changes || []).find((change: WorklogChange) => change.fieldName === fieldNameAssign)
                if (result) {
                    // eslint-disable-next-line quotes
                    if (result.newValue !== "") {
                        return ', Assigned to as ' + (wl.changes || []).find((change: WorklogChange) => change.fieldName === fieldNameAssign)?.newValue
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            }

            if (wl.type === 'Create') {
                workLogElements.push(<Timeline.Item color={'green'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + ` ${wl.createdBy} created ticket with Status as ${getNewValue('ticketStatus')}, ${getNewValue('priority')}, ${getMoreValueOnCreateOfAssignGroup('Assignment Group')}${getMoreValueOnCreateOfAssignee('Assignee')}`}</Timeline.Item>)
            } else if (wl.type === 'Pending') {
                (wl.changes || []).forEach((ch: any) => {
                    if (ch.fieldName === 'ticketStatus') {
                        statusBeforePending = ch.oldValue
                    }
                    if (ch.fieldName === 'pendingUntil') {
                        pendingUntil = ch.newValue
                    }
                })
                if (statusBeforePending !== undefined) {
                    workLogElements.push(<Timeline.Item dot={<Icon type="pause-circle" style={{ fontSize: '16px' }} />} color="red">{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + ` ${wl.createdBy} changed status from ${statusBeforePending} to Pending until ${moment(pendingUntil).format('YYYY-MM-DD HH:mm:ss')}`}</Timeline.Item>)
                } else {
                    workLogElements.push(<Timeline.Item dot={<Icon type="pause-circle" style={{ fontSize: '16px' }} />} color="red">{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + ` ${wl.createdBy} changed Pending until ${moment(pendingUntil).format('YYYY-MM-DD HH:mm:ss')}`}</Timeline.Item>)
                }
            } else if (wl.type === 'Update') {
                (wl.changes || []).forEach((ch: WorklogChange) => {
                    if (ch.fieldName === 'category' || ch.fieldName === 'subcategory' || ch.fieldName === 'item') {
                        return null
                    } else {
                        if (!wl.createdBy) {
                            wl.createdBy = 'system'
                        }
                        if (ch.fieldName === 'pendingUntil' && ch.oldValue !== null) {
                            workLogElements.push(<Timeline.Item color="blue">{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + ` ${wl.createdBy} changed Pending until ${moment(ch.oldValue!!).format('YYYY-MM-DD HH:mm:ss')}`}</Timeline.Item>)
                        } else {
                            workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + ` ${wl.createdBy} changed ${convertCamelCaseToSentenceCase(mappingWord(ch.fieldName))} from ${ch.oldValue} to ${ch.newValue}`}</Timeline.Item>)
                        }
                    }
                })
            } else if (wl.type === 'SlaAttached') {
                if (!wl.createdBy) {
                    wl.createdBy = 'System'
                }
                // workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + ` ${wl.createdBy} attached to SLA goal ${getNewValue('slaGoalName')} of SLA policy ${slaPolicyName} at status ${getNewValue('slaStatus')} and stage ${getNewValue('slaStage')}`}</Timeline.Item>)
            } else if (wl.type === 'SlaDetached') {
                if (!wl.createdBy) {
                    wl.createdBy = 'System'
                }
                // workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + ` ${wl.createdBy} detached from SLA goal ${slaGoalName} of SLA policy  ${slaPolicyName} `}</Timeline.Item>)
            } else if (wl.type === 'SlaChanged') {
                (wl.changes || []).forEach((ch: WorklogChange) => {
                    if (!wl.createdBy) {
                        wl.createdBy = 'System'
                        if (ch.fieldName === 'slaStatus') {
                            // workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + ` SLA goal ${slaGoalName} of SLA policy ${slaPolicyName}  has status changed from ${getOldValue('slaStatus')} to ${getNewValue('slaStatus')} `} </Timeline.Item>)
                        }
                    } else {
                        if (ch.fieldName === 'slaStage') {
                            // workLogElements.push(<Timeline.Item color={'blue'}>{moment(wl.createdDate).format('YYYY-MM-DD HH:mm:ss') + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + ` SLA goal ${slaGoalName} of SLA policy ${slaPolicyName}  has stage changed from ${getOldValue('slaStage')} to ${getNewValue('slaStage')} with status as ${slaStatus} `} </Timeline.Item>)
                        }
                    }
                })
            }
        }
        return (
            <Timeline style={{ marginLeft: '40px', marginTop: '10px' }}>
                {workLogElements.map((temps: any, i: number) => {
                    return (
                        <div key={i}>{temps}</div>
                    )
                })}
            </Timeline>
        )
    }

    const ToggleIsReadMoreTopic = () => {
        setIsReadMoreTopic(!isReadMoreTopic)
    }

    const ToggleIsReadMoreDesc = () => {
        setIsReadMoreDesc(!isReadMoreDesc)
    }

    const ToggleIsReadMoreResolution = () => {
        setIsReadMoreResolution(!isReadMoreResolution)
    }

    const handleDownload = (file) => {
        const link = document.createElement('a')
        link.href = file.url
        link.download = file.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
            setPreviewVisible(true)
        } else {
            setPreviewVisible(false)
        }
        setPreviewImage(file.url || file.preview)
    }

    const fileListView = () => {
        const fileList = props.incident!!.uploadLink?.map((file : UploadLink) => {
            const uploadFile: UploadFile = {
                uid: file.name,
                name: file.name,
                status: 'done',
                url: file.url,
                type: file.type,
                size: 0,
                thumbUrl: exportIcon(file.type)
            }
            return uploadFile
        })
        return (
            <Upload
                listType="picture"
                className={'upload-list-inline'}
                fileList={fileList}
                onPreview={(e: any) => handlePreview(e)}
                // onChange={(e: any) => setFileList(fileList)}
                onDownload={(e: any) => handleDownload(e)}
                showUploadList={{ showRemoveIcon: false }}
            />
        )
        // (<Upload
        //     listType="picture-card"
        //     fileList={fileList}
        //     showUploadList={{ showRemoveIcon: false }}
        //     onPreview={handlePreview}
        // />)
    }

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item href={'/IncidentList'}>Ticket</Breadcrumb.Item>
                <Breadcrumb.Item>{props.incident?.ticketNumber}</Breadcrumb.Item>
            </Breadcrumb>
            <Row gutter={24}>
                <br />
                {/* Left Box */}
                <Col span={18} lg={18} md={18} sm={24} xs={24}>
                    {/* <Card style={{ backgroundColor: '#F0FFF5', borderLeft: '10px solid #4DC977', boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}> */}
                    <Card style={{ boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                        <Row >
                            <Col span={6} lg={6} md={6} sm={24} xs={24} style={{ marginRight: 20 }}>
                                <span style={{ color: '#2F80ED' }}>Ticket Number : {ticketNumber}</span>
                            </Col>
                            <Col span={4} lg={4} md={4} sm={24} xs={24} style={{ marginRight: 20 }}>
                                <Dropdown
                                    disabled={!checkRolesState(RoleType.Incident, 'CloseIncident', props.incident?.createdBy) && !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy)} overlay={() => my_menu()} trigger={['click']}>
                                    <span className="ant-dropdown-link" >{currentStatus?.status}<Icon type="down" style={{ marginBottom: '5px' }} /></span>
                                </Dropdown>

                                {/* Show Modal Pending */}
                                <div>
                                    <Modal
                                        visible={visiblePending}
                                        title="Pending to Date"
                                        onOk={handlePendingOk}
                                        onCancel={handlePendingCancel}
                                        footer={[
                                            <Button key="back" onClick={handlePendingCancel}>
                                                Cancel
                                            </Button>,
                                            <Button key="submit" type="primary" onClick={handlePendingOk} loading={isLoading}
                                                disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy) ||
                                                (disabledSaveButton && currentStatus?.status !== 'Pending')}>
                                                Save
                                            </Button>
                                        ]}
                                    >
                                        <Form onSubmit={handlePendingOk}>
                                            <Row gutter={16}>
                                                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                    <Form.Item label="Date">
                                                        {getFieldDecorator('datePending', {
                                                            initialValue: initialPendingUntilDate,
                                                            rules: [
                                                                {
                                                                    type: 'object',
                                                                    required: true,
                                                                    message: 'Please select date!'
                                                                }
                                                            ]
                                                        })(<DatePicker style={{width: '100%'}} />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                                    <Form.Item label="Time">
                                                        {getFieldDecorator('timePending', {
                                                            initialValue: initialPendingUntilTime,
                                                            rules: [
                                                                {
                                                                    type: 'object',
                                                                    required: true,
                                                                    message: 'Please select time!'
                                                                }
                                                            ]
                                                        })(<TimePicker style={{width: '100%'}} />)}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Form.Item label="Pending Reason">
                                                    {getFieldDecorator('PendingReason', {
                                                        initialValue: initialPendingReason,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Please input pending reason!'
                                                            }
                                                        ]
                                                    })(<TextArea style={{ marginTop: '10px' }} rows={4} placeholder={'Pending reason'} maxLength={255}/>)}
                                                </Form.Item>
                                            </Row>
                                        </Form>

                                    </Modal>
                                </div>
                            </Col>

                            <Col span={4} lg={4} md={4} sm={24} xs={24} style={{ marginRight: 20 }}>
                                { props.priority.map((value: PriorityLevel, index) => {
                                    return (
                                        priority?.priority?.name === value.name ? (
                                            <Tag style={{backgroundColor: value.tagColor, color: '#fff'}} key={index}>{priority?.priority?.name}</Tag>
                                        ) : null
                                    )
                                })}
                            </Col>
                            <Col span={8} lg={8} md={8} sm={24} xs={24} style={{ direction: 'rtl' }}>
                                <span onClick={showModal}><Icon type="edit" /></span>
                            </Col>
                        </Row>

                        {/* Show Modal Edit */}
                        <Modal
                            title="Edit Topic and Description"
                            onCancel={handleCancel}
                            visible={visible}
                            footer={[
                                <Button key="back" onClick={handleCancel}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary"
                                    loading={isEditIncidentLoading}
                                    onClick={onSubmitSubjectAndDescription}
                                    disabled={checkSaveButton() || !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy) || disabledSaveButton}>
                                    Save
                                </Button>
                            ]}
                        >
                            <Row>
                                <Form.Item label="Topic">
                                    {getFieldDecorator('Topic', {
                                        initialValue: topic,
                                        rules: [
                                            { required: true, message: 'Topic is required' }, {min: 3, message: 'Enter more than 3 characters' }
                                        ]
                                    })(
                                        <Input onChange={handleChangeTitle} placeholder="Topic" style={{ marginTop: '10px' }} maxLength={255}/>
                                    )}
                                </Form.Item>
                            </Row>
                            <Row>
                                <Form.Item label="Description">
                                    {getFieldDecorator('Description', {
                                        initialValue: description,
                                        rules: [
                                            { required: true, message: 'Description is required' }, {min: 3, message: 'Enter more than 3 characters' }
                                        ]
                                    })(
                                        <TextArea rows={4} onChange={handleChangeDescription} placeholder="Description" style={{ marginTop: '10px' }} />
                                    )}
                                </Form.Item>
                            </Row>
                        </Modal>

                        <Row style={{ marginTop: '10px' }}>
                            <Col span={6} lg={6} md={6} sm={24} xs={24} style={{ marginRight: 20 }}>
                                {/* <span>Create : {createdDate}</span> */}
                                {(currentStatus?.status === 'New' || currentStatus?.status === 'InProgress' || currentStatus?.status === 'Assigned')
                                    ? <div>Created : {moment(createdDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                                    : <div> {currentStatus?.status} : {moment(lastStatusTimestamp).format('YYYY-MM-DD HH:mm:ss')}</div>}
                            </Col>
                            <Col span={10} style={{ marginRight: 20 }}>
                                {/* <span>Due by in 10 days (on Wed, 5 Feb at 5:00 PM )</span> */}
                                {controlStatusDetail()}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col span={6} lg={6} md={6} sm={24} xs={24} style={{ marginRight: 20 }}>
                                {/* <span>Create : {createdDate}</span> */}
                                {<div>Ticket Type : {ticketType}</div>}
                            </Col>
                            <Col span={10} style={{ marginRight: 20 }}>
                                {/* <span>Due by in 10 days (on Wed, 5 Feb at 5:00 PM )</span> */}
                                {<div>Channel : {channel}</div>}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col span={3} lg={3} md={3} sm={24} xs={24} style={{textAlign: 'justify'}}>
                                <span className={'label-requester'}>Topic : </span>
                            </Col>
                            <Col span={20} lg={20} md={20} sm={24} xs={24} id='topic'>
                                {isReadMoreTopic
                                    ? <div>{topic}</div>
                                    : <div style={CutTextToReadMore(2)}>
                                        {topic}
                                    </div>}
                                {countLines('topic') >= 2 ? !isReadMoreTopic ? <a onClick={ToggleIsReadMoreTopic}>Read more</a> : <a onClick={ToggleIsReadMoreTopic}>Read less</a> : null}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }} gutter={16}>
                            <Col span={3} lg={3} md={3} sm={24} xs={24}>
                                <span className={'label-requester'}>Description : </span>
                            </Col>
                            <Col span={20} lg={20} md={20} sm={24} xs={24} id='des' style={{textAlign: 'justify', textJustify: 'auto'}}>
                                {isReadMoreDesc
                                    ? <div>{description}</div>
                                    : <div style={CutTextToReadMore(5)}>
                                        {description}
                                    </div>}
                                {countLines('des') >= 5 || description?.length >= 700 ? !isReadMoreDesc ? <a onClick={ToggleIsReadMoreDesc}>Read more</a> : <a onClick={ToggleIsReadMoreDesc}>Read less</a> : null}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }} gutter={16}>
                            <Col span={3} lg={3} md={3} sm={24} xs={24}>
                                <span className={'label-requester'}>Resolution : </span>
                            </Col>
                            <Col span={20} lg={20} md={20} sm={24} xs={24} id='Resolution'>
                                {isReadMoreResolution
                                    ? <div>{ReactHtmlParser(commentText)}</div>
                                    : <div style={CutTextToReadMore(5)}>
                                        {ReactHtmlParser(commentText)}
                                    </div>}
                                {countLines('Resolution') >= 5 || commentText?.length >= 700 ? !isReadMoreResolution ? <a onClick={ToggleIsReadMoreResolution}>Read more</a> : <a onClick={ToggleIsReadMoreResolution}>Read less</a> : null}
                            </Col>
                        </Row>
                        {
                            props.incident?.uploadLink != null
                                ? (
                                    <Row>
                                        {fileListView()}
                                        <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)} >
                                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Row>
                                )
                                : null
                        }

                    </Card>
                    <Card size={'small'} style={{ boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                        <Button shape="round" className={'workLogButton'} onClick={handleShowWorkLog}>
                            WorkLog
                        </Button>

                         <Button shape="round" className={'workLogButton'} onClick={handleShowSLA}>
                            SLA Status
                         </Button>
                        {showWorkLog ? (<div style={{ height: '200px', overflowY: 'scroll', marginTop: '20px' }}>{displayWorkLog()}</div>) : null}
                         {showSla
                            ? (<div>
                                <SpinningFetcher fetchingState={slaCalculationsFetchingState}>
                                    <SlaDisplay incidentId={id} incidentStatus={currentStatus?.status!!} pendingUntil={pendingUntil} />
                                </SpinningFetcher>
                            </div>)
                            : null
                         }
                    </Card>
                    <CommentForm incidentId={id}
                        requesterEmail={email!!}
                        ticketNumber={ticketNumber!!}
                        requester={requester!!}
                        topic={topic!!}
                        description={description!!}
                        createdDate={createdDate!!}
                        isDisableStatus={disabledSaveButton}
                    />
                    <CommentSection incidentId={id} />

                    {/* Open Editor */}
                    {/* Reply */}
                </Col>
                {/* Right Box */}
                <Col span={6} lg={6} md={6} sm={24} xs={24}>
                    <Card>
                        <p className={'sub-title'}>Requester</p>
                        <Row>
                            <Col offset={1}>
                                <label style={{ color: '#6A6C8F' }}>Requester :</label>
                                <label style={{ marginLeft: '10PX' }}>{requester}</label>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col offset={1}>
                                <label style={{ color: '#6A6C8F' }}>Email :</label>
                                <label style={{ marginLeft: '10PX' }}>{email}</label>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col offset={1}>
                                <label style={{ color: '#6A6C8F' }}>Phone Number :</label>
                                <label style={{ marginLeft: '10PX' }}>{phone}</label>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col offset={1}>
                                <label style={{ color: '#6A6C8F' }}>Company :</label>
                                <label style={{ marginLeft: '10PX' }}>{company}</label>
                            </Col>
                        </Row>
                        {/* <Row style={{ marginTop: '10px' }}>
                            <Col offset={1}>
                                <label style={{ color: '#448AFF' }}>Resent Ticket</label>
                            </Col>
                        </Row> */}
                        <div style={{border: '1px solid #E0E0E0', marginTop: '30px', marginBottom: '30px'}}/>
                        <Row>
                            <IncidentStatusUpdater currentStatus={tempStatus} changeOldStatus={changeOldStatus} oldStatus={oldStatus?.status}/>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const DescriptionTicketIncident = Form.create({ name: 'register' })(IncidentDetailsView)

export default connect(mapStateToProps,
    {
        getAllStatus,
        getAllWorkLogByIncidentId,
        updateIncident,
        getAllSlaCalculationByTicketIds: ForIncident.getAllSlaCalculationByTicketIds,
        getAllPriority
    })(DescriptionTicketIncident)
