import React, { useState, useEffect, useCallback } from 'react'
import {Table, Input, Button, Icon, Empty} from 'antd'
import Highlighter from 'react-highlight-words'
import { SplittedCondition } from './form-model'
import {Condition, SlaCondition, SlaPolicy, SlaPolicyFunc} from './model'
import {nullOrEmptyObject} from '../../common-misc'
import {PriorityLevel} from '../../incident-management/priority'
import {User} from '../../authorization-module/user-role/duck/model'
import {SupportTeamManagement} from '../../authorization-module/support-team-management/model'

interface Props {
    slaPolicy: SlaPolicy
    isView: boolean
    handleToUpdate: (boolean) => void
    priority: PriorityLevel[]
    user: User[]
    supportTeam: SupportTeamManagement[]
}

export const SlaPolicyFormConditionList: React.FC<Props> = (props: Props) => {
    const [searchText, setSearchText] = useState<string>('')
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState({}), [])

    // const buildSplittedConditions = () => {
    //     return Array.from(Object.keys(props.slaPolicy.conditions)).flatMap((key) => {
    //         const values = props.slaPolicy.conditions[key]
    //         return values.map((value) => ({key: key, value} as SplittedCondition))
    //     })
    // }

    const deleteSplitCondition = (splitCondition: SlaCondition) => {
        if (SlaPolicyFunc.deleteConditionIfExist(props.slaPolicy, splitCondition.rule, splitCondition.value)) {
            forceUpdate()
            if (nullOrEmptyObject(props.slaPolicy.conditions)) {
                props.handleToUpdate(true)
            }
        }
    }

    const getColumnSearchProps = (dataIndex: any) => ({
        // eslint-disable-next-line react/display-name
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters }:any):any => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e:any) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8, color: 'red'}}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        // eslint-disable-next-line react/display-name
        filterIcon: (filtered:any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value:any, record:any) => {
            if (dataIndex === 'value') {
                return convertConditionValue(record).toLowerCase().includes(value.toLowerCase())
            } else if (dataIndex === 'rule') {
                return convertConditionRule(record.rule).toLowerCase().includes(value.toLowerCase())
            } else {
                return record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
            }
        },
        onFilterDropdownVisibleChange: (visible:any) => {
            if (visible) {
            }
        },
        // eslint-disable-next-line react/display-name
        render: (text:any) => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text}
            />
        )
    })

    const handleSearch = (selectedKeys: any, confirm: any) => {
        confirm()
        setSearchText(selectedKeys[0])
    }

    const handleReset = (clearFilters: any) => {
        clearFilters()
        setSearchText('')
    }

    const convertConditionRule = (rule) => {
        if (rule === 'supportTeamId') {
            return 'Group'
        }
        if (rule === 'userId') {
            return 'Assignee'
        }
        if (rule === 'priorityId') {
            return 'Priority'
        }
        return ''
    }

    const convertConditionValue = (condition: SlaCondition):string => {
        if (condition.rule === 'supportTeamId') {
            if (props.supportTeam) {
                const supportTeam = props.supportTeam.find(it => it.id?.toString() === condition.value.toString())
                return supportTeam?.name!!
            }
        }
        if (condition.rule === 'userId') {
            if (props.user) {
                const user = props.user.find(it => it.id?.toString() === condition.value.toString())
                return user?.people.fullName!!
            }
        }
        if (condition.rule === 'priorityId') {
            if (props.priority) {
                const priority = props.priority.find(it => it.id?.toString() === condition.value.toString())
                return priority?.name!!
            }
        }
        return ''
    }

    const columns = [
        {
            title: '',
            width: '20%',
            // eslint-disable-next-line react/display-name
            render: (splitCondition: SlaCondition) =>
                // eslint-disable-next-line react/prop-types
                <span ><Icon type="delete" onClick={() => deleteSplitCondition(splitCondition)} className= { props.isView ? 'disabled-icon' : 'deleteButton'} /></span>
        },
        {
            title: 'Rule',
            dataIndex: 'rule',
            key: 'id',
            width: '40%',
            sorter: (a, b) => a.rule?.localeCompare(b.rule),
            ...getColumnSearchProps('rule'),
            render: (row: string) => (
                <>
                    <div>
                        <span>{convertConditionRule(row)}</span>
                    </div>
                </>
            )
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            width: '40%',
            sorter: (a, b) => convertConditionValue(a).localeCompare(convertConditionValue(b)),
            ...getColumnSearchProps('value'),
            render: (row: string, data: SlaCondition) => (
                <>
                    <div>
                        <span>{convertConditionValue(data)}</span>
                    </div>
                </>
            )
        }
    ]

    return (
        <>
            <div>
                {nullOrEmptyObject(props.slaPolicy.conditions)
                    ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    ) : (
                        <Table
                            columns={columns}
                            dataSource={props.slaPolicy.conditions}
                            // dataSource={buildSplittedConditions().map((it) => ({...it, rowKey: [it.key, it.value].join(':')}))}
                            rowKey={obj => obj.rule + obj.value}
                            pagination={{ pageSize: 5 }} size="small" className={props.isView ? '' : 'table table-striped'}
                        />)
                }

            </div>
        </>
    )
}
