import { Dispatch } from 'redux'
import {
    AddIncidentViewSuccessEvent,
    CurrentViewCriteriaChangedEvent,
    GetCurrentIncidentViewSuccessEvent,
    SetCurrentIncidentViewSuccessEvent,
    UpdateIncidentViewSuccessEvent
} from './state-event'
import { IncidentFilter, IncidentView } from './model'
import { axiosGet, axiosPatch, axiosPost } from '../rest'
import { IncidentViewState } from './state-model'

export const getCurrentIncidentView = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<IncidentViewState>('/api/incident/views/getCurrentView')
            dispatch(GetCurrentIncidentViewSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.error(err)
            throw err
        }
    }
}

export const setCurrentIncidentView = (viewName: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<IncidentViewState>('/api/incident/views/setCurrentView?viewName=' + viewName)
            dispatch(SetCurrentIncidentViewSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.error(err)
            throw err
        }
    }
}

export interface AddIncidentViewParams {
    name: string
    criteria: IncidentFilter
    visibility: string
}

export const addIncidentView = (params: AddIncidentViewParams) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<IncidentView>('/api/incident/views', {...params})
            dispatch(AddIncidentViewSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}
export const updateIncidentView = (incidentView: IncidentView) => {
    return async (dispatch: Dispatch) => {
        try {
            const {id, name, criteria, visibility} = incidentView
            const data = {name, criteria, visibility}
            const response = await axiosPatch<IncidentView>(`/api/incident/views/${id}`, data)
            dispatch(UpdateIncidentViewSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const notifyCurrentViewCriteriaChanged = (criteria: IncidentFilter) => {
    return async (dispatch: Dispatch) => {
        dispatch(CurrentViewCriteriaChangedEvent.build(criteria))
    }
}

export const setIncidentSorting = async (sortBy: string, orderBy: string) => {
    try {
        const res = await axiosPost<IncidentViewState>('/api/incident/views/setCurrentSorting', {sortBy, orderBy})
        return res.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const newUserView = async (secretCode: string) => {
    try {
        const res = await axiosGet<number>(`/api/incident/views/newUserView/${secretCode}`)
        return res.status
    } catch (err) {
        console.log(err)
        throw err
    }
}

