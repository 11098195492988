import React, { useState } from 'react'
import {
    Row, Col, Modal, Form,
    DatePicker, TimePicker, Input, Button, message
} from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { updateIncidentStatus } from './service'
import {TicketStatus} from './model'

interface IProps extends FormComponentProps {
    targetId?: number,
    currentStatus: (string) => void,
    statuses: TicketStatus[],
    disablePending: boolean
}

const ModalPendingForm: React.FC<IProps> = (props: IProps) => {
    const { getFieldDecorator } = props.form
    const { TextArea } = Input
    const [isVisible, setIsVisible] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handlePendingOk = (e: any) => {
        e.preventDefault()

        props.form.validateFields((err: any, fieldsValue: any) => {
            if (!err) {
                setIsLoading(true)
                // Should format date value before submit.
                const values = {
                    ...fieldsValue,
                    // eslint-disable-next-line quote-props
                    'datePending': fieldsValue.datePending.format('YYYY-MM-DD'),
                    // eslint-disable-next-line quote-props
                    'timePending': fieldsValue.timePending.format('HH:mm:ss')
                }
                const newStatus: any = 'Pending'
                const dueDatePending = values.datePending + ' ' + values.timePending + '.000'
                const reasonOfPending = values.PendingReason
                // eslint-disable-next-line prefer-const
                let ticketStatus = props.statuses.filter(it => it.status === newStatus)
                updateIncidentStatus(props.targetId!!, ticketStatus[0], reasonOfPending, dueDatePending)
                    .then((res) => {
                        props.currentStatus(newStatus)
                        setIsVisible(false)
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        setIsLoading(false)
                        message.error(err.response.data.message)
                    })
            }
        })
    }

    return (
        <>
            <Modal
                visible={isVisible}
                title="Pending to Date"
                onOk={handlePendingOk}
                onCancel={() => setIsVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setIsVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handlePendingOk} loading={isLoading}
                        disabled={props.disablePending}
                    >
                        Save
                    </Button>
                ]}
            >
                <Form onSubmit={handlePendingOk}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Date">
                                {getFieldDecorator('datePending', {
                                    rules: [
                                        {
                                            type: 'object',
                                            required: true,
                                            message: 'Please select Date!'
                                        }
                                    ]
                                })(<DatePicker />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Time">
                                {getFieldDecorator('timePending', {
                                    rules: [
                                        {
                                            type: 'object',
                                            required: true,
                                            message: 'Please select time!'
                                        }
                                    ]
                                })(<TimePicker />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item label="Pending Reason">
                            {getFieldDecorator('PendingReason', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input pending reason!'
                                    }
                                ]
                            })(<TextArea style={{ marginTop: '10px' }} rows={4} maxLength={255}/>)}
                        </Form.Item>
                    </Row>
                </Form>

            </Modal>
        </>
    )
}

const ModalPending = Form.create<IProps>({})(ModalPendingForm)
export default ModalPending
