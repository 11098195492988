import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { StoreState } from '../../store'
import { Button, Card, Col, Dropdown, Form, Icon, Menu, message, Row, Table, Input } from 'antd'
import { Link } from 'react-router-dom'
import IncidentFilterForm from '../incident-view/IncidentFilterForm'
import IncidentRow from './IncidentRow'
import { FetchingState, fetchWithState, SpinningFetcher } from '../../common-components'
import { Incident, IncidentFieldLabel, IncidentWithSlaCalculations } from './index'
import {
    getCurrentIncidentView,
    IncidentFilter,
    notifyCurrentViewCriteriaChanged, UserChangeOrderByEvent,
    UserChangeSortByEvent
} from '../incident-view'
import { searchIncidents, updateIncident } from './service'
import { getAllSupportTeam } from '../support-team'
import { getAllPriority } from '../priority'
import { getAllStatus } from '../status'
import { getAllCategory } from '../category'
import { FormComponentProps } from 'antd/es/form'
import { ForIncident } from '../../sla-management/sla-calculation/service'
import { getVersion, notEmpty, resolveOrderDirectionLabel } from '../../common-misc'
import moment from 'moment'
import { IncidentWebSocketChannel } from './web-socket-channel'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.categories,
        statuses: state.statuses,
        supportTeams: state.supportTeams,
        incidentViewState: state.incidentViewState,
        criteria: state.incidentViewState.currentView?.criteria,
        priority: state.priorities,
        incidents: state.incidentState?.incidents || [],
        tablePagination: state.tablePagination.incidentsTotal,
        userRolesAndPermissions: state.userRolesAndPermissions,
        permission: state.currentUserRole
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    searchIncidents: (criteria: IncidentFilter, sortBy: string, orderBy: string, skip: number, limit: number) => Promise<number>
    updateIncident: (incident: Incident) => Promise<number>
    getAllStatus: () => Promise<number>
    getAllSupportTeam: () => Promise<number>
    getAllCategory: () => Promise<number>
    notifyCurrentViewCriteriaChanged: (criteria: IncidentFilter) => Promise<void>
    getCurrentIncidentView: () => Promise<number>
    getAllPriority: () => Promise<number>
    getAllSlaCalculationByTicketIds: (ticketIds: number[]) => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

const IncidentList: React.FC<Props> = (props: Props) => {
    const [incidentViewStateFetchingState, setIncidentViewStateFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [incidentsFetchingState, setIncidentsFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [slaCalculationsFetchingState, setSlaCalculationsFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [currentPage, setCurrentPage] = useState<number>(1)

    const { Search } = Input

    const dispatch = useDispatch()
    const limit: number = 10

    useEffect(() => {
        IncidentWebSocketChannel.subscribe()

        // Fetch dropdown fields options
        props.getAllStatus().catch((err) => message.error(`Failed fetching all status. ${err}`))
        props.getAllSupportTeam().catch((err) => message.error(`Failed fetching all status. ${err}`))
        props.getAllCategory().catch((err) => message.error(`Failed fetching all status. ${err}`))
        props.getAllPriority().catch((err) => message.error(`Failed fetching all status. ${err}`))
        // get current view and search incident by view
        fetchWithState(incidentViewStateFetchingState, setIncidentViewStateFetchingState, props.getCurrentIncidentView)
    }, [])

    useEffect(() => {
        setIncidentsFetchingState(FetchingState.NotStarted)
    }, [getVersion(props.incidentViewState), props.incidentViewState.sortBy, props.incidentViewState.orderBy, currentPage])

    useEffect(() => {
        if (incidentsFetchingState === FetchingState.NotStarted) {
            if (props.criteria) {
                const f = function () {
                    return props.searchIncidents(props.criteria!!, props.incidentViewState.sortBy!!, props.incidentViewState.orderBy!!, (currentPage - 1) * limit, limit)
                        .then((res) => {
                            setSlaCalculationsFetchingState(FetchingState.NotStarted)
                        })
                        .catch((err) => message.error(`Failed searching for incidents. ${err}`))
                }
                fetchWithState(incidentsFetchingState, setIncidentsFetchingState, f)
            }
        }
    }, [incidentsFetchingState, props.criteria, props.incidentViewState.sortBy, props.incidentViewState.orderBy])

    useEffect(() => {
        const f = async () => {
            IncidentWebSocketChannel.subscribeToIncidentIds(props.incidents.map((it) => it.id!!))
            await slaExtension.fetchData()
        }
        fetchWithState(slaCalculationsFetchingState, setSlaCalculationsFetchingState, f)
    }, [slaCalculationsFetchingState, currentPage])

    const slaExtension = {
        fetchData: async () => {
            if (notEmpty(props.incidents)) {
                const incidentIds = props.incidents.map((it) => it.id!!)
                await props.getAllSlaCalculationByTicketIds(incidentIds)
            }
        }
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            // eslint-disable-next-line react/display-name
            render: (row: IncidentWithSlaCalculations) => (
                // eslint-disable-next-line react/prop-types
                <IncidentRow key={row.id} incident={row} xxx={moment()} slaCalculationsFetchingState={slaCalculationsFetchingState} priority={props.priority} />
            )
            // TODO xxx is a workaround. Find perfect solution later.
        }
    ]

    const setSortBy = (fieldName: string) => {
        dispatch(UserChangeSortByEvent.build(fieldName))
    }

    const setOrderBy = (direction: string) => {
        dispatch(UserChangeOrderByEvent.build(direction))
    }

    const notifyCurrentViewCriteriaChanged =
        props.notifyCurrentViewCriteriaChanged as (IncidentFilter) => Promise<undefined>

    function modifyCriteria(criteria: IncidentFilter) {
        notifyCurrentViewCriteriaChanged(criteria).catch((error) => message.error(error.toString()))
    }

    const onWordingSearch = (value: string) => {
        modifyCriteria({ ...props.criteria, wording: value })
    }

    const sortBySelector = (
        <Menu>
            <Menu.Item onClick={() => setSortBy('createdDate')}>
                <span>Date Created</span>
            </Menu.Item>
            <Menu.Item onClick={() => setSortBy('lastModifiedDate')}>
                <span>Last Modified</span>
            </Menu.Item>
            <Menu.Item onClick={() => setSortBy('priority')}>
                <span>Priority</span>
            </Menu.Item>
            <Menu.Item onClick={() => setSortBy('ticketStatus')}>
                <span>Status</span>
            </Menu.Item>
            <Menu.Item onClick={() => setSortBy('number')}>
                <span>Ticket Number</span>
            </Menu.Item>
        </Menu>
    )

    const orderBySelector = (
        <Menu>
            <Menu.Item onClick={() => setOrderBy('asc')}>
                <span>Ascending</span>
            </Menu.Item>
            <Menu.Item onClick={() => setOrderBy('desc')}>
                <span>Descending</span>
            </Menu.Item>
        </Menu>
    )

    const handleClickNumberPage = (page: number) => {
        setCurrentPage(page)
    }

    const resolveFieldLabel = (fieldName: string) => IncidentFieldLabel.mapping[fieldName] || ''

    // eslint-disable-next-line no-return-assign
    return (
        <div style={{ overflow: window.innerWidth < 768 ? 'auto' : 'none'}} >
            <SpinningFetcher fetchingState={incidentViewStateFetchingState}>
                <Row gutter={24}>
                    <Col span={18} lg={18} md={18} sm={24} xs={24}>
                        <Card className={'border-height-default'} style={{minWidth: 'max-content'}}>
                            <Row>
                                <Col span={12}>
                                    <h2 className="sub-title">Ticket</h2>
                                </Col>
                                <Col span={12} style={{ textAlign: 'end' }}>
                                    <Link to="/IncidentForm"><Button type="primary" disabled={!checkRolesState(RoleType.Incident, 'CreateIncident')}>Create</Button></Link>
                                </Col>
                            </Row>
                            <Row>
                                <Search
                                    placeholder="Can search Topic and Ticket ID"
                                    allowClear={true}
                                    onSearch={value => onWordingSearch(value)}
                                    style={{ width: '30%', marginBottom: 20 }}
                                />
                            </Row>
                            <Row>
                                <Col span={5} style={{ marginRight: 10 }}>
                                    <span>Sort by <span style={{ fontWeight: 'bold' }}>
                                        <Dropdown overlay={sortBySelector} >
                                            <span className="ant-dropdown-link">
                                                {resolveFieldLabel(props.incidentViewState.sortBy!!)}
                                                <Icon type="down"
                                                    style={{ marginBottom: '5px' }} /></span>
                                        </Dropdown></span></span>
                                </Col>
                                <Col span={5} >
                                    <Dropdown overlay={orderBySelector}>
                                        <span className="ant-dropdown-link"
                                            style={{ fontWeight: 'bold' }}>{resolveOrderDirectionLabel(props.incidentViewState.orderBy!!)}<Icon type="down"
                                                style={{ marginBottom: '5px' }} /></span>
                                    </Dropdown>
                                </Col>
                            </Row>
                            <SpinningFetcher fetchingState={incidentsFetchingState}>
                                <Table className="table-config-header" dataSource={props.incidents} columns={columns} rowKey="id"
                                    pagination={{
                                        pageSize: limit,
                                        current: currentPage,
                                        total: props.tablePagination!!,
                                        // showSizeChanger: true,
                                        // showQuickJumper: true,
                                        onChange: (event) => { handleClickNumberPage(event) }
                                    }} />
                            </SpinningFetcher>
                        </Card>
                    </Col>
                    <Col span={6} lg={6} md={6} sm={24} xs={24}>
                        <Card className={'border-height-default'}>
                            <SpinningFetcher fetchingState={incidentViewStateFetchingState}>
                                <IncidentFilterForm />
                            </SpinningFetcher>
                        </Card>
                    </Col>
                </Row>
            </SpinningFetcher>
        </div>
    )
}

const IncidentListWrapped = Form.create({ name: 'GlobalFormSetting' })(IncidentList)

export default connect(
    mapStateToProps,
    {
        searchIncidents,
        updateIncident,
        getAllStatus,
        getAllSupportTeam,
        getAllCategory,
        notifyCurrentViewCriteriaChanged,
        getCurrentIncidentView,
        getAllPriority
        ,getAllSlaCalculationByTicketIds: ForIncident.getAllSlaCalculationByTicketIds
    }
)(IncidentListWrapped)
