import {buildStateEvent, GenericStateEvent} from '../../../../common-redux'
import {JobWorkLog} from './model'

export interface WorklogStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllWorklogByJobNoSuccessEvent = {
    type: 'GetAllWorklogByJobNoSuccessEvent',
    build: (payload: JobWorkLog[]) => buildStateEvent(GetAllWorklogByJobNoSuccessEvent.type, payload)
}

export const WorklogAddedEvent = {
    type: 'WorklogAddedEvent',
    build: (payload: JobWorkLog) => buildStateEvent(WorklogAddedEvent.type, payload)
}
