import { SavePermissionAndScopeListEvent, SavePermissionAndScopeStateEvent } from './state-event'
import { RoleState } from './model'
import { Permissions } from './permissions'

export const rolesStateReducer = (state: RoleState = {}, event: SavePermissionAndScopeStateEvent) => {
    if (event.type === SavePermissionAndScopeListEvent.type) {
        Permissions.getInstance().roleState = event.payload
        return event.payload
    } else {
        return state
    }
}
