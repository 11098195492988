import React from 'react'
import {Col, Collapse} from 'antd'
import Menu from 'antd/lib/menu'

interface Props {
    selectFolder: Function
    countKnowledge? : number
}

const {Panel} = Collapse

export const ApprovalCategories: React.FC<Props> = (props: Props) => {
    // const handleClick = (e: ClickParam) => {
    //     console.log('click ', e.keyPath)
    //     // TODO get Knowledge
    // }

    return (
        <Col span={6} xs={24} sm={24} md={24} lg={24} xl={6}>
            <Collapse defaultActiveKey={['1']}>
                <Panel header="Approval Category" key="1">
                    <Menu mode="inline">
                        <Menu.Item key='all'>
                            <span className='km-active-menu'>Knowledge ({props.countKnowledge || 0})</span>
                        </Menu.Item>
                    </Menu>
                </Panel>
            </Collapse>
        </Col>
    )
}
