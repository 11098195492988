import { StatusStateEvent, GetAllStatusSuccessEvent } from './state-event'
import { Status } from './model'
import {TicketStatus} from '../incident';

export const statusStateReducer = (state: TicketStatus[] = [], event: StatusStateEvent<any>) => {
    if (event.type === GetAllStatusSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
