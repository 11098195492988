/* eslint-disable react/display-name */
import { Form, Row, Col, Input, InputNumber, Button, Table, Icon, Empty, message, Modal } from 'antd'
import React, { useState, useEffect } from 'react'
import { FormComponentProps } from 'antd/lib/form'
import { Charging, ChargingGroup } from './Model'
import { useLocation } from 'react-router-dom'
import { StoreState } from '../../../store'
import { getChargingByWoId, deleteCharging } from './service'
import { connect } from 'react-redux'
import {decryptDataVspace} from '../../../common-misc'

interface Param {
    topic: string,
    woId: string,
    getDataLogistic?: Function,
    getDataRepair?: Function,
    deleteDataLogistic?: Function,
    deleteDataRepair?: Function,
    key: string
}
const mapStateToProps = (state: StoreState) => {
    return {
        chargingGroup: state.chargingGroup,
        job: state.job
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getChargingByWoId: (woId: number) => Promise<number>
    deleteCharging: (id: String) => Promise<number>
}
type Props = StateProps & DispatchProps & Param & FormComponentProps
const { confirm } = Modal
const LogisticChargingForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, resetFields, getFieldsError, getFieldValue } = props.form
    const [dataCost, setDataCost] = useState<Charging[]>([])
    const [VSpaceName, setVSpaceName] = useState<string>('')
    const location = useLocation()
    const isEdit = location.pathname.indexOf('WoFormEdit') >= 0
    const [totalCost, setTotalCost] = useState<number>(0)
    const [isDisable, setIsDisable] = useState<boolean>(false)

    useEffect(() => {
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            const email = dataVspace.email
            setVSpaceName(email)
        }
        if (isEdit) {
            fetch()
        }
    }, [])

    useEffect(() => {
        resetFields()
    }, [props.key])

    useEffect(() => {
        if (isEdit) {
            let total: number = 0
            const data: ChargingGroup[] = props.chargingGroup.filter(groupByChargeType)
            if (data.length > 0) {
                (data[0].chargingList || []).map((it) => {
                    // @ts-ignore
                    total += it.chargeCost
                })
                setDataCost(data[0].chargingList || [])
                setTotalCost(total)
            }
        }
    }, [props.chargingGroup])

    // useEffect(() => {
    //     console.log(dataCost)
    //     if (props.topic === 'Logistic') {
    //         if (props.getDataLogistic) {
    //             props.getDataLogistic(dataCost)
    //         }
    //     } else if (props.topic === 'Repair') {
    //         if (props.getDataRepair) {
    //             props.getDataRepair(dataCost)
    //         }
    //     }
    // }, [dataCost.length])

    useEffect(() => {
        if (location.pathname.includes('/WoFormEdit')) {
            checkApproveToDisable()
        }
    }, [props.job])

    const fetch = () => {
        props.getChargingByWoId(props.woId as unknown as number).catch((err) => message.error(`Failed fetching data. ${err}`))
    }

    const groupByChargeType = (element: ChargingGroup) => {
        return element.chargeType === props.topic
    }

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field])
    }

    const addCost = (e) => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                const data: Charging = {
                    chargeType: props.topic,
                    chargeDescription: values.description,
                    chargeCost: values.cost,
                    createdBy: VSpaceName,
                    lastModifiedBy: VSpaceName
                }
                dataCost.push((data as Charging))
                // setDataCost(dataCost)
                if (props.topic === 'Logistic') {
                    if (props.getDataLogistic) {
                        props.getDataLogistic(dataCost)
                    }
                } else if (props.topic === 'Repair') {
                    if (props.getDataRepair) {
                        props.getDataRepair(dataCost)
                    }
                }
                setTotalCost(dataCost.reduce((sum, { chargeCost }) => sum + chargeCost!!, 0))
                resetFields()
            }
        })
    }

    const deleteCost = (data: Charging) => {
        try {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete this data?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                async onOk() {
                    const tempData = dataCost.filter((it) => {
                        return it.chargeDescription !== data.chargeDescription
                    })
                    if (isEdit) {
                        if (data.id) {
                            props.deleteCharging(data.id!!).then(() => {
                                setDataCost(tempData)
                                setTotalCost(tempData.reduce((sum, { chargeCost }) => sum + chargeCost!!, 0))
                            }).catch((err) => {
                                message.error(`You have unSuccessfully save the data. ${err}`)
                            })
                        } else {
                            setDataCost(tempData)
                            setTotalCost(tempData.reduce((sum, { chargeCost }) => sum + chargeCost!!, 0))
                        }
                    } else {
                        setDataCost(tempData)
                        setTotalCost(tempData.reduce((sum, { chargeCost }) => sum + chargeCost!!, 0))
                    }

                    if (props.topic === 'Logistic') {
                        if (props.deleteDataLogistic) {
                            props.deleteDataLogistic(data)
                        }
                    } else if (props.topic === 'Repair') {
                        if (props.deleteDataRepair) {
                            props.deleteDataRepair(data)
                        }
                    }
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const checkApproveToDisable = () => {
        const isApprove = props.job.filter(data => data.jobTopic === 'Approve' && data.jobStatus === 'Closed')
        const isRejected = props.job.filter(data => data.jobTopic === 'Approve' && data.jobStatus === 'Rejected')
        if (isApprove.length === 0 && isRejected.length === 0) {
            setIsDisable(false)
        } else {
            setIsDisable(true)
        }
    }

    const columns = [
        {
            title: '',
            width: '20%',
            render: (row) => (
                <span aria-disabled={isDisable}><Icon type="delete" onClick={() => deleteCost(row)} style={ isDisable ? { opacity: '0.5', pointerEvents: 'none'} : { opacity: '1'}}/></span>
            )
        },
        {
            title: 'Description',
            dataIndex: 'chargeDescription',
            key: 'chargeDescription',
            width: '60%'
        },
        {
            title: 'Cost',
            dataIndex: 'chargeCost',
            key: 'chargeCost',
            width: '20%'
        }
    ]

    const validateNameUniqueness = (_: any, value: any, callback) => {
        const chargeDescriptionList = dataCost.map((data) => {
            return data.chargeDescription
        })
        if (chargeDescriptionList.includes(value.trim())) {
            callback('The Description is already exists. It must be unique. Please try another one.')
        } else {
            callback()
        }
    }

    return (
        <>
            <div className='BG_Gray' style={{overflow: 'auto'}}>
                <h2 className='sub-title'>{props.topic}</h2>
                <Form layout="vertical" className='BG_orange' style={{minWidth: 'max-content'}}>
                    <Row gutter={4}>
                        <Col sm={4} md={8} lg={12} xl={16} style={{ marginTop: 5 }}>
                            <Form.Item label="Description">
                                {getFieldDecorator('description',
                                    {
                                        rules: [{ required: true, whitespace: true, message: 'Description is required' },
                                            { min: 3, message: 'Enter more than 3 characters' },
                                            { validator: validateNameUniqueness }
                                        ]
                                    })(
                                    <Input type="text" placeholder="Description" maxLength={255} disabled={isDisable}/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col md={6} style={{ marginTop: 5 }}>
                            <Form.Item label="Cost">
                                {getFieldDecorator('cost',
                                    {
                                        initialValue: 0,
                                        rules: [
                                            { required: true, message: 'Cost is required' },
                                            { type: 'number' }]
                                    })(
                                    <InputNumber min={0} style={{width: '100%'}} disabled={isDisable}/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col md={2} style={{ marginTop: 5}}>
                            <Form.Item >
                                <Button type="primary" htmlType="button" onClick={(e) => addCost(e)} style={{ marginTop: 26 }} disabled={getFieldValue('description') ? hasErrors(getFieldsError()) : true }>
                                        Add
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <br /><br />
                {dataCost.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    : <>
                        <Table
                            columns={columns}
                            dataSource={dataCost}
                            pagination={false}
                            className='table table-striped'
                            rowKey='chargeDescription'
                        />
                        <br /><br />
                        <div style={{ borderTop: '1px solid #D0D0D0', textAlign: 'end', padding: 10 }}>
                            <span style={{ marginRight: 10 }}>Total</span>
                            {totalCost}
                            <span> THB</span>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

const LogisticCharging = Form.create<Props>({})(LogisticChargingForm)

export default connect(
    mapStateToProps,
    { getChargingByWoId, deleteCharging }
)(LogisticCharging)
