import React, {useEffect, useState} from 'react'
import {
    Dropdown, Icon, Menu
    // Badge, Button, notification, Divider, Empty
} from 'antd'
// import Auth from '../auth/Auth'
import { handleUnimplementedLink } from '../common-components'
import AutoAvatar from '../common-components/autoAvatar'
import { IncidentWebSocketChannel } from '../incident-management/incident/web-socket-channel'
import { Frame } from 'stompjs'
import { BASE_URL } from '../incident-management/service'

interface Props {
    // auth: Auth;
    broken: boolean
}

export const UserDetails: React.FC<Props> = (props: Props) => {
    // const [count, setCount] = useState<number>(0)
    // const [link, setlink] = useState<String>('')
    useEffect(() => {
        IncidentWebSocketChannel.subscribeNotify(onDashboardEvent)
        // IncidentWebSocketChannel.subscribeToNotify()
    }, [])

    const onDashboardEvent = (frame: Frame) => {
        // const event: IncidentWebSocketEvent = JSON.parse(frame.body)
        if (frame.body) {
            const spitPath = frame.body.split('/')
            // setlink(spitPath[spitPath.length - 1])
            // setCount(1)
            // openNotification('bottomLeft')
            // console.log(spitPath[spitPath.length - 1])
            window.open(`${BASE_URL}/api/dashboard/downloadReport?reportName=${spitPath[spitPath.length - 1]}`)
        }
    }

    const myMenu = (
        <Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="http://www.alipay.com/" onClick={handleUnimplementedLink}>
                    Profile
                </a>
            </Menu.Item>
            <Menu.Item>
                <button onClick={() => logout()}>Logout</button>
            </Menu.Item>
        </Menu>
    )

    // const myNotify = (
    //     <Menu>
    //         {count === 0 ? <Empty /> : <Menu.Item>
    //             <a target="_blank" rel="noopener noreferrer" onClick={() => DownloadSuccess()}>
    //                 Download File At Link {`${BASE_URL}/api/dashboard/downloadReport?reportName=${link}`}
    //             </a>
    //         </Menu.Item>}
    //     </Menu>
    // )

    // const DownloadSuccess = () => {
    //     window.open(`${BASE_URL}/api/dashboard/downloadReport?reportName=${link}`)
    //     setlink('')
    //     setCount(0)
    // }

    const logout = () => {
        localStorage.clear()
        window.location.href = '/'
    }

    // const openNotification = placement => {
    //     notification.info({
    //         message: 'Notification',
    //         description:
    //             'Generate Report Dashboard Success full ',
    //         placement
    //     })
    // }

    return (
        <>
            {/* <span style={{ marginTop: 5, width: 50 }}>
                <Dropdown overlay={myNotify} trigger={['click']}>
                    <Badge count={count}>
                        <Icon type="bell" theme="filled" style={{ marginRight: 10, fontSize: 24, color: '#8c8c8c' }} />
                    </Badge>
                </Dropdown>
            </span> */}
            {
                localStorage.getItem('username') ? (
                    <div >
                        <AutoAvatar title={localStorage.getItem('username')!!} broken={props.broken}/>
                        {props.broken ? null : <Dropdown overlay={myMenu} trigger={['click']}>
                            <span className="ant-dropdown-link" style={{ marginLeft: 5 }} >{localStorage.getItem('username')} <Icon type="down" style={{ marginBottom: '5px' }} /></span>
                        </Dropdown>}
                    </div>
                ) : null
            }
        </>
    )
}
