import { buildStateEvent, GenericStateEvent } from '../../common-redux'
import { Status } from './model'
import {TicketStatus} from '../incident';

export interface StatusStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllStatusSuccessEvent = {
    type: 'GetAllStatusSuccessEvent',
    build: (payload: TicketStatus[]) => buildStateEvent(GetAllStatusSuccessEvent.type, payload)
}
