import { buildStateEvent, GenericStateEvent } from '../../common-redux'
import { Incident } from './model'
import { IncidentWebSocketEvent } from './web-socket-model'

export interface IncidentStateEvent<T> extends GenericStateEvent<T> {}

export const SearchIncidentsSuccessEvent = {
    type: 'SearchIncidentsSuccessEvent',
    build: (payload: Incident[]) => buildStateEvent(SearchIncidentsSuccessEvent.type, payload)
}

export const GetIncidentByIdSuccessEvent = {
    type: 'GetIncidentByIdSuccessEvent',
    build: (payload: Incident) => buildStateEvent(GetIncidentByIdSuccessEvent.type, payload)
}

export const IncidentAddedEvent = {
    type: 'IncidentAddedEvent',
    build: (payload: Incident) => buildStateEvent(IncidentAddedEvent.type, payload)
}

export const IncidentUpdatedEvent = {
    type: 'IncidentUpdatedEvent',
    build: (payload: Incident) => buildStateEvent(IncidentUpdatedEvent.type, payload)
}

export const IncidentDeletedEvent = {
    type: 'IncidentDeletedEvent',
    build: (payload: number) => buildStateEvent(IncidentDeletedEvent.type, payload)
}

export const SlaCalculationsUpdatedEvent = {
    type: 'SlaCalculationsUpdatedEvent',
    build: (payload: IncidentWebSocketEvent) => buildStateEvent(SlaCalculationsUpdatedEvent.type, payload)
}

export const GetAllIncidentSuccessEvent = {
    type: 'GetAllIncidentSuccessEvent',
    build: (payload: Incident[]) => buildStateEvent(GetAllIncidentSuccessEvent.type, payload)
}
