import React from 'react'
import { Select } from 'antd'
import { IncidentFilter } from '../model'
import { SelectValue } from 'antd/es/select'
import { Status } from '../../status'
import {TicketStatus} from '../../incident'

interface OwnProps {
    onChanged: (value: string[]) => void
    criteria: IncidentFilter
    statuses: TicketStatus[]
}

type Props = OwnProps

const StatusFilter: React.FC<Props> = (props: Props) => {
    const { Option } = Select

    const onStatusesChanged = (value: SelectValue) => {
        props.onChanged(Array.isArray(value) ? (value as Array<any>).map((it) => it as string) : [value as string])
    }

    return (
        <>
            <p className={'topicName'}>Status</p>
            <Select mode="tags" style={{ width: '85%' }} onChange={onStatusesChanged} value={props.criteria.statuses?.split(',') || []}>
                {props.statuses?.map((it, index) => { return <Option key={index} value={it.status}>{it.status}</Option> })}
            </Select><br />
        </>
    )
}

export default StatusFilter
