export interface UserRole {
    id?: string
    createdBy?: string
    lastModifiedBy?: string
    createdDate?: string
    lastModifiedDate?: string
    name?: string
    security?: PermissionAndScope
    incident?: PermissionAndScope
    knowledge?: PermissionAndScope
    slaPolicy?: PermissionAndScope
}

export interface PermissionAndScope {
    push(obj: PermissionAndScope)
    permissions?: string[]
    scope?: string
}

export interface RoleState {
    security?: PermissionAndScope[]
    incident?: PermissionAndScope[]
    knowledge?: PermissionAndScope[]
    slaPolicy?: PermissionAndScope[]
}

export enum RoleType {
    Security = 'security',
    Incident = 'incident',
    Knowledge = 'knowledge',
    SlaPolicy = 'slaPolicy'
}

export enum ScopeType {
    GlobalAccess = 'GlobalAccess',
    GroupAccess = 'GroupAccess',
    RestrictedAccess = 'RestrictedAccess',
    SelectedGroupAccess = 'SelectedGroupAccess'
}
