import { buildStateEvent, GenericStateEvent } from '../../common-redux'
import { RoleAndPermissions } from './model'

export interface RoleAndPermissionsStateEvent<T> extends GenericStateEvent<T> { }

export interface RoleKMStateEvent<T> extends GenericStateEvent<T> { }

export const GetAllRoleAndPermissionsOptionsSuccessEvent = {
    type: 'GetAllRoleAndPermissionsOptionsSuccessEvent',
    build: (payload: RoleAndPermissions) => buildStateEvent(GetAllRoleAndPermissionsOptionsSuccessEvent.type, payload)
}

export const GetAllRoleAndPermissionsSuccessEvent = {
    type: 'GetAllRoleAndPermissionsSuccessEvent',
    build: (payload: RoleAndPermissions[]) => buildStateEvent(GetAllRoleAndPermissionsSuccessEvent.type, payload)
}

export const DeleteRoleAndPermissionsEvent = {
    type: 'DeleteRoleAndPermissionsEvent',
    build: (payload: string) => buildStateEvent(DeleteRoleAndPermissionsEvent.type, payload)
}

export const UpdateRoleAndPermissionsEvent = {
    type: 'UpdateRoleAndPermissionsEvent',
    build: (payload: RoleAndPermissions) => buildStateEvent(UpdateRoleAndPermissionsEvent.type, payload)
}

export const UpdatePageRoleAndPermissionsEvent = {
    type: 'UpdatePageRoleAndPermissionsEvent',
    build: (payload: number) => buildStateEvent(UpdatePageRoleAndPermissionsEvent.type, payload)
}

export const UpdateTotalContentRoleAndPermissionsEvent = {
    type: 'UpdateTotalContentRoleAndPermissionsEvent',
    build: (payload: number) => buildStateEvent(UpdateTotalContentRoleAndPermissionsEvent.type, payload)
}

export const GetAllRoleKMSuccessEvent = {
    type: 'GetAllRoleKMSuccessEvent',
    build: (payload: string[]) => buildStateEvent(GetAllRoleKMSuccessEvent.type, payload)
}
