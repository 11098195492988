import React, { useEffect, useState } from 'react'
import {
    Row, Col, Input, Empty, Spin, message
} from 'antd'
import TableRelatePM from './TableRelatePM'
import {findWorkOrderIdOrLocation} from '../wo-management/service'
import { workOrder } from '../wo-management'

interface Param {
    isUpdatePM: boolean,
    setIsUpdatePM: Function,
    serialNo: string
}

type Props = Param
const { Search } = Input
const SearchWoIdAndLocation: React.FC<Props> = (props: Props) => {
    const [isChangeSize, setIsChangeSize] = useState<boolean>(true)
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
    const [dataTypePM, setDataTypePM] = useState<workOrder[]>([])
    const [isNewData, setIsNewData] = useState<boolean>(false)

    useEffect(() => {
        if (window.innerWidth > 765) {
            setIsChangeSize(true)
        } else {
            setIsChangeSize(false)
        }
    }, [window.innerWidth])

    const onSearch = value => {
        if (value) {
            setIsLoadingData(true)
            setIsNewData(!isNewData)
            findWorkOrderIdOrLocation(value).then((res) => {
                setDataTypePM(res)
                setIsLoadingData(false)
            }).catch((_err) => {
                message.warning('Not found')
                setIsLoadingData(false)
            })
        }
    }

    const handleChangSearch = (e) => {
        if (e.target.value) {
            setSearchValue(e.target.value)
        } else {
            setDataTypePM([])
        }
    }

    return (
        <div>
            <span><h3 className='main-title'>Update Preventive Maintenance</h3></span>
            <Row>
                <Col span={ isChangeSize ? 5 : 24} style={{margin: '0 10px'}}>
                    <Search placeholder="Can search WOV ID or Location of WOV" allowClear onSearch={onSearch} style={{ width: '100%' }} onChange={(e) => handleChangSearch(e)} />
                </Col>
            </Row>
            <br />
            <Spin spinning={isLoadingData}>
                {searchValue ? <div style={{ padding: 20 }}>
                    <TableRelatePM data={dataTypePM} isUpdatePM={props.isUpdatePM} setIsUpdatePM={props.setIsUpdatePM} serialNo={props.serialNo} isNewData={isNewData}/>
                </div> : <Empty style={{ minHeight: !isChangeSize ? 'auto' : 420 }} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Spin>
        </div>
    )
}

export default SearchWoIdAndLocation
