import { BaseDocument } from '../../common-model'
import { SlaGoal } from '../sla-policy/model'
import moment from 'moment'

export interface SlaCalculation extends BaseDocument {
    ticketType: string
    ticketId: string
    slaMeasurableId: string
    slaPolicyHistoryId: string
    slaGoalName: string
    businessHourHistoryId: string
    slaGlobalSettingHistoryId: string
    attachedTimestamp: string
    detachedTimestamp?: string
    measurementClockStartTime: string
    measurementClockStopTime?: string
    slaTargetTime: string
    elapsedDuration: string
    pausedDuration: string
    currentlyPaused: boolean
    inProgressOutOfWorkHour: boolean
    inProgressNextWorkHour?: string
    inProgressNextOffWorkHour?: string
    slaStatus: SlaStatus
    slaStage: SlaStage
    lastCalculatedTimestamp: string
}

export enum SlaStage {
    InProgress = 'InProgress',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
    Detached = 'Detached'
}

export enum SlaStatus {
    Met = 'Met',
    Missed = 'Missed',
    Cancelled = 'Cancelled'
}

function ensureConditions(slaStage: SlaStage, slaStatus: SlaStatus, slaCalculation: SlaCalculation, slaGoal: SlaGoal | null) {
    if (slaCalculation.slaStage !== slaStage || slaCalculation.slaStatus !== slaStatus) {
        throw Error(`Not in stage of ${slaStage} ${slaStatus}`)
    }
}

export const SlaCalculationFunc = {
    calculateSlaGoalTargetTime: (slaGoal: SlaGoal): moment.Duration => {
        return moment.duration(slaGoal!!.day, 'days')
            .add(slaGoal!!.hour, 'hours')
            .add(slaGoal!!.minute, 'minutes')
    },
    calculateInProgressMissedDuration: (slaCalculation: SlaCalculation, slaGoal: SlaGoal): moment.Duration => {
        ensureConditions(SlaStage.InProgress, SlaStatus.Missed, slaCalculation, slaGoal)
        let result = moment.duration(slaCalculation.elapsedDuration)
            .subtract(SlaCalculationFunc.calculateSlaGoalTargetTime(slaGoal!!))
        if (!slaCalculation.inProgressOutOfWorkHour && !slaCalculation.currentlyPaused) {
            result = result.add(moment().diff(slaCalculation.lastCalculatedTimestamp))
        }
        return result
    },
    calculateInProgressTimeLeftDuration: (slaCalculation: SlaCalculation, slaGoal: SlaGoal): moment.Duration => {
        ensureConditions(SlaStage.InProgress, SlaStatus.Met, slaCalculation, slaGoal)
        let result = SlaCalculationFunc.calculateSlaGoalTargetTime(slaGoal!!)
            .subtract(moment.duration(slaCalculation.elapsedDuration))
        if (!slaCalculation.inProgressOutOfWorkHour && !slaCalculation.currentlyPaused) {
            result = result.subtract(moment().diff(slaCalculation.lastCalculatedTimestamp))
        }
        return result
    },
    calculateCompletedOverusedDuration: (slaCalculation: SlaCalculation, slaGoal: SlaGoal): moment.Duration => {
        ensureConditions(SlaStage.Completed, SlaStatus.Missed, slaCalculation, slaGoal)
        return moment.duration(slaCalculation.elapsedDuration)
            .subtract(SlaCalculationFunc.calculateSlaGoalTargetTime(slaGoal!!))
    },
    calculateCompletedTimeLeftDuration: (slaCalculation: SlaCalculation, slaGoal: SlaGoal): moment.Duration => {
        ensureConditions(SlaStage.Completed, SlaStatus.Met, slaCalculation, slaGoal)
        return SlaCalculationFunc.calculateSlaGoalTargetTime(slaGoal!!)
            .subtract(moment.duration(slaCalculation.elapsedDuration))
    }
}
