import { message } from 'antd'

export enum FetchingState {
    NotStarted,
    Started,
    Succeed,
    Failed
}

export function fetchWithState<T>(
    fetchingState: FetchingState,
    setFetchingState: (value: (((prevState: FetchingState) => FetchingState) | FetchingState)) => void,
    fetcher: (() => Promise<T>),
    onFulfilled?: ((result: T) => void),
    onRejected?: ((error: any) => void),
    onReturned?: (() => void)
) {
    if (fetchingState === FetchingState.NotStarted) {
        setFetchingState(FetchingState.Started)
        fetcher()
            .then((result: T) => {
                setFetchingState(FetchingState.Succeed)
                if (onFulfilled) onFulfilled!!(result)
            })
            .catch((error) => {
                setFetchingState(FetchingState.Failed)
                if (error?.response) {
                    if (error?.response.status !== 404) {
                        message.error(error?.toString())
                    }
                } else {
                    message.error(error?.toString())
                }
                if (onRejected) onRejected!!(error)
            })
            .finally(() => { if (onReturned) onReturned!!() })
    }
}
