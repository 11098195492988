import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Icon, Input, message, Row, Spin} from 'antd'
import {FormComponentProps} from 'antd/es/form'
import Logo from '../assets/images/Logo.png'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {dispatchRoles} from '../authorization-module/permissions'
import {UserLogin} from '../authorization-module/user-role/duck/model'
import * as sha256 from 'fast-sha256'
import Footer from '../common-components/DefaultFooter'
import {decryptDataVspace} from '../common-misc'
// import errors = Simulate.error;

type Props = FormComponentProps

const Login: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form
    const [isLogin, setIsLogin] = useState<boolean>(false)
    const [isFetchData, setIsFetchData] = useState<boolean>(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (localStorage.getItem('mspUser') === '1') {
            setIsLogin(true)
        }
    }, [])

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                setIsFetchData(true)
                const convertData: UserLogin = {
                    username: values.username,
                    // password: values.password
                    password: new TextDecoder('ascii').decode(sha256.pbkdf2(values.password, new TextEncoder().encode('MSPTeamWuhoo'), 10, 100))
                }
                axios.post(`${process.env.REACT_APP_BASE_URL_AUTH}/api/auth/login`, convertData).then((response) => {
                    dispatchRoles(dispatch, response.data.data.roles)
                    if (decryptDataVspace()) {
                        localStorage.removeItem('dataVspace')
                    }
                    console.log(response.data.data)
                    localStorage.setItem('mspUser', '1')
                    localStorage.setItem('username', values.username)
                    localStorage.setItem('userId', response.data.data.userId!!)
                    localStorage.setItem('userRoles', JSON.stringify(response.data.data.roles))
                    setIsLogin(true)
                }).catch((_err) => {
                    console.log(_err)
                    if (_err) {
                        if (_err.response) {
                            if (_err.response.status === 401) {
                                message.error('Username Or Password is wrong')
                            } else if (_err.response.status === 403) {
                                message.error('Sorry, you are not authorized to access')
                            }
                        }
                    } else {
                        message.error('Connect Time out')
                    }
                    setIsFetchData(false)
                })
            }
        })
    }

    return (
        <div>
            {isLogin ? (window.location.pathname = '/Dashboard') : null}
            <Row style={{ height: 710 }}>
                <Col md={24} lg={12} style={{ textAlign: 'center', marginTop: '18%' }}>
                    <img src={Logo} />
                </Col>
                <Col md={24} lg={12} style={{ backgroundColor: '#323276', height: '100%', color: '#ffffff' }}>
                    <Spin spinning={isFetchData}>
                        <Form style={{marginTop: 30, paddingTop: '35%'}} onSubmit={handleSubmit} className="login-form">
                            <Form.Item>
                                {getFieldDecorator('username', {
                                    rules: [{ required: true, message: 'Please input your username!' }]
                                })(
                                    <Input
                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder="Username"
                                    />
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Please input your Password!' }]
                                })(
                                    <Input
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password"
                                        placeholder="Password"
                                    />
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" >
                                    Log in
                                </Button>
                                <a className="login-form-forgot" href="/account/forgotPassword">Forgot password</a>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Col>
            </Row>
            <Footer/>
        </div>
    )
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(Login)

export default WrappedNormalLoginForm
