import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, message, Modal, Row, Select, Steps } from 'antd'
import DetailSignature from './DetailSignature'
import { FormComponentProps } from 'antd/lib/form'
import { StoreState } from '../../../store'
import { Job, updateJobStatus } from '../../Relate/Job'
import { JobWorkLog } from '../../Relate/Job/worklog'
import { connect } from 'react-redux'
import { addWorkLog } from '../../Relate/Job/worklog/service'
import { useLocation } from 'react-router-dom'
import { getChargingByWoIdNoRedux } from '../../Relate/Charging/service'
import { ChargingGroup } from '../../Relate/Charging/Model'
import { decryptDataVspace } from '../../../common-misc'
import { assignees, assignmentGroups } from '../model'
import { getAssetForWOByWOId } from '../../Relate/Asset'

interface Param {
    isDirection: boolean
    isSignature: boolean
    job: Job
    isCurrentJob: boolean
    woId?: string
    handleChangeStatus: (boolean) => void
    username: string
    isStatus: boolean,
    roleId: number,
    vSpaceIncidentNo: string,
    getDataFromMyStep: Function,
    // getAssignedTo: Function
    User?: assignmentGroups[],
    isAssetNotNull: boolean,
    updateAssignedNextJob?: Function
    assignedValue?: string[]
}

const mapStateToProps = (state: StoreState) => {
    return {
        workLog: state.jobWorkLog || [],
        jobs: state.job
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    addWorkLog: (workLog: JobWorkLog) => Promise<number>
    // getAllWorkLogByJobNo: (jobNo: string) => Promise<JobWorkLog[] | undefined>
}

type Props = Param & FormComponentProps & StateProps & DispatchProps

const { Step } = Steps
const { Option } = Select
const MyStep: React.FC<Props> = (props: Props) => {
    const [currentStatus, setCurrentStatus] = useState<number>(0)
    const [visibleApprove, setVisibleApprove] = useState<boolean>(false)
    const [visibleSignature, setVisibleSignature] = useState<boolean>(false)
    const [visibleDetailWO, setVisibleDetailWO] = useState<boolean>(false)
    const jobStatus = props.job.jobStatus
    const jobTopic = props.job.jobTopic
    const [description, setDescription] = useState<string>()
    const [isDisableOpen, setIsDisableOpen] = useState<boolean>(false)
    const [isDisableInProgress, setIsDisableInProgress] = useState<boolean>(false)
    const [isDisableCancel, setIsDisableCancel] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isAddWorkLog, setIsAddWorkLog] = useState<boolean>(false)
    const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
    const [dataChargingGroup, setDataChargingGroup] = useState<ChargingGroup[] | undefined>()
    const [isAsset, setIsAsset] = useState<boolean>(false)
    const [User, setUser] = useState<assignmentGroups[]>(props.User || [])
    const [AssignedTo, setAssignedTo] = useState<assignees[]>([])
    const [nextAssignedTo, setNextAssignedTo] = useState<assignees[]>([])
    const location = useLocation()
    let email: string = ''
    const dataVspace = decryptDataVspace()
    if (typeof dataVspace === 'object') {
        email = dataVspace.email
    }

    const { getFieldDecorator, validateFields, getFieldValue, setFieldsValue, resetFields } = props.form
    useEffect(() => {
        if (location.pathname.includes('/WoFormEdit')) {
            setIsEdit(true)
            if (props.job) {
                setFieldsValue({
                    assignmentGroup: props.job.supportTeam || undefined,
                    assignedTo: props.job.assignee || undefined
                })
            }
        }
        if (jobStatus) {
            if (jobStatus === 'Open') {
                setCurrentStatus(0)
            }
            if (jobStatus === 'In Progress') {
                setCurrentStatus(1)
            }
            if (jobStatus === 'Closed') {
                setCurrentStatus(2)
            }
            if (jobStatus === 'Cancelled') {
                setCurrentStatus(3)
            }
        }
        fetchCharging()
        // fetchWorkLogData()
        fetchCharging()
        fetchAsset()
        checkCurrentStatusToDisable()
        // if (props.job.id === props.jobs[0].id && props.job.jobStatus === 'Open') {
        //     if (props.assignedValue) {
        //         setFieldsValue({ assignmentGroup: props.assignedValue[0], assignedTo: props.assignedValue[1] })
        //         let changeAssignTo = { ...props.jobs.find((it) => it.id === (Number(props.job.id)).toString()), supportTeam: props.assignedValue[0], assignee: props.assignedValue[1] }
        //         updateJobStatus((Number(props.job.id)).toString(), changeAssignTo)
        //     }
        // }
    }, [])

    useEffect(() => {
        if (props.isCurrentJob && (isEdit || location.pathname === '/WoList')) {
            setIsDisableInProgress(props.roleId === 799)
        }

        if (currentStatus === 0) {
            setIsDisableOpen(true)
            setIsDisableCancel(true)
        }
        if (currentStatus === 1) {
            setIsDisableOpen(true)
            setIsDisableCancel(props.roleId === 799)
        }
        if (currentStatus === 2) {
            setIsDisableInProgress(true)
            setIsDisableCancel(true)
        }
        if (currentStatus === 3) {
            setIsDisableOpen(true)
            setIsDisableInProgress(true)
        }
    }, [props.isCurrentJob, currentStatus])

    // useEffect(() => {
    //     fetchWorkLogData()
    // }, [props.handleChangeStatus, isAddWorkLog])

    useEffect(() => {
        if (User) {
            (User || []).map((data) => {
                if (data.name === getFieldValue('assignmentGroup')) {
                    setAssignedTo(data.assignees.filter(it => it.name?.length !== 0))
                }
            })
        }
    }, [getFieldValue('assignmentGroup'), getFieldValue('assignedTo'), User])

    useEffect(() => {
        if (User) {
            (User || []).map((data) => {
                if (data.name === getFieldValue('assignmentNextGroup')) {
                    setNextAssignedTo(data.assignees.filter(it => it.name?.length !== 0))
                }
            })
        }
    }, [getFieldValue('assignmentNextGroup'), getFieldValue('nextAssignedTo'), User])

    useEffect(() => {
        if (User) {
            (User || []).map((data) => {
                if (data.name === getFieldValue('assignmentNextGroupnonSign')) {
                    setNextAssignedTo(data.assignees.filter(it => it.name?.length !== 0))
                }
            })
        }
    }, [getFieldValue('assignmentNextGroupnonSign'), getFieldValue('nextAssignedTononSign'), User])

    useEffect(() => {
        if (props.job.supportTeam) {
            setFieldsValue({ assignmentGroup: props.job.supportTeam })
        }
        if (props.job.assignee) {
            setFieldsValue({ assignedTo: props.job.assignee })
        }
    }, [props.job])

    // const fetchWorkLogData = () => {
    //     props.getAllWorkLogByJobNo(props.job.jobNo).then((data) => {
    //         setJobWorkLog(data)
    //     }).catch((err) => message.error(`Failed loading initial work log data. ${err}`))
    // }

    const fetchCharging = async () => {
        if (props.woId) {
            const res = await getChargingByWoIdNoRedux(props.woId)
            setDataChargingGroup(res)
        }
    }

    const fetchAsset = async () => {
        if (props.woId) {
            const res = await getAssetForWOByWOId(props.woId)
            const relatedAsset = res.filter(items => items.assetType === 'asset')
            if (relatedAsset.length !== 0) {
                setIsAsset(true)
            } else {
                setIsAsset(false)
            }
        }
    }

    const onChange = newStatus => {
        if (currentStatus < newStatus && newStatus !== 2) {
            if (newStatus === 1) {
                setVisibleApprove(true)
            }
        } else {
            if (newStatus === 2 && props.isSignature && ((jobTopic === 'Replace' && props.job.attachedSignatureReplace === null) || (jobTopic === 'Return' && props.job.attachedSignatureReturn === null))) {
                setVisibleSignature(true)
                setVisibleApprove(false)
            } else {
                setVisibleApprove(true)
            }
        }
    }

    const updateWorkLog = () => {
        // validateFields((error) => {
        if (getFieldValue('description')) {
            setIsAddWorkLog(false)
            setIsSubmitLoading(true)
            const workLog: JobWorkLog = {
                currentStatus: '',
                previousStatus: '',
                jobNo: props.job.jobNo,
                description: description,
                createdBy: props.username,
                lastModifiedBy: email
            }
            props.addWorkLog(workLog)
                .then(() => {
                    setIsSubmitLoading(false)
                    setIsAddWorkLog(true)
                    message.success('The Update has finished successfully.')
                })
                .catch((err) => {
                    setIsSubmitLoading(false)
                    setIsAddWorkLog(false)
                    message.error(`You have unSuccessfully save the data. ${err}`)
                })
            props.form.setFieldsValue({
                description: undefined
            })
        }
        // })
    }

    const handleOk = () => {
        validateFields((error) => {
            if (!Object.keys(error || []).some(element => ['assignmentNextGroup', 'nextAssignedTo'].includes(element))) {
                if (currentStatus === 1) {
                    if (isAsset && props.isAssetNotNull) {
                        setVisibleApprove(false)
                        setVisibleSignature(false)
                        setVisibleDetailWO(true)
                    } else {
                        message.error('Please relate Asset before change status to Closed')
                        setVisibleSignature(false)
                    }
                } else {
                    setVisibleApprove(false)
                    setVisibleSignature(false)
                    setVisibleDetailWO(true)
                }
                // updateJobStatus(props.job.id!!, 'Closed').then(() => {
                //     setCurrentStatus(2)
                //     message.success('The Update has finished successfully.')
                // }).catch((err) => {
                //     setVisibleApprove(false)
                //     setVisibleDetailWO(true)
                //     message.error(`You have unSuccessfully save the data. ${err}`)
                // })
            }
        })
    }

    const isCloseStep = (value) => {
        if (value) {
            const jobData = {
                lastModifiedBy: email,
                jobStatus: 'Closed'
            }
            updateJobStatus(props.job.id!!, jobData).then(() => {
                setCurrentStatus(2)
                props.handleChangeStatus(!props.isStatus)
                message.success('The Update has finished successfully.')
            }).catch((err) => {
                setVisibleApprove(false)
                setVisibleDetailWO(true)
                message.error(`You have unSuccessfully save the data. ${err}`)
            })
            if (props.job.id !== props.jobs[props.jobs.length - 1].id) {
                if (props.updateAssignedNextJob) {
                    props.updateAssignedNextJob((Number(props.job.id) + 1).toString(), getFieldValue('assignmentNextGroup'), getFieldValue('nextAssignedTo'))
                }
                let nextJob = { ...props.jobs.find((it) => it.id === (Number(props.job.id) + 1).toString()), supportTeam: getFieldValue('assignmentNextGroup'), assignee: getFieldValue('nextAssignedTo') }
                updateJobStatus((Number(props.job.id) + 1).toString(), nextJob).then(() => {
                    resetFields(['assignmentNextGroup', 'nextAssignedTo'])
                }).catch((err) => {
                    message.error(`You have unSuccessfully save the data. ${err}`)
                })
            }
        }
    }

    const handleCancel = () => {
        setCurrentStatus(1)
        setVisibleApprove(false)
        setVisibleSignature(false)
        resetFields(['assignmentNextGroup', 'nextAssignedTo'])
    }

    const handleCancelApprove = () => {
        setVisibleApprove(false)
        resetFields(['assignmentNextGroupnonSign', 'nextAssignedTononSign'])
    }

    const handleOkDetailWO = () => {
        setVisibleDetailWO(false)
        checkCurrentStatusToDisable()
    }

    const handleCancelDetailWO = () => {
        setVisibleDetailWO(false)
    }

    const handleOkApprove = () => {
        validateFields((error) => {
            if (!Object.keys(error || []).some(element => ['assignmentNextGroupnonSign', 'nextAssignedTononSign'].includes(element))) {
                const jobData = {
                    lastModifiedBy: email, jobStatus: ''
                }
                if (currentStatus === 0) {
                    jobData.jobStatus = 'In Progress'
                    updateJobStatus(props.job.id!!, jobData).then(() => {
                        setVisibleApprove(false)
                        setCurrentStatus(1)
                        setIsDisableCancel(props.roleId === 799)
                        props.handleChangeStatus(!props.isStatus)
                        message.success('The Update has finished successfully.')
                    }).catch((err) => {
                        setVisibleApprove(false)
                        message.error(`You have unSuccessfully save the data. ${err}`)
                    })
                } else if (currentStatus === 1) {
                    if (props.job.templateNo === 2 && props.job.jobTopic === 'Collect' && dataChargingGroup?.length === 0) {
                        message.error('Please input Charging before change status as Closed')
                        setVisibleApprove(false)
                    } else {
                        if (props.job.templateNo !== 8) {
                            if (isAsset && props.isAssetNotNull) {
                                jobData.jobStatus = 'Closed'
                                updateJobStatus(props.job.id!!, jobData).then(() => {
                                    setVisibleApprove(false)
                                    setCurrentStatus(2)
                                    props.handleChangeStatus(!props.isStatus)
                                    setIsDisableInProgress(props.roleId === 799)
                                    // message.success('The Update has finished successfully.')
                                }).catch((err) => {
                                    setVisibleApprove(false)
                                    message.error(`You have unSuccessfully save the data. ${err}`)
                                })
                                if (props.job.id !== props.jobs[props.jobs.length - 1].id) {
                                    if (props.updateAssignedNextJob) {
                                        props.updateAssignedNextJob((Number(props.job.id) + 1).toString(),getFieldValue('assignmentNextGroupnonSign'), getFieldValue('nextAssignedTononSign'))
                                    }
                                    let nextJob = { ...props.jobs.find((it) => it.id === (Number(props.job.id) + 1).toString()), supportTeam: getFieldValue('assignmentNextGroupnonSign'), assignee: getFieldValue('nextAssignedTononSign') }
                                    updateJobStatus((Number(props.job.id) + 1).toString(), nextJob).then(() => {
                                        message.success('The Update has finished successfully.')
                                        resetFields(['assignmentNextGroupnonSign', 'nextAssignedTononSign'])
                                    }).catch((err) => {
                                        message.error(`You have unSuccessfully save the data. ${err}`)
                                    })
                                    
                                }
                            } else {
                                message.error('Please relate Asset before change status to Closed')
                                setVisibleApprove(false)
                            }
                        } else {
                            jobData.jobStatus = 'Closed'
                            updateJobStatus(props.job.id!!, jobData).then(() => {
                                setVisibleApprove(false)
                                setCurrentStatus(2)
                                props.handleChangeStatus(!props.isStatus)
                                setIsDisableInProgress(props.roleId === 799)
                                message.success('The Update has finished successfully.')
                            }).catch((err) => {
                                setVisibleApprove(false)
                                message.error(`You have unSuccessfully save the data. ${err}`)
                            })
                        }
                    }
                }
            }
        })
    }

    const handleCloseModal = (isClose: boolean) => {
        setVisibleDetailWO(isClose)
    }

    const checkCurrentStatusToDisable = () => {
        if (props.roleId === 799) {
            setIsDisableOpen(true)
            setIsDisableInProgress(true)
            setIsDisableCancel(true)
        } else {
            if (props.isCurrentJob && props.job.id) {
                if (currentStatus >= 0) {
                    setIsDisableOpen(true)
                    setIsDisableCancel(true)
                } else if (currentStatus >= 1) {
                    setIsDisableInProgress(true)
                } else if (currentStatus >= 2) {
                    setIsDisableInProgress(true)
                    setIsDisableCancel(true)
                }
            } else {
                setIsDisableOpen(true)
                setIsDisableInProgress(true)
                setIsDisableCancel(true)
            }
        }
    }

    // const displayWorkLog = () => {
    //     const workLogElements: JSX.Element[] = []
    //     // eslint-disable-next-line react/prop-types
    //     if (jobWorkLog) {
    //         for (const workLog of jobWorkLog) {
    //             if (workLog.description) {
    //                 workLogElements.push(<Timeline.Item color={'green'}>
    //                     <p>{moment(workLog.createdDate).format('YYYY-MM-DD HH:mm:ss') + `, ${workLog.createdBy}`}</p>
    //                     <p>{` ${workLog.description}`}</p>
    //                 </Timeline.Item>)
    //             } else {
    //                 if (workLog.previousStatus !== null) {
    //                     workLogElements.push(<Timeline.Item color={'green'}>
    //                         <p>{moment(workLog.createdDate).format('YYYY-MM-DD HH:mm:ss') + `, ${workLog.createdBy}`}</p>
    //                         <p>{`changed Status from ${workLog.previousStatus} to ${workLog.currentStatus}`}</p>
    //                     </Timeline.Item>)
    //                 } else {
    //                     workLogElements.push(<Timeline.Item color={'green'}>
    //                         <p>{moment(workLog.createdDate).format('YYYY-MM-DD HH:mm:ss') + `, ${workLog.createdBy}`}</p>
    //                         <p>{`changed Status to ${workLog.currentStatus}`}</p>
    //                     </Timeline.Item>)
    //                 }
    //             }
    //         }
    //     }
    //     return (
    //         <>
    //             <div style={{ height: 200, width: '100%', backgroundColor: '#ffffff', overflowY: 'scroll', padding: 10 }}>
    //                 {workLogElements.length !== 0 ? (
    //                     <Timeline style={{ marginLeft: '40px', marginTop: '10px' }}>
    //                         {workLogElements.map((temps: any, i: number) => {
    //                             return (
    //                                 <div key={i}>{temps}</div>
    //                             )
    //                         })}
    //                     </Timeline>
    //                 ) : (
    //                     <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    //                 )}
    //             </div>
    //         </>
    //     )
    // }

    const checkToDisableAssetApproval = () => {
        const filterJob = props.jobs.filter(job => job.jobStatus === 'Closed')
        return !(filterJob.length >= 2)
    }

    const changeGroup = (selectValue) => {
        if (selectValue) {
            props.getDataFromMyStep(props.job.jobNo, selectValue, null)
            props.form.setFieldsValue({
                assignedTo: undefined
            })
        } else {
            setAssignedTo([])
            props.form.setFieldsValue({
                assignedTo: undefined
            })
            props.getDataFromMyStep(props.job.jobNo, null, null)
        }
    }

    const changeAssignTo = (selectValue) => {
        if (selectValue) {
            props.getDataFromMyStep(props.job.jobNo, getFieldValue('assignmentGroup'), selectValue)
        } else {
            props.getDataFromMyStep(props.job.jobNo, getFieldValue('assignmentGroup'), null)
        }
    }

    const changeNextGroup = (selectValue) => {
        if (selectValue) {
            props.form.setFieldsValue({
                nextAssignedTo: undefined
            })
        } else {
            setNextAssignedTo([])
            props.form.setFieldsValue({
                nextAssignedTo: undefined
            })
        }
    }


    const changeNextGroupnonSign = (selectValue) => {
        if (selectValue) {

            props.form.setFieldsValue({
                nextAssignedTononSign: undefined
            })
        } else {
            setNextAssignedTo([])
            props.form.setFieldsValue({
                nextAssignedTononSign: undefined
            })

        }
    }

    return jobTopic === 'Approve' ? (
        <>
            <Steps current={currentStatus} onChange={onChange} direction={props.isDirection ? 'horizontal' : 'vertical'}>
                <Step title="Awaiting Approval" description={(<>
                    <Form >
                        <Row>
                            <Col xs={24} xl={12} span={8} style={{ textAlign: 'left' }}>
                                <Form.Item>
                                    <Button type="default" onClick={() => setVisibleDetailWO(true)}
                                        disabled={checkToDisableAssetApproval()}>View Asset Approval</Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </>)} />
            </Steps>
            {/* {!props.isDirection ? displayWorkLog() : null} */}
            {props.isSignature ? <Modal
                visible={visibleDetailWO}
                onOk={handleOkDetailWO}
                onCancel={handleCancelDetailWO}
                footer={null}
                width={'auto'}
                style={{ padding: '5%', minWidth: 1024 }}
            >
                <DetailSignature isVisible={visibleDetailWO} woId={props.woId || ''} idJob={props.job.id!!} JobTopic={props.job.jobTopic} isCloseStep={isCloseStep} dataRelateAsset={[]}
                    handleCloseModal={handleCloseModal} isApprove={true} vSpaceIncidentNo={props.vSpaceIncidentNo} />
            </Modal> : null}
        </>
    ) : (
        <>
            <Form >
                {!props.isDirection ? <div style={{ padding: 30 }}>
                    <Form.Item label="Assignment group">
                        {getFieldDecorator('assignmentGroup', {
                            initialValue: props.job.supportTeam || undefined,
                            rules: [{ required: props.job.templateNo === 8 }]
                        }
                        )(
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select an Assignment group"
                                disabled={currentStatus > 1}
                                onChange={(e) => changeGroup(e)}
                            >
                                {User.map((it, index) => {
                                    return <Option value={it.name} key={index}>{it.name}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Assigned to">
                        {getFieldDecorator('assignedTo', {
                            initialValue: props.job.assignee
                        }
                        )(
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select an Assigned to"
                                disabled={currentStatus > 1}
                                onChange={(e) => changeAssignTo(e)}
                                allowClear
                            >
                                {(AssignedTo || []).map((it, index) => {
                                    return <Option value={it.email} key={index}>{it.name}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>
                </div> : null}
                <Steps current={currentStatus} onChange={onChange} direction={props.isDirection ? 'horizontal' : 'vertical'}>
                    <Step title="Open" disabled={props.job.jobStatus === 'Created' ? true : props.job.templateNo === 8 ? (currentStatus === 1 || currentStatus === 2) : isDisableOpen}
                        style={{ paddingBottom: props.isDirection ? undefined : 25 }}
                        description={<><p>Click step 2 to In Progress</p> <Button disabled={props.job.jobStatus === 'Created' ? true : currentStatus !== 0} onClick={onChange}> Next</Button></>}
                    >

                    </Step>
                    <Step title="In Progress" description={(<>
                        <Row>
                            <Col xs={24} xl={24} style={{ paddingRight: 30 }}>
                                <Form.Item>
                                    {getFieldDecorator('description',
                                        {
                                            initialValue: '',
                                            // rules: [{ required: true }]
                                        })(
                                            <Input placeholder="This is description" style={{ width: props.isDirection ? 250 : '100%' }} maxLength={255}
                                                disabled={currentStatus !== 1 || props.roleId === 799} onChange={(value) => setDescription(value.target.value)} />
                                        )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} xl={4} span={4} style={{ textAlign: 'left' }}>
                                <Form.Item>
                                    <Button type="primary" htmlType='button'
                                        disabled={currentStatus !== 1 || props.roleId === 799} onClick={updateWorkLog} loading={isSubmitLoading}>Submit</Button>
                                </Form.Item>
                            </Col>
                            <Col xs={4} xl={4} span={4}> <Button disabled={props.job.jobStatus === 'Created' ? true : currentStatus !== 1} onClick={() => { onChange(2) }}> Next</Button></Col>
                        </Row>
                    </>)} disabled={props.job.jobStatus === 'Created' ? true : props.job.templateNo === 8 ? currentStatus === 2 : isDisableInProgress} />
                    <Step title="Closed" disabled={props.job.jobStatus === 'Created' ? true : props.job.templateNo === 8 ? currentStatus === 0 : isDisableCancel} description='Click step 3 to Close Job' />
                </Steps>
            </Form>
            {/* {!props.isDirection ? displayWorkLog() : null} */}
            <Modal
                title="Signature"
                visible={visibleSignature}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel} >
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk} >
                        Signature
                    </Button>,
                ]}
            >
                <p>Please signature {props.job.id !== props.jobs[props.jobs.length - 1].id ? 'and assign the next job' : null} before closing this one.</p>
                {props.job.id !== props.jobs[props.jobs.length - 1].id ? <><Form.Item label="Assignment group">
                    {getFieldDecorator('assignmentNextGroup', {
                        rules: [{ required: currentStatus === 1 && props.job.id !== props.jobs[props.jobs.length - 1].id, message: 'please select Assignment Group' }]
                    }
                    )(
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select an Assignment group"
                            onChange={(e) => changeNextGroup(e)}
                        >
                            {User.map((it, index) => {
                                return <Option value={it.name} key={index}>{it.name}</Option>
                            })}
                        </Select>
                    )}
                </Form.Item>
                    <Form.Item label="Assigned to">
                        {getFieldDecorator('nextAssignedTo', {
                            rules: [{ required: currentStatus === 1 && props.job.id !== props.jobs[props.jobs.length - 1].id, message: 'please select Assigned To' }]
                        }
                        )(
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select an Assigned to"
                                // onChange={(e) => changeNextAssignTo(e)}
                                allowClear
                            >
                                {(nextAssignedTo || []).map((it, index) => {
                                    return <Option value={it.email} key={index}>{it.name}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item></> : null}

            </Modal>
            {visibleApprove ? <Modal
                title="Are you sure"
                visible={visibleApprove}
                onOk={handleOkApprove}
                onCancel={handleCancelApprove}
                okText={currentStatus === 1 && props.job.id !== props.jobs[props.jobs.length - 1].id ? 'Save' : 'OK'}
            >
                <p>Do you want to change status as In progress or Closed?</p>
                {currentStatus === 1 && props.job.id !== props.jobs[props.jobs.length - 1].id ? <>
                    <p> Please assign the next job before closing this one.</p>
                    <Form.Item label="Assignment group">
                        {getFieldDecorator('assignmentNextGroupnonSign', {
                            rules: [{ required: currentStatus === 1 && props.job.id !== props.jobs[props.jobs.length - 1].id, message: 'please select Assignment Group' }]
                        }
                        )(
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select an Assignment group"
                                onChange={(e) => changeNextGroupnonSign(e)}
                            >
                                {User.map((it, index) => {
                                    return <Option value={it.name} key={index}>{it.name}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Assigned to">
                        {getFieldDecorator('nextAssignedTononSign', {
                            rules: [{ required: currentStatus === 1 && props.job.id !== props.jobs[props.jobs.length - 1].id, message: 'please select Assigned To' }]
                        }
                        )(
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select an Assigned to"
                                // onChange={(e) => changeNextAssignTononSign(e)}
                                allowClear
                            >
                                {(nextAssignedTo || []).map((it, index) => {
                                    return <Option value={it.email} key={index}>{it.name}</Option>
                                })}
                            </Select>
                        )}
                    </Form.Item>
                </> : <></>}
            </Modal> : null}
            {props.isSignature ? <Modal
                visible={visibleDetailWO}
                onOk={handleOkDetailWO}
                onCancel={handleCancelDetailWO}
                footer={null}
                width={'auto'}
                style={{ padding: '5%', minWidth: 1024 }}
            >
                <DetailSignature isVisible={visibleDetailWO} woId={props.woId || ''} idJob={props.job.id!!} JobTopic={props.job.jobTopic} isCloseStep={isCloseStep} dataRelateAsset={[]}
                    handleCloseModal={handleCloseModal} isApprove={props.job.jobTopic === 'Approve' && props.job.jobStatus !== 'Closed'} vSpaceIncidentNo={props.vSpaceIncidentNo} />
            </Modal> : null}
        </>
    )
}

const MyJobStep = Form.create<Props>({})(MyStep)

export default connect(mapStateToProps,
    {
        addWorkLog: addWorkLog
    })(MyJobStep)
