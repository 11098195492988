import {PriorityLevel, PriorityMatrix} from './../priority/model'
import { SlaCalculation } from '../../sla-management/sla-calculation/model'
import { BaseDocument, BaseEntity } from '../../common-model'
import { Status } from '../status'
import 'moment-duration-format'
import { timeDiffAsString } from '../../common-time'
import {UploadLink} from '../../knowledge-management/knowledge/uploads'
import { User } from '../../authorization-module/user-role/duck/model'
import { People } from '../../authorization-module/people/model'
import { String } from 'aws-sdk/clients/cloudwatchevents'
import {UserSupportTeam} from '../../authorization-module/support-team-management/model';

export interface Incident extends BaseEntity {
    ticketNumber: string // inc
    subject: string // topic
    description: string // description
    resolution?: string
    company: string // G-able
    category?: string
    subcategory?: string
    item?: string
    supportTeam?: SupportTeam
    priority?: PriorityMatrix
    ticketStatus: TicketStatus
    user?: User
    pendingReason?: string
    pendingUntil?: string
    people: People
    viewed?: boolean
    lastStatusTimestamp?: string
    statusBeforePending?: TicketStatus
    uploadLink?: UploadLink[]
    statusHistories?: StatusHistory[]
    customField?: FieldDetail[]
    incidentSetting: IncidentSetting[]
}

export interface SupportTeam extends BaseEntity{
    name: string
    assignees: UserSupportTeam[]
    email:string
    active: boolean
}

export interface IncidentWithSlaCalculations extends Incident {
    slaCalculations: SlaCalculation[]
}

export const IncidentFieldLabel = {
    mapping: {
        createdDate: 'Date Created',
        lastModifiedDate: 'Last Modified',
        priority: 'Priority',
        ticketStatus: 'Status',
        number: 'Ticket Number',
        desc: 'Descending',
        asc: 'Ascending',
        priorityOrder: 'Priority',
        statusOrder: 'Status'
    }
}

export const IncidentFunc = {
    formatPendingRemaining: function (incident: Incident): string {
        if (!incident.pendingUntil) {
            return 'Not in pending status'
        }
        return timeDiffAsString(null, incident.pendingUntil, true)
    },
    isPending: (incident: Incident) => !!incident.pendingUntil
}

export interface TicketStatus extends BaseEntity {
    ticketType: string
    status: string
    active: boolean
}

export interface IncidentSetting extends BaseEntity {
    incidentId?: number
    incGeneralSetting: GeneralIncidentSetting
    // incGeneralSetting: GeneralIncidentSetting[]
}

export interface GeneralIncidentSetting extends BaseEntity{
    settingType?: string
    value?: string
    active?: boolean
}

export interface StatusHistory extends BaseEntity {
    ticketId: number
    status: String
}

export interface FieldDetail extends BaseEntity {
    ticketId?: number
    name?: string
    value?: string
    ticketTypeId?: GeneralIncidentSetting
    label?: string
    description?: string
    inputType?: string
    dataType?: string
    selectOption?: string
    defaultValue?: string | number
    placeholder?: string
    isRequireFields?: boolean
}
