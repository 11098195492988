import React from 'react'
import moment from 'moment'
import RunningClock, { RunningClockMode } from './RunningClock'

interface Props {
    until: string | moment.Moment
    wordingWhenTimedUp?: string
}

const TimeLeft: React.FC<Props> = (props: Props) => {
    return <RunningClock mode={RunningClockMode.TimeLeft} referencedTimePoint={props.until} wordingWhenCountDownToZero={props.wordingWhenTimedUp}/>
}

export default TimeLeft
