import {Dispatch} from 'redux'
import {axiosDelete, axiosGet, axiosPatch, axiosPost} from '../../rest'
import {axiosGetPeople} from './rest'
import {ResponseUserRole, Roles, User, UserConfirm} from './model'
import {People} from '../../people/model'
import {
    ROLES_DATA_SHOW,
    USER_ROLES_DATA_ADD,
    USER_ROLES_DATA_DELETE,
    USER_ROLES_DATA_EDIT,
    USER_ROLES_DATA_SHOW
} from './type'

export const showUserList = (roles: string[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<ResponseUserRole[]>(`/api/authority/user/filterUser?roles=${roles}`)
            dispatch(USER_ROLES_DATA_SHOW.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const searchUser = async (roles: string[], searchValue: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<ResponseUserRole[]>(`/api/incident/user/searchUser?value=${searchValue}`, roles)
            dispatch(USER_ROLES_DATA_SHOW.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const addUserRole = (userRole: ResponseUserRole) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<ResponseUserRole[]>('/api/authority/user', userRole)
            dispatch(USER_ROLES_DATA_ADD.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const confirmUser = async (user: UserConfirm) => {
    try {
        const response = await axiosPost<any>('/api/authority/user/confirmAccount', user)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const forgotPassword = async (username: string, event: string) => {
    try {
        const response = await axiosGet<any>(`/api/authority/user/forgotPassword/${username}`)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const resend = async (username: string, event: string) => {
    try {
        const response = await axiosGet<any>(`/api/authority/user/resend/${username}`)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getUsername = async (code: string) => {
    try {
        const response = await axiosGet<any>(`/api/authority/user/getUsername/${code}`)
        return response.data.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const editUser = (userRole: ResponseUserRole, id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPatch<ResponseUserRole>(`/api/authority/user/${id}`, userRole)
            dispatch(USER_ROLES_DATA_EDIT.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const deleteUser = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<ResponseUserRole[]>(`/api/authority/user/${id}`)
            dispatch(USER_ROLES_DATA_DELETE.build(id))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const showRoleList = async () => {
    try {
        const response = await axiosGet<Roles[]>('/api/authority/roles/allRoles')
        return response.data.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const showActiveRoleList = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Roles[]>('/api/authority/roles')
            dispatch(ROLES_DATA_SHOW.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const searchPeople = async (people: string) => {
    try {
        const params = {
            people: people
        }
        const response = await axiosGetPeople<People[]>('/api/auth/peoples/search', {params})
        return response.data.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getAllUser = async () => {
    try {
        const response = await axiosGet<User[]>('/api/authority/user/allUser')
        return response.data.data
    } catch (error) {
        console.log(error)
        throw error
    }
}
