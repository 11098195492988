import { BaseDocument } from '../../../common-model'

export interface CommentResponseModel extends BaseDocument {
    knowledgeId: string
    id?: string
    content: string
    replyOf?: string
    replies?: CommentResponseModel[]
    like?: number
    liked?: number // 0 = dislike, 1 = liked
}

export interface CommentComponentModel {
    id?: string
    author: string
    replyByTooltip?: string
    content: JSX.Element
    datetime: string
    actions?: JSX.Element[]
    replies?: CommentResponseModel[]
    replyOf?: string
}

export enum LikeType {
    Comment = 'Comment',
    Knowledge = 'Knowledge'
}

export interface LikeCommentModel extends BaseDocument {
    type: LikeType
    idOfType: string
    username: string
    value?: number
}
