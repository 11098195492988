/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react'
import {Button, Card, Col, Drawer, Dropdown, Icon, Input, Menu, message, Modal, Row, Spin, Table, Tooltip, DatePicker} from 'antd'
import {Link} from 'react-router-dom'
import {KnowledgeList, KnowledgeStatus} from './model'
import {Category} from '../category'
import {Folder} from '../folder'
import {convertCamelCaseToSentenceCase} from '../util'
import {KnowledgeListColumn} from './KnowledgeListColumn'
import CategoryForm from '../category/CategoryForm'
import {ClickParam} from 'antd/lib/menu'
import {checkRolesState, RoleType} from '../../authorization-module/permissions'
import {decryptDataVspace, fixWidthExcel} from '../../common-misc'
import {assignmentGroups, getAssignmentUserWithRole} from '../../msp-wo/wo-management'
import {RoleAndPermissions} from '../../authorization-module/role-and-permissions'
import {checkDeletePermission, getDataExportKM} from './service'
import moment from 'moment'
import * as XLSX from 'xlsx'
import KnowledgeListPreview from './KnowledgeListPreview'

const config = require('./config.json')
const { confirm } = Modal
interface Params {
    knowledge: KnowledgeList
    title: string,
    description: string,
    category?: Category
    folder?: Folder
    isFetch: boolean
    kmRole: string[]
    fetchKm: Function,
    deleteKm: Function,
    deleteFolder: Function,
    deleteCategory: Function,
    setSearchInput: Function,
    searchStatus: string,
    searchInput: string
}

interface HeaderDetail {
    title: string
    description: string
}

type Props = Params
const { RangePicker } = DatePicker
const KnowledgeListView: React.FC<Props> = (props: Props) => {
    const [title, setTitle] = useState<string>(props.title)
    const [description, setDescription] = useState<string>(props.description)
    const [stateVisibleFrom, setStateVisibleFrom] = useState<boolean>(false)
    const [stateIsCreateCategory, setStateIsCreateCategory] = useState<boolean>(true)
    const [editData, setEditData] = useState<any>()
    const [defaultRangeCreatedDate, setDefaultRangeCreatedDate] = useState<any[]>([])
    // const [searchStatus, setSearchStatus] = useState<string>('AllStatus')
    // const [searchInput, props.setSearchInput] = useState<string>('')
    const [roleVspace, setRoleVspace] = useState<string>('')
    const [dataRoleVspace, setDataRoleVspace] = useState<assignmentGroups>()
    const [isKMRoleUser, setIsKMRoleUser] = useState<boolean>(false)
    const [isShowPreView, setIsShowPreView] = useState<boolean>(false)
    const dateFormat = 'YYYY-MM-DD'
    const pageSize = config.pageSize
    let searchTimeOut: NodeJS.Timeout
    const username = localStorage.getItem('username')
    useEffect(() => {
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            const role = dataVspace.roleId.toString()
            setRoleVspace(role)
            fetchAssignmentUserEngineer(role)
        }
        const roleName: RoleAndPermissions[] = JSON.parse(localStorage.getItem('userRoles') || '')
        if (roleName.length === 1) {
            setIsKMRoleUser(roleName.some(it => it.name === 'vspace_km_user'))
        }
    }, [])

    useEffect(() => {
        setTitle(props.title)
    }, [props.title])

    useEffect(() => {
        setDescription(props.description)
    }, [props.description])

    const { Search } = Input

    const handleClick = (e: ClickParam) => {
        props.fetchKm(props.category?.id, props.folder?.id, e.key)
    }

    const handleOnClickPageNumber = (page: number) => {
        localStorage.setItem('km_current_page', page.toString())
        props.fetchKm(props.category?.id, props.folder?.id)
    }

    const inputSearch = (e) => {
        clearTimeout(searchTimeOut)
        props.setSearchInput(e.target.value)
    }

    const fetchAssignmentUserEngineer = (role: string) => {
        getAssignmentUserWithRole(role).then((res) => {
            (res || []).map((data) => {
                if (role === data.roleId.toString()) {
                    setDataRoleVspace(data)
                }
                return { id: data.name, value: data.name}
            })
        }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
    }

    const menu = (
        <Menu onClick={handleClick}>
            <Menu.Item key="AllStatus">
                {convertCamelCaseToSentenceCase('AllStatus')}
            </Menu.Item>
            <Menu.Item key={KnowledgeStatus.MyNote}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.MyNote)}
            </Menu.Item>
            <Menu.Item key={KnowledgeStatus.Draft}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.Draft)}
            </Menu.Item>
            <Menu.Item key={KnowledgeStatus.AwaitingApproval}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.AwaitingApproval)}
            </Menu.Item>
            {!isKMRoleUser ? <Menu.Item key={KnowledgeStatus.AwaitingRetire}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.AwaitingRetire)}
            </Menu.Item> : null}
            <Menu.Item key={KnowledgeStatus.Published}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.Published)}
            </Menu.Item>
            <Menu.Item key={KnowledgeStatus.Rejected}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.Rejected)}
            </Menu.Item>
            {!isKMRoleUser ? <Menu.Item key={KnowledgeStatus.Retired}>
                {convertCamelCaseToSentenceCase(KnowledgeStatus.Retired)}
            </Menu.Item> : null}
        </Menu>
    )

    const deleteKM = (knowledgeId, status, ticketNumber) => {
        const isRetireStatus = status === KnowledgeStatus.Retired
        const deleteKmFunction = props.deleteKm(isRetireStatus)
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete of this data ?',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                // รอ delete km
                deleteKmFunction(isRetireStatus ? ticketNumber : knowledgeId).then(() => {
                    message.success('Delete Knowledge Success')
                    props.fetchKm(props.category?.id, props.folder?.id)
                }).catch(() => {
                    message.error('Failed to delete Knowledge')
                })
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const columns = [
        {
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <KnowledgeListColumn rowData={row} deleteKM={deleteKM} hasPermission={checkPermissionDelete}/>
            ),
            key: 'id'
        }
    ]

    function openDrawer(e) {
        switch (e.key) {
        case 'Category':
            setStateVisibleFrom(true)
            setStateIsCreateCategory(true)
            setEditData(undefined)
            break
        case 'Folder':
            setStateVisibleFrom(true)
            setStateIsCreateCategory(false)
            setEditData(undefined)
            break
        }
    }

    function openEditDrawer(type: string, data: any) {
        switch (type) {
        case 'Category':
            setStateVisibleFrom(true)
            setStateIsCreateCategory(true)
            setEditData(data)
            break
        case 'Folder':
            setStateVisibleFrom(true)
            setStateIsCreateCategory(false)
            setEditData(data)
            break
        }
    }

    const closeDrawer = () => {
        setStateVisibleFrom(false)
    }

    const checkPermissionEdit = (folder: Folder): boolean => {
        if (folder?.managedBy.length !== 0) {
            if (!folder?.managedBy?.some(it => props.kmRole.includes(it.groupName))) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const checkPermissionDelete = (folderId: number): boolean => {
        const folder = props.category?.folderList?.find(it => it.id?.toString() === folderId.toString())
        const isCreatedBy = props.knowledge?.content?.find(it => it.createdBy === username)
        const isCanDelete = checkDeletePermission()
        if (folder) {
            if ((folder?.managedBy.length === 0 || folder?.managedBy?.some(it => props.kmRole.includes(it.groupName)) || isCreatedBy) && isCanDelete) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    // @ts-ignore
    const createMenu = (
        <div>
            {checkRolesState(RoleType.Knowledge, 'CreateKnowledge') ? (
                <Menu onClick={openDrawer}>
                    <Menu.Item key="Category">
                        <span className='km-font-menu '>Category</span>
                    </Menu.Item>
                    <Menu.Item key="Folder">
                        <span className='km-font-menu '>Folder</span>
                    </Menu.Item>
                    <Menu.Item key="Knowledge" disabled={checkPermissionEdit(props.folder!!)}>
                        {props.folder?.id ? (
                            <Link to={`/KnowledgeForm/${props.category?.id}/${props.folder?.id}`} >
                                <span className='km-font-menu '>Knowledge</span>
                            </Link>
                        ) : props.category?.id ? (
                            <Link to={`/KnowledgeForm/${props.category?.id}`} >
                                <span className='km-font-menu '>Knowledge</span>
                            </Link>
                        ) : (
                            <Link to='/KnowledgeForm'>
                                <span className='km-font-menu '>Knowledge</span>
                            </Link>
                        )}
                    </Menu.Item>
                </Menu>
            ) : (
                <Menu onClick={openDrawer}>
                    <Menu.Item key="Category" disabled>
                        <span>Category</span>
                    </Menu.Item>
                    <Menu.Item key="Folder" disabled>
                        <span>Folder</span>
                    </Menu.Item>
                    <Menu.Item key="Knowledge" disabled>
                        <span>Knowledge</span>
                    </Menu.Item>
                </Menu>
            )}
        </div>
    )

    const editCategoryTools = () => {
        if (props.folder?.id) {
            if (props.folder?.id !== 'all') {
                return (
                    <Tooltip placement="bottom" title={'Edit'}>
                        <Icon className="edit_icon" type="edit" style={{color: 'black', marginLeft: 4}}
                            onClick={() => {
                                openEditDrawer('Folder', props.folder)
                            }}/>
                    </Tooltip>
                )
            }
        } else if (props.category?.id) {
            return (
                <Tooltip placement="bottom" title={'Edit'}>
                    <Icon className="edit_icon" type="edit" style={{color: 'black', marginLeft: 4}}
                        onClick={() => {
                            openEditDrawer('Category', props.category)
                        }}/>
                </Tooltip>
            )
        }
    }

    const drawerTitle = () => {
        if (editData) {
            return stateIsCreateCategory ? 'Edit Category' : 'Edit Folder'
        }
        return stateIsCreateCategory ? 'Create Category' : 'Create Folder'
    }

    const setEditedTitle = (data) => {
        setTitle(data)
    }

    const onCreatedRangeChanged = (e:string[] | undefined | any) => {
        if (e !== undefined) {
            if (e[0] !== '' && e[1] !== '') {
                setDefaultRangeCreatedDate([moment(new Date(e[0])),
                    moment(new Date(e[1]))])
            } else {
                setDefaultRangeCreatedDate([moment(new Date()),
                    moment(new Date())])
            }
        }
    }

    const ExportKM = () => {
        if (defaultRangeCreatedDate.length !== 0) {
            getDataExportKM(moment(defaultRangeCreatedDate[0]).format('YYYY-MM-DD'), moment(defaultRangeCreatedDate[1]).format('YYYY-MM-DD')).then((response) => {
                if (response) {
                    const ws1 = XLSX.utils.json_to_sheet(response)
                    ws1['!cols'] = fixWidthExcel(response, ws1)
                    const wb = XLSX.utils.book_new()
                    XLSX.utils.book_append_sheet(wb, ws1, 'Knowledge')
                    XLSX.writeFile(wb, 'Knowledge.xlsx')
                }
            })
        }
    }

    const deleteCategoryTools = () => {
        if (props.folder?.id) {
            if (props.folder?.id !== 'all') {
                return (
                    <Tooltip placement="bottom" title={'Delete'}>
                        <Icon className="edit_icon" type="delete" style={{ color: 'black', marginLeft: 4 }}
                            onClick={() => {
                                // openEditDrawer('Folder', props.folder)
                                CheckDeleteFolder(props.folder?.id!!)
                            }} />
                    </Tooltip>
                )
            }
        } else if (props.category?.id) {
            return (
                <Tooltip placement="bottom" title={'Delete'}>
                    <Icon className="edit_icon" type="delete" style={{ color: 'black', marginLeft: 4 }}
                        onClick={() => {
                            // openEditDrawer('Category', props.category)
                            if (props.category?.folderList?.length === 0) {
                                CheckDeleteCategory(props.category?.id)
                            } else {
                                Modal.warning({
                                    title: 'Warning',
                                    content: 'Please delete all folders.'
                                })
                            }
                        }} />
                </Tooltip>
            )
        }
    }

    const CheckDeleteFolder = (folderId: string | undefined) => {
        confirm({
            title: 'Are you sure?',
            content: 'When a folder is deleted, all KM article in it is permanently erased. Do you confirm Delete of this data?',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                props.deleteFolder(Number(folderId!!))
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const CheckDeleteCategory = (categoryId: string | undefined) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete of this data ?',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                // รอ delete km
                props.deleteCategory(Number(categoryId!!))
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }
    console.log()
    return (
        <Spin tip="Loading..." spinning={props.isFetch}>
            <Drawer
                title={drawerTitle()}
                width={window.innerWidth < 768 ? 'auto' : 720}
                onClose={closeDrawer}
                visible={stateVisibleFrom}
                bodyStyle={{ paddingBottom: 80, overflow: 'auto' }}
            >
                <CategoryForm
                    isCategoryForm={stateIsCreateCategory}
                    isFormVisible={stateVisibleFrom}
                    editData={editData}
                    setEditedTitle={setEditedTitle}
                    closeForm={closeDrawer}
                    categoryName={props.category?.id}
                    kmRole={props.kmRole || []}
                />
            </Drawer>
            <Card className='card-height-default'>
                <Row>
                    <Dropdown overlay={createMenu} trigger={['click']} >
                        <Button type='primary' style={{ float: 'right'}}>
                            Add new
                        </Button>
                    </Dropdown>
                </Row>
                <Row style={{ paddingTop: 5}}>
                    <Col span={13}>
                        <h2 className="main-title">
                            {`${title} (${props.knowledge.pagination?.totalElements || 0}) `}
                            {editCategoryTools()}
                            {checkRolesState(RoleType.Knowledge, 'CreateKnowledge') && checkRolesState(RoleType.Knowledge, 'ViewKnowledge') && checkRolesState(RoleType.Knowledge, 'UpdateKnowledge') && checkRolesState(RoleType.Knowledge, 'DeleteKnowledge') && roleVspace === '351' ? deleteCategoryTools() : null}
                            {window.innerWidth < 768 ? (
                                <Col span={24}>
                                    <Dropdown overlay={menu} trigger={['click']} >
                                        <a className="ant-dropdown-link" style={{ fontSize: 16 }} onClick={e => e.preventDefault()}>
                                            {convertCamelCaseToSentenceCase(props.searchStatus)} <Icon type="down" />
                                        </a>
                                    </Dropdown>
                                </Col>
                            ) : (
                                <Dropdown overlay={menu} trigger={['click']} className='spacing'>
                                    <a className="ant-dropdown-link" style={{ fontSize: 16 }} onClick={e => e.preventDefault()}>
                                        {convertCamelCaseToSentenceCase(props.searchStatus)} <Icon type="down" />
                                    </a>
                                </Dropdown>
                            ) }
                        </h2>
                    </Col>
                    <Col span={11} style={{display: 'flex', justifyContent: 'end'}}>
                        {checkRolesState(RoleType.Knowledge, 'CreateKnowledge') && checkRolesState(RoleType.Knowledge, 'ViewKnowledge') && checkRolesState(RoleType.Knowledge, 'UpdateKnowledge') && checkRolesState(RoleType.Knowledge, 'DeleteKnowledge') && roleVspace === '351' ? <>
                            <RangePicker
                                value={defaultRangeCreatedDate} style={{ paddingRight: 10}}
                                format={dateFormat} onChange={(moment, dateStrings) => { onCreatedRangeChanged(dateStrings) }}
                            />
                            <Button onClick={() => setIsShowPreView(true)} style={{ marginRight: 10}}>
                                Preview
                            </Button>
                            <Button onClick={ExportKM}>
                                Export
                            </Button>
                        </> : null}
                    </Col>
                </Row>
                <Row>
                    <div>{description}</div>
                    <br />
                </Row>
                <Row>
                    <Search
                        placeholder="Can search Topic, Detail, Tag, ID and Created By"
                        onChange={inputSearch}
                        value={props.searchInput}
                        style={{ minWidth: 200 }}
                        onSearch={() => { props.fetchKm(props.category?.id, props.folder?.id) }}
                    />
                </Row>
                <br/>
                <Row >
                    <div style={{ overflowX: 'auto' }}>
                        <Table
                            className="table-configherder table-noherder fix_scroll_md"
                            dataSource={props.knowledge.content}
                            columns={columns}
                            rowKey="id"
                            pagination={{
                                pageSize: pageSize,
                                current: props.knowledge.pagination?.currentPage,
                                total: props.knowledge.pagination?.totalElements,
                                onChange: (event) => { handleOnClickPageNumber(event) }
                            }} />
                    </div>
                </Row>
            </Card>
            <Modal
                title="Preview Knowledge"
                visible={isShowPreView}
                onCancel={() => setIsShowPreView(false)}
                footer={null}
                width={1200}
            >
                <KnowledgeListPreview defaultRangeCreatedDate={defaultRangeCreatedDate} />
            </Modal>
        </Spin>

    )
}

export default KnowledgeListView
