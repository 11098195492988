import { buildStateEvent, GenericStateEvent } from '../../common-redux'
import { Comment } from './model'

export interface CommentStateEvent<T> extends GenericStateEvent<T> {}

export const GetCommentListByIncidentIdSuccessEvent = {
    type: 'GetCommentListByIncidentIdSuccessEvent',
    build: (payload: Comment[]) => buildStateEvent(GetCommentListByIncidentIdSuccessEvent.type, payload)
}

export const CommentAddedEvent = {
    type: 'CommentAddedEvent',
    build: (payload: Comment) => buildStateEvent(CommentAddedEvent.type, payload)
}
