import {buildStateEvent, GenericStateEvent} from '../../common-redux'
import {AssetOwnerLocation} from './model'

export interface assetOwnerStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllAssetOwnerLocationEvent = {
    type: 'GetAllAssetOwnerLocationEvent',
    build: (payload: AssetOwnerLocation[]) => buildStateEvent(GetAllAssetOwnerLocationEvent.type, payload)
}

export const GetAssetFilterLocationEvent = {
    type: 'GetAssetFilterLocationEvent',
    build: (payload: string[]) => buildStateEvent(GetAssetFilterLocationEvent.type, payload)
}

export const GetAssetFilterStatusEvent = {
    type: 'GetAssetFilterStatusEvent',
    build: (payload: string[]) => buildStateEvent(GetAssetFilterStatusEvent.type, payload)
}

export const GetAssetFilterDeviceTypeEvent = {
    type: 'GetAssetFilterDeviceTypeEvent',
    build: (payload: string[]) => buildStateEvent(GetAssetFilterDeviceTypeEvent.type, payload)
}

export const GetAssetFilterComputerNameEvent = {
    type: 'GetAssetFilterComputerNameEvent',
    build: (payload: string[]) => buildStateEvent(GetAssetFilterComputerNameEvent.type, payload)
}

export const GetAssetFilterBrandEvent = {
    type: 'GetAssetFilterBrandEvent',
    build: (payload: string[]) => buildStateEvent(GetAssetFilterBrandEvent.type, payload)
}

export const GetAssetFilterModelEvent = {
    type: 'GetAssetFilterModelEvent',
    build: (payload: string[]) => buildStateEvent(GetAssetFilterModelEvent.type, payload)
}

export const GetAssetFilterEmployeeIDEvent = {
    type: 'GetAssetFilterEmployeeIDEvent',
    build: (payload: string[]) => buildStateEvent(GetAssetFilterEmployeeIDEvent.type, payload)
}

export const GetAssetFilterDeptNameEvent = {
    type: 'GetAssetFilterDeptNameEvent',
    build: (payload: string[]) => buildStateEvent(GetAssetFilterDeptNameEvent.type, payload)
}

export const GetAssetFilterCityEvent = {
    type: 'GetAssetFilterCityEvent',
    build: (payload: string[]) => buildStateEvent(GetAssetFilterCityEvent.type, payload)
}

export const GetAssetFilterZoneEvent = {
    type: 'GetAssetFilterZoneEvent',
    build: (payload: string[]) => buildStateEvent(GetAssetFilterZoneEvent.type, payload)
}
