import { Dispatch } from 'redux'
import { axiosGet, axiosPatch, axiosPost } from './rest'
import { AssetOwnerLocation, FilterAsset, status, TicketAffectedAsset, TicketChangeHistory } from './model'
import { AssetSignedForPrint, DataAssetSingedWithWoNo, preventive, preventiveChangeHistory } from '../preventive/model'
import {
    GetAllAssetOwnerLocationEvent,
    GetAssetFilterBrandEvent,
    GetAssetFilterCityEvent,
    GetAssetFilterComputerNameEvent,
    GetAssetFilterDeptNameEvent,
    GetAssetFilterDeviceTypeEvent,
    GetAssetFilterEmployeeIDEvent,
    GetAssetFilterLocationEvent,
    GetAssetFilterModelEvent,
    GetAssetFilterStatusEvent,
    GetAssetFilterZoneEvent
} from './state-event'
import { SelectValue } from 'antd/lib/select'

// @ts-ignore
export const getAssetList = (search?: string, filter: FilterAsset | undefined, sortBy?: String, orderBy?: String, skip?: String, limit?: String) => {
    return async (dispatch: Dispatch) => {
        try {
            let path = '/api/asset/searchAll';
            if (search) {
                path = path + `?search=${search}&sortBy=${sortBy}&orderBy=${orderBy}&skip=${skip}&limit=${limit}`
            } else {
                path = path + `?sortBy=${sortBy}&orderBy=${orderBy}&skip=${skip}&limit=${limit}`
            }
            const response = await axiosPost<AssetOwnerLocation>(path, filter)
            // @ts-ignore
            dispatch(GetAllAssetOwnerLocationEvent.build(response.data.data))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getAssetFilter = () => {
    return async (dispatch: Dispatch) => {
        try {
            var promistList = [
                getSearchAllFieldValue('city'),
                getSearchAllFieldValue('location'),
                getSearchAllFieldValue('deviceType'),
                getSearchAllFieldValue('computerName'),
                getSearchAllFieldValue('brand'),
                getSearchAllFieldValue('employeeId'),
                getSearchAllFieldValue('model'),
                getSearchAllFieldValue('deptName'),
                getSearchAllFieldValue('zone')
            ]
            return Promise.all(promistList).then((values) => {
                dispatch(GetAssetFilterCityEvent.build(values[0]))
                dispatch(GetAssetFilterLocationEvent.build(values[1]))
                dispatch(GetAssetFilterDeviceTypeEvent.build(values[2]))
                dispatch(GetAssetFilterComputerNameEvent.build(values[3]))
                dispatch(GetAssetFilterBrandEvent.build(values[4]))
                dispatch(GetAssetFilterEmployeeIDEvent.build(values[5]))
                dispatch(GetAssetFilterModelEvent.build(values[6]))
                dispatch(GetAssetFilterDeptNameEvent.build(values[7]))
                dispatch(GetAssetFilterZoneEvent.build(values[8]))
                dispatch(GetAssetFilterStatusEvent.build(status))
                return 200
            }).catch((err) => {
                throw err
            })
            // @ts-ignore
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const findByAssetId = async (id: string) => {
    try {
        const response = await axiosGet<AssetOwnerLocation>(`/api/asset/${id}`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const createAssetOwnerLocation = async (assetOwnerLocation: AssetOwnerLocation) => {
    try {
        const response = await axiosPost<AssetOwnerLocation>('/api/asset/assetOwnerLocation', assetOwnerLocation)
        return response.data.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const updateAssetOwnerLocation = async (id: string, assetOwnerLocation: AssetOwnerLocation) => {
    try {
        const response = await axiosPatch<AssetOwnerLocation[]>(`/api/asset/${id}`, assetOwnerLocation)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getSearchAllFieldValue = async (field: string) => {
    try {
        const response = await axiosGet<string[]>(`api/item-types/${field}`)
        return response.data.data || []
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getSearchAllFieldValueNew = async (label: string) => {
    try {
        const response = await axiosGet<string[]>(`/api/item-types/${label}`)
        return { label: label, response: response.data.data || [] }
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getTicketAffectedAssetBySerialNo = async (serialNo: string) => {
    try {
        const response = await axiosGet<TicketAffectedAsset[]>(`/api/ticketAffectedAsset/serial/${serialNo}`)
        return response.data.data || []
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getChangeHistoryBySerialNo = async (serialNo: string) => {
    try {
        const response = await axiosGet<TicketChangeHistory[]>(`/api/ticketChangeHistory/serial/${serialNo}`)
        return response.data.data || []
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const checkFieldDuplicate = async (serialNo: string | undefined, kbankBarcode: string | undefined) => {
    try {
        const response = await axiosGet<AssetOwnerLocation[]>(`/api/asset/find?serialNo=${serialNo}&kBankBarcode=${kbankBarcode}`)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const findBySerialNoOrKbankBarcode = async (searchValue: string) => {
    try {
        const response = await axiosGet<AssetOwnerLocation>(`/api/asset/findAssetBySerialNoOrKbankBarcode/${searchValue}`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const createPreventive = async (data: preventive) => {
    try {
        const response = await axiosPost<preventive>('/api/pv_mnt', data)
        return response.data.data as preventive
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const updatePreventive = async (id: string, data: preventive) => {
    try {
        const response = await axiosPatch<preventive>('/api/pv_mnt/' + id, data)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const findPreventiveHistoriesBySerialNo = async (SerialNo: string) => {
    try {
        const response = await axiosGet<preventive[]>(`/api/pv_mnt/historyBySerialNumber/${SerialNo}`)
        return response.data.data!! as preventive[]
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getAssetSignedForPrint = async (location: string) => {
    try {
        const response = await axiosGet<AssetSignedForPrint[]>(`/api/signedAsset/getAssetSignedForPrint/${location}`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getAssetSignedDetailForPrint = async (SerialNo: string) => {
    try {
        const response = await axiosGet<DataAssetSingedWithWoNo[]>(`/api/signedAsset/getAssetSignedDetailForPrint/${SerialNo}`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const findExistPvm = async (serialNo: string, woId: number) => {
    try {
        const response = await axiosGet<number>(`/api/pv_mnt/findExistPvm?serialNo=${serialNo}&woId=${woId}`)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const searchFetch = (value: SelectValue, allData: string[], setState: Function) => {
    if (typeof value === 'string' && value.length >= 1) {
        const filterData = allData.filter((data: string) => data.toUpperCase().includes(value.toUpperCase()))
        if (filterData.length > 0) {
            if (filterData.length < 20) {
                setState(filterData)
            } else {
                setState(filterData.slice(0, 20))
            }
        }
    } else {
        setState(allData.slice(0, 20))
    }
}

export const createPreventiveChangeHistory = async (data: preventiveChangeHistory): Promise<number> => {
    try {
        const response = await axiosPost<preventiveChangeHistory>('/api/pv_history', data)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getPreventiveChangeHistory = async (pmId: string) => {
    try {
        const response = await axiosGet<preventiveChangeHistory[]>(`/api/pv_history/history/${pmId}`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getAllAssetFieldsOption = () => {
    return async (dispatch: Dispatch) => {
        try {
            const res = await getAssetFieldListOption()
            var promistList = await res.map((it) => {
                return getSearchAllFieldValueNew(it.itemLabel)
            })
            dispatch(GetAssetFilterStatusEvent.build(status))
            return (await Promise.all(promistList)).forEach((it: any) => {
                if (it.label === 'City') {
                    dispatch(GetAssetFilterCityEvent.build(it.response))
                } else if (it.label === 'Employee ID') {
                    dispatch(GetAssetFilterEmployeeIDEvent.build(it.response))
                } else if (it.label === 'Location') {
                    dispatch(GetAssetFilterLocationEvent.build(it.response))
                } else if (it.label === 'Device Type') {
                    dispatch(GetAssetFilterDeviceTypeEvent.build(it.response))
                } else if (it.label === 'computerName') {
                    dispatch(GetAssetFilterComputerNameEvent.build(it.response))
                } else if (it.label === 'Brand') {
                    dispatch(GetAssetFilterBrandEvent.build(it.response))
                } else if (it.label === 'Model') {
                    dispatch(GetAssetFilterModelEvent.build(it.response))
                } else if (it.label === 'Department') {
                    dispatch(GetAssetFilterDeptNameEvent.build(it.response))
                } else if (it.label === 'Zone') {
                    dispatch(GetAssetFilterZoneEvent.build(it.response))
                }
            })
            // @ts-ignore
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getAssetFilterNew = () => {
    return async (dispatch: Dispatch) => {
        try {
            var promistList = [
                getSearchAllFieldValueNew('Location'),
                getSearchAllFieldValueNew('Device Type'),
                getSearchAllFieldValueNew('Brand'),
                getSearchAllFieldValueNew('Model'),
            ]
            return Promise.all(promistList).then((values) => {
                dispatch(GetAssetFilterLocationEvent.build(values[0].response))
                dispatch(GetAssetFilterDeviceTypeEvent.build(values[1].response))
                dispatch(GetAssetFilterBrandEvent.build(values[2].response))
                dispatch(GetAssetFilterModelEvent.build(values[3].response))
                dispatch(GetAssetFilterStatusEvent.build(status))
                return 200
            }).catch((err) => {
                throw err
            })
            // @ts-ignore
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getAssetFieldListOption = async () => {
    try {
        const response = await axiosGet(`/api/item-types`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getEditAssetFieldOption = () => {
    return async (dispatch: Dispatch) => {
        try {
            var promistList = [
                getSearchAllFieldValueNew('Location'),
                getSearchAllFieldValueNew('Computer Name'),
                getSearchAllFieldValueNew('Employee ID'),
                getSearchAllFieldValueNew('Department'),
            ]
            return Promise.all(promistList).then((values) => {
                dispatch(GetAssetFilterLocationEvent.build(values[0].response))
                dispatch(GetAssetFilterComputerNameEvent.build(values[1].response))
                dispatch(GetAssetFilterEmployeeIDEvent.build(values[2].response))
                dispatch(GetAssetFilterDeptNameEvent.build(values[3].response))
                dispatch(GetAssetFilterStatusEvent.build(status))
                return 200
            }).catch((err) => {
                throw err
            })
            // @ts-ignore
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}
