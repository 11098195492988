import {
    assetOwnerStateEvent,
    GetAllAssetOwnerLocationEvent,
    GetAssetFilterBrandEvent,
    GetAssetFilterCityEvent,
    GetAssetFilterComputerNameEvent,
    GetAssetFilterDeptNameEvent,
    GetAssetFilterDeviceTypeEvent,
    GetAssetFilterEmployeeIDEvent,
    GetAssetFilterLocationEvent,
    GetAssetFilterModelEvent,
    GetAssetFilterStatusEvent,
    GetAssetFilterZoneEvent
} from './state-event'
import {AssetOwnerLocationTotalData, FilterAsset} from './model'

export const assetOwnerLocationListStateReducer = (state: AssetOwnerLocationTotalData = {data: [], total: 0}, event: assetOwnerStateEvent<any>) => {
    if (event.type === GetAllAssetOwnerLocationEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}

export const assetFilterStateReducer = (state: FilterAsset = {}, event: assetOwnerStateEvent<any>) => {
    if (event.type === GetAssetFilterBrandEvent.type) {
        state.brand = event.payload
    } else if (event.type === GetAssetFilterCityEvent.type) {
        state.city = event.payload
    } else if (event.type === GetAssetFilterComputerNameEvent.type) {
        state.computerName = event.payload
    } else if (event.type === GetAssetFilterDeptNameEvent.type) {
        state.deptName = event.payload
    } else if (event.type === GetAssetFilterDeviceTypeEvent.type) {
        state.deviceType = event.payload
    } else if (event.type === GetAssetFilterEmployeeIDEvent.type) {
        state.employeeID = event.payload
    } else if (event.type === GetAssetFilterLocationEvent.type) {
        state.location = event.payload
    } else if (event.type === GetAssetFilterModelEvent.type) {
        state.model = event.payload
    } else if (event.type === GetAssetFilterStatusEvent.type) {
        state.status = event.payload
    } else if (event.type === GetAssetFilterZoneEvent.type) {
        state.zone = event.payload
    } else {
        return state
    }
    return state
}
