import {GetAllImpactSuccessEvent, ImpactStateEvent} from './state-event'
import {PriorityLevel} from '../priority'

export const impactStateReducer = (state: PriorityLevel[] = [], event: ImpactStateEvent<any>) => {
    if (event.type === GetAllImpactSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
