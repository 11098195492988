import { Dispatch } from 'redux'
import { axiosGet } from '../rest'
import { Worklog } from './model'
import { GetAllWorklogByIncidentIdSuccessEvent } from './state-event'

export const getAllWorkLogByIncidentId = (incidentId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Worklog[]>(`/api/incident/incidents/${incidentId}/worklogs`)
            dispatch(GetAllWorklogByIncidentIdSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}
