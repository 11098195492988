import React, { useState } from 'react'
import QrReader from 'react-qr-reader'

interface param {
    getDataScan: Function
}

type Props = param

const QRCode: React.FC<Props> = (props: Props) => {
    const [result, setResult] = useState()

    const handleScan = data => {
        if (data) {
            setResult(data)
            console.log(result)
            props.getDataScan(data)
        }
    }

    const handleError = err => {
        console.error(err)
    }

    return (
        <div>
            <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: 'auto', height: 'auto' }}
            />
            {/* <p>{result}</p> */}
        </div>
    )
}

export default QRCode
