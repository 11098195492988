import React, { useEffect, useState } from 'react'
import {Collapse} from 'antd'
import TableJob from './TableJob'
import { getAssetForWOByWOId } from '../../Relate/Asset'

interface Params {
    woId: string,
    username: string,
    handleIsChangeStatus: (boolean) => void,
    permission: boolean,
    roleId: number,
    isCloseAndCancel: boolean
}

type Props = Params
const { Panel } = Collapse

const ExpandList: React.FC<Props> = (props: Props) => {
    const [isAssetNotNull, setIsAssetNotNull] = useState<boolean>(false)
    const callback = (key) => {
        console.log(key)
    }

    useEffect(()=>{
        fetchAsset() 
    })

    const fetchAsset = async () => {
        if (props.woId) {
            const res = await getAssetForWOByWOId(props.woId)
            if (res.length !== 0) {
                setIsAssetNotNull(true)
            } else {
                setIsAssetNotNull(false)
            }
        }
    }

    return (
        <>
            <Collapse defaultActiveKey={['1']} onChange={callback} >
                <Panel header="Job" key="1">
                    <TableJob woId={props.woId} username={props.username}
                        handleIsChangeStatus={props.handleIsChangeStatus}
                        permission={props.permission} roleId={props.roleId}
                        isCloseAndCancel={props.isCloseAndCancel}
                        isAssetNotNull={isAssetNotNull}/>
                </Panel>
            </Collapse>
        </>
    )
}

export default ExpandList
