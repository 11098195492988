import React, { useState, useEffect } from 'react'
import TableSLAIncident from './TableSLAIncident'
import { Row, Col, Button, Icon, Form, Select, DatePicker, message } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import { useLocation } from 'react-router-dom'
import { genReport, genReportNOSLA } from './service'
import { connect } from 'react-redux'
import { StoreState } from '../store'
import {checkRolesState, RoleType} from '../authorization-module/permissions';

const mapStateToProps = (state: StoreState) => {
    return {
        DashboardIncidentBacklogMyTicket: state.DashboardIncidentBacklogMyTicket
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = FormComponentProps & StateProps
const { Option } = Select
const { RangePicker } = DatePicker
const ReportDashBoard: React.FC<Props> = (props: Props) => {
    const [show, setShow] = useState<String>('TotalGroup')
    const [created, setCreated] = useState<String>('last30Days')
    const [time, setTime] = useState<String[]>([])
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [sortBy, setSortBy] = useState<string>('number')
    const [limit, setLimit] = useState<string>('100')
    const [loading, setLoading] = useState<boolean>(false)
    const { getFieldDecorator } = props.form
    const location = useLocation()

    useEffect(() => {
        setLimit(String(props.DashboardIncidentBacklogMyTicket.total))
    }, [props.DashboardIncidentBacklogMyTicket.total])

    const ExportCSV = () => {
        // message.info('Please Wait. The Report Is Being Created.')
        setLoading(true)
        if (location.pathname === '/ReportDashBoardNoneSLA') {
            genReportNOSLA(show, created, time[0], time[1], orderBy, sortBy, '0', limit)
                .then(() => {
                    setLoading(false)
                })
                .catch((_err) => {
                    setLoading(false)
                    message.error('Can\'t Create File Excel')
                })
        } else {
            genReport(show, created, time[0], time[1], orderBy, sortBy, '0', limit)
                .then(() => {
                    setLoading(false)
                })
                .catch((_err) => {
                    setLoading(false)
                    message.error('Can\'t Create File Excel')
                })
        }
    }

    const changeShow = (e) => {
        setShow(e)
    }

    const changeCreated = (e) => {
        if (e !== 'anytime') {
            setTime([])
        }
        setCreated(e)
    }

    const changeTime = (value, dateString) => {
        setTime(dateString)
    }

    const renderTopic = (data: String): String => {
        let Topic: String = ''
        // if (data === 'anytime') {
        //     Topic = 'ALL'
        // } else
        if (data === 'MyGroup') {
            Topic = 'My Group'
        } else if (data === 'MyTicket') {
            Topic = 'My Ticket'
        } else if (data === 'TotalGroup') {
            Topic = 'Total Group'
        }
        return Topic
    }

    const getChangeTable = (sortBy, orderBy, skip, limit) => {
        if (sortBy) {
            setSortBy(sortBy)
        }
        if (orderBy) {
            setOrderBy(orderBy)
        }
        if (limit) {
            setLimit(limit)
        }
    }

    return (
        <div>
            <Row style={{ textAlign: 'right' }}>
                <Button loading={loading} className="addCriteriaButton" onClick={() => ExportCSV()} htmlType="button" disabled={!checkRolesState(RoleType.Incident, 'ViewIncident')}> {!loading ? <Icon type="upload" /> : null}Export</Button>
            </Row>
            <br />
            <Form>
                <Row gutter={16}>
                    <Col span={18} xs={24} sm={24} md={18} lg={18}/>
                    <Col span={3} xs={24} sm={24} md={3} lg={3}>
                        <span>Show :</span>
                        <Form.Item>
                            {getFieldDecorator('Show', {
                                initialValue: show
                            })(
                                <Select onChange={(e) => changeShow(e)}>
                                    {/* <Option value="anytime">All</Option> */}
                                    <Option value="MyGroup">My Group</Option>
                                    <Option value="MyTicket">My Ticket</Option>
                                    <Option value="TotalGroup">Total Group</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={3} xs={24} sm={24} md={3} lg={3}>
                        <span>Created :</span>
                        <Form.Item >
                            {getFieldDecorator('Created', {
                                initialValue: created
                            })(
                                <Select onChange={(e) => changeCreated(e)}>
                                    <Option value="last30Days">Last 30 days</Option>
                                    <Option value="today">Today</Option>
                                    <Option value="yesterday">Yesterday</Option>
                                    <Option value="thisWeek">This Week</Option>
                                    <Option value="thisMonth">This Month</Option>
                                    <Option value="anytime">Select Time Period</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={18} xs={24} sm={24} md={18} lg={18}/>
                    <Col span={6} xs={24} sm={24} md={6} lg={6}>
                        {created === 'anytime' ? (<>
                            <Form.Item >
                                {getFieldDecorator('TimePeriod')(
                                    <RangePicker
                                        // showTime={{ format: 'HH:mm' }}
                                        onChange={changeTime}
                                        format="YYYY-MM-DD"
                                        placeholder={['Start Time', 'End Time']}
                                        style={{
                                            width: '100%'
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </>) : null}
                    </Col>
                </Row>
            </Form>
            <br />
            <Row className="TopicDashboard">
                <Col span={24}>
                    <span className="main-title">{location.pathname.length < 17 ? 'SLA' : 'Ticket Incident'} ({renderTopic(show)})</span>
                </Col>
            </Row>
            <br />
            <Row className='fix_scroll_md'>
                <TableSLAIncident show={show} create={created} start={time[0]} end={time[1]} getChangeTable={getChangeTable} />
            </Row>
        </div>
    )
}

const WrappedNormalReportDashBoard = Form.create({ name: 'report' })(ReportDashBoard)

export default connect(mapStateToProps, {})(WrappedNormalReportDashBoard)
