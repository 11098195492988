import { Folder } from './model'
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from '../rest'

export const getFolderById = async (folderId: string) => {
    try {
        const response = await axiosGet<Folder>(`/api/knowledge/folders/${folderId}`)
        return response.data.data!!
    } catch (err) {
        throw err.response
    }
}

export const createFolder = async (folder: Folder) => {
    try {
        const response = await axiosPost<Folder>('/api/knowledge/folders', folder)
        return response.data.data!!
    } catch (err) {
        throw err.response
    }
}

export const updateFolder = async (folder: Folder) => {
    try {
        return await axiosPatch<Folder>(`/api/knowledge/folders/${folder.id}`, folder)
    } catch (err) {
        throw err.response
    }
}

export const deleteFolder = async (folderId: string) => {
    try {
        return await axiosDelete<Folder>(`/api/knowledge/folders/${folderId}`)
    } catch (err) {
        throw err.response
    }
}
