/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { AutoComplete, Button, Col, Dropdown, Form, Icon, Menu, Modal, Row, Spin, Tag, Tooltip } from 'antd'
import { StoreState } from '../../store'
import IncidentTimeIndex from './IncidentTimeIndex'
import SlaTimeUsedUpPercentage from './SlaTimeUsedUpPercentage'

import { Incident, IncidentWithSlaCalculations, TicketStatus } from './index'
import ModalPending from './PendingDialog'
import { markIncidentViewed, updateIncident } from './service'
import { message } from 'antd/es'
import { disableByStatus, notEmpty, toDistinctArray } from '../../common-misc'
import { FetchingState, SpinningFetcher } from '../../common-components'
import { SlaCalculation } from '../../sla-management/sla-calculation/model'
import { SlaGoal } from '../../sla-management/sla-policy/model'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { PriorityLevel } from '../priority'
import { FormComponentProps } from 'antd/es/form'
import { SelectValue } from 'antd/lib/select'
import { getAllWorkLogByIncidentId } from '../worklog/service'
import { User } from '../../authorization-module/user-role/duck/model'
import { SupportTeam } from '../incident/model'
import { UserSupportTeam } from '../../authorization-module/support-team-management/model'

const mapStateToProps = (state: StoreState) => {
    return {
        statuses: state.statuses,
        supportTeams: state.supportTeams,
        incidentState: state.incidentState,
        workLog: state.workLog
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    updateIncident: (incident: Incident) => Promise<number>
    getAllWorkLogByIncidentId: (incidentId: string) => Promise<number>
}

interface Params {
    incident: IncidentWithSlaCalculations
    xxx: any
    slaCalculationsFetchingState: FetchingState
    priority: PriorityLevel[]
}

type Props = StateProps & DispatchProps & Params & FormComponentProps
const IncidentRow: React.FC<Props> = (props: Props) => {
    const { SubMenu } = Menu

    const incident = props.incident
    const [myModal, setMyModal] = useState<JSX.Element | null>()
    const [visibleModal, setVisibleModal] = useState(false)
    const [isRequireAssignGroup, setIsRequireAssignGroup] = useState<boolean>(false)
    const [isRequireAssignee, setIsRequireAssignee] = useState<boolean>(false)
    const [assignee, setAssignee] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [currentStatus, setCurrentStatus] = useState<string>()
    const [oldStatus, setOldStatus] = useState<string>()
    const [isSpinning, setIsSpinning] = useState<boolean>(false)
    const [mySupportTeamName, setMySupportTeamName] = useState<string[]>()
    const { getFieldDecorator } = props.form
    const [rowId, setRowId] = useState<number>()
    const [disableAssignTo, setDisabledAssignTo] = useState<boolean>(false)
    const [slaCalculationsFetchingState, setSlaCalculationsFetchingState] = useState<FetchingState>(props.slaCalculationsFetchingState)

    useEffect(() => {
        if (props.incident.ticketStatus.status !== null) {
            setCurrentStatus(props.incident.ticketStatus.status)
        }
        if (props.supportTeams.length !== 0) {
            const supportTeamName = props.supportTeams.map((supportTeam: SupportTeam) => {
                return supportTeam?.name
            })
            setMySupportTeamName(supportTeamName || [])
        }
    }, [props.supportTeams])

    useEffect(() => {
        if (rowId) {
            fetchAllWorklog(rowId)
        }
    }, [rowId])

    useEffect(() => {
        disableByStatus(currentStatus, setDisabledAssignTo, props.incident?.user?.username, props.incident.supportTeam)
    }, [currentStatus])

    useEffect(() => {
        console.log(props.slaCalculationsFetchingState)
        setSlaCalculationsFetchingState(props.slaCalculationsFetchingState)
    }, [props.slaCalculationsFetchingState])

    const fetchAllWorklog = (rowId) => props.getAllWorkLogByIncidentId(rowId)

    function DueMenuSupport(row) {
        return (
            <Menu>
                {
                    // eslint-disable-next-line react/prop-types
                    props.supportTeams.map((data: SupportTeam, keys: number) => {
                        return (
                            <SubMenu title={<span>{data.name || ''}</span>} key={keys}>
                                {data.assignees.map((user, index: number) => {
                                    return (
                                        <Menu.Item key={index}
                                            onClick={() => changeMySupportTeam(data, user.user, row)}>
                                            <span>{user.user.people.fullName}</span>
                                        </Menu.Item>
                                    )
                                })}
                            </SubMenu>
                        )
                    })
                }
            </Menu>
        )
    }

    function changeMySupportTeam(supportTeam: SupportTeam, assignee: User, incident: Incident) {
        setIsSpinning(true)
        setSlaCalculationsFetchingState(FetchingState.Started)
        let newStatus: TicketStatus | undefined
        if (supportTeam !== null && assignee !== null) {
            newStatus = props.statuses.find(it => it.status === 'InProgress')!!
        }
        props.updateIncident({
            ...incident,
            supportTeam: supportTeam,
            user: assignee,
            ticketStatus: newStatus as TicketStatus
        }).then((res) => {
            if (res === 200) {
                setSlaCalculationsFetchingState(FetchingState.Succeed)
                setIsSpinning(false)
            }
        }).catch((err) => {
            setIsSpinning(false)
            message.error(`Failed updating incident. ${err}`)
        })
    }

    function DueMenuStatus(row: Incident) {
        let currentKey: number
        return (
            <Menu>
                {myModal ? (displayModal()) : null}
                {
                    // eslint-disable-next-line react/prop-types
                    props.statuses.map((status, keys: number) => {
                        const currentStatus = status.status
                        if (currentStatus === props.incident?.ticketStatus.status) {
                            currentKey = keys
                            setRowId(row.id)
                            if (oldStatus === '' || oldStatus === undefined) {
                                const worklog = (props.workLog || []).filter(value => value.type === 'Pending')[0]
                                if (worklog) {
                                    const value = worklog.changes.filter(obj => obj.fieldName === 'ticketStatus' && obj.oldValue !== null)[0]
                                    if (value) {
                                        setOldStatus(value.oldValue)
                                    }
                                }
                            }
                        }
                        if (currentKey || currentKey === 0) {
                            return checkRolesState(RoleType.Incident, 'CloseIncident', props.incident?.createdBy) && !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy)
                                // can close but can't update
                                ? (
                                    // status = closed and current status = resolved
                                    keys === 5 && currentKey === 4 ? (
                                        <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}>
                                            <span>{currentStatus}</span>
                                        </Menu.Item>
                                    ) : (
                                        <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)} disabled={true}>
                                            <span>{currentStatus}</span>
                                        </Menu.Item>
                                    )
                                ) : (
                                    currentKey <= keys || keys === 3
                                        ? (
                                            props.incident?.ticketStatus.status === 'Resolved' && keys === 5 ? (
                                                <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
                                                    disabled={!checkRolesState(RoleType.Incident, 'CloseIncident', props.incident?.createdBy)}>
                                                    <span>{currentStatus}</span>
                                                </Menu.Item>
                                            ) : (
                                                keys === 5 ? (
                                                    <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
                                                        disabled={true}>
                                                        <span>{currentStatus}</span>
                                                    </Menu.Item>
                                                ) : (
                                                    <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}>
                                                        <span>{currentStatus}</span>
                                                    </Menu.Item>
                                                )
                                            )
                                        )
                                        : (
                                            <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
                                                disabled={true}>
                                                <span>{currentStatus}</span>
                                            </Menu.Item>
                                        )
                                )
                        } else {
                            if (props.incident?.ticketStatus.status === 'Pending') {
                                return keys === 3 ? (
                                    <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}>
                                        <span>{currentStatus}</span>
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
                                        disabled={oldStatus !== currentStatus}>
                                        <span>{currentStatus}</span>
                                    </Menu.Item>
                                )
                            } else if (props.incident?.ticketStatus.status === 'Resolved') {
                                return keys === 3 ? (
                                    <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}>
                                        <span>{currentStatus}</span>
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)} disabled={true}>
                                        <span>{currentStatus}</span>
                                    </Menu.Item>
                                )
                            } else {
                                return (
                                    <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
                                        disabled={true}>
                                        <span>{currentStatus}</span>
                                    </Menu.Item>
                                )
                            }
                        }
                    })
                }
            </Menu>
        )
    }

    function displayModal() {
        return (
            <>
                {myModal}
                {setMyModal(null)}
            </>
        )
    }

    const handleOkRequireField = (e: any) => {
        e.preventDefault()
        props.form.validateFields((err: any, fieldsValue: any) => {
            let newStatus = ''
            if (!err) {
                setIsLoading(true)
                if (fieldsValue.Assignment_group !== null && fieldsValue.Assigned_to !== null) {
                    newStatus = 'InProgress'
                } else {
                    newStatus = 'Assigned'
                }
                setCurrentStatus(newStatus)
                const supportTeam: SupportTeam = props.supportTeams.find(it => it.name === fieldsValue.Assignment_group)!!
                const assignee: UserSupportTeam | undefined = supportTeam.assignees.find(it => it.user.people.fullName === fieldsValue.Assigned_to)
                const ticketStatus: TicketStatus = props.statuses.find(it => it.status === newStatus)!!
                const request: Incident = {
                    ...incident,
                    supportTeam: supportTeam || undefined,
                    user: assignee?.user || undefined,
                    ticketStatus: ticketStatus || undefined
                }
                props.updateIncident(request).then(res => {
                    if (res === 200) {
                        setIsLoading(false)
                        setVisibleModal(false)
                        setOldStatus('')
                    }
                }).catch((err) => {
                    setIsLoading(false)
                    setVisibleModal(false)
                    message.error(`Failed updating incident. ${err}`)
                })
            }
        })
    }

    const handleCancelRequireField = () => {
        setVisibleModal(false)
        setIsRequireAssignee(false)
        setIsRequireAssignGroup(false)
        props.form.setFieldsValue({
            Assignment_group: incident.supportTeam?.name,
            Assigned_to: incident.user?.email
        })
    }

    const onSaveModalPending = (newStatus: string) => {
        setCurrentStatus(newStatus)
    }

    function changeMyStatus(newStatus: string, data: Incident) {
        const ticketStatus = props.statuses.find(it => it.status === newStatus)
        if (ticketStatus?.status === 'Pending') {
            if (currentStatus === 'Pending') {
                setOldStatus(oldStatus!!)
            } else {
                setOldStatus(currentStatus!!)
            }
            setMyModal(<ModalPending targetId={data.id}
                currentStatus={onSaveModalPending}
                statuses={props.statuses}
                disablePending={!checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy) ||
                    (disableAssignTo && currentStatus !== 'Pending')}
            />)
        } else if (ticketStatus?.status === 'InProgress' || ticketStatus?.status === 'Resolved' || ticketStatus?.status === 'Closed') {
            if (ticketStatus?.status === 'Resolved' && data.resolution === null) {
                message.error('Unsuccessfully, Please input Resolution in Add note field.')
            } else {
                if (!(props.incident?.user && props.incident.supportTeam)) {
                    setVisibleModal(true)
                    setIsRequireAssignee(true)
                    setIsRequireAssignGroup(true)
                    setOldStatus(oldStatus)
                } else {
                    props.updateIncident(
                        {
                            id: data.id,
                            ticketStatus: ticketStatus
                        } as unknown as Incident
                    )
                    setCurrentStatus(ticketStatus?.status)
                    setOldStatus(oldStatus)
                }
            }
        } else if (ticketStatus?.status === 'Assigned') {
            if (!props.incident?.supportTeam) {
                setVisibleModal(true)
                setIsRequireAssignGroup(true)
                setOldStatus(oldStatus)
            } else {
                props.updateIncident(
                    {
                        id: data.id,
                        ticketStatus: ticketStatus
                    } as unknown as Incident
                )
                setCurrentStatus(ticketStatus?.status)
                setOldStatus(oldStatus)
            }
        } else {
            setCurrentStatus(ticketStatus?.status)
            setOldStatus(oldStatus)
            props.updateIncident(
                {
                    id: data.id,
                    ticketStatus: ticketStatus
                } as unknown as Incident
            )
        }
    }

    const buildTooltipTextForSlaTag = (incident: IncidentWithSlaCalculations) => {
        const findSlaGoal = (slaCalculation: SlaCalculation): SlaGoal =>
            props.incidentState.slaPolicyHistories.get(slaCalculation.slaPolicyHistoryId)?.slaGoals
                .find((it) => it.name === slaCalculation.slaGoalName)!!

        return (<div>
            {notEmpty(incident.slaCalculations)
                ? incident.slaCalculations!!.map((it) => (
                    <p key={it.id!! + it.lastModifiedDate}>
                        <SlaTimeUsedUpPercentage incident={incident} slaCalculation={it} slaGoal={findSlaGoal(it)} />
                    </p>
                ))
                : null
            }
        </div>)
    }

    function slaTag(incident: IncidentWithSlaCalculations): (JSX.Element | null) {
        const items = incident.slaCalculations
        const allStatusUnique = toDistinctArray(items.map((it) => it.slaStatus))
        const anyNotCancelled = allStatusUnique.find((it) => it !== 'Cancelled') != null

        return anyNotCancelled ? (<Tag className="tagSLA">SLA</Tag>) : null
    }

    const FilterSupportTeam = (e: SelectValue) => {
        props.form.setFieldsValue({
            Assigned_to: null
        })
        const targetSupportTeams = props.supportTeams.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === e
        })
        if (targetSupportTeams.length !== 0) {
            targetSupportTeams.forEach((res: any) => {
                const user: string[] = []
                res.assignees?.map(it => {
                    user.push(it.user.people.fullName)
                    setAssignee(user)
                })
            })
        } else {
            setAssignee([])
        }
    }

    const FilterAssignee = () => {
        const valAssignee = props.form.getFieldValue('Assignment_group')
        const targetSupportTeams = props.supportTeams.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === valAssignee
        })
        targetSupportTeams.forEach((res) => {
            const user: string[] = []
            res.assignees?.map(it => {
                user.push(it.user.people.fullName)
            })
            setAssignee(user)
        })
    }

    const checkAssignee = (e: SelectValue) => {
        if (e) {
            FilterSupportTeam(e)
        } else {
            props.form.setFieldsValue({
                Assigned_to: null
            })
        }
    }

    const onBlurTeam = (value: SelectValue) => {
        if (mySupportTeamName) {
            const isTeam = mySupportTeamName.includes((value as string))
            if (!isTeam) {
                props.form.setFieldsValue({
                    Assignment_group: null
                })
            }
        }
    }

    const onBlurAssignee = (value: SelectValue) => {
        if (assignee) {
            const isAssignee = assignee.includes((value as string))
            if (!isAssignee) {
                props.form.setFieldsValue({
                    Assigned_to: null
                })
            }
        }
    }

    return (
        <>
            <Row >
                <Col span={5} style={{ marginRight: 20 }}>
                    <Spin spinning={isSpinning}>
                        <span><span className='TextFrontDesc'>Assigned to</span>   <Tooltip placement="top" title={
                            incident.supportTeam === null && incident.user === null ? 'None' : incident.supportTeam !== null && incident.user === null ? incident.supportTeam?.name + ' / None' : incident.supportTeam?.name + ' / ' + incident.user?.people.fullName}>
                            <span style={{ float: 'right' }}>{
                                incident.supportTeam !== null ? ((incident.supportTeam?.name.length || ''.length) > 5 ? (<span>
                                    <Dropdown disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident.createdBy) || disableAssignTo} overlay={() => DueMenuSupport(incident)} trigger={['click']}>
                                        <span
                                            className="ant-dropdown-link">{(incident.supportTeam!!.name || '').substring(0, 5) + '... / '}{incident?.user?.people.fullName ? (incident.user?.people.fullName!! || '').substring(0, 5) + '...' : ''}<Icon
                                                type="down" /></span>
                                    </Dropdown>
                                </span>) : (<span>
                                    <Dropdown disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident.createdBy) || disableAssignTo} overlay={() => DueMenuSupport(incident)} trigger={['click']}>
                                        <span
                                            className="ant-dropdown-link">{incident.supportTeam?.name + ' / '}{incident.user?.people.fullName ? (incident.user?.people.fullName!! || '').substring(0, 7) + '...' : ''}<Icon
                                                type="down" /></span>
                                    </Dropdown>
                                </span>)) : (
                                    <span>
                                        <Dropdown disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident.createdBy) || disableAssignTo} overlay={() => DueMenuSupport(incident)} trigger={['click']}>
                                            <span className="ant-dropdown-link">None<Icon type="down" /></span>
                                        </Dropdown>
                                    </span>
                                )
                            }
                            </span></Tooltip></span><br />
                        <span><span className='TextFrontDesc'>Status</span>  <span style={{ float: 'right' }}>
                            <Dropdown
                                disabled={!checkRolesState(RoleType.Incident, 'CloseIncident', props.incident.createdBy) && !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident.createdBy)}
                                overlay={() => DueMenuStatus(incident)} trigger={['click']} key={incident.id}>
                                <span className="ant-dropdown-link">{incident ? incident.ticketStatus.status : ''}<Icon type="down" /></span>
                            </Dropdown>
                        </span>
                        </span><br />
                        <span><span className='TextFrontDesc'>Priority </span> <span style={{ float: 'right' }}>
                            {props.priority.map((priority: PriorityLevel, index) => {
                                return (
                                    incident.priority?.priority?.name === priority.name ? (
                                        <Tag style={{backgroundColor: priority.tagColor, color: '#fff'}} key={index}>{incident.priority.priority?.name}</Tag>
                                    ) : null
                                )
                            })}
                        </span></span>
                    </Spin>
                </Col>
                <Col span={18} style={{ borderLeft: '1px solid #E0E0E0' }}>
                    <div style={{ marginLeft: 10 }}>
                        <Row>
                            <Col span={3}>
                                <Link to={`/IncidentDetailsView/${incident.id}`} target="_blank"
                                    onClick={() => markIncidentViewed(incident.id)}>{incident.ticketNumber}</Link>
                            </Col>
                            <Col span={17} style={{ textAlign: 'justify' }}>
                                {incident.subject.length > 80 ? (<>{incident.subject.substring(0, 80) + ' ...'}</>) : (<>{incident.subject}</>)}
                            </Col>
                            <Col span={4}>
                                <div style={{ float: 'right' }}>
                                    {incident.viewed === false ? (<div>
                                        <Tag color="#87d068" style={{ marginLeft: 10 }}>NEW</Tag>
                                    </div>) : null}
                                </div>
                                <div style={{ float: 'right' }}>
                                    <SpinningFetcher fetchingState={slaCalculationsFetchingState} size='small'>
                                        {
                                            notEmpty(incident.slaCalculations) ? (
                                                <Tooltip placement="bottom" title={buildTooltipTextForSlaTag(incident)}>
                                                    {slaTag(incident)}
                                                </Tooltip>
                                            ) : null
                                        }
                                    </SpinningFetcher>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={3}>
                                <span className='TextFrontDesc'>Requester : </span>
                            </Col>
                            <Col span={21}>
                                <span>{incident.people?.fullName}</span><br />
                            </Col>
                        </Row>
                        <IncidentTimeIndex incident={incident} />
                    </div>
                    <div>
                        <Modal
                            visible={visibleModal}
                            title="Please Require filed"
                            onOk={handleOkRequireField}
                            onCancel={handleCancelRequireField}
                            footer={[
                                <Button key="back" onClick={handleCancelRequireField}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" onClick={handleOkRequireField} disabled={disableAssignTo} loading={isLoading}>
                                    Save
                                </Button>
                            ]}
                        >
                            <Form onSubmit={handleOkRequireField}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item label={'Assignment group'}>
                                            {getFieldDecorator('Assignment_group'
                                                , {
                                                    initialValue: incident.supportTeam?.name,
                                                    rules: [{ required: isRequireAssignGroup, whitespace: true, message: 'Assignment group is required' }]
                                                })(
                                                    <AutoComplete
                                                        style={{ width: '100%' }}
                                                        dataSource={(mySupportTeamName || []).map((it) => {
                                                            return it
                                                        })}
                                                        onChange={(e) => checkAssignee(e)}
                                                        onSelect={(e) => FilterSupportTeam(e)}
                                                        onBlur={(e) => onBlurTeam(e)}
                                                        placeholder={'Select an assignment group'}
                                                    />
                                                )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={8}>
                                    <Col span={24}>
                                        <Form.Item label={'Assignment to'}>
                                            {getFieldDecorator('Assigned_to'
                                                , {
                                                    initialValue: incident.user?.username,
                                                    rules: [{ required: isRequireAssignee, whitespace: true, message: 'Assignee is required' }]
                                                }
                                            )(
                                                <AutoComplete style={{ width: '100%' }}
                                                    dataSource={assignee}
                                                    onFocus={() => FilterAssignee()}
                                                    onBlur={(e) => onBlurAssignee(e)}
                                                    placeholder={'Select an assignee'}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Form>

                        </Modal>
                    </div>
                </Col>
            </Row>
        </>
    )
}
const WrappedRegistrationForm = Form.create<Props>()(IncidentRow)
export default connect(
    mapStateToProps, {
    updateIncident, getAllWorkLogByIncidentId: getAllWorkLogByIncidentId
})(WrappedRegistrationForm)
