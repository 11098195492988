import React, { useState, useEffect } from 'react'
import { Table, Input, Icon, message, Row, Col, Button, Modal, Form, AutoComplete} from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { getAllAsset, searchAssetSerial } from './service'
import { Asset } from './model'
import QRCode from '../../../common-components/QRCode'
import Scanner from '../Asset/BarcodeScanner'

const mapStateToProps = (state: StoreState) => {
    return {
        asset: state.asset
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllAsset: () => Promise<number>
}

interface Param {
    getData: Function,
    isAsset: boolean,
    setData: Asset[],
    assetvisible: boolean,
    assetSpare: boolean
}

const { Search } = Input

type Props = Param & StateProps & DispatchProps & FormComponentProps
const SelectAsset: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue } = props.form
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [data, setDataAsset] = useState<any>(null)
    const [dataSpare, setDataAssetSpare] = useState<any>(null)
    const [dataSearch, setDataSearch] = useState<Asset[]>([])
    const [selectData, setSelectData] = useState<Asset[]>([])
    const [visible, setVisible] = useState<boolean>(false)
    const [barcodeVisible, setBarcodeVisible] = useState<boolean>(false)
    const [valueScanner, setValueScanner] = useState<string | undefined>(undefined)
    const [dataScanner, setDataScanner] = useState<string | undefined>(undefined)
    const [isChangeSize, setIsChangeSize] = useState<boolean>(true)

    useEffect(() => {
        setDataSearch([])
        setSelectData([])
        setFieldsValue({
            search: undefined
        })
    }, [props.setData])

    useEffect(() => {
        if (window.innerWidth > 765) {
            setIsChangeSize(true)
        } else {
            setIsChangeSize(false)
        }
    }, [window.innerWidth])

    useEffect(() => {
        if (props.isAsset) {
            if (props.setData && data) {
                const concatData = data.concat(props.setData)
                const arrayUniqueByKey = [...new Map(concatData.map(item => [item.id, item])).values()]
                props.getData(arrayUniqueByKey, props.isAsset)
            } else {
                props.getData(data, props.isAsset)
            }
        } else {
            if (props.setData && dataSpare) {
                const concatData2 = dataSpare.concat(props.setData)
                const arrayUniqueByKey2 = [...new Map(concatData2.map(item => [item.id, item])).values()]
                props.getData(arrayUniqueByKey2, props.isAsset)
            } else {
                props.getData(dataSpare, props.isAsset)
            }
        }
    }, [data, dataSpare])

    useEffect(() => {
        setDataSearch([])
        setSelectData([])
        setFieldsValue({
            search: undefined
        })
    }, [props.assetvisible, props.assetSpare])

    const columns = [
        {
            title: 'Serial No',
            dataIndex: 'serialNo',
            // eslint-disable-next-line react/display-name
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Kbank Barcode',
            dataIndex: 'kbankBarcode'
        },
        {
            title: 'Device Type',
            dataIndex: 'deviceType'
        },
        {
            title: 'Computer Name',
            dataIndex: 'computerName'
        },
        {
            title: 'Brand',
            dataIndex: 'brand'
        },
        {
            title: 'Model',
            dataIndex: 'model'
        },
        {
            title: 'Employee ID',
            dataIndex: 'employeeId'
        },
        {
            title: 'Dept Name',
            dataIndex: 'deptName'
        },
        {
            title: 'City',
            dataIndex: 'city'
        },
        {
            title: 'Location',
            dataIndex: 'location'
        },
        {
            title: 'Zone',
            dataIndex: 'zone'
        },
        {
            title: 'Status',
            dataIndex: 'status'
        }

    ]

    const CheckTypeData = (row, checkAsset) => {
        if (checkAsset === true) {
            const addKeyAsset = selectData.map((it) => {
                it.assetType = 'asset'
                return it
            })
            setDataAsset(addKeyAsset)
        } else {
            const addKeySpare = selectData.map((it) => {
                it.assetType = 'spare'
                return it
            })
            setDataAssetSpare(addKeySpare)
        }
    }

    const barcodeScanner = (value) => {
        if (value) {
            setBarcodeVisible(false)
            setFieldsValue({
                search: value
            })
            onSearch(value)
        }
    }

    const onSearch = (value) => {
        if (!isLoading) {
            setIsLoading(true)
            if (value) {
                searchAssetSerial(value).then((res) => {
                    setDataSearch(res)
                    if (res.length === 0) {
                        message.info('No Data')
                    }
                    setIsLoading(false)
                }).catch((err) => {
                    setIsLoading(false)
                    message.error(`You have unSuccessfully get the data. ${err}`)
                })
            } else {
                setIsLoading(false)
                setDataSearch([])
            }
        }
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
            setSelectData(selectedRows)
        },
        getCheckboxProps: record => ({
            // disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name
        }),
        selectedRowKeys: selectData.map((data) => data.serialNo) as string[]
    }

    const selectAsset = () => {
        if (props.isAsset) {
            CheckTypeData(selectData, true)
        } else {
            CheckTypeData(selectData, false)
        }
    }

    const scanBarcode = (): void => {
        setVisible(true)
    }

    const handleOK = () => {
        setVisible(false)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const handleOKBarcode = () => {
        setBarcodeVisible(false)
    }

    const handleCancelBarcode = () => {
        setBarcodeVisible(false)
    }

    const getDataScan = (value) => {
        setVisible(false)
        setValueScanner(value)
        onSearch(value)
    }

    return (
        <>
            <Row>
                <Col span={isChangeSize ? 4 : 10} >
                    <Form onSubmit={onSearch}>
                        <Form.Item >
                            {getFieldDecorator('search',
                                {
                                    initialValue: valueScanner ? valueScanner!! : undefined
                                })(
                                <Search placeholder="Input Serial No or KBank Barcode." allowClear onSearch={onSearch} onKeyDown={(e: React.KeyboardEvent) => e.keyCode === 13 ? e.preventDefault() : ''} style={{ width: '100%', margin: '0 10px' } } />
                            )}
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                    {isChangeSize ? null : <Icon style={{ marginLeft: '5%', marginTop: '3%' }} className="scan_icon" type="barcode" onClick={e => { setBarcodeVisible(true) }} />}
                </Col>
                <Col span={isChangeSize ? 20 : 10} style={{ textAlign: 'right' }}>
                    <Button type='ghost' htmlType='button' onClick={() => selectAsset()}> Relate</Button>
                </Col>
            </Row>

            <Table 
                rowSelection={rowSelection}
                rowKey='serialNo'
                columns={columns}
                dataSource={dataSearch!!}
                pagination={{ showSizeChanger: true, pageSize: 10 }}
                style={{overflow: 'auto'}}
                loading={isLoading}
            />

            <Modal
                title="Scan QR-Code"
                visible={visible}
                onOk={handleOK}
                onCancel={handleCancel}
                footer={null}
            >
                <QRCode getDataScan={getDataScan} />
            </Modal>

            <Modal
                title="Scan Barcode"
                visible={barcodeVisible}
                onOk={handleOKBarcode}
                onCancel={handleCancelBarcode}
                width={700}
                footer={null}
            >
                {barcodeVisible
                    ? <Scanner barcodeScanner={barcodeScanner} />
                    : null}
                <p>{dataScanner}</p>
            </Modal>
        </>
    )
}

const searchForm = Form.create<Props>({ name: 'searchAsset' })(SelectAsset)

export default connect(mapStateToProps, {
    getAllAsset
})(searchForm)
