export interface Dashboard {
    data: any
    total: number
}

export interface DataForChart {
    labels: Array<String>
    datasets: Array<DataSet>
}

export interface DataSet {
    data: Array<number>
    backgroundColor: Array<String>
    hoverBackgroundColor: Array<String>
}

export interface IncidentBacklog {
    _id: String
    slaGoalName?: String
    measurementClockStartTime?: String
    slaTargetTime?: String
    slaTag?: String
    slaPolicyName?: String
    number: String
    subject: String
    priority: String
    status: String
    supportTeam: String
    assignee: String
    createdDate: String
    ticketId: String
}

export enum ChartType {
    OpenTicket = 'openTicket',
    OpenByStatus = 'openByStatus',
    OpenByPriority = 'openByPriority',
    OpenSLA = 'sla',
    IncidentBacklog = 'incidentBacklog'
}

export enum GroupType {
    TotalGroup = 'TotalGroup',
    MyGroup = 'MyGroup',
    MyTicket = 'MyTicket',
}

export const Color = [
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF',
    '#FF6384', '#FF9F40', '#4BC0C0', '#36A2EB', '#9966FF'
]

// Complete  4DC977
// Due in 2F80ED
// Due soon ED9E28
// Missed EB5757
// Overdue #726E6D

export const ColorSLA = ['#4DC977', '#2F80ED', '#ED9E28', '#EB5757', '#726E6D']
