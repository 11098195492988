import React, {useEffect} from 'react'
import {Select} from 'antd'
import {SelectValue} from 'antd/es/select'
import {IncidentFilter} from '../model'
import {Category} from '../../category'

interface Props {
    categories: Category[]
    onChanged: (value: string[]) => void
    criteria: IncidentFilter
}

export const CategoryFilter: React.FC<Props> = (props: Props) => {
    const { Option } = Select
    const myCategory: string[] = []

    const onRequesterChanged = (value: SelectValue) => {
        props.onChanged(Array.isArray(value) ? (value as Array<any>).map((it) => it as string) : [value as string])
    }

    return (
        <>
            <p className={'topicName'}>Category</p>
            {props.categories.length !== 0 ? (<span style={{ display: 'none' }}>{
                props.categories.map((category: Category) => {
                    if (category.active) {
                        myCategory.push(category.category)
                    }
                })
            }</span>
            ) : null}

            <Select mode="tags" style={{ width: '85%' }} onChange={onRequesterChanged} value={props.criteria.categories?.split(',') || []} placeholder={'Select a category'}>
                {[...new Set(myCategory)].map((it, index) => { return <Option key={index} value={it}>{it}</Option> })}
            </Select><br />
        </>
    )
}
