import { axiosGet, axiosPost} from '../../asset-management/rest'
import {AssetItems} from './model';
import {workOrder} from '../../wo-management';
import {Job, JobCreatedEvent} from '../../Relate/Job';
import {Dispatch} from 'redux';

export async function getAssetItemsByLocation(location: string): Promise<AssetItems[]> {
    try {
        const response = await axiosGet<AssetItems[]>(`/api/signedAsset/getAssetForSigned/${location}`)
        return response.data.data!! || []
    } catch (error) {
        console.log(error)
        throw error
    }
}
export async function getSignNumber(): Promise<string> {
    try{
        const response = await axiosGet<string>(`/api/ticketNumber/getTicketNumber?ticketType=SignNumber`)
        return response.data.data!! || ''
    } catch (error) {
        console.log(error)
        throw error
    }
}
export async function addListOfSignItem(signItem: AssetItems[]): Promise<number> {
    try {
        const response = await axiosPost<Job>('/api/signedAsset/list', signItem)
        return response.status
    } catch (err) {
        console.log(err)
        throw err
    }
}