import React, {useEffect, useState} from 'react'
import {Breadcrumb, Button, Card, Col, Form, message, Modal, Radio, Row} from 'antd'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {StoreState} from '../../store'
import {FormComponentProps} from 'antd/lib/form'
import TableStatus from './TableStatus'
import {getAllGlobalSetting, updateGlobalSetting} from './service'
import {SlaGlobalSetting, SlaMeasurementClockStartType} from './model'
import {FetchingState, fetchWithState, SpinningFetcher} from '../../common-components'

const mapStateToProps = (state: StoreState) => {
    return { globalSettings: state.slaGlobalSettings }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllGlobalSetting: () => Promise<number>
    updateGlobalSetting: (globalSetting: SlaGlobalSetting) => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

const myDataTable: any[] = []
const GlobalFormSetting: React.FC<Props> = (props: Props) => {
    const [isRedirect, setIsRedirect] = useState<boolean>(false)
    const [fetchingState, setFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [myTable, setMyTable] = useState<any>()
    const [tempStartTimeType, setTempStartTimeType] = useState<SlaMeasurementClockStartType>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchWithState(fetchingState, setFetchingState, props.getAllGlobalSetting)
    }, [])

    useEffect(() => {
        if (props.globalSettings) {
            setTempStartTimeType(props.globalSettings.measurementClockStartType)
        }
    }, [props.globalSettings])

    // const setDataTable = () => {
    //     startTimeType = props.globalSettings.measurementClockStartType
    //     // props.globalSettings.forEach((res: any) => {
    //     //     // myDataTable = res.ticketStatuses
    //     //     startTimeType = res.measurementClockStartType
    //     //     // if (res.ticketStatuses.length !== 0) {
    //     //     //     myDataTable = res.ticketStatuses
    //     //     //     startTimeType = res.measurementClockStartType
    //     //     // }
    //     // })
    // }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        props.form.validateFields((err: any, values: any) => {
            if (!err) {
                setIsLoading(true)
                const res = props.updateGlobalSetting({
                    // id: data.id,
                    originId: props.globalSettings.originId,
                    ticketType: props.globalSettings.ticketType,
                    measurementClockStartType: values.AttachedIncident,
                    ticketStatuses: myDataTable,
                    version: props.globalSettings.version
                })
                console.log(res)
                message.success('You have successfully saved the data.')
                setIsRedirect(true)
                setIsLoading(false)
            }
        })
    }

    const addToRules = async () => {
        const newOrder = props.form.getFieldValue('Order')
        const newIncidentStatus = props.form.getFieldValue('IncidentStatus')
        const newObj = {
            name: newIncidentStatus,
            order: newOrder - 1
        }
        myDataTable.push(newObj)

        props.form.setFieldsValue({
            Order: undefined,
            IncidentStatus: undefined
        })
        await RefTable(myDataTable)
    }

    const RefTable = (myDataTable:any) => {
        setMyTable(<TableStatus dataTable={myDataTable} />)
    }

    const { getFieldDecorator } = props.form

    const modalConfirmCancel = (): void => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            }
        })
    }

    const onChangeStartTimeType = () => {
        const type = props.form.getFieldValue('AttachedIncident')
    }

    return (
        <SpinningFetcher fetchingState={fetchingState}>
            {isRedirect ? (<Redirect to="/Setting" />) : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>General SLA Setting</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Row>
                <Col span={24}>
                    <Card style={{height: '530px'}}>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col span={24}>
                                    <div>
                                        <h2 className="main-title"><span className={'requiredIcon'}>* </span>Start Time Type</h2>
                                        <Form.Item>
                                            {getFieldDecorator('AttachedIncident', {
                                                initialValue: props.globalSettings.measurementClockStartType,
                                                rules: [{
                                                    required: true,
                                                    message: 'Start Time Type is required'
                                                }]
                                            })(
                                                <Radio.Group onChange={(e) => {
                                                    console.log(e.target.value)
                                                    setTempStartTimeType(e.target.value)
                                                }}>
                                                    <Radio value="Attach" name='startTimeType'>SLA Attached to Incident</Radio><br />
                                                    <Radio value="Status" name='startTimeType'>Status time stamp</Radio>
                                                </Radio.Group>
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{paddingLeft: '35px'}}>
                                    {console.log(tempStartTimeType)}
                                    { tempStartTimeType && tempStartTimeType === 'Attach' ? (
                                        <p >Using first SLA Attached time stamp. Retaining SLA start time information throughout SLA policy.<br/>
                                            <br/>
                                        For example:<br/>
                                        [Measurement start when status 'Assigned']<br/>
                                        [No SLA assign to team 'A']<br/>
                                        -	[8:00]: An incident is raised<br/>
                                        -	[8:10]: The incident is assigned to Team A<br/>
                                        -	[8:30]: The incident is re-assigned to Team B, matched to SLA policy. SLA is attached to the incident and SLA start time is [8:30]<br/>
                                        -	[9:00]: The incident is re-assigned to Team C, matched to SLA polity. SLA is attached to the incident and SLA start time is [8:30]<br/>
                                        </p>
                                    ) : (
                                        <p>Using first measurement start time stamp. Retaining SLA start time information throughout SLA policy.<br/>
                                            <br/>
                                        For example:<br/>
                                        [Measurement start when status 'Assigned']<br/>
                                        [No SLA assign to team 'A']<br/>
                                        -	[8:00]:  An incident is raised<br/>
                                        -	[8:10]:  The incident is assigned to Team A<br/>
                                        -	[8:30]:  The incident is re-assigned to Team B, matched to SLA policy. SLA is attached to the incident and SLA start time is [8:10]<br/>
                                        -	[9:00]: The incident is re-assigned to Team C, matched to SLA polity.  SLA is attached to the incident and SLA start time is [8:10]<br/>
                                        </p>
                                    )}
                                </Col>
                            </Row>
                            {/* it still cannot be used */}
                            {/* <Row> */}
                            {/*    <p>Ticket Type *</p> */}
                            {/*    <div style={{ padding: 10 }}> */}
                            {/*        <Col span={3}> */}
                            {/*            <span><b className="Mytext-form">Order</b></span> */}
                            {/*            <Form.Item > */}
                            {/*                {getFieldDecorator('Order', { */}
                            {/*                    rules: [{ required: false, message: 'Required' }] */}
                            {/*                })(<InputNumber min={0} max={365} placeholder="Order" />)} */}
                            {/*            </Form.Item> */}
                            {/*        </Col> */}
                            {/*        <Col span={6}> */}
                            {/*            <span><b className="Mytext-form">Incident Status</b></span> */}
                            {/*            <Form.Item > */}
                            {/*                {getFieldDecorator('IncidentStatus', */}
                            {/*                    { */}
                            {/*                        rules: [{ required: false, whitespace: true, message: 'Required' }] */}
                            {/*                    })( */}
                            {/*                    <Input type="text" placeholder="Incident Status" maxLength={255} /> */}
                            {/*                )} */}
                            {/*            </Form.Item> */}
                            {/*        </Col> */}
                            {/*        <Col span={2} style={{ marginLeft: 45, marginTop: 25 }}> */}
                            {/*            <Button onClick={() => addToRules()} htmlType="button" >Add</Button> */}
                            {/*        </Col> */}
                            {/*    </div> */}
                            {/* </Row> */}
                            {/* <Row> */}
                            {/*    <div style={{ marginLeft: '10%', width: 300 }}> */}
                            {/*        {myTable || <TableStatus dataTable={myDataTable} />} */}
                            {/*    </div> */}
                            {/* </Row> */}
                            <Row>
                                <div style={{ float: 'right', marginTop: '150px' }}>
                                    <Form.Item>
                                        <Button type="default" htmlType="button" style={{ marginRight: 10 }} onClick={() => modalConfirmCancel()}>
                                            Cancel
                                        </Button>
                                        <Button type="primary" htmlType="submit" loading={isLoading}>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </SpinningFetcher>
    )
}

const SlaGlobalSettingPage = Form.create({ name: 'GlobalFormSetting' })(GlobalFormSetting)

export default connect(mapStateToProps,
    {
        getAllGlobalSetting, updateGlobalSetting
    })(SlaGlobalSettingPage)
