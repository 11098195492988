import React, {useEffect, useState} from 'react'
import { AutoComplete, message } from 'antd'
import {Requester, searchPeople, searchRequester} from '../../requester'
import { SelectValue } from 'antd/lib/select'
import { IncidentFilter } from '../model'
import {People} from '../../../authorization-module/people/model';

interface OwnProps {
    onChanged: (value: string | undefined) => void
    criteria: IncidentFilter
}

type Props = OwnProps

export const RequesterFilter: React.FC<Props> = (props: Props) => {
    const [suggestions, setSuggestions] = useState<string[]>([])
    const [inputText, setInputText] = useState<string>(props.criteria.requesterName || '')

    useEffect(()=>{
        setInputText(props.criteria.requesterName || '')
    }, [props.criteria.requesterName])

    const onRequesterSearchTriggered = (searchText: string) => {
        populateRequesterSuggestions(searchText)
    }

    const onRequesterSelected = (value: SelectValue) => {
        if (value !== props.criteria.requesterName) {
            props.onChanged(value === '-- Empty --' ? undefined : (value as string))
        }
    }

    const onInputTextChanged = (value: SelectValue) => {
        setInputText(value as string)
    }

    const populateRequesterSuggestions = (input: any) => {
        searchPeople(input).then((res: People[]) => {
            setSuggestions(
                res?.map((requester) => requester.firstName + ' ' + requester.lastName) || []
            )
        })
            // .catch((error) => message.error(error.toString()))
    }

    return (
        <>
            <p className={'topicName'}>Requesters</p>
            <AutoComplete
                dataSource={suggestions}
                style={{ width: '85%' }}
                onSelect={onRequesterSelected}
                onSearch={onRequesterSearchTriggered}
                onChange={onInputTextChanged}
                onBlur={onRequesterSelected}
                placeholder="Name"
                value={inputText}
            /><br />
        </>

    )
}
