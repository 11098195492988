import { buildStateEvent, GenericStateEvent } from '../../common-redux'
import { SlaGlobalSetting } from './model'

export interface GlobalSettingStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllGlobalSettingSuccessEvent = {
    type: 'GetAllGlobalSettingSuccessEvent',
    build: (payload: SlaGlobalSetting) => buildStateEvent(GetAllGlobalSettingSuccessEvent.type, payload)
}

export const UpdateGlobalSettingSuccessEvent = {
    type: 'UpdateGlobalSettingSuccessEvent',
    build: (payload: SlaGlobalSetting) => buildStateEvent(UpdateGlobalSettingSuccessEvent.type, payload)
}
