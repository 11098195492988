/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect } from 'react'
import {
    Icon,
    Modal,
    Table
} from 'antd'
import { Tooltip } from 'antd/es'
import { SupportTeamManagement, UserSupportTeam } from './model'
interface Props {
    data: any[]
    updateSUP: Function
    SupportTeam: SupportTeamManagement[]
    supportTeamId: number
}
const TableAssignTeam: React.FC<Props> = (props: Props) => {
    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%'
        }, {
            title: 'Name',
            dataIndex: 'user.people.fullName',
            key: 'user.people.fullName',
            width: '45%',
            render: (text, data) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'}>
                        <Icon className="delete_icon" type="delete" style={{marginLeft: '48px'}}
                            onClick={() => handleOnDeleteAssignee(data)}/>
                    </Tooltip>
                    <span style={{marginLeft: '15px'}}>{text}</span>
                </>
            )
        }, {
            title: 'Email',
            dataIndex: 'user.username',
            key: 'user.username',
            width: '45%',
            render: (text) => (
                <>
                    <span style={{marginLeft: '20px'}}>{text}</span>
                </>
            )
        }, {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            className: 'custom'

        }
    ]

    const handleOnDeleteAssignee = (row) => {
        const tempPatch: UserSupportTeam[] = []
        let patch: any = {}
        console.log(row.user.people.email)
        console.log(props.data)
        const data = (props.data || []).filter(assignee => !(row.user.people.email === assignee.user.people.email))
        console.log(data)
        data.map(data => {
            tempPatch.push({user: data.user, id: data.id, supportTeamId: props.supportTeamId})
        })
        patch = {
            assignees: tempPatch
        }
        Modal.confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete this data?',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            onCancel: () => {
            },
            onOk: () => {
                props.updateSUP(row.supportTeamId, patch)
            }
        })
    }

    return (
        <Table columns={columns}
            rowKey="id"
            dataSource={props.data || []}
            showHeader={false}
            pagination={false}
            style={{
                marginTop: '-8px',
                marginBottom: '-9px',
                marginLeft: '-60px',
                backgroundColor: '#ffffff'
            }}/>
    )
}

export default TableAssignTeam
