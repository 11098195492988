import React, { useState } from 'react'
import {Dropdown, Form, Icon, Menu, message, Select, Tooltip} from 'antd'

import { StoreState } from '../../store'
import { connect } from 'react-redux'
import IncidentViewSaveAsModalForm from './IncidentViewSaveAsModalForm'
import { FormComponentProps } from 'antd/lib/form'
import { getCurrentIncidentView, setCurrentIncidentView, updateIncidentView } from './service'
import { IncidentViewState } from './state-model'

interface Props extends FormComponentProps {
    incidentViewState: IncidentViewState
    setCurrentIncidentView: Function
    getCurrentIncidentView: Function
    updateIncidentView: Function
}

const ViewSelection: React.FC<Props> = (props: Props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [isShowingSaveAsModalForm, setIsShowingSaveAsModalForm] = useState<boolean>(false)

    const onViewSelected = (v: string) => {
        props.setCurrentIncidentView(v).catch((reason: any) =>
            message.error('Failed to set current view. ' + reason?.toString())
        )
        setIsEditing(false)
    }

    const onEditIconClicked = () => {
        setIsEditing(true)
    }

    const getEditUiElementsColor = () => props.incidentViewState.currentView?.isCriteriaDirty === true ? 'red' : '#323276'

    const isOverwritingAllowedForCurrentView = () => props.incidentViewState.currentView?.isPredefined === false

    const onSaveIconClicked = () => {
        props.updateIncidentView(props.incidentViewState.currentView!!).then((res: number) => {
            message.success('The update has finished successfully.')
        })
        setIsEditing(false)
    }

    const onDiscardIconClicked = () => {
        setIsEditing(false)
        window.location.reload()
    }

    const onSaveAsIconClicked = () => {
        setIsShowingSaveAsModalForm(true)
    }

    const onSaveAsModalFormClosed = (isSuccess: boolean) => {
        if (isSuccess) {
            setIsEditing(false)
        }
        setIsShowingSaveAsModalForm(false)
    }

    function viewSelector() {
        return (
            <Menu>
                {
                    // eslint-disable-next-line react/prop-types
                    ((props.incidentViewState.allVisibleViewNames || []).map((data: string, index: number) => {
                        return (
                            <Menu.Item key={index} onClick={() => onViewSelected(data)}>
                                <span>{data}</span>
                            </Menu.Item>
                        )
                    }))
                }
            </Menu>
        )
    }

    return (
        <>
            <div className={'border-current-view'}>
                <span style={{color: '#6A6C8F'}}>View :</span>&nbsp;&nbsp;
                <Dropdown overlay={() => viewSelector()} trigger={['click']} className={isEditing ? 'selectStyleRed' : 'selectStyleBlack'}>
                    <span className="ant-dropdown-link" >{ props.incidentViewState.currentView?.name.length!! > 18 ? (<>{props.incidentViewState.currentView?.name.substring(0, 18) + '..'}</>) : (<>{props.incidentViewState.currentView?.name}</>) } <Icon type="down" className={'pen-icon'}/></span>
                </Dropdown>
                {/* <IncidentViewSaveAsModalForm /> */}
                {!isEditing ? (
                    <Tooltip placement="bottom" title={'Edit'}>
                        <Icon className="edit_icon" type="edit" style={{ color: getEditUiElementsColor() }}
                            onClick={onEditIconClicked}/>
                    </Tooltip>
                ) : (
                    <span>
                        {isOverwritingAllowedForCurrentView()
                            ? <Tooltip placement="bottom" title={'Save'}><span onClick={onSaveIconClicked}
                                style={{ margin: '1px' }}><Icon type="check"/></span></Tooltip>
                            : null
                        }
                        <Tooltip placement="bottom" title={'Discard changes'}><span onClick={onDiscardIconClicked}
                            style={{ margin: '1px' }}><Icon
                                type="close"/></span></Tooltip>
                        <Tooltip placement="bottom" title={'Save as'}><span onClick={onSaveAsIconClicked}
                            style={{ margin: '1px' }}><Icon type="copy"
                                theme="filled"/></span></Tooltip>
                    </span>
                )}
            </div>
            {isShowingSaveAsModalForm
                ? <IncidentViewSaveAsModalForm onClose={onSaveAsModalFormClosed} onOpen={isShowingSaveAsModalForm}/>
                : null
            }
        </>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        incidentViewState: state.incidentViewState
    }
}

const wrappedByForm = Form.create<Props>()(ViewSelection)

export default connect(
    mapStateToProps,
    { setCurrentIncidentView, getCurrentIncidentView, updateIncidentView }
)(wrappedByForm)
