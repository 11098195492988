import { Dispatch } from 'redux'
import { axiosGet } from '../rest'
import { Impact } from './model'
import { GetAllImpactSuccessEvent } from './state-event'

export const getAllImpact = () => {
    return async (dispatch: Dispatch) => {
        try {
            // const params = {
            //     incidentId: incidentId
            // }
            const response = await axiosGet<Impact[]>('/api/incident/priorityLevel/impact')
            dispatch(GetAllImpactSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}
