/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react'
import {FormComponentProps} from 'antd/lib/form'
import {workOrder} from '../wo-management'
import {
    Form, Icon, Row, Col, Input, Select, Button, message, AutoComplete
} from 'antd'
import moment from 'moment'
import {PMHistory, preventive, preventiveChangeHistory} from './model'
import {
    createPreventive,
    findBySerialNoOrKbankBarcode,
    getAssetFilter,
    searchFetch,
    updatePreventive,
    AssetOwnerLocation,
    status, createPreventiveChangeHistory,
    getPreventiveChangeHistory
} from '../asset-management'
import {decryptDataVspace} from '../../common-misc'
import {StoreState} from '../../store'
import {connect} from 'react-redux'

const mapStateToProps = (state: StoreState) => {
    return {
        filterAsset: state.filterAsset
    }
}
type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAssetFilter: () => Promise<number>
}

interface Param {
    data?: workOrder
    isUpdatePM: boolean,
    setIsUpdatePM: Function,
    serialNo: string
    dataPM?: PMHistory
}
const {Option} = Select
type Props = Param & FormComponentProps & DispatchProps & StateProps
const PreventiveForm: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, getFieldValue, setFieldsValue} = props.form
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [vSpaceName, setVspaceName] = useState<string>('')
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [originalAsset, setOriginalAsset] = useState<AssetOwnerLocation>()
    const [deptName, setDeptName] = useState<string[]>(props.filterAsset.deptName?.slice(0, 20) || [])
    const [employeeID, setEmployeeID] = useState<string[]>(props.filterAsset.employeeID?.slice(0, 20) || [])
    const [location, setLocation] = useState<string[]>(props.filterAsset.location?.slice(0, 20) || [])
    const [computerName, setComputerName] = useState<string[]>(props.filterAsset.computerName?.slice(0, 20) || [])
    const isDataAdjust = getFieldValue('Reason') === 'Data Adjustment'

    useEffect(() => {
        loadFilter()
        fetchAssetData()
    }, [])

    useEffect(() => {
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            const email = dataVspace.email
            setVspaceName(email)
        }
        if (props.dataPM) {
            setIsEdit(true)
            if (props.dataPM.reason === 'Data Adjustment') {
                fetchPreventiveChangeHistory(props.dataPM.id)
            }
        }
    }, [props.dataPM])

    const fetchAssetData = () => {
        findBySerialNoOrKbankBarcode(props.serialNo).then((res) => {
            setOriginalAsset(res)
        }).catch((err) => {
            message.error(`You have unSuccessfully Get the data. ${err}`)
        })
    }

    const fetchPreventiveChangeHistory = (pmId) => {
        getPreventiveChangeHistory(pmId).then((res) => {
            setFieldFormPreventiveChangeHistory(res)
        }).catch((err) => {
            message.error(`You have unSuccessfully Get the data. ${err}`)
        })
    }

    const loadFilter = async () => {
        if (Object.keys(props.filterAsset).length === 0) {
            props.getAssetFilter().then(() => {
                setDeptName(props.filterAsset.deptName?.slice(0, 20) || [])
                setEmployeeID(props.filterAsset.employeeID?.slice(0, 20) || [])
                setLocation(props.filterAsset.location?.slice(0, 20) || [])
                setComputerName(props.filterAsset.computerName?.slice(0, 20) || [])
            })
        }
    }

    const setFieldFormPreventiveChangeHistory = (data: preventiveChangeHistory[]) => {
        data.forEach((item: preventiveChangeHistory) => {
            if (item.original) {
                setFieldsValue({
                    OriginalDeptName: item.deptName,
                    OriginalEmployeeID: item.employeeId,
                    OriginalLocation: item.location,
                    OriginalAssetStatus: item.assetStatus,
                    OriginalPhone: item.phone,
                    OriginalComputerName: item.computerName
                })
            } else {
                setFieldsValue({
                    AdjustDeptName: item.deptName,
                    AdjustEmployeeID: item.employeeId,
                    AdjustLocation: item.location,
                    AdjustAssetStatus: item.assetStatus,
                    AdjustPhone: item.phone,
                    AdjustComputerName: item.computerName
                })
            }
        })
    }

    const handleSubmit = (e: any): void => {
        e.preventDefault()
        props.form.validateFields((_err: any, values: any) => {
            if (!_err) {
                setIsLoading(true)
                const convertData: preventive = {
                    woId: props.data?.id!!,
                    serialNo: props.serialNo,
                    pmStatus: 'Completed',
                    engineerOnsite: values.EngineerOnSite,
                    engineerName: values.EngineerName,
                    reason: values.Reason,
                    note: values.Note,
                    location: props.data?.contactUserLocation!!,
                    createdBy: vSpaceName,
                    lastModifiedBy: vSpaceName
                }

                if (isEdit) {
                    updatePreventive(props.dataPM?.id!!, convertData).then(() => {
                        message.success('The Update has finished successfully.')
                        props.setIsUpdatePM(false)
                    }).catch((err) => {
                        message.error('You have unSuccessfully save the data.')
                        message.error(err)
                    })
                } else {
                    SavePM(convertData)
                }
            }
        })
    }

    const SavePM = async (data) => {
        // eslint-disable-next-line no-return-await
        return await createPreventive(data).then((res: preventive) => {
            if (res.id && isDataAdjust) {
                const promiseAll: Promise<number | void>[] = []
                const originalData: preventiveChangeHistory = {
                    pmId: Number(res.id),
                    serialNo: res.serialNo,
                    deptName: getFieldValue('OriginalDeptName'),
                    employeeId: getFieldValue('OriginalEmployeeID'),
                    location: getFieldValue('OriginalLocation'),
                    assetStatus: getFieldValue('OriginalAssetStatus'),
                    phone: getFieldValue('OriginalPhone'),
                    computerName: getFieldValue('OriginalComputerName'),
                    original: true,
                    createdBy: data.createdBy,
                    lastModifiedBy: data.lastModifiedBy
                }
                promiseAll.push(
                    createPreventiveChangeHistory(originalData).catch((err) => {
                        message.error(`You have unSuccessfully save the data. ${err.response.message}`)
                    })
                )
                const adjustData: preventiveChangeHistory = {
                    pmId: Number(res.id),
                    serialNo: res.serialNo,
                    deptName: getFieldValue('AdjustDeptName'),
                    employeeId: getFieldValue('AdjustEmployeeID'),
                    location: getFieldValue('AdjustLocation'),
                    assetStatus: getFieldValue('AdjustAssetStatus'),
                    phone: getFieldValue('AdjustPhone'),
                    computerName: getFieldValue('AdjustComputerName'),
                    original: false,
                    createdBy: data.createdBy,
                    lastModifiedBy: data.lastModifiedBy
                }
                promiseAll.push(
                    createPreventiveChangeHistory(adjustData).catch((err) => {
                        message.error(`You have unSuccessfully save the data. ${err.response.message}`)
                    })
                )

                Promise.all(promiseAll).finally(() => {
                    setIsLoading(false)
                    message.success('You have successfully saved the data.')
                    props.setIsUpdatePM(false)
                })
            } else {
                setIsLoading(false)
                message.success('You have successfully saved the data.')
                props.setIsUpdatePM(false)
            }
        }).catch(() => {
            message.error('You have unSuccessfully save the data.')
            setIsLoading(false)
        })
    }

    const dataAdjustmentForm = () => {
        return (
            <div>
                <Row gutter={8}>
                    <Col span={12} xs={24} lg={12}>
                        <h3 className={'sub-title'}>Original Data</h3>
                        {customSelectWithFilter('Dept Name', 'OriginalDeptName', true, originalAsset?.deptName)}
                        {customInput('Employee ID', 'OriginalEmployeeID', true, originalAsset?.employeeId)}
                        {customSelectWithFilter('Location', 'OriginalLocation', true, originalAsset?.location)}
                        {customSelect('Asset Status', 'OriginalAssetStatus', status, true, originalAsset?.status)}
                        {customInput('Phone', 'OriginalPhone', true, originalAsset?.phone)}
                        {customInput('Computer Name', 'OriginalComputerName', true, originalAsset?.computerName)}
                    </Col>
                    <Col span={12} xs={24} lg={12}>
                        <h3 className={'sub-title'}>Adjust Data</h3>
                        {customSelectWithFilter('Dept Name', 'AdjustDeptName', isEdit, null, 255, deptName, props.filterAsset.deptName, setDeptName)}
                        {customInput('Employee ID', 'AdjustEmployeeID', isEdit, null, 15, employeeID, props.filterAsset.employeeID, setEmployeeID)}
                        {customSelectWithFilter('Location', 'AdjustLocation', isEdit, null, 255, location, props.filterAsset.location, setLocation)}
                        {customSelect('Asset Status', 'AdjustAssetStatus', status, isEdit)}
                        {customInput('Phone', 'AdjustPhone', isEdit)}
                        {customInput('Computer Name', 'AdjustComputerName', isEdit, null, 15, computerName, props.filterAsset.computerName, setComputerName)}
                    </Col>
                </Row>
            </div>
        )
    }

    const customSelectWithFilter = (name: string,
        key: string,
        isDisable: boolean = false,
        initialValue?: any,
        maxInput?: number,
        autoCompleteDataSource?: string[],
        fullData?: string[],
        setState?: Function,
        required: boolean = false) => {
        return (
            <Form.Item label={name}>
                {getFieldDecorator(key,
                    {
                        initialValue: initialValue || undefined,
                        rules: [{required: required, whitespace: true, message: `${name} is required`}]
                    })(
                    <Select placeholder={`${name}`}
                        onSearch={(value) => {
                            searchFetch(value, fullData || [], setState!!)
                        }}
                        disabled={isDisable}
                        allowClear={true}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(inputValue, option) =>
                            (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    >
                        {(autoCompleteDataSource || []).map((item, index) => {
                            return <Option value={item} key={index} title={item}>{item}</Option>
                        })}
                    </Select>
                )}
            </Form.Item>
        )
    }

    const customInput = (name: string,
        key: string,
        isDisable: boolean = false,
        initialValue?: any,
        maxInput?: number,
        autoCompleteDataSource?: string[],
        fullData?: string[],
        setState?: Function,
        required: boolean = false) => {
        return (
            <Form.Item label={name}>
                {getFieldDecorator(key,
                    {
                        initialValue: initialValue,
                        rules: [{required: required, whitespace: true, message: `${name} is required`}]
                    })(
                    autoCompleteDataSource ? <AutoComplete
                        disabled={isDisable}
                        dataSource={autoCompleteDataSource}
                        onChange={(value) => {
                            searchFetch(value, fullData || [], setState!!)
                        }}
                        placeholder={name}
                        filterOption={(inputValue, option) =>
                            (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    >
                        <Input type="text" maxLength={maxInput}/>
                    </AutoComplete> : <Input type="text" maxLength={maxInput} placeholder={name} disabled={isDisable}/>
                )}
            </Form.Item>
        )
    }

    const customSelect = (name: string, key: string, options: string[], isDisable: boolean = false, initValue?: any) => {
        return (
            <Form.Item label={name}>
                {getFieldDecorator(key, {
                    initialValue: initValue
                })(
                    <Select
                        placeholder='select status'
                        style={{width: '100%'}}
                        disabled={isDisable}
                        allowClear
                    >
                        {options.map((data) => {
                            return (<Option key={data}>{data}</Option>)
                        })}
                    </Select>
                )}
            </Form.Item>
        )
    }

    return (
        <div>
            <Row>
                <Col style={{
                    margin: 'auto',
                    width: window.innerWidth < 768 ? '100%' : '80%',
                    padding: 10,
                    minWidth: 200
                }}>
                    <span><Icon
                        type="unordered-list"/> <span>Order ID :{isEdit ? props.dataPM?.woNum : props.data?.number}</span></span>
                    <Form layout='vertical' style={{padding: 10}} onSubmit={handleSubmit}>
                        <Form.Item label="PM Team">
                            {getFieldDecorator('EngineerOnSite',
                                {
                                    initialValue: isEdit ? props.dataPM?.engineerOnsite!! : undefined,
                                    rules: [{required: true, whitespace: true, message: 'PM Team is required'},
                                        {min: 3, message: 'Enter more than 3 characters'}]
                                })(
                                <Input type="text" placeholder="PM Team" maxLength={255} disabled={isEdit}/>
                            )}
                        </Form.Item>
                        <Form.Item label="PM Name">
                            {getFieldDecorator('EngineerName',
                                {
                                    initialValue: isEdit ? props.dataPM?.engineerName!! : undefined,
                                    rules: [{required: true, whitespace: true, message: 'PM Name is required'},
                                        {min: 3, message: 'Enter more than 3 characters'}]
                                })(
                                <Input type="text" placeholder="PM Name" maxLength={255} disabled={isEdit}/>
                            )}
                        </Form.Item>
                        <div><span>Date: {moment().format('YYYY-MM-DD')}</span></div>
                        <div><span>PM Status : Completed</span></div>
                        <Form.Item label="Reason">
                            {getFieldDecorator('Reason', {
                                initialValue: isEdit ? props.dataPM?.reason!! : undefined,
                                rules: [{
                                    required: true
                                }]
                            })(
                                <Select placeholder="Select an Reason" allowClear disabled={isEdit}>
                                    <Option value="Add New Asset" key='1'>Add new asset</Option>
                                    <Option value="Data Adjustment" key='2'>Data adjustment</Option>
                                    <Option value="No Data Adjust" key='3'>No data adjust</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <br/>
                        {isDataAdjust ? dataAdjustmentForm() : null}
                        <Form.Item label="Note">
                            {getFieldDecorator('Note',
                                {
                                    initialValue: isEdit ? props.dataPM?.note!! : undefined
                                })(
                                <Input type="text" placeholder="Note" maxLength={250}/>
                            )}
                        </Form.Item>
                        <Form.Item style={{float: 'right', marginTop: 10}}>
                            <Button htmlType="button" style={{marginRight: 10}}
                                onClick={() => props.setIsUpdatePM(false)}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" loading={isLoading}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

const PreventiveFormWithForm = Form.create<Props>({})(PreventiveForm)
export default connect(mapStateToProps, {getAssetFilter})(PreventiveFormWithForm)
