import React from 'react'
import moment from 'moment'
import {durationAsString} from '../../common-time'

export enum RunningClockMode {
    TimeLeft = 'TimeLeft',
    TimeElapsed = 'TimeElapsed'
}

interface Props {
    prefixProvider?: () => string | null
    durationProvider?: () => moment.Duration
    referencedTimePoint?: string | moment.Moment
    wordingWhenCountDownToZero?: string
    mode: RunningClockMode
}

interface State {
    text: string | undefined
    prefix: string | undefined
    timeIsAlreadyUp: boolean
}

class RunningClock extends React.Component<Props, State> {
    timeout?: NodeJS.Timeout

    constructor(props: Props) {
        super(props)
        this.state = {
            text: undefined,
            prefix: undefined,
            timeIsAlreadyUp: false
        }
    }

    setText(value: string | undefined) {
        this.setState({...this.state, text: value})
    }

    setPrefix(value: string | undefined) {
        this.setState({...this.state, prefix: value})
    }

    setTimeIsAlreadyUp(value: boolean) {
        this.setState({...this.state, timeIsAlreadyUp: value})
    }

    determineTimeIsAlreadyUp(): boolean {
        return moment().isAfter(moment(this.props.referencedTimePoint))
    }

    componentDidMount(): void {
        const _this = this
        const f = function() {
            const prefixProvider = _this.props.prefixProvider
            let durationProvider = _this.props.durationProvider
            if (!durationProvider) {
                if (_this.props.mode === RunningClockMode.TimeElapsed) {
                    durationProvider = function() { return moment.duration(moment().diff(moment(_this.props.referencedTimePoint))) }
                } else {
                    _this.setTimeIsAlreadyUp(_this.determineTimeIsAlreadyUp())
                    durationProvider = function() { return moment.duration(moment(_this.props.referencedTimePoint).diff(moment())) }
                }
            }
            _this.setPrefix((prefixProvider ? prefixProvider!!() : null) || '')
            const textToBe = durationAsString(durationProvider!!(), true)
            if (textToBe !== _this.state.text) {
                _this.setText(textToBe)
            }
            _this.timeout = setTimeout(f, 1000)
        }
        f()
    }

    componentWillUnmount(): void {
        const timeout = this.timeout
        if (timeout != null) {
            clearTimeout(timeout)
        }
    }

    buildTitle(): string {
        return `Count ${this.props.mode === RunningClockMode.TimeElapsed ? 'up from' : 'down to'} ${moment(this.props.referencedTimePoint).format('YYYY-MM-DD HH:mm:ss')}`
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <span title={this.buildTitle()}>
                {this.state.timeIsAlreadyUp ? (this.props.wordingWhenCountDownToZero || '0 secs') : `${this.state.prefix || ''}${this.state.prefix ? ' ' : ''}${this.state.text || ''}`}
            </span>
        )
    }
}

export default RunningClock
