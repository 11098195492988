import { Dispatch } from 'redux'
import {Incident, TicketStatus} from './model'
import { IncidentFilter } from '../incident-view'
import {
    IncidentAddedEvent,
    SearchIncidentsSuccessEvent,
    IncidentUpdatedEvent,
    GetAllIncidentSuccessEvent
} from './state-event'
import { IncidentTableTotalDataEvent } from '../../store/state-event'
import { axiosGet, axiosPatch, axiosPost } from '../rest'
import { IncidentWebSocketChannel } from './web-socket-channel'

interface IncidentSearchResult {
    incidents: Incident[],
    total: number
}

export const searchIncidents = (criteria: IncidentFilter, sortBy: string, orderBy: string, skip?: number, limit?: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<IncidentSearchResult>('/api/incident/incidents/search?sortBy=' + sortBy + '&orderBy=' + orderBy + '&skip=' + skip?.toString() + '&limit=' + limit?.toString(), criteria)
            const searchResult: IncidentSearchResult = response.data.data!!
            dispatch(IncidentTableTotalDataEvent.build(searchResult.total))
            dispatch(SearchIncidentsSuccessEvent.build(searchResult.incidents))
            IncidentWebSocketChannel.subscribeToIncidentIds(searchResult.incidents.map((it) => it.id!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export async function getIncidentById(id: number): Promise<Incident> {
    try {
        const res = await axiosGet<Incident>(`/api/incident/incidents/${id}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getPrefilledNewIncident(): Promise<Incident> {
    try {
        const res = await axiosGet<Incident>('/api/incident/incidents/getPrefilledNewIncident')
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const addIncident = (incident: Incident) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<Incident>('/api/incident/incidents', incident)
            dispatch(IncidentAddedEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const updateIncident = (patch: Incident) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            delete patch.id
            const response = await axiosPatch<Incident>(`/api/incident/incidents/${id}`, patch)
            dispatch(IncidentUpdatedEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export async function updateIncidentStatus(
    id: number, ticketStatus: TicketStatus, pendingReason: string, pendingUntil: string
): Promise<Incident> {
    try {
        const res = await axiosPatch<Incident>(`/api/incident/incidents/${id}`, {ticketStatus: ticketStatus, pendingReason, pendingUntil})
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function markIncidentViewed(id: any): Promise<Incident> {
    try {
        const res = await axiosPatch<Incident>(`/api/incident/incidents/${id}`, {viewed: true})
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getAllIncident = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Incident[]>('/api/incident/incidents')
            dispatch(GetAllIncidentSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}
