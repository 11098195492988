import { buildStateEvent, GenericStateEvent } from '../common-redux'
import { CustomFileds } from './model'

export interface CustomFiledsStateEvent<T> extends GenericStateEvent<T> { }

export const GetAllCustomFiledsSuccessEvent = {
    type: 'GetAllCustomFiledsSuccessEvent',
    build: (payload: CustomFileds) => buildStateEvent(GetAllCustomFiledsSuccessEvent.type, payload)
}

export const AddCustomFiledsSuccessEvent = {
    type: 'AddCustomFiledsSuccessEvent',
    build: (payload: CustomFileds) => buildStateEvent(AddCustomFiledsSuccessEvent.type, payload)
}

export const DeleteCustomFiledsSuccessEvent = {
    type: 'DeleteCustomFiledsSuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteCustomFiledsSuccessEvent.type, payload)
}

export const UpdateCustomFiledsSuccessEvent = {
    type: 'UpdateCustomFiledsSuccessEvent',
    build: (payload: CustomFileds) => buildStateEvent(UpdateCustomFiledsSuccessEvent.type, payload)
}
