import { Dispatch } from 'redux'
import { axiosGet } from '../rest'
import { Status } from './model'
import { GetAllStatusSuccessEvent } from './state-event'
import {TicketStatus} from '../incident';

export const getAllStatus = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<TicketStatus[]>('/api/incident/statuses/Incident')
            dispatch(GetAllStatusSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}

export const getTicketStatus = (ticketStatus: TicketStatus[], status: string):TicketStatus => {
    const target = ticketStatus.find(it => it.status === status)!!
    return target
}

