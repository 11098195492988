import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { Modal } from 'antd'
import { SelectValue } from 'antd/es/select'
import { MouseEventHandler } from 'react'

const wrapForCheckbox = <T extends unknown>(handler: ((value: Array<T>) => void)): ((value: Array<CheckboxValueType>) => void) => {
    return (checkedValue: Array<CheckboxValueType>) => {
        handler(checkedValue.map((it) => it as T))
    }
}

const wrapForSelect = <T extends unknown>(handler: ((value: T) => void)): ((value: SelectValue) => void) => {
    return (value: SelectValue) => {
        handler(value as T)
    }
}

export class Checkbox {
    static wrap = wrapForCheckbox
}

export class Select {
    static wrap = wrapForSelect
}

export const handleUnimplementedLink: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault()
    Modal.error({title: 'Not implemented!!', content: 'หน้านี้ยังไม่ถูกพัฒนา'})
}
