import { buildStateEvent, GenericStateEvent } from '../../common-redux'
import { KnowledgeList } from './model'

export interface KnowledgeStateEvent<T> extends GenericStateEvent<T> {}

export interface GetAllKnowledgeSuccessEvent extends KnowledgeStateEvent<KnowledgeList> {}

export const GetAllKnowledgeSuccessEvent = {
    type: 'GetAllKnowledgeSuccessEvent',
    build: (payload: KnowledgeList) => buildStateEvent(GetAllKnowledgeSuccessEvent.type, payload)
}

export const DeleteKnowledgeSuccessEvent = {
    type: 'DeleteKnowledgeSuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteKnowledgeSuccessEvent.type, payload)
}
