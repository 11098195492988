import {BaseDocument} from '../../common-model'

export const status = ['Disposed', 'Expired', 'In Repair', 'In Store', 'Instore Spare', 'In Use', 'Inuse Loaned', 'Inuse Spare', 'Waiting for Repair', 'Waiting for Return', 'Write off']
export interface AssetOwnerLocation extends BaseDocument {
    serialNo?: string,
    kbankBarcode?: string,
    dateInService?: Date,
    expiryDate?: Date,
    invoiceDate?: Date,
    poNumber?: string,
    deviceType?: string,
    computerName?: string,
    brand?: string,
    model?: string,
    monitorType?: string,
    monitorBrand?: string,
    monitorSerial?: string,
    status?: string,
    employeeId?: string,
    company?: string,
    group?: string,
    deptName?: string,
    description?: string,
    phone?: string,
    city?: string,
    location?: string,
    zone?: string,
    country?: string,
    regional?: string,
    dataOrigin?: string,
    attachFile?: string,
    createdDatetime?: string,
    lastModifiedDatetime?: string
}

export interface FilterAsset {
    brand?: string[],
    city?: string[],
    computerName?: string[]
    deptName?: string[],
    deviceType?: string[],
    employeeID?: string[],
    location?: string[],
    model?: string[],
    status?: string[],
    zone?: string[]
}

export interface TicketAffectedAsset {
    ticketNumber: string,
    subject: string,
    lastWorklog: string,
    status: string,
    createdDatetime: Date,
    closedDatetime: Date,
    productCategorizationTier1: string,
    productCategorizationTier2: string,
    productCategorizationTier3: string,
    endUserContactName: string
}

export interface TicketChangeHistory {
    createdDatetime: Date,
    field: string,
    oldValue: string,
    newValue: string,
    createdBy: string,
}

export interface AssetOwnerLocationTotalData {
    data: AssetOwnerLocation[],
    total: number
}
