import { buildStateEvent, GenericStateEvent } from '../../../common-redux'
import { ResponseUserRole, Roles } from './model'

export interface UserRoleStateEvent<T> extends GenericStateEvent<T> { }
export interface RoleStateEvent<T> extends GenericStateEvent<T> { }

export const USER_ROLES_DATA_SHOW = {
    type: 'USER_ROLES_DATA_SHOW',
    build: (payload: ResponseUserRole[]) => buildStateEvent(USER_ROLES_DATA_SHOW.type, payload)
}

export const USER_ROLES_DATA_ADD = {
    type: 'USER_ROLES_DATA_ADD',
    build: (payload: ResponseUserRole[]) => buildStateEvent(USER_ROLES_DATA_ADD.type, payload)
}

export const USER_ROLES_DATA_EDIT = {
    type: 'USER_ROLES_DATA_EDIT',
    build: (payload: ResponseUserRole) => buildStateEvent(USER_ROLES_DATA_EDIT.type, payload)
}

export const USER_ROLES_DATA_DELETE = {
    type: 'USER_ROLES_DATA_DELETE',
    build: (payload: string) => buildStateEvent(USER_ROLES_DATA_DELETE.type, payload)
}

export const ROLES_DATA_SHOW = {
    type: 'ROLES_DATA_SHOW',
    build: (payload: Roles[]) => buildStateEvent(ROLES_DATA_SHOW.type, payload)
}

export const UpdateUserRoleTotalPageSuccessEvent = {
    type: 'UpdateUserRoleTotalPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdateUserRoleTotalPageSuccessEvent.type, payload)
}
export const UpdateUserRoleCurrentPageSuccessEvent = {
    type: 'UpdateUserRoleCurrentPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdateUserRoleCurrentPageSuccessEvent.type, payload)
}
