import {Dispatch} from 'redux'
import {axiosGet} from '../rest'
import {GetAllUrgencySuccessEvent} from './state-event'
import {PriorityLevel} from '../priority'

export const getAllUrgency = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<PriorityLevel[]>('/api/incident/priorityLevel/urgency')
            dispatch(GetAllUrgencySuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}
