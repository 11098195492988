import {buildStateEvent, GenericStateEvent} from '../../../common-redux'
import { ChargingGroup } from './Model'

export interface costStateEvent<T> extends GenericStateEvent<T> {}

export const GetChargingSuccessEvent = {
    type: 'GetChargingSuccessEvent',
    build: (payload: ChargingGroup[]) => buildStateEvent(GetChargingSuccessEvent.type, payload)
}

export const DeleteChargingSuccessEvent = {
    type: 'DeleteChargingSuccessEvent',
    build: (payload: String) => buildStateEvent(DeleteChargingSuccessEvent.type, payload)
}
