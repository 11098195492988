import { DBSchema, openDB } from 'idb';

// export const idb = {
//   db1: openDB("msp_sos", 1),
// };

interface MyDB extends DBSchema {
  'pm': {
    key: number;
    value: any;
  };
}

export async function createDB() {
  const db = await openDB<MyDB>('msp_sos', 1, {
    upgrade(db) {
      db.createObjectStore('pm', { keyPath: 'id' });
    },
  });

  // This works
  // await db.add('pm', { id: 'cat001', strength: 11, speed: 9 }, Math.random());
}



// export async function clearDB() {
//   (await idb.db1).clear('workOrder')
// }

export async function TestaddData() {
  const db1 = await openDB<MyDB>('msp_sos', 1);
  // table , value, key
  if (db1.objectStoreNames.length !== 0) {
    db1.count('pm').then(res => {
      if (res === 0) {
        db1.add('pm', { id: 'cat002', strength: 11, speed: 9 }, Math.random()).then(result => {
          console.log('success!', result);
        }).catch(err => {
          console.error('error: ', err);
        });
      }
    })
  }
}

export async function TestgetData() {
  const db1 = await openDB<MyDB>('msp_sos', 1);
  // table , value, key
  if (db1.objectStoreNames.length !== 0) {
    db1.count('pm').then(res => {
      if (res > 0) {
        db1.getAll('pm').then(console.log).catch(() => {
          console.error('Something went wrong, transaction aborted');
        })
      }
    })
  }
}

// export async function addDataToWorkOrderIdb(key, value) {
//   (await idb.db1).add("workOrder", value, key).then(result => {
//     console.log('success!', result);
//   })
//     .catch(err => {
//       console.error('error: ', err);
//     });
// }

// export async function InitData() {
//   const db1 = await openDB('msp_sos', 1);
//   db1.count('workOrder').then(res => {
//     if (res === 0) {
//       var transaction = db1.transaction(["workOrder"], 'readwrite');
//       transaction.db.add('workOrder', { id: 'cat001', strength: 10, speed: 10 })
//       transaction.done
//         .then(() => {
//           console.log('All steps succeeded, changes committed!');
//         })
//         .catch(() => {
//           console.error('Something went wrong, transaction aborted');
//         });
//       // db1.add('workOrder', { id: 'cat002', strength: 11, speed: 9 });
//       // db1.add('workOrder', { id: 'cat003', strength: 8, speed: 12 });
//       // db1.add('workOrder', { id: 'cat004', strength: 12, speed: 13 });
//     }
//   })
// }

// export async function getDaTaWorkOrderIdb() {
//   const db1 = await openDB('msp_sos', 1);
//   // retrieve by key:
//   db1.get('workOrder', 'cat001').then(console.log).catch(() => {
//     console.error('Something went wrong, transaction aborted');
//   });
//   // retrieve all:
//   db1.getAll('workOrder').then(console.log).catch(() => {
//     console.error('Something went wrong, transaction aborted');
//   });

//   // count the total number of items in a store:
//   db1.count('workOrder').then(console.log).catch(() => {
//     console.error('Something went wrong, transaction aborted');
//   });
//   // get all keys:
//   db1.getAllKeys('workOrder').then(console.log).catch(() => {
//     console.error('Something went wrong, transaction aborted');
//   });
// }



// export async function EditDaTaWorkOrderIdb(id) {
//   // replace cat001 with a supercat:
//   const db2 = await openDB('msp_sos', 1);
//   db2.put('workOrder', { id: 'cat001', strength: 99, speed: 99 }, id);
//   db2.close();
// }

// export async function deleteDaTaWorkOrderIdb(id) {
//   (await idb.db1).delete('workOrder', id).then(result => {
//     console.log('success!', result);
//   })
//     .catch(err => {
//       console.error('error: ', err);
//     });
// }