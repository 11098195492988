import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { decryptDataVspace } from '../common-misc'

const params = new URLSearchParams(window.location.search)
const data = params.get('data')
const incidentNo = params.get('incidentNo')

const isHasPermission = (permission: string): boolean => {
    const result = localStorage.getItem('role')
    let objRole = {}
    if (result) {
        objRole = JSON.parse(result)
    }
    const arrKey = Object.keys(objRole)
    let isAuthorize: boolean = false
    arrKey.map(o => {
        (objRole[o] || []).map(it => {
            if (it.permissions.includes(permission)) {
                isAuthorize = true
            }
        })
    })
    return isAuthorize
}

const isAuthorizePath = (path: string): boolean => {
    if (path.includes('UserSetting')) {
        return isHasPermission('ManageUserRole')
    }
    if (path.includes('UserSettingForm')) {
        return isHasPermission('ManageUserRole')
    }
    if (path.includes('GlobalSetting')) {
        return isHasPermission('ManageSlaGlobalSettings')
    }
    if (path.includes('SlaPolicySettingList')) {
        return isHasPermission('ManageSlaPolicy')
    }
    if (path.includes('SlaPolicySettingForm')) {
        return isHasPermission('ManageSlaPolicy')
    }
    if (path.includes('BusinessHourSettingList')) {
        return isHasPermission('ManageBusinessHours')
    }
    if (path.includes('BusinessHourSettingForm')) {
        return isHasPermission('ManageBusinessHours')
    }
    if (path.includes('RoleAndPermissionsSettingList')) {
        return isHasPermission('ManageRoleAndPermission')
    }
    if (path.includes('RoleAndPermissionsSettingForm')) {
        return isHasPermission('ManageRoleAndPermission')
    }
    if (path.includes('IncidentList')) {
        return isHasPermission('ViewIncident')
    }
    if (path.includes('KnowledgeForm')) {
        return isHasPermission('CreateKnowledge')
    }
    if (path.includes('IncidentForm')) {
        return isHasPermission('CreateIncident')
    }
    if (path.includes('IncidentDetailsView')) {
        return isHasPermission('UpdateIncident')
    }
    if (path.includes('ApprovalCenter')) {
        return isHasPermission('ApproveOrRejectKnowledge')
    }
    if (path.includes('Knowledge')) {
        return isHasPermission('ViewKnowledge')
    }

    return true
}

function checkPath(path: string, word: string): boolean {
    return path.includes(word)
}

export const PrivateRoute = ({ component: Component, auth, ...rest }: any) => (
    <Route {...rest} render={(props: any) => (
        <React.Suspense fallback={<div />}>
            {isAuthorizePath(props.location.pathname) ? null : window.location.href = '/403'}
            {
                localStorage.getItem('mspUser') === '1' ? <Component {...props} /> : <Redirect to="/" />
            }
        </React.Suspense>
    )} />
)

const validateUserVspace = (param): boolean => {
    let check = false
    if (localStorage.getItem('scrollHeightTo1000')) {
        localStorage.removeItem('scrollHeightTo1000')
    }
    if (param.location.search) {
        if (param.location.search.substring(0, 12) === '?datamoblie=') {
            const getDataMobile = decodeURI(param.location.search.substring(12, param.length))
            if (getDataMobile) {
                localStorage.setItem('dataVspace', getDataMobile)
                check = true
            }
        } else {
            if (data) {
                localStorage.setItem('dataVspace', decodeURI(data!!))
            }
            check = true
        }
        let pathName = param.location.pathname
        if (incidentNo) {
            pathName += `?incidentNo=${incidentNo}`
        }
        window.history.replaceState({}, 'title', pathName)
    }
    return check
}

export const PrivateRouteForVspace = ({ component: Component, auth, ...rest }: any) => (
    <Route {...rest} render={(props: any) => (
        <React.Suspense fallback={<div />}>
            {
                validateUserVspace(props) || decryptDataVspace() || window.location.pathname.includes('vSpaceLogin') ? <Component {...props} /> : <Redirect to={`/vSpaceLogin?redirect=${window.location.pathname.slice(1)}`} />
            }
        </React.Suspense>
    )} />
)

export const PrivateRouteKM = ({ component: Component, auth, ...rest }: any) => (
    <Route {...rest} render={(props: any) => (
        <React.Suspense fallback={<div />}>
            {isAuthorizePath(props.location.pathname) ? null : window.location.href = '/403'}
            {
                validateUserVspace(props) || decryptDataVspace() || localStorage.getItem('mspUser') === '1' ? <Component {...props} /> : <Redirect to={`/vSpaceLogin?redirect=${window.location.pathname.slice(1)}`} />
            }
        </React.Suspense>
    )} />
)
