import React from 'react'
import UserRoleList from './component/UserRoleList'
import { Breadcrumb, Card, Row, Col, Button } from 'antd'
import { Link } from 'react-router-dom'

interface parem {
}

type Props = parem

const UserRole: React.FC<Props> = (props: Props) => {
    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>User</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Card>
                <Row>
                    <Col span={23}>
                        <h3 className={'main-title'}>User</h3>
                    </Col>
                    <Col span={1}>
                        <Button type="primary"><Link to="/UserSettingForm">Create</Link></Button>
                    </Col>
                </Row>
                <br />
                <UserRoleList />
            </Card>
        </>
    )
}

export default UserRole
