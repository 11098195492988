import axios from 'axios'

export function axiosFactory(baseURL: string) {
    return () => axios.create({
        baseURL: baseURL,
        headers: {
            username: localStorage.getItem('username') || '',
            userId: localStorage.getItem('userId') || 0,
            // Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            // id_token: localStorage.getItem('id_token'),
            'Return-Updated-Document': true
        }
    })
}
