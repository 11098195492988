import React from 'react'

import {SlaCalculation, SlaCalculationFunc, SlaStage, SlaStatus} from '../../sla-management/sla-calculation/model'
import {IncidentWithSlaCalculations} from './model'
import {SlaGoal} from '../../sla-management/sla-policy/model'
import RunningClock, {RunningClockMode} from '../sla/RunningClock'
import {timeDiffAsString} from '../../common-time'
import moment from 'moment'

interface Params {
    incident: IncidentWithSlaCalculations
    slaCalculation: SlaCalculation
    slaGoal: SlaGoal
}

type Props = Params

interface State {
    isSoon: boolean
}

class SlaTimeUsedUpPercentage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isSoon: false
        }
    }

    getIsSoon(): boolean {
        return this.state.isSoon
    }

    setIsSoon(value: boolean) {
        this.setState({...this.state, isSoon: value})
    }

    matchStageAndStatus(stage: SlaStage, status: SlaStatus): boolean {
        return this.props.slaCalculation.slaStage === stage && this.props.slaCalculation.slaStatus === status
    }

    renderIfInProgressMet(): JSX.Element | null {
        if (!this.matchStageAndStatus(SlaStage.InProgress, SlaStatus.Met)) {
            return null
        }
        const _this = this
        const timeLeftProvider = function (): moment.Duration {
            return SlaCalculationFunc.calculateInProgressTimeLeftDuration(_this.props.slaCalculation, _this.props.slaGoal)
        }
        const prefixProvider = function (): string {
            const timeGoal = SlaCalculationFunc.calculateSlaGoalTargetTime(_this.props.slaGoal)
            const percent = timeGoal.asSeconds() > 0 ? (timeLeftProvider().asSeconds() / timeGoal.asSeconds() * 100) : 100
            const soon = percent < 20
            _this.setIsSoon(soon)
            return `Due${soon ? ' soon' : ''} in next`
        }
        return (
            <span style={{color: '#4F4F4F'}}>
                <span style={{color: this.getIsSoon() ? '#ED9E28' : '#2F80ED', fontSize: '18px'}}>• </span>
                <RunningClock prefixProvider={prefixProvider} durationProvider={timeLeftProvider} mode={RunningClockMode.TimeLeft}/>
            </span>
        )
    }

    renderIfInProgressMissed(): JSX.Element | null {
        if (!this.matchStageAndStatus(SlaStage.InProgress, SlaStatus.Missed)) {
            return null
        }
        const _this = this
        const durationMissedProvider = function (): moment.Duration {
            return SlaCalculationFunc.calculateInProgressMissedDuration(_this.props.slaCalculation, _this.props.slaGoal)
        }
        return (
            <span style={{color: '#4F4F4F'}}>
                <span style={{color: '#EB5757', fontSize: 18}}>• </span>
                <span>Missed </span>
                <RunningClock durationProvider={durationMissedProvider} mode={RunningClockMode.TimeElapsed}/>
            </span>
        )
    }

    renderIfCompletedMissed(): JSX.Element | null {
        if (!this.matchStageAndStatus(SlaStage.Completed, SlaStatus.Missed)) {
            return null
        }
        const _this = this
        return (
            <span style={{color: '#4F4F4F'}}>
                <span style={{
                    color: '#726E6D',
                    fontSize: 18
                }}>• </span>
                <span>Overdue </span>
                {timeDiffAsString(_this.props.slaCalculation.slaTargetTime, _this.props.slaCalculation.measurementClockStopTime!!, true)}
            </span>
        )
    }

    renderIfCompletedMet(): JSX.Element | null {
        if (!this.matchStageAndStatus(SlaStage.Completed, SlaStatus.Met)) {
            return null
        }
        return (
            <span style={{color: '#4F4F4F'}}><span style={{color: '#4DC977', fontSize: 18}}>• </span> Completed in time</span>
        )
    }

    render() {
        return (
            <>
                {this.renderIfInProgressMissed()}
                {this.renderIfInProgressMet()}
                {this.renderIfCompletedMissed()}
                {this.renderIfCompletedMet()}
                {/* {
                props.slaStage === 'Cancelled' && props.slaStatus === 'Cancelled'
                    ? (<Badge color="#4F4F4F" text={'Cancelled SLA'} />)
                    : null
                    <Badge color="#2F80ED" text={'Completed in time'} />
            } */}
            </>
        )
    }
}

export default SlaTimeUsedUpPercentage
