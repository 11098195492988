import React, {useEffect, useState} from 'react'
import {Button, Empty, Form, Input, Timeline} from 'antd'
import {FormComponentProps} from 'antd/lib/form'
import moment from 'moment'
import {WorklogResponse, WoWorklog} from '../Job/worklog'
import {StoreState} from '../../../store'
import {addWoWorkLog, getAllWorkLogByWoNo} from '../Job/worklog/service'
import {message} from 'antd/es'
import {connect} from 'react-redux'
import {Job} from '../Job'

interface Param {
    woNo?: string,
    itemJob: Job[]
    key: string
    permission: boolean
}

const mapStateToProps = (state: StoreState) => {
    return {
        workLog: state.jobWorkLog || []
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = Param & FormComponentProps & StateProps

const {TextArea} = Input
const Worklog: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, setFieldsValue, resetFields} = props.form
    const [woWorklog, setWoWorkLog] = useState<WorklogResponse[]>()

    useEffect(() => {
        fetchWorkLogData()
    }, [props.workLog, props.itemJob])

    useEffect(() => {
        resetFields()
    }, [props.key])

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                const worklog: WoWorklog = {
                    woNo: props.woNo,
                    messageLog: values.worklog
                }
                addWoWorkLog(worklog).then((res) => {
                    setWoWorkLog(res)
                    setFieldsValue({
                        worklog: undefined
                    })
                    message.success('Add Worklog has finished successfully.')
                }).catch((err) => {
                    message.error(`You have unSuccessfully save the data. ${err}`)
                })
            }
        })
    }

    const fetchWorkLogData = () => {
        if (props.woNo) {
            getAllWorkLogByWoNo(props.woNo).then((data) => {
                setWoWorkLog(data)
            }).catch((err) => message.error(`Failed loading initial work log data. ${err}`))
        }
    }

    const displayWorkLog = () => {
        const workLogElements: JSX.Element[] = []
        // eslint-disable-next-line react/prop-types
        if (woWorklog) {
            for (const workLog of woWorklog) {
                if (workLog.worklog) {
                    workLogElements.push(<Timeline.Item color={'green'}>
                        <p>{moment(workLog.create_datetime).format('YYYY-MM-DD HH:mm:ss')}</p>
                        <p>{` ${workLog.worklog}`}</p>
                    </Timeline.Item>)
                }

                // else {
                //     if (workLog.previousStatus !== null) {
                //         workLogElements.push(<Timeline.Item color={'green'}>
                //             <p>{moment(workLog.createdDate).format('YYYY-MM-DD HH:mm:ss') + `, ${workLog.createdBy}`}</p>
                //             <p>{`changed Status from ${workLog.previousStatus} to ${workLog.currentStatus}`}</p>
                //         </Timeline.Item>)
                //     } else {
                //         workLogElements.push(<Timeline.Item color={'green'}>
                //             <p>{moment(workLog.createdDate).format('YYYY-MM-DD HH:mm:ss') + `, ${workLog.createdBy}`}</p>
                //             <p>{`changed Status to ${workLog.currentStatus}`}</p>
                //         </Timeline.Item>)
                //     }
                // }
            }
        }
        return (
            <>
                <div style={{height: 500, width: '100%', backgroundColor: '#ffffff', overflowY: 'scroll', padding: 10}}>
                    {workLogElements.length !== 0 ? (
                        <Timeline style={{marginLeft: '40px', marginTop: '10px'}}>
                            {workLogElements.map((temps: any, i: number) => {
                                return (
                                    <div key={i}>{temps}</div>
                                )
                            })}
                        </Timeline>
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    )}
                </div>
            </>
        )
    }

    return (
        <div>
            <Form layout="vertical">
                <Form.Item label="Worklog">
                    {getFieldDecorator('worklog', {
                        rules: [{
                            required: true
                        }]
                    })(
                        <TextArea rows={4} placeholder="Worklog" maxLength={500} />
                    )}
                </Form.Item>
                <Form.Item style={{float: 'right', marginTop: 10}}>
                    {/* <Button htmlType="button" style={{marginRight: 10}}>
                        Cancel
                    </Button> */}
                    <Button onClick={handleSubmit} type="primary" htmlType="submit" style={{marginRight: 10}} disabled={props.permission}>
                         Add
                    </Button>
                </Form.Item>
            </Form>
            <br/>
            <div>
                {displayWorkLog()}
            </div>
        </div>
    )
}

const WorklogForm = Form.create<Props>({name: 'Worklog'})(Worklog)

export default connect(mapStateToProps, {})(WorklogForm)
