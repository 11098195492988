import {Dispatch} from 'redux'
import {axiosGet, axiosPatch, axiosPost} from '../../rest'
import {Job} from './model'
import {GetAllJobSuccessEvent, JobCreatedEvent, JobUpdatedEvent} from './state-event'

export const getAllJob = (woId?: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const params = {
                woId: woId
            }
            const response = await axiosGet<Job[]>('/api/job/relate', {params})
            dispatch(GetAllJobSuccessEvent.build(response.data.data!!))
            return response.data.data
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}

export const addJob = (job: Job[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<Job>('/api/job/list', job)
            dispatch(JobCreatedEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const checkIsCurrentJob = (index: number, data: Job[], expandedRow: Job) => {
    let currentJob = false
    let currentJobIndex = 0;
    let selectedIndex
    (data || []).map((job, index) => {
        if (job.id === expandedRow.id) {
            selectedIndex = index
        }
    });
    (data || []).map((job, jobIndex) => {
        if (job.jobStatus !== 'Closed' && currentJobIndex === jobIndex) {
            currentJobIndex = jobIndex
            if (currentJobIndex === selectedIndex) {
                currentJob = true
            }
        }
        if ((job.jobStatus === 'Closed' || job.jobStatus === 'Cancelled') && currentJobIndex === jobIndex) {
            currentJobIndex = currentJobIndex + 1
        }
    })
    return currentJob
}

export const getCurrentJobNo = (data: Job[]) => {
    let currentJobNo
    let currentJobIndex = 0;
    (data || []).forEach((job, jobIndex) => {
        if ((job.jobStatus !== 'Closed' && currentJobIndex === jobIndex) ||
            (job.jobStatus !== 'Rejected' && currentJobIndex === jobIndex) ||
            (job.jobStatus !== 'Cancelled' && currentJobIndex === jobIndex)) {
            currentJobIndex = jobIndex
            currentJobNo = job.jobNo
        }
        if ((job.jobStatus === 'Closed' || job.jobStatus === 'Rejected' || job.jobStatus === 'Cancelled') && currentJobIndex === jobIndex) {
            currentJobIndex = currentJobIndex + 1
        }
    })
    return currentJobNo
}

export async function updateJobStatus (id: string, job: Object) : Promise<Job> {
    try {
        const response = await axiosPatch<Job>(`/api/job/${id}`, {...job})
        // dispatch(JobUpdatedEvent.build(response.data.data!!))
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export async function updateJobSignature (id: string, Signature: Object) : Promise<Job> {
    try {
        const response = await axiosPatch<Job>(`/api/job/${id}`, Signature)
        // dispatch(JobUpdatedEvent.build(response.data.data!!))
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export async function cancelAllJobs (ids: string[]) : Promise<Job[]> {
    try {
        const response = await axiosPost<Job[]>(`/api/job/Cancel`, ids)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}
