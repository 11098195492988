import {
    CategoryStateEvent,
    GetAllCategorySuccessEvent,
    AddCategorySuccessEvent,
    AddCategoryByExcelSuccessEvent,
    DeleteCategorySuccessEvent,
    UpdateCategorySuccessEvent,
    UpdateCategoryCurrentPageSuccessEvent,
    UpdateCategoryTotalPageSuccessEvent,
    CategoryManagementStateEvent,
    DeleteAllCategorySuccessEvent
} from './state-event'
import { Category, CategoryManagementPagination } from './model'

export const categoryStateReducer = (state: Category[] = [], event: CategoryStateEvent<any>) => {
    if (event.type === GetAllCategorySuccessEvent.type) {
        return event.payload || []
    } else if (event.type === AddCategorySuccessEvent.type) {
        if (state) {
            return [...state!!, event.payload]
        } else {
            return [event.payload, ...state!!]
        }
    } else if (event.type === AddCategoryByExcelSuccessEvent.type) {
        return [...state, ...event.payload] || []
    } else if (event.type === DeleteCategorySuccessEvent.type) {
        return state.filter((it) => it.id !== event.payload)
    } else if (event.type === UpdateCategorySuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else if (event.type === DeleteAllCategorySuccessEvent.type) {
        const temp : Category[] = []
        state.forEach(it => {
            if (!(event.payload || []).includes(it)) {
                temp.push(it)
            }
        })
        state = temp
        return state
    } else {
        return state
    }
}

export const categoryManagementPaginationReducer = (state: CategoryManagementPagination = {}, event: CategoryManagementStateEvent<any>) => {
    if (event.type === UpdateCategoryTotalPageSuccessEvent.type) {
        state.total = event.payload
    } else if (event.type === UpdateCategoryCurrentPageSuccessEvent.type) {
        state.page = event.payload + 1
    } else {
        return state
    }
    return state
}
