import { axiosPost } from '../rest'
import axios from 'axios'
import { LoginRequestBody, LoginResponse, LoginVspaceRequestBody } from './model'

export async function loginMspWithVspaceToken(body: LoginRequestBody) {
    try {
        const response = await axiosPost<LoginResponse>('/api/token/loginMSPWithTokenVspace', body)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export async function loginVspace(body: LoginVspaceRequestBody) {
    try {
        const response = await axiosPost<LoginResponse>('/api/vspaceUser/login', body)
        return response.data.data!!
    } catch (err) {
        throw err.response
    }
}

export async function getIp() {
    try {
        const response = await axios.get('https://api.ipify.org?format=json')
        return response.data.ip as string
    } catch (err) {
        console.log(err)
        throw err
    }
}
