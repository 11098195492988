/* eslint-disable react/display-name */
import React from 'react'
import { PMHistory } from './model'
import {
    Table, Tooltip
} from 'antd'
import {convertCamelCaseToSentenceCase} from '../../knowledge-management/util'

interface Param {
    dataHistory: PMHistory[]
    getDataEdit: Function
}

type Props = Param
const TablePreventiveHistory: React.FC<Props> = (props: Props) => {
    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdDatetime',
            key: 'createdDatetime',
            render: (row) => <span>{(row as string).substring(0, 10)}</span>
        },
        {
            title: 'Order ID',
            dataIndex: 'woNum',
            key: 'woNum'
        },
        {
            title: 'Assignment group',
            key: 'assignGroup',
            dataIndex: 'assignGroup'
        },
        {
            title: 'Assigned to',
            key: 'assignee',
            dataIndex: 'assignee'
        },
        {
            title: 'PM Team',
            key: 'engineerOnsite',
            dataIndex: 'engineerOnsite'
        },
        {
            title: 'PM Name',
            key: 'engineerName',
            dataIndex: 'engineerName'
        },
        {
            title: 'Reason',
            key: 'reason',
            dataIndex: 'reason',
            render: (data: string) => (
                <>
                    <p style={{margin: 'unset'}}>{convertCamelCaseToSentenceCase(data)}</p>
                </>
            )
        },
        {
            title: 'Note',
            key: 'note',
            dataIndex: 'note',
            render: (data: string) => (
                <>
                    <div>
                        {data?.length > 25 ? (<>
                            <Tooltip placement="bottom" title={data}>
                                {data?.substring(0, 25) + ' ...'}
                            </Tooltip>
                        </>) : (<>{data}</>)}
                    </div>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (row) => <a href='#' onClick={() => handleEdit(row)}>Edit</a>
        }
    ]

    const handleEdit = (row) => {
        if (row) {
            props.getDataEdit(row)
        }
    }

    return (
        <div style={{overflow: 'auto'}}>
            <br/>
            <div className='main-title' style={{width: '100%'}}>Preventive Maintenance History</div>
            <br/>
            <Table rowKey='woNum' columns={columns} dataSource={props.dataHistory} pagination={false}/>
        </div>
    )
}

export default TablePreventiveHistory
