import {axiosGet, axiosGetWithPagination, axiosDelete, axiosPatch, axiosPost } from '../rest'
import {Assignee, SupportTeamManagement} from './model'
import {Dispatch} from 'redux'
import {
    AddSupportTeamSuccessEvent,
    DeleteSupportTeamSuccessEvent,
    GetAllSupportTeamSuccessEvent,
    UpdateSupportTeamCurrentPageSuccessEvent,
    UpdateSupportTeamSuccessEvent,
    UpdateSupportTeamTotalPageSuccessEvent
} from './state-event'

export const getSupportTeamsWithPagination = (page: number, pageSize: number, searchField: string, searchValue: string, activateFilter: boolean[], sortBy: string, orderBy: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const url = `/api/auth/supportTeams/getAllSupportTeam?page=${page}&pageSize=${pageSize}&searchField=${searchField}&searchValue=${searchValue}&activate=${activateFilter}&sortBy=${sortBy}&orderBy=${orderBy}`
            const response = await axiosGetWithPagination<SupportTeamManagement[]>(url)
            dispatch(UpdateSupportTeamCurrentPageSuccessEvent.build(page))
            dispatch(UpdateSupportTeamTotalPageSuccessEvent.build(response.data.data.totalElements))
            dispatch(GetAllSupportTeamSuccessEvent.build(response.data.data.content))
            return response.status
        } catch (err) {
            dispatch(GetAllSupportTeamSuccessEvent.build([]))
            console.log(err)
            throw err
        }
    }
}
export async function getAllSupportTeam() {
    try {
        const response = await axiosGet<SupportTeamManagement[]>('/api/auth/supportTeams')
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}
export async function getSupportTeamById(id: string) : Promise<SupportTeamManagement> {
    try {
        const response = await axiosGet<SupportTeamManagement>(`/api/auth/supportTeams/${id}`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}
export async function getUser(user: string) : Promise<Assignee[]> {
    if (!user) user = 'user'
    try {
        const response = await axiosGet<Assignee[]>(`/api/auth/supportTeams/getUser/${user}`)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const addSupportTeam = (s: SupportTeamManagement) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<SupportTeamManagement>('/api/auth/supportTeams', s)
            dispatch(AddSupportTeamSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err.response.data.message
        }
    }
}

export const editSupportTeam = (id: string, s) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPatch<SupportTeamManagement>(`/api/auth/supportTeams/update/${id}`, s)
            dispatch(UpdateSupportTeamSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err.response)
            throw err.response.data.message
        }
    }
}

export const deleteSupportTeam = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/auth/supportTeams/${id}`)
            dispatch(DeleteSupportTeamSuccessEvent.build(id))
            return response.status
        } catch (err) {
            console.log(err)
            throw err.response.data.message
        }
    }
}
