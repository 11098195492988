/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState } from 'react'
import { Checkbox, Col, Icon, Input, message, Modal, Row, Select, Switch, Table, Tooltip } from 'antd'
import { FetchingState, fetchWithState, SpinningFetcher } from '../../common-components'
import {
    Category,
    getAllCategoriesWithPagination,
    GetAllCategorySuccessEvent
} from '../../incident-management/category'
import { connect, useDispatch } from 'react-redux'
import { StoreState } from '../../store'
import { deleteCategory, updateCategory } from '../../incident-management/category/service'
import {encodeSpecialCharacter} from '../../common-misc'

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.categories,
        categoryManagementPagination: state.categoryManagementPagination
    }
}

interface Params {
    getAllCategory: (categories: string[]) => void
    handleOnClickInOtherPage: (boolean) => void
    getDataToEdit: (data: Category) => void
    getPage: (page: number) => void
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllCategoriesWithPagination: (page: number, pageSize: number, searchField?: string, searchValue?: string, active?: boolean[], sortBy?: string, orderBy?: string) => Promise<number>;
    deleteCategory: (id: string) => Promise<number>;
    updateCategory: (businessHour: Category) => Promise<number>;
}

type Props = StateProps & DispatchProps & Params
const { Search } = Input
const { Option } = Select
const { confirm } = Modal
const TableCategory: React.FC<Props> = (props: Props) => {
    const [fetchingState, setFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [page, setPage] = useState<number>(1)
    const [searchType, setSearchType] = useState<string>('0')
    const pageSize: number = 10
    const [sortBy, setSortBy] = useState<string>('createdDate')
    const [orderBy, setOrderBy] = useState<string>('ASC')
    const [activateFilter, setActivateFilter] = useState<boolean[]>([true, false])
    const [value, setValue] = useState<string>('')
    const [loader, setLoader] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [filterValue, setFilterValue] = useState<boolean[]>([true, false])

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        setLoader(true)
        if (value !== '') {
            fetch()
            // props.getAllCategoriesWithPagination(page, pageSize, searchType, value, activateFilter, sortBy, orderBy).catch((err) => { console.log('err :', err) })
            setLoader(false)
        } else {
            fetch()
            // props.getAllCategoriesWithPagination(page, pageSize, searchType, '', activateFilter, sortBy, orderBy).catch(() => dispatch(GetAllCategorySuccessEvent.build([])))
            setLoader(false)
            props.getAllCategory(getCategoryName(props.categories))
        }
    }, [page, orderBy, activateFilter])

    const fetch = () => {
        if (fetchingState === FetchingState.Succeed) {
            fetchWithState(FetchingState.NotStarted, setFetchingState, () => props.getAllCategoriesWithPagination(page, pageSize, searchType, value, activateFilter, sortBy, orderBy).catch(
                () => dispatch(GetAllCategorySuccessEvent.build([])))
            )
        } else {
            fetchWithState(fetchingState, setFetchingState, () => props.getAllCategoriesWithPagination(page, pageSize, searchType, value, activateFilter, sortBy, orderBy)
                .catch(() => dispatch(GetAllCategorySuccessEvent.build([])))
            )
        }
    }

    const getCategoryName = (cat: Category[]): string[] => {
        return (cat || []).map((value) => {
            return value.category
        })
    }

    const deleteCategory = (data: Category) => {
        try {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete this data?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                async onOk() {
                    if (data.active) {
                        message.warning('Please Deactivate.')
                    } else {
                        props.deleteCategory(data.id?.toString()!!).then(() => {
                            props.getAllCategoriesWithPagination(page, pageSize, searchType, '', activateFilter, sortBy, orderBy).then(() => {
                                message.success('Delete data successfully.')
                            })
                        }).catch((err) => {
                            message.error(`You have unSuccessfully save the data. ${err}`)
                        })
                        await props.getAllCategoriesWithPagination(page, pageSize, searchType, '', activateFilter, sortBy, orderBy)
                    }
                },
                onCancel() { }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const updateCategory = (data: Category) => {
        try {
            if (data) {
                props.getDataToEdit(data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        props.getPage(page)
    }

    const handleChangeTypeSearch = (e) => {
        setSearchType(e)
    }

    const handleSearchValue = (value) => {
        setValue(value)
        if (page !== 1) setPage(1)
        fetchWithState(FetchingState.NotStarted, setFetchingState, () => props.getAllCategoriesWithPagination(page, pageSize, searchType, encodeSpecialCharacter(value), activateFilter, sortBy, orderBy).catch(() => {
            dispatch(GetAllCategorySuccessEvent.build([]))
        }))
    }

    const toggleActiveness = (cat) => {
        const proceed = () => {
            props.updateCategory({ id: cat.id, active: !cat.active } as Category)
                .catch((err) => {
                    if (err.response.status === 409) {
                        message.warning('This Incident is being used by Category.')
                    } else {
                        message.error(`Failed toggling Category activeness. ${err}`)
                    }
                })
        }

        if (!cat.active) {
            proceed()
        } else {
            try {
                confirm({
                    title: 'Are you sure?',
                    content: 'Do you confirm Deactivate this data?',
                    okText: 'OK',
                    okType: 'danger',
                    autoFocusButton: 'cancel',
                    cancelText: 'Cancel',
                    async onOk() {
                        proceed()
                    },
                    onCancel() { }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        if (sorter.order === 'ascend') {
            setOrderBy('ASC')
            setSortBy(sorter.field)
        } else if (sorter.order === 'descend') {
            setOrderBy('DESC')
            setSortBy(sorter.field)
        }
    }

    const handleFilter = (value) => {
        setFilterValue(value)
        if (value) {
            if (value.length === 1) {
                if (value[0] === 'true' || value[0] === true) {
                    setActivateFilter([true])
                } else {
                    setActivateFilter([false])
                }
            } else if (value.length === 2) {
                setActivateFilter([true, false])
            } else {
                setActivateFilter([])
            }
            setPage(1)
        }
    }

    const renderFilter = () => {
        return (<>
            <Checkbox.Group style={{ width: '100%' }} onChange={handleFilter} value={filterValue}>
                <Row>
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={true}>Activate</Checkbox>
                    </Col>
                    <br />
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={false}>Deactivate</Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
        </>)
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (row: any) =>
                <span ><Icon type="delete" onClick={() => deleteCategory(row)} /></span>
        }, {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (row: any) =>
                <span ><Icon type="edit" onClick={() => updateCategory(row)} /></span>
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            sorter: true
        },
        {
            title: 'Subcategory',
            dataIndex: 'subCategory',
            key: 'subCategory',
            sorter: true,
            render: (data) => {
                return (
                    <>
                        {data}
                    </>
                )
            }
        },
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            sorter: true,
            render: (data) => {
                return (
                    <>
                        {data}
                    </>
                )
            }
        },
        {
            title: 'Last Modified',
            dataIndex: '',
            key: '',
            width: '20%',
            render: (row: Category) => {
                return (
                    <>
                        {row.lastModifiedDate?.length === 23 ? (row.lastModifiedDate as string).substring(0, row.lastModifiedDate.length - 4) : row.lastModifiedDate}
                    </>
                )
            }
        },
        {
            title: '',
            dataIndex: 'active',
            key: 'active',
            width: '10%',
            filters: [
                {
                    text: 'Activate',
                    value: 'true'
                },
                {
                    text: 'Deactivate',
                    value: 'false'
                }
            ],
            filteredValue: filterValue,
            filterDropdown: renderFilter,
            render: (check, row) => {
                return (
                    <>
                        <Tooltip placement="bottom" title={row.active ? 'Deactivate' : 'Activate'}>
                            <Switch style={{ float: 'right' }} checked={check} onChange={() => toggleActiveness(row)} />
                        </Tooltip>
                    </>
                )
            }
        }
    ]

    return (
        <div style={{ minWidth: 768 }}>
            <SpinningFetcher fetchingState={fetchingState}>
                <div style={{ float: 'right' }}>
                    <Row gutter={16}>
                        <Col span={7}>
                            <Select defaultValue={searchType} style={{ width: 120 }} onChange={(e) => handleChangeTypeSearch(e)}>
                                <Option value="0">All</Option>
                                <Option value="1">Category</Option>
                                <Option value="2">Subcategory</Option>
                                <Option value="3">Item</Option>
                            </Select>
                        </Col>
                        <Col span={7}>
                            <Search
                                defaultValue={value}
                                placeholder="Can search Category , Subcategory , or Item"
                                onSearch={value => handleSearchValue(value)}
                                style={{ width: 300 }}
                            />
                        </Col>
                    </Row>
                </div>
                <br />
                <br />
                <Table
                    columns={columns}
                    dataSource={props.categories || []}
                    // expandedRowRender={expandedRowRender}
                    // defaultExpandAllRows={true}
                    pagination={{
                        pageSize: pageSize,
                        current: page,
                        total: props.categoryManagementPagination.total,
                        onChange: (event) => { handleOnClickPageNumber(event) }
                    }}
                    rowKey="id"
                    onChange={handleChangeTable}
                    loading={loader}
                    locale={{
                        filterConfirm: '',
                        filterReset: ''
                    }}
                    expandIconColumnIndex={0}
                    expandIconAsCell={false}
                />
            </SpinningFetcher>
        </div>
    )
}

export default connect(mapStateToProps, {
    getAllCategoriesWithPagination, deleteCategory, updateCategory
})(TableCategory)
