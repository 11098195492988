export interface ReduxEvent {}

export interface GenericStateEvent<T> extends ReduxEvent {
    type: any
    payload: T
}

export function buildStateEvent<T>(type: any, payload: T) {
    return {type, payload} as GenericStateEvent<T>
}
