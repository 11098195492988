import { KnowledgeStatus } from './knowledge/model'

export const statusColor = (status: string) => {
    switch (status) {
    case KnowledgeStatus.Draft:
        return 'cyan'
    case KnowledgeStatus.Published:
        return 'green'
    case KnowledgeStatus.Rejected:
        return 'red'
    case KnowledgeStatus.AwaitingApproval:
        return 'gold'
    case KnowledgeStatus.AwaitingRetire:
        return 'gold'
    case KnowledgeStatus.Retired:
        return 'purple'
    default:
        return ''
    }
}

export const convertCamelCaseToSentenceCase = (fieldValue: string) => {
    // adding space between strings
    const result = fieldValue.replace(/([A-Z])/g, ' $1')

    // converting first character to uppercase and join it to the final string
    const final = result.charAt(0).toUpperCase() + result.slice(1)
    return final
}
