import { SlaGlobalSetting } from './model'
import { GetAllGlobalSettingSuccessEvent, UpdateGlobalSettingSuccessEvent } from './state-event'
import { axiosPost, axiosGet } from '../rest'
import { Dispatch } from 'redux'

// fix ticket type = incident waiting UI for support other type
export const getAllGlobalSetting = (ticketType = 'Incident') => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<SlaGlobalSetting>(`/api/sla/slaGlobalSettings/type/${ticketType}`)
            dispatch(GetAllGlobalSettingSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const updateGlobalSetting = (globalSetting: SlaGlobalSetting) => {
    return async (dispatch: Dispatch) => {
        try {
            // const {
            //     ticketType,
            //     measurementClockStartType,
            //     ticketStatuses
            // } = globalSetting
            // const patch = {
            //     ticketType,
            //     measurementClockStartType,
            //     ticketStatuses
            // }
            const response = await axiosPost<SlaGlobalSetting>('/api/sla/slaGlobalSettings', globalSetting)
            dispatch(UpdateGlobalSettingSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}
