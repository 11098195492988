import React, { useState } from 'react'
import { timeDiffAsString } from '../../common-time'

interface Props {
    Date: string
}

const TimeRender: React.FC<Props> = (props: Props) => {
    const [Time, setTime] = useState<string>(timeDiffAsString(props.Date, null, false, true))

    setInterval(() => {
        setTime(timeDiffAsString(props.Date, null, false, true))
    }, 60000)

    return (
        <>
            {Time}
        </>
    )
}

export default TimeRender
