import {buildStateEvent, GenericStateEvent} from '../../common-redux'
import {SupportTeamManagement} from './model'

export interface SupportTeamManagementStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllSupportTeamSuccessEvent = {
    type: 'GetAllSupportTeamSuccessEvent',
    build: (payload: SupportTeamManagement[]) => buildStateEvent(GetAllSupportTeamSuccessEvent.type, payload)
}
export const AddSupportTeamSuccessEvent = {
    type: 'AddSupportTeamSuccessEvent',
    build: (payload: SupportTeamManagement) => buildStateEvent(AddSupportTeamSuccessEvent.type, payload)
}
export const UpdateSupportTeamSuccessEvent = {
    type: 'UpdateSupportTeamSuccessEvent',
    build: (payload: SupportTeamManagement) => buildStateEvent(UpdateSupportTeamSuccessEvent.type, payload)
}
export const UpdateSupportTeamTotalPageSuccessEvent = {
    type: 'UpdateSupportTeamTotalPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdateSupportTeamTotalPageSuccessEvent.type, payload)
}
export const UpdateSupportTeamCurrentPageSuccessEvent = {
    type: 'UpdateSupportTeamCurrentPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdateSupportTeamCurrentPageSuccessEvent.type, payload)
}
export const DeleteSupportTeamSuccessEvent = {
    type: 'DeleteSupportTeamSuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteSupportTeamSuccessEvent.type, payload)
}
export const UpdateSupportTeamSortBySuccessEvent = {
    type: 'UpdateSupportTeamSortBySuccessEvent',
    build: (payload: string) => buildStateEvent(UpdateSupportTeamSortBySuccessEvent.type, payload)
}
export const UpdateSupportTeamOrderBySuccessEvent = {
    type: 'UpdateSupportTeamOrderBySuccessEvent',
    build: (payload: string) => buildStateEvent(UpdateSupportTeamOrderBySuccessEvent.type, payload)
}
export const UpdateSupportTeamFilterSuccessEvent = {
    type: 'UpdateSupportTeamFilterSuccessEvent',
    build: (payload: string[]) => buildStateEvent(UpdateSupportTeamFilterSuccessEvent.type, payload)
}
export const UpdateSupportTeamSearchFieldSuccessEvent = {
    type: 'UpdateSupportTeamSearchFieldSuccessEvent',
    build: (payload: string) => buildStateEvent(UpdateSupportTeamSearchFieldSuccessEvent.type, payload)
}
export const UpdateSupportTeamSearchValueSuccessEvent = {
    type: 'UpdateSupportTeamSearchValueSuccessEvent',
    build: (payload: string) => buildStateEvent(UpdateSupportTeamSearchValueSuccessEvent.type, payload)
}
