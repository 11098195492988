import { SupportTeamStateEvent, GetAllSupportTeamSuccessEvent } from './state-event'
import { SupportTeam } from '../incident/model'

export const supportTeamStateReducer = (state: SupportTeam[] = [], event: SupportTeamStateEvent<SupportTeam[]>) => {
    if (event.type === GetAllSupportTeamSuccessEvent.type) {
        return event.payload || []
    } else {
        return state
    }
}
