import React, {ChangeEvent, useState} from 'react'
import {Button, Form, Input, message, Modal, Radio, Row, Spin} from 'antd'
import {connect} from 'react-redux'
import {StoreState} from '../../store'
import {FormComponentProps} from 'antd/lib/form'
import {RadioChangeEvent} from 'antd/es/radio'
import {addIncidentView, AddIncidentViewParams, setCurrentIncidentView} from './index'
import {IncidentViewState} from './state-model'
import {BaseDocumentFunc} from '../../common-model'

interface Props extends FormComponentProps {
    addIncidentView: (AddIncidentViewParams) => Promise<number>
    setCurrentIncidentView: Function
    incidentViewState: IncidentViewState
    onClose: (boolean) => void
    onOpen: boolean
}

const IncidentViewSaveAsModalForm: React.FC<Props> = (props: Props) => {
    const [isSpinning, setIsSpinning] = useState<boolean>(false)
    const { getFieldDecorator } = props.form
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [params, setParams] = useState<AddIncidentViewParams>({
        name: '',
        criteria: BaseDocumentFunc.cloneAndDeleteFields(props.incidentViewState.currentView!!.criteria),
        visibility: props.incidentViewState.currentView!!.visibility || 'Myself'
    })
    const [isInputReady, setIsInputReady] = useState<boolean>(false)

    const onSubmit = async () => {
        setIsSpinning(true)
        setIsLoading(true)
        await props.addIncidentView(params)
            .then(() => {
                message.success(`View '${params.name}' saved successfully.`)
                props.onClose(true)
                setIsLoading(false)
            })
            .catch((error: any) => {
                setIsLoading(false)
                console.log(`Failed saving new view. ${error}.`)
            })
            .finally(() => {
                setIsSpinning(false)
                setIsLoading(false)
            })
        await props.setCurrentIncidentView(params.name).catch((reason: any) =>
            console.log('Failed to set current view. ' + reason?.toString())
        )
    }

    const onCancel = () => {
        props.onClose(false)
    }

    const onNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
        params.name = e.target.value.trim()
        updateInputReadiness()
    }

    const onVisibilityChanged = (e: RadioChangeEvent) => {
        params.visibility = e.target.value
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        if (props.incidentViewState.allVisibleViewNames?.includes(value.trim()) === true) {
            callback(`This view name '${value}' is already in used. Please try the other name.`)
        } else {
            callback()
        }
    }

    const updateInputReadiness = () => {
        setIsInputReady(params.name?.length >= 3 && params.visibility?.length > 0)
    }

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
    }

    return (
        <Spin spinning={isSpinning} size="large">
            {/* Show Modal Edit Incident View */}
            <Modal
                title="Save as"
                onCancel={onCancel}
                visible={props.onOpen}
                footer={[
                    <Button key="back" onClick={onCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={onSubmit} disabled={!isInputReady} loading={isLoading}>
                        Save
                    </Button>
                ]}
            >
                <Form onSubmit={onSubmit}>
                    <Row >
                        <Form.Item label="View Name">
                            {getFieldDecorator('viewName', {
                                initialValue: params.name,
                                rules: [
                                    { required: true, message: 'View name is required' }, {min: 3, message: 'Enter more than 3 characters' },
                                    {max: 50, message: 'message max 50 characters'}, { validator: validateNameUniqueness }
                                ]
                            })(
                                <Input onChange={onNameChanged} placeholder="View name"/>
                            )}
                        </Form.Item>
                        <Form.Item label="Visible to">
                            {getFieldDecorator('visibility', {
                                initialValue: params.visibility,
                                rules: [{ required: true, message: 'Visibility is require' }]
                            })(
                                <Radio.Group value={params.visibility} onChange={onVisibilityChanged} >
                                    <Radio style={radioStyle} value={'Myself'}>Myself</Radio>
                                    <Radio style={radioStyle} value={'My Group'}>My Group</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Row>
                </Form>
            </Modal>
        </Spin>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        incidentViewState: state.incidentViewState
    }
}

const wrappedByForm = Form.create<Props>()(IncidentViewSaveAsModalForm)

export default connect(mapStateToProps, {
    addIncidentView, setCurrentIncidentView
})(wrappedByForm)
