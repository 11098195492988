/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import {useLocation, useParams} from 'react-router-dom'
import { getDataVspace } from './service'
import {
    Empty, Spin
} from 'antd'
import { getWorkOrderById } from '../../wo-management/service'
import {decryptDataVspace, nullOrEmptyMap} from '../../../common-misc'

interface Param {

}

type Props = Param
const RelateTicket: React.FC<Props> = (props: Props) => {
    const [dataVspace, setDataVspace] = useState<any>(undefined)
    const [loadData, setLoadData] = useState<boolean>(false)
    const location = useLocation()
    const {id} = useParams()

    let email: string | undefined
    const params = new URLSearchParams(window.location.search)
    const incidentNo = params.get('incidentNo') || ''
    let token: string | undefined
    const dataVspaceInfo = decryptDataVspace()
    if (typeof dataVspaceInfo === 'object') {
        email = dataVspaceInfo.email
        token = dataVspaceInfo.token
    }

    useEffect(() => {
        if (!location.pathname.includes('/WoFormEdit')) {
            // create
            const convertData = {
                token: token,
                email: email,
                ticket_sid: incidentNo
            }
            const formData = new URLSearchParams()

            for (const key in convertData) {
                formData.append(key, convertData[key])
            }
            fetchDataVspace(formData)
        } else {
            // edit
            const idWO = id || ''
            getWorkOrderById(idWO).then((res) => {
                const convertData = {
                    token: token,
                    email: email,
                    ticket_sid: res.incidentNo!!
                }
                const formData = new URLSearchParams()

                for (const key in convertData) {
                    formData.append(key, convertData[key])
                }
                fetchDataVspace(formData)
            })
        }
    }, [])

    const fetchDataVspace = async (data) => {
        setLoadData(true)
        getDataVspace(data).then((res) => {
            setDataVspace(res)
            setLoadData(false)
        }).catch(() => {
            setLoadData(false)
        })
    }

    return (
        <>
            <div className='BG_Gray'>
                <Spin spinning={loadData}>
                    {dataVspace === undefined
                        ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        : nullOrEmptyMap(dataVspace.data?.refer_remedy_hd)
                            ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                            : <span style={{ padding: 10 }}>{dataVspace.data?.refer_remedy_hd!! || ''} : {dataVspace.data?.subject!! || ''}</span>
                    }
                </Spin>
            </div>
        </>
    )
}

export default RelateTicket
