import { CommentResponseModel, LikeCommentModel } from './model'
import { axiosGet, axiosPost } from '../../rest'

export const getCommentByKnowledgeId = async (knowledgeId: String) => {
    try {
        const response = await axiosGet<CommentResponseModel[]>(`/api/knowledge/comments/commentsByKnowledgeId/${knowledgeId}`)
        return response.data.data || []
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const addComment = async (comment: CommentResponseModel) => {
    try {
        const response = await axiosPost<CommentResponseModel>('/api/knowledge/comments', comment)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const likeComment = async (body: LikeCommentModel) => {
    try {
        const response = await axiosPost('/api/knowledge/likes', body)
        return response.status === 201
    } catch (err) {
        console.log(err)
        throw err
    }
}
