import {axiosGet, axiosPatch, axiosPost} from '../incident-management/rest'
import {
    AddCustomFiledsSuccessEvent,
    GetAllCustomFiledsSuccessEvent,
    UpdateCustomFiledsSuccessEvent
} from './state-event'
import {Dispatch} from 'redux'
import {CustomFileds} from './model'

export const getAllCustomFields = (value: String) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<CustomFileds>('/api/setting/customFieldByTicketTypeId/' + value)
            dispatch(GetAllCustomFiledsSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const addCustomFields = (customField: CustomFileds) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<CustomFileds>('/api/setting', customField)
            dispatch(AddCustomFiledsSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const updateCustomFields = (patch: CustomFileds) => {
    return async (dispatch: Dispatch) => {
        try {
            const ticketType = patch.ticketType!!
            // delete patch.id
            const response = await axiosPost<CustomFileds>(`/api/setting/customFieldUpdateByTicketTypeId/${ticketType}`, patch.fields)
            dispatch(UpdateCustomFiledsSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}
