import React from 'react'
import { Row } from 'antd'

interface Prop {
    size: number
}

export const Space: React.FC<Prop> = (props: Prop) => {
    return (
        <Row style={{ marginTop: props.size }}></Row>
    )
}
