import {BaseDocument, BaseEntity, FileUpload} from '../../common-model'
import { UploadLink } from '../../knowledge-management/knowledge/uploads/model'

export interface Comment extends BaseEntity {
    incidentId?: number;
    text?: string;
    visibleToCustomer: boolean;
    uploadLink?: FileUpload[];
    type: CommentType,
    from?: string,
    to?: string,
    isResolution?: boolean
    // uploadLink?: UploadLink[]
}

export enum CommentType {
    Reply = 'Reply',
    Forward = 'Forward',
    AddNote = 'AddNote'
}
