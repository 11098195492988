import {applyMiddleware, combineReducers, createStore} from 'redux'
import {KnowledgeList, knowledgeStateReducer} from '../knowledge-management'
import {
    Category as KnowledgeCategory,
    CategoryStateReducer as knowledgeCategoryStateReducer
} from '../knowledge-management/category'

import {Comment, commentStateReducer} from '../incident-management/comment'

import {IncidentState, incidentStateReducers, SupportTeam, TicketStatus} from '../incident-management/incident'
import {IncidentViewState} from '../incident-management/incident-view/state-model'
import {impactStateReducer} from '../incident-management/impact'
import {urgencyStateReducer} from '../incident-management/urgency'
import {
    priorityConfigurationStateReducer,
    PriorityLevel,
    PriorityMatrix,
    priorityStateReducer
} from '../incident-management/priority'
import {
    Category,
    CategoryManagementPagination,
    categoryManagementPaginationReducer,
    categoryStateReducer
} from '../incident-management/category'

import {worklogStateReducer} from '../incident-management/worklog/state-reducer'
import {incidentViewStateReducer} from '../incident-management/incident-view'

import {Worklog} from '../incident-management/worklog'
import {statusStateReducer} from '../incident-management/status'
import {supportTeamStateReducer} from '../incident-management/support-team'
import {BusinessHour, businessHourStateReducer} from '../sla-management/business-hour'
import {slaPolicyStateReducer} from '../sla-management/sla-policy/state-reducer'
import {SlaGlobalSetting, slaGlobalSettingStateReducer} from '../sla-management/sla-global-setting'
import {SlaPolicy} from '../sla-management/sla-policy/model'
import thunk from 'redux-thunk'
import {ResponseUserRole, Roles, UserRolesPagination} from '../authorization-module/user-role/duck/model'
import {
    rolesReducer,
    userRolesPaginationStateReducer,
    userRolesReducer
} from '../authorization-module/user-role/duck/reducers'
import {composeWithDevTools} from 'redux-devtools-extension'
import {rolesStateReducer, RoleState} from '../authorization-module/permissions'
import {TablePagination, UserRolesAndPermissions} from './model'
import {tablePaginationStateReducer, userRolesAndPermissionsReducer} from './state-reducer'
import {
    SupportTeamManagement,
    SupportTeamManagementPagination
} from '../authorization-module/support-team-management/model'
import {
    supportTeamManagementStateReducer,
    supportTeamPaginationStateReducer
} from '../authorization-module/support-team-management/state-reducer'
import {
    RoleAndPermissions,
    RoleAndPermissionsOptionsStateReducer,
    RoleAndPermissionsPaging,
    RoleAndPermissionsPagingStateReducer,
    RoleAndPermissionsStateReducer,
    RoleKMStateReducer
} from '../authorization-module/role-and-permissions'
import {
    GeneralIncidentSetting,
    GeneralIncidentSettingStateReducer
} from '../incident-management/general-incident-setting'
import {CustomFileds, CustomFiledsStateReducer} from '../configurable-fields/'
import {
    Dashboard,
    DashboardIncidentBacklogMyticketReducer,
    DashboardOpenbyPriorityTicketMyGroupReducer,
    DashboardOpenbyPriorityTicketMyticketReducer,
    DashboardOpenbyPriorityTicketTotalGroupReducer,
    DashboardOpenbyStatusTicketMyGroupReducer,
    DashboardOpenbyStatusTicketMyticketReducer,
    DashboardOpenbyStatusTicketTotalGroupReducer,
    DashboardOpenSLAMyGroupReducer,
    DashboardOpenSLAMyticketReducer,
    DashboardOpenSLATotalGroupReducer,
    DashboardOpenTicketMyGroupReducer,
    DashboardOpenTicketTotalGroupReducer
} from '../msp-portal'
import {Job, jobStateReducer} from '../msp-wo/Relate/Job'
import {Asset, AssetStateReducer} from '../msp-wo/Relate/Asset'
import {JobWorkLog} from '../msp-wo/Relate/Job/worklog'
import {jobWorklogStateReducer} from '../msp-wo/Relate/Job/worklog/state-reducer'
import {
    TotalWorkOrderContent,
    totalWorkOrderContentStateReducer,
    workOrder,
    WorkOrderList,
    workOrderListStateReducer,
    workOrderStateReducer,
    workOrderPaginationStateReducer,
    WorkOrderWithPagination
} from '../msp-wo/wo-management'
import {
    assetFilterStateReducer,
    assetOwnerLocationListStateReducer,
    AssetOwnerLocationTotalData,
    FilterAsset
} from '../msp-wo/asset-management'
import {ChargingGroup} from '../msp-wo/Relate/Charging/Model'
import {chargingStateReducer} from '../msp-wo/Relate/Charging/state-reducer'

export interface StoreState {
    businessHours: BusinessHour[]
    incidentState: IncidentState
    slaPolicies: SlaPolicy[]
    categories: Category[]
    categoryManagementPagination: CategoryManagementPagination
    supportTeams: SupportTeam[]
    impacts: PriorityLevel[]
    urgencies: PriorityLevel[]
    statuses: TicketStatus[]
    workLog: Worklog[]
    slaGlobalSettings: SlaGlobalSetting
    knowledgeCategories: KnowledgeCategory[]
    knowledgesList: KnowledgeList
    incidentViewState: IncidentViewState
    comments: Comment[]
    priorities: PriorityLevel[]
    tablePagination: TablePagination
    userRolesAndPermissions: UserRolesAndPermissions
    userRoles: ResponseUserRole[]
    userRolesPagination: UserRolesPagination
    roles: Roles[]
    currentUserRole: RoleState
    supportTeamManagement: SupportTeamManagement[]
    supportTeamManagementPagination: SupportTeamManagementPagination
    priorityMatrix: PriorityMatrix[]
    roleAndPermissions: RoleAndPermissions[]
    roleAndPermissionsOptions: RoleAndPermissions
    generalIncidentSetting: GeneralIncidentSetting
    roleAndPermissionsPaging: RoleAndPermissionsPaging
    customFields: CustomFileds
    DashboardOpenTicketTotalGroup: Dashboard
    DashboardOpenbyStatusTicketTotalGroup: Dashboard
    DashboardOpenbyPriorityTicketTotalGroup: Dashboard
    DashboardOpenSLATotalGroup: Dashboard
    DashboardOpenTicketMyGroup: Dashboard
    DashboardOpenbyStatusTicketMyGroup: Dashboard
    DashboardOpenbyPriorityTicketMyGroup: Dashboard
    DashboardOpenSLAMyGroup: Dashboard
    DashboardOpenByStatusTicketMyTicket: Dashboard
    DashboardOpenByPriorityTicketMyTicket: Dashboard
    DashboardOpenSLAMyTicket: Dashboard
    DashboardIncidentBacklogMyTicket: Dashboard
    job: Job[]
    asset: Asset[],
    filterAsset: FilterAsset,
    workOrder: workOrder[]
    workOrderList: WorkOrderList[]
    assetOwnerLocationList: AssetOwnerLocationTotalData
    totalWorkOrderContent: TotalWorkOrderContent
    jobWorkLog: JobWorkLog[],
    chargingGroup: ChargingGroup[],
    kmMapRole: string[],
    workOrdersPagination: WorkOrderWithPagination
}

export const reducers = combineReducers<StoreState>({
    businessHours: businessHourStateReducer,
    incidentState: incidentStateReducers,
    slaPolicies: slaPolicyStateReducer,
    categories: categoryStateReducer,
    categoryManagementPagination: categoryManagementPaginationReducer,
    supportTeams: supportTeamStateReducer,
    impacts: impactStateReducer,
    urgencies: urgencyStateReducer,
    statuses: statusStateReducer,
    workLog: worklogStateReducer,
    slaGlobalSettings: slaGlobalSettingStateReducer,
    knowledgeCategories: knowledgeCategoryStateReducer,
    knowledgesList: knowledgeStateReducer,
    incidentViewState: incidentViewStateReducer,
    comments: commentStateReducer,
    priorities: priorityStateReducer,
    tablePagination: tablePaginationStateReducer,
    userRolesAndPermissions: userRolesAndPermissionsReducer,
    userRoles: userRolesReducer,
    userRolesPagination: userRolesPaginationStateReducer,
    roles: rolesReducer,
    currentUserRole: rolesStateReducer,
    supportTeamManagement: supportTeamManagementStateReducer,
    supportTeamManagementPagination: supportTeamPaginationStateReducer,
    priorityMatrix: priorityConfigurationStateReducer,
    roleAndPermissions: RoleAndPermissionsStateReducer,
    roleAndPermissionsOptions: RoleAndPermissionsOptionsStateReducer,
    roleAndPermissionsPaging: RoleAndPermissionsPagingStateReducer,
    generalIncidentSetting: GeneralIncidentSettingStateReducer,
    customFields: CustomFiledsStateReducer,
    DashboardOpenTicketTotalGroup: DashboardOpenTicketTotalGroupReducer,
    DashboardOpenbyStatusTicketTotalGroup: DashboardOpenbyStatusTicketTotalGroupReducer,
    DashboardOpenbyPriorityTicketTotalGroup: DashboardOpenbyPriorityTicketTotalGroupReducer,
    DashboardOpenSLATotalGroup: DashboardOpenSLATotalGroupReducer,
    DashboardOpenTicketMyGroup: DashboardOpenTicketMyGroupReducer,
    DashboardOpenbyStatusTicketMyGroup: DashboardOpenbyStatusTicketMyGroupReducer,
    DashboardOpenbyPriorityTicketMyGroup: DashboardOpenbyPriorityTicketMyGroupReducer,
    DashboardOpenSLAMyGroup: DashboardOpenSLAMyGroupReducer,
    DashboardOpenByStatusTicketMyTicket: DashboardOpenbyStatusTicketMyticketReducer,
    DashboardOpenByPriorityTicketMyTicket: DashboardOpenbyPriorityTicketMyticketReducer,
    DashboardOpenSLAMyTicket: DashboardOpenSLAMyticketReducer,
    DashboardIncidentBacklogMyTicket: DashboardIncidentBacklogMyticketReducer,
    job: jobStateReducer,
    asset: AssetStateReducer,
    filterAsset: assetFilterStateReducer,
    workOrder: workOrderStateReducer,
    workOrderList: workOrderListStateReducer,
    assetOwnerLocationList: assetOwnerLocationListStateReducer,
    totalWorkOrderContent: totalWorkOrderContentStateReducer,
    jobWorkLog: jobWorklogStateReducer,
    chargingGroup: chargingStateReducer,
    kmMapRole: RoleKMStateReducer,
    workOrdersPagination: workOrderPaginationStateReducer
})

export default createStore(reducers, composeWithDevTools(applyMiddleware(thunk)))
