import { Comment } from './model'

export enum CommentWebSocketEventType {
    Inserted = 'Inserted',
}

export interface CommentWebSocketEvent {
    eventType: CommentWebSocketEventType
    incidentId: string
    comment: Comment
}
