import moment from 'moment'
import 'moment-duration-format'

export const timeDiffAsString = (from: string | moment.Moment | null, to: string | moment.Moment | null, showSeconds: boolean, showFullminutes?: boolean) => {
    from = (typeof from === 'string') ? moment(from) : (from || moment())
    to = (typeof to === 'string') ? moment(to) : (to || moment())
    const duration = moment.duration(to.diff(from))
    if (showFullminutes) {
        return durationAsString(duration, showSeconds, showFullminutes)
    }
    return durationAsString(duration, showSeconds)
}

export function durationAsString(duration: string | moment.Duration, showSeconds: boolean, showFullMinutes?: boolean): string {
    if (typeof duration === 'string') {
        duration = moment.duration(duration)
    }

    if (duration.asMilliseconds() < 0) {
        duration = moment.duration(0)
    }

    const makeText = (minutesUnitText: string) => {
        // @ts-ignore
        return duration.format({
            template: `d [days] h [hours] m [${minutesUnitText}]${showSeconds ? ' s [secs]' : ''}`,
            // trim: 'all',
            usePlural: true
        })
    }

    if (showFullMinutes) {
        if (duration.asMinutes() < 1) {
            return 'a few seconds'
        } else if (duration.asMinutes() >= 1 && duration.asMinutes() <= 2) {
            return 'a minute'
        } else {
            return makeText('minutes')
        }
    } else {
        // @ts-ignore
        const text = makeText('mins')

        if (text.length === 0) {
            return showSeconds ? '0 secs' : '0 mins'
        } else {
            return text
        }
    }
}
