import React from 'react'
import { Select } from 'antd'
import { SelectValue } from 'antd/es/select'
import { IncidentFilter } from '../model'
import { PriorityLevel } from '../../priority'

interface Props {
    onChanged: (value: string[]) => void
    criteria: IncidentFilter
    priorities: PriorityLevel[]
}

export const PriorityFilter: React.FC<Props> = (props: Props) => {
    const { Option } = Select

    const onRequesterChange = (value: SelectValue) => {
        props.onChanged(Array.isArray(value) ? (value as Array<any>).map((it) => it as string) : [value as string])
    }

    return (
        <>
            <p className={'topicName'}>Priority</p>
            <Select mode="tags" style={{ width: '85%' }} onChange={onRequesterChange} value={props.criteria.priorities?.split(',') || []} placeholder={'Select a priority'}>
                {props.priorities?.map((it, index) => { return <Option key={index} value={it.name}>{it.name}</Option> })}
            </Select><br />
        </>
    )
}
