import {Dispatch} from 'redux'
import {axiosGet, axiosPost} from '../rest'
import {PriorityLevel, PriorityMatrix} from './model'
import {
    GetAllPrioritySuccessEvent,
    GetPriorityMatrixSuccessEvent,
    CreatePriorityMatrixNewVersionSuccessEvent
} from './state-event'
import {Impact} from '../impact/model'
import {Urgency} from '../urgency/model'
import {RoleAndPermissions} from '../../authorization-module/role-and-permissions'

export const getAllPriority = () => {
    return async (dispatch: Dispatch) => {
        try {
            // const params = {
            //     incidentId: incidentId
            // }
            const response = await axiosGet<PriorityLevel[]>('/api/incident/priorityLevel/priority')
            dispatch(GetAllPrioritySuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}

export async function resolvePriority(impact: number, urgency: number, incidentId?: number): Promise<PriorityMatrix> {
    try {
        const params = {
            incidentId: incidentId
        }
        const response = await axiosGet<PriorityMatrix>(`/api/incident/priorities/${impact}/${urgency}`, {params})
        return response.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getPriorityConfiguration = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<PriorityMatrix>('/api/incident/priorityMatrix')
            dispatch(GetPriorityMatrixSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}

export const createPriorityMatrixNewVersion = (patch: PriorityMatrix[] | undefined) => {
    return async (dispatch: Dispatch) => {
        try {
            // const id = patch.id!!
            // delete patch.id
            console.log(patch)
            const response = await axiosPost<PriorityMatrix[]>('/api/incident/priorityMatrix', patch)
            dispatch(CreatePriorityMatrixNewVersionSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const createNewPriorityLevel = async (newItem: PriorityLevel[]) => {
    try {
        const response = await axiosPost<PriorityLevel[]>('/api/incident/priorityLevel', newItem)
        return response.data.data!!
    } catch (err) {
        throw err.response
    }
}
