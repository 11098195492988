import {BusinessHour, Holiday} from './model'
import {
    AddBusinessHourSuccessEvent,
    DeleteBusinessHourSuccessEvent,
    GetAllBusinessHourSuccessEvent,
    UpdateBusinessHourSuccessEvent
} from './state-event'
import { axiosDelete, axiosPatch, axiosPost, axiosGet } from '../rest'
import { Dispatch } from 'redux'

export async function getBusinessHourById(id: string): Promise<BusinessHour> {
    try {
        const res = await axiosGet<BusinessHour>(`/api/sla/businessHours/${id}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}
export async function getAllBusinessHourWithOrigin(): Promise<BusinessHour[]> {
    try {
        const res = await axiosGet<BusinessHour[]>('/api/sla/businessHours/findAll')
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getAllBusinessHour = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<BusinessHour[]>('/api/sla/businessHours')
            dispatch(GetAllBusinessHourSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const addBusinessHour = (businessHour: BusinessHour) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<BusinessHour>('/api/sla/businessHours', businessHour)
            dispatch(AddBusinessHourSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteBusinessHour = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/sla/businessHours/${id}`)
            dispatch(DeleteBusinessHourSuccessEvent.build(id))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

// edit function
export const updateBusinessHour = (patch: BusinessHour) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            delete patch.id
            const response = await axiosPatch<BusinessHour>(`/api/sla/businessHours/${id}`, patch)
            dispatch(UpdateBusinessHourSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export async function loadHoliday(){
    try {
        const res = await axiosGet<Holiday>(`/api/sla/businessHours/holidays`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}
