import {Requester} from './index'
import {axiosGet, axiosGetAuth} from '../rest'
import {People} from '../../authorization-module/people/model';

export async function searchRequester(input: string): Promise<Requester[]> {
    try {
        const res = await axiosGet<Requester[]>(`/api/incident/requesters/search?input=${input}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}
export async function searchPeople(input: string): Promise<People[]> {
    try {
        const res = await axiosGetAuth<People[]>(`/api/auth/peoples/search?people=${input}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}
