/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, {useEffect, useState} from 'react'
import {Table, Tag} from 'antd'
import {Link, useLocation} from 'react-router-dom'
import {connect} from 'react-redux'
import {StoreState} from '../store'
import {getIncidentBacklog, getIncidentBacklogNOSLA} from './service'
import {getAllPriority, PriorityLevel} from '../incident-management/priority'
import {checkRolesState} from '../authorization-module/permissions/service'
import {RoleType} from '../authorization-module/permissions'

const mapStateToProps = (state: StoreState) => {
    return {
        DashboardIncidentBacklogMyTicket: state.DashboardIncidentBacklogMyTicket,
        priority: state.priorities
    }
}

interface DispatchProps {
    getIncidentBacklog: (showBy, created?, startDate?, endDate?, sortBy?, orderBy?, skip?, limit?) => Promise<number>
    getIncidentBacklogNOSLA: (showBy, created?, startDate?, endDate?, sortBy?, orderBy?, skip?, limit?) => Promise<number>
    getPriorityLevelByType: (type: string) => Promise<number>
}

type StateProps = ReturnType<typeof mapStateToProps>

interface param {
    show: String,
    create: String,
    start?: String,
    end?: String,
    getChangeTable?: (sortBy, orderBy, skip, limit) => void
}

type Props = param & StateProps & DispatchProps

const TableSLAIncidentForDashboard: React.FC<Props> = (props: Props) => {
    const [page, setPage] = useState<number>(1)
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [sortBy, setSortBy] = useState<string>('number')
    const [pageSize, setPageSize] = useState<number>(10)
    const location = useLocation()

    useEffect(() => {
        props.getPriorityLevelByType('priority')
    }, [])

    useEffect(() => {
        if (location.pathname === '/ReportDashBoardNoneSLA') {
            props.getIncidentBacklogNOSLA(props.show, props.create, props.start, props.end, sortBy, orderBy, (page - 1) * pageSize, pageSize)
        } else {
            props.getIncidentBacklog(props.show, props.create, props.start, props.end, sortBy, orderBy, (page - 1) * pageSize, pageSize)
        }
        if (props.getChangeTable) {
            props.getChangeTable(orderBy, sortBy, 0, props.DashboardIncidentBacklogMyTicket.total)
        }
    }, [pageSize, page, orderBy, sortBy, props.show, props.create, props.start, props.end])

    useEffect(() => {
        setPage(1)
    }, [pageSize])

    const SLA = [
        {
            title: 'Ticket Number',
            dataIndex: 'number',
            sorter: true,
            render: (row, record) => (<>
                {checkRolesState(RoleType.Incident, 'ViewIncident')
                    ? <Link to={`/IncidentDetailsView/${record.ticketId}`} target="_blank" >{row}</Link>
                    : <>{row}</>}
            </>)
        },
        {
            title: 'Topic',
            dataIndex: 'subject',
            sorter: true,
            render: (data) => (<>
                <span>{(data as String).length > 25 ? (data as String).substr(0, 25) + '...' : data}</span>
            </>)
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            sorter: true,
            render: (priority) => {
                let colorCode: string = ''
                props.priority.forEach((it: PriorityLevel) => {
                    if (priority === it.name) {
                        colorCode = it.tagColor
                    }
                })

                return (
                    <>
                        <Tag style={{ backgroundColor: colorCode!!, color: '#ffffff' }}>{priority}</Tag>
                    </>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'category',
            sorter: true,
            width: '5%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'SubCategory',
            dataIndex: 'subcategory',
            sorter: true,
            width: '5%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Item',
            dataIndex: 'item',
            sorter: true,
            width: '5%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            sorter: true,
            width: '5%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Incident Status',
            dataIndex: 'status',
            sorter: true
        },
        {
            title: 'Assignment group',
            dataIndex: 'supportTeam',
            sorter: true,
            render: (data) => (<>
                {data ? <span>{(data as String).length > 15 ? (data as String).substr(0, 15) + '...' : data}</span> : ''}
            </>)
        },
        {
            title: 'Assigned to',
            dataIndex: 'assignee',
            sorter: true,
            render: (data) => (<>
                {data ? <span>{(data as String).length > 10 ? (data as String).substr(0, 10) + '...' : data}</span> : ''}
            </>)
        },
        {
            title: 'SLA Name',
            dataIndex: 'slaPolicyName',
            width: '5%',
            sorter: true,
            render: (data) => (<>
                {data ? <span>{(data as String).length > 25 ? (data as String).substr(0, 25) + '...' : data}</span> : ''}
            </>)
        },
        {
            title: 'SLA Goal',
            dataIndex: 'slaGoalName',
            sorter: true,
            width: '5%'
        },
        {
            title: 'Start Time',
            dataIndex: 'measurementClockStartTime',
            sorter: true,
            width: '5%',
            render: (row) => {
                if (row) {
                    return <span>{row.replace('.0', '')}</span>
                }
            }
        },
        {
            title: 'Due Date',
            dataIndex: 'slaTargetTime',
            sorter: true,
            width: '5%',
            render: (row) => {
                if (row) {
                    return <span>{row.replace('.0', '')}</span>
                }
            }
        },
        {
            title: 'SLA',
            dataIndex: 'slaTag',
            sorter: true,
            width: '5%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Completed Time',
            dataIndex: 'measurementClockStopTime',
            sorter: true,
            width: '5%',
            render: (row) => {
                if (row) {
                    return <span>{row.replace('.0', '')}</span>
                }
            }
        },
        {
            title: 'Resolved Time',
            dataIndex: 'resolvedTime',
            sorter: true,
            width: '5%',
            render: (row) => {
                if (row) {
                    return <span>{row.replace('.0', '')}</span>
                }
            }
        }
    ]

    const INCIDENT = [
        {
            title: 'Ticket Number',
            dataIndex: 'number',
            sorter: true,
            render: (data, record) => (<>
                {checkRolesState(RoleType.Incident, 'ViewIncident')
                    ? <Link to={`/IncidentDetailsView/${record.id}`} target="_blank" >{data}</Link>
                    : <>{data}</>}
            </>)
        },
        {
            title: 'Topic',
            dataIndex: 'subject',
            sorter: true,
            render: (data) => (<>
                <span>{(data as String).length > 25 ? (data as String).substr(0, 25) + '...' : data}</span>
            </>)
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            sorter: true,
            render: (priority) => {
                let colorCode: string = ''
                props.priority.forEach((it: PriorityLevel) => {
                    if (priority === it.name) {
                        colorCode = it.tagColor
                    }
                })

                return (
                    <>
                        <Tag style={{ backgroundColor: colorCode!!, color: '#ffffff' }}>{priority}</Tag>
                    </>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'category',
            sorter: true,
            width: '8%',
            // render: (data) => {
            //     return (
            //         <>
            //             <span>{data}</span>
            //         </>
            //     )
            // }
        },
        {
            title: 'SubCategory',
            dataIndex: 'subcategory',
            sorter: true,
            width: '9%',
            // render: (data) => {
            //     return (
            //         <>
            //             <span>{data}</span>
            //         </>
            //     )
            // }
        },
        {
            title: 'Item',
            dataIndex: 'item',
            sorter: true,
            width: '8%',
            // render: (data) => {
            //     return (
            //         <>
            //             <span>{data}</span>
            //         </>
            //     )
            // }
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            sorter: true,
            width: '8%',
            render: (data) => {
                return (
                    <>
                        <span>{data}</span>
                    </>
                )
            }
        },
        {
            title: 'Incident Status',
            dataIndex: 'status',
            sorter: true
        },
        {
            title: 'Assignment group',
            dataIndex: 'supportTeam',
            sorter: true,
            render: (data) => (<>
                {data ? <span>{(data as String).length > 15 ? (data as String).substr(0, 15) + '...' : data}</span> : ''}
            </>)
        },
        {
            title: 'Assigned to',
            dataIndex: 'assignee',
            sorter: true,
            render: (data) => (<>
                {data ? <span>{(data as String).length > 10 ? (data as String).substr(0, 10) + '...' : data}</span> : ''}
            </>)
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            sorter: true,
            render: (row) => {
                if (row) {
                    const splitDate = (row as string).split('T')
                    if (splitDate) {
                        return (
                            <>
                                <span>{splitDate[0]} {(splitDate[1] as string).substr(0, 8)}</span>
                            </>
                        )
                    }
                }
            }
        }
    ]

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter)
        if (sorter.order === 'ascend') {
            setOrderBy('ASC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('number')
            }
        } else if (sorter.order === 'descend') {
            setOrderBy('DESC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('number')
            }
        } else {
            setOrderBy('ASC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('number')
            }
        }
        setPageSize(pagination.pageSize)
    }
    return (
        <>
            <Table
                columns={location.pathname.length < 17 ? SLA : INCIDENT}
                dataSource={props.DashboardIncidentBacklogMyTicket.data!!}
                rowKey='id'
                pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: props.DashboardIncidentBacklogMyTicket.total!!,
                    onChange: (event) => { handleOnClickPageNumber(event) },
                    showSizeChanger: true
                }}
                onChange={handleChangeTable}
                style={{width: location.pathname.length < 17 ? 1800 : 'auto'}}
            />
        </>
    )
}

export default connect(mapStateToProps, {
    getIncidentBacklog, getPriorityLevelByType: getAllPriority, getIncidentBacklogNOSLA
})(TableSLAIncidentForDashboard)
