import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row } from 'antd'
import { Link } from 'react-router-dom'
import '../knowledge.css'

import ApprovalList from './ApprovalList'
import {KnowledgeList, KnowledgeStatus} from '../knowledge'
import { FormComponentProps } from 'antd/es/form'
import { FetchingState, SpinningFetcher } from '../../common-components'
import { Space } from '../../common-components/Space'
import { ApprovalCategories } from './ApprovalCategories'
import { getAllKnowledgeWithApprovalStatus } from './service'

interface StateProps {
    knowledgeList: KnowledgeList
}

type Props = StateProps & FormComponentProps

const ApprovalHome: React.FC<Props> = (props: Props) => {
    const [isFetchingKnowledges, setFetchingKnowledges] = useState<FetchingState>(FetchingState.NotStarted)
    const [knowledgeList, setKnowledgeList] = useState<KnowledgeList>(props.knowledgeList)
    const listOfStatus = [KnowledgeStatus.AwaitingApproval, KnowledgeStatus.AwaitingRetire]

    const param = {
        currentPage: 1, listStatus: listOfStatus
    }
    const fetchData = () => {
        setFetchingKnowledges(FetchingState.Started)
        getAllKnowledgeWithApprovalStatus(param).then(knowledge => {
            setKnowledgeList(knowledge)
            setFetchingKnowledges(FetchingState.Succeed)
        }).catch(error => {
            console.log(error)
            setFetchingKnowledges(FetchingState.Failed)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <Breadcrumb className={'content'}>
                <Breadcrumb.Item>
                    <Link to="#">Approval Center</Link>
                </Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10}/>
            <Row gutter={20} className='fix_scroll_md'>
                <ApprovalCategories selectFolder={() => {}} countKnowledge={knowledgeList ? knowledgeList.pagination?.totalElements : 0}/>
                <SpinningFetcher fetchingState={isFetchingKnowledges}>
                    <ApprovalList knowledgeList={knowledgeList} />
                </SpinningFetcher>
            </Row>
        </>
    )
}

export default ApprovalHome
