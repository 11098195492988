import {Dispatch} from 'redux'
import {axiosGet, axiosPost} from '../../../rest'
import {JobWorkLog, WorklogResponse, WoWorklog} from './model'
import {GetAllWorklogByJobNoSuccessEvent, WorklogAddedEvent} from './state-event'

export const getAllWorkLogByJobNo = (jobNo: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<JobWorkLog[]>(`/api/worklog/details/${jobNo}`)
            dispatch(GetAllWorklogByJobNoSuccessEvent.build(response.data.data!!))
            return response.data.data
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}

export const addWorkLog = (workLog: JobWorkLog) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<JobWorkLog>('/api/worklog', workLog)
            dispatch(WorklogAddedEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getAllWorkLogByWoNo = async (woNo: string) => {
    try {
        const response = await axiosGet<WorklogResponse[]>(`/api/worklog/detailForMsp/${woNo}`)
        return response.data.data!!
    } catch (err) {
        console.log(err.response)
        throw err
    }
}

export const addWoWorkLog = async (workLog: WoWorklog) => {
    try {
        const response = await axiosPost<WorklogResponse[]>('/api/woWorklog', workLog)
        return response.data.data!!
    } catch (err) {
        console.log(err)
        throw err
    }
}
