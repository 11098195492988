/* eslint-disable react/display-name */
import { Icon, Table, Modal } from 'antd'
import React, { useState } from 'react'
import { getAssetSignedDetailForPrint } from '../asset-management/service'
import PMSignature from './signature/PMSignature'

interface Param {
    data: any
}

type Props = Param

const TableSelectLocationForPrint: React.FC<Props> = (props: Props) => {
    const [visibleModal, setVisibleModal] = useState<boolean>(false)
    const [dataForPrint, setDataForPrint] = useState<Object>({})
    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (row) => <a href='#' onClick={() => handlePrint(row)}><Icon type="printer" /></a>
        },
        {
            title: 'No',
            dataIndex: '',
            key: '',
            render: (row, i, j) => <span>{j + 1}</span>
        },
        {
            title: 'Sign Number',
            dataIndex: 'signNo',
            key: 'signNo'
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location'
        },
        {
            title: 'Signed Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (row) => <span>
                {row.length > 19 ? (row as string).substring(0, 19) : row }
            </span>
        }]

    const handlePrint = (row) => {
        const tempData = {}
        getAssetSignedDetailForPrint(row.signNo).then((res) => {
            res.forEach((data) => {
                console.log(data)
                tempData[data.woNo] = data.assetWorkOrderList
            })
            setDataForPrint(tempData)
            setVisibleModal(true)
        })
    }

    const handleOk = () => {
        setVisibleModal(false)
    }

    const handleCancel = () => {
        setVisibleModal(false)
    }

    const handleSign = () => {
        console.log('sign')
    }
    return (
        <div>
            <Table rowKey='signNo' columns={columns} dataSource={props.data} pagination={false} />
            <Modal
                title=""
                visible={visibleModal}
                onOk={handleOk}
                onCancel={handleCancel}
                style={{ minWidth: 1024, padding: '5%' }}
                width={'100%'}
                footer={null}
            >
                <PMSignature assetItems={dataForPrint} isSignature={true} isClosed={false} isForPrint={true} handleSign={handleSign}/>
            </Modal>
        </div>
    )
}

export default TableSelectLocationForPrint
