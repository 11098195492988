import {BaseDocument, BaseEntity, BaseEntityHistory} from '../../common-model'

export interface BusinessHour extends BaseEntityHistory {
    name: string
    description: string
    fullTime: boolean
    breakTimeStart?: string
    breakTimeEnd?: string
    version?: number
    workingDays?: WorkingDay[]
    holidays?: Holiday[]
    activate?: boolean
    active?: Boolean

}

export interface Holiday {
    holidayName?: string
    date?: string
    version?: number
}

export enum DayOfWeek {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}

export interface WorkingDay {
    businessHourId?: number
    dayOfWeek?: DayOfWeek
    start?: string
    stop?: string
}
