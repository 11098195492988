/* eslint-disable no-unused-expressions */
import { Dispatch } from 'redux'
import { UserRole, PermissionAndScope, RoleState, RoleType, ScopeType } from './model'
import { SavePermissionAndScopeListEvent } from './state-event'
// import { modelOfSupportTeam } from '../incident-management/support-team'
import { Permissions } from './permissions'
// import { SupportTeams } from '../incident-management/support-team/supportTeams'
// import { connect } from 'react-redux'

export const RoleUtils = {
    initRoleState: (permissionAndScope: (PermissionAndScope | undefined)[]) => {
        const rolesMerge: PermissionAndScope[] = []
        // var rolesScopeList: string[] = []
        var rolesScopeList = permissionAndScope.map(role => role?.scope)
        // for (const roles of permissionAndScope) {
        //     const permissionList = roles?.map(role => role.scope) || []
        //     rolesScopeList.push(...permissionList)
        // }
        rolesScopeList = rolesScopeList.filter(scope => typeof scope === 'string')
        rolesScopeList = RoleUtils.removeDups(rolesScopeList)
        for (const roles of permissionAndScope) {
            for (const role of permissionAndScope) {
                for (let index = 0; index < rolesScopeList.length; index++) {
                    if (role && role.permissions && role.scope === rolesScopeList[index]) {
                        if (rolesMerge[index] === undefined) {
                            rolesMerge[index] = role
                        } else {
                            const oldData = rolesMerge[index].permissions || []
                            rolesMerge[index].permissions = RoleUtils.removeDups([...oldData, ...role.permissions])
                        }
                    }
                }
            }
            // for (const role of roles || []) {
            //     for (let index = 0; index < rolesScopeList.length; index++) {
            //         if (role && role.permission && role.scope === rolesScopeList[index]) {
            //             if (rolesMerge.filter((roleData) => { return roleData.permission === role.permission && roleData.scope === role.scope }).length === 0) {
            //                 rolesMerge.push(role)
            //             }
            //         }
            //     }
            // }
        }
        return rolesMerge
    },
    removeDups: (names) => {
        const unique = {}
        names.forEach(function(i) {
            if (!unique[i]) {
                unique[i] = true
            }
        })
        return Object.keys(unique)
    }
}

export function dispatchRoles(dispatch: Dispatch, roles: UserRole[]) {
    if (roles) {
        const rolesMerge: RoleState = {
            security: RoleUtils.initRoleState(roles.map(role => role.security)),
            incident: RoleUtils.initRoleState(roles.map(role => role.incident)),
            knowledge: RoleUtils.initRoleState(roles.map(role => role.knowledge)),
            slaPolicy: RoleUtils.initRoleState(roles.map(role => role.slaPolicy))
        }
        localStorage.setItem('role', JSON.stringify(rolesMerge))
        dispatch(SavePermissionAndScopeListEvent.build(rolesMerge))
    }
}

export const loadRolesState = () => {
    return async (dispatch: Dispatch) => {
        try {
            const userRolesString = localStorage.getItem('userRoles')
            if (!userRolesString) {
                throw Error('no userRoles')
            }
            console.log(userRolesString)
            if (userRolesString) {
                const userRoles = JSON.parse(userRolesString)
                // const response = await axiosGet<ResponseUserRole[]>('/api/authority/userRoles/getCurrentUserRoles')
                // const roles = response.data.data!!
                dispatchRoles(dispatch, userRoles)
            }
            return 200
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const checkRolesState = (roleType: RoleType, permissionName: string, createdBy?: string) => {
    const localRoles = localStorage.getItem('role')
    let roleState = Permissions.getInstance().roleState
    if (localRoles) {
        roleState = JSON.parse(localRoles)
    }

    if (roleState === {}) {
        return false
    }

    const checkAccessPermission = (roles: PermissionAndScope[]) => {
        for (const role of roles) {
            if (role.permissions?.includes(permissionName)) { return true }
        }
        return false
    }

    if (roleType === RoleType.Security) {
        const securityRoles = roleState.security || []
        if (checkAccessPermission(securityRoles)) return true
    } else if (roleType === RoleType.Incident) {
        const incidentRoles = roleState.incident || []
        if (checkAccessPermission(incidentRoles)) return true
    } else if (roleType === RoleType.Knowledge) {
        const knowledgeRoles = roleState.knowledge || []
        if (checkAccessPermission(knowledgeRoles)) return true
    } else if (roleType === RoleType.SlaPolicy) {
        const slaPolicyRoles = roleState.slaPolicy || []
        if (checkAccessPermission(slaPolicyRoles)) return true
    }

    return false
}
