import {buildStateEvent, GenericStateEvent} from '../../../common-redux'
import {Job} from './model'

export interface JobStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllJobSuccessEvent = {
    type: 'GetAllJobSuccessEvent',
    build: (payload: Job[]) => buildStateEvent(GetAllJobSuccessEvent.type, payload)
}

export const JobCreatedEvent = {
    type: 'JobCreatedEvent',
    build: (payload: Job) => buildStateEvent(JobCreatedEvent.type, payload)
}

export const JobUpdatedEvent = {
    type: 'JobUpdatedEvent',
    build: (payload: Job) => buildStateEvent(JobUpdatedEvent.type, payload)
}
