import {
    AddBusinessHourSuccessEvent,
    BusinessHourStateEvent,
    DeleteBusinessHourSuccessEvent,
    GetAllBusinessHourSuccessEvent,
    UpdateBusinessHourSuccessEvent
} from './state-event'
import { BusinessHour } from './model'

export const businessHourStateReducer = (state: BusinessHour[] = [], event: BusinessHourStateEvent<any>) => {
    if (event.type === GetAllBusinessHourSuccessEvent.type) {
        return event.payload || []
    } else if (event.type === AddBusinessHourSuccessEvent.type) {
        return [...state, event.payload]
    } else if (event.type === DeleteBusinessHourSuccessEvent.type) {
        return state.filter((it) => it.id !== event.payload)
    } else if (event.type === UpdateBusinessHourSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}
