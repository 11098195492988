import React from 'react'
import { Avatar } from 'antd'

const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae']

interface Prop {
    title: string
    broken: boolean
}

const AutoAvatar: React.FC<Prop> = (props: Prop) => {
    var codeColorIndex = 0
    if (props.title) {
        for (var i = 0; i < props.title.length; i++) {
            codeColorIndex += props.title.charCodeAt(i)
        }
    }

    const color = colorList[codeColorIndex % 4]
    return (
        <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size="large" src={props.title || ''}>
            {props.broken === true ? '' : props.title?.substr(0, 1) || ''}
        </Avatar>
    )
}

export default AutoAvatar
