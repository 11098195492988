import React, {PropsWithChildren, useEffect, useState} from 'react'
import {Breadcrumb, Button, Card, Col, Form, Icon, message, Row, Upload, AutoComplete} from 'antd'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {StoreState} from '../../store'
import {
    Category, getAllCategoriesWithPagination,
    addCategory,
    importExcel,
    RollbackDataFromFileToCategoryForExcel,
    updateCategory,
    getAllCategoryNoRedux
} from '../../incident-management/category'
import * as XLSX from 'xlsx'
import {UploadFile} from 'antd/lib/upload/interface'
import TableCategory from './TableCategory'
import {FormComponentProps} from 'antd/es/form'

const mapStateToProps = (state: StoreState) => {
    return { categories: state.categories }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    addCategory: (category: Category) => Promise<number>;
    updateCategory: (category: Category) => Promise<number>;
    importExcel: (category: Category[]) => Promise<number>;
    getAllCategoriesWithPagination: (page: number, pageSize: number, searchField?: string, searchValue?: string, active?: boolean[], sortBy?: string, orderBy?: string) => Promise<number>;
}

interface Params {

}

type Props = Params & StateProps & DispatchProps & PropsWithChildren<Params> & FormComponentProps

const CategoryForm = (props: Props) => {
    const { getFieldDecorator, resetFields, validateFields, setFieldsValue, getFieldValue } = props.form
    const [myFile, setMyFile] = useState<UploadFile[]>([])
    const [categoryNames, setCategoryNames] = useState<string[]>([])
    const [catAll, setCatAll] = useState<Category[]>([])
    const [subCatName, setSubCatName] = useState<string[]>([])
    const [itemName, setItemName] = useState<string[]>([])
    const username = localStorage.getItem('username') || ''
    const [RequiredSubCat, setRequiredSubCat] = useState<boolean>(false)
    const [RequiredItem, setRequiredItem] = useState<boolean>(false)
    const [tempCat, setTempCat] = useState<string>('')
    const [dataUpdate, setDataUpdate] = useState<Category>()
    const [allCatName, setAllCatName] = useState<string[]>([])
    const [page, setPage] = useState<number>(1)

    useEffect(() => {
        getAllCatName()
    }, [])

    const downloadData = async () => {
        getAllCategoryNoRedux().then((response) => {
            if (response) {
                const ws1 = XLSX.utils.json_to_sheet(response)
                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, ws1, 'Categories')
                XLSX.writeFile(wb, 'Category.xlsx')
            }
        })
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setMyFile(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList)
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`)
            } else if (info.file.status === 'error') {
                // message.error(`${info.file.name} file upload failed.`)
                setMyFile([])
            }
        },
        beforeUpload: (file: any) => {
            const isXlsx = file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            if (isXlsx) {
                message.error('You can only upload xlsx file!')
                setMyFile([])
            } else {
                const reader = new FileReader()
                reader.readAsBinaryString(file)
                reader.onloadend = function (e: any) {
                    const bstr = e.target.result
                    const wb = XLSX.read(bstr, { type: 'binary' })
                    /* Get first worksheet */
                    const wsName = wb.SheetNames[0]
                    const ws = wb.Sheets[wsName]
                    /* Convert array of arrays */
                    const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
                    /* Update state */
                    const myCat: Category[] = RollbackDataFromFileToCategoryForExcel(data)
                    props.importExcel(myCat).then(() => {
                        props.getAllCategoriesWithPagination(1, 10, '0', '', [true, false], 'createdDate', 'ASC').then(() => {
                            message.success('You have successfully saved the data.')
                            setMyFile([])
                        })
                    }).catch((err) => {
                        message.error(`You have unSuccessfully save the data. ${err}`)
                    })

                    // setMyFile((state: any) =>
                    //     ([...state, {
                    //         uid: file.uid,
                    //         name: file.name,
                    //         status: 'done',
                    //         url: reader.result,
                    //         type: base64MimeType(reader.result),
                    //         size: e.total
                    //     }])
                    // )
                }
            }
            return isXlsx
        },
        showUploadList: { showDownloadIcon: false }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        validateFields((err, values) => {
            if (!err) {
                const NewCategory: Category = {
                    category: values.catName,
                    subCategory: values.subCat,
                    item: values.item,
                    active: true,
                    createdBy: username,
                    lastModifiedBy: username
                }
                if (!dataUpdate) {
                    props.addCategory(NewCategory).then(() => {
                        message.success('You have successfully saved the data.')
                        getAllCatName()
                        fetchData()
                    }).catch((err) => {
                        message.error(`You have unSuccessfully save the data. ${err}`)
                    })
                } else {
                    // edit
                    const updateData = {
                        id: dataUpdate.id,
                        category: values.catName,
                        subCategory: values.subCat,
                        item: values.item,
                        active: dataUpdate.active,
                        createdBy: username,
                        lastModifiedBy: username
                    }
                    props.updateCategory(updateData).then(() => {
                        message.success('You have successfully update the data.')
                        setDataUpdate(undefined)
                    }).catch((err) => {
                        message.error(`You have unSuccessfully save the data. ${err}`)
                    })
                }
                resetFields()
            }
        })
    }

    const fetchData = () => {
        props.getAllCategoriesWithPagination(page, 10, '0', '', [true, false], 'createdDate', 'ASC').then(() => {
            setMyFile([])
        })
    }

    const getPage = (page: number) => {
        setPage(page)
    }

    const handleOnClickInOtherPage = (check: boolean) => {
        if (check) {
            props.form.setFieldsValue({
                catName: undefined
            })
        }
    }

    const getAllCatName = async () => {
        getAllCategoryNoRedux().then((res) => {
            setCatAll(res || [])
            const tempCat = (res || []).map((cat) => {
                return cat.category!!
            })
            setAllCatName(tempCat || [])
        })
    }

    const getAllCategory = (category: string[]) => {
        setCategoryNames(category)
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        let invalid = false;
        (props.categories || []).map((it) => {
            if (it.category === props.form.getFieldValue('catName') &&
                it.subCategory === props.form.getFieldValue('subCat') &&
                it.item === props.form.getFieldValue('item')) {
                invalid = true
            }
        })
        if (invalid) {
            callback('The item Name is already exists. It must be unique. Please try another one.')
        } else {
            callback()
        }
    }

    const checkRequiredSubCat = (data, type: string) => {
        if (type === 'Cat') {
            const check = catAll.some((res) => res.category === data)
            if (check) {
                setRequiredSubCat(true)
            } else {
                setRequiredSubCat(false)
            }
        } else if (type === 'item') {
            if (data) {
                setRequiredSubCat(true)
            } else {
                setRequiredSubCat(false)
            }
        } else if (type === 'subCat') {
            if (data) {
                const filterSubCat = catAll.filter((cat) => cat.category === getFieldValue('catName'))
                const check = filterSubCat.some((res) => res.subCategory === data)
                if (check) {
                    setRequiredItem(true)
                } else {
                    setRequiredItem(false)
                }
            }
        }
    }

    const getDataToEdit = (data: Category) => {
        setFieldsValue({
            catName: data.category,
            subCat: data.subCategory,
            item: data.item
        })
        setDataUpdate(data)
    }

    const handelSelectCat = (value) => {
        setTempCat(value)
        const filterByCategoryName = catAll.filter(it => it.category === value)
        const subCatName: string[] = []
        filterByCategoryName.forEach((cat) => {
            if (cat.subCategory) {
                subCatName.push(cat.subCategory)
            }
        })
        setSubCatName([...new Set(subCatName)])
    }

    const handelSelectSubCat = (value) => {
        const filterByCategoryName = catAll.filter(it => it.category === getFieldValue('catName'))
        const filterBySubCat = filterByCategoryName.filter(it => it.subCategory === value)
        const itemName: string[] = []
        filterBySubCat.forEach((cat) => {
            if (cat.item) {
                itemName.push(cat.item)
            }
        })
        setItemName([...new Set(itemName)])
    }

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Category</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Card >
                <Row>
                    <Col span={20} lg={20} md={20} sm={24} xs={24}>
                        <h2 className='main-title'>Category</h2>
                    </Col>
                    <Col span={2} lg={2} md={2} sm={24} xs={24} style={{ paddingLeft: '20px' }}>
                        <Upload
                            {...uploadProps}
                            listType="text"
                            className="upload-list-inline"
                            showUploadList={false}
                            multiple={false}
                        >
                            <Button
                                // disabled={myFile.length === 1}
                                // disabled={true}
                            // className={myFile.length === 1 ? '' : 'addCriteriaButton'}
                            >
                                <Icon type="download" /> Import</Button>
                        </Upload>
                    </Col>
                    <Col span={2} lg={2} md={2} sm={24} xs={24} style={{ paddingLeft: '20px' }}>
                        <Button
                            onClick={() => downloadData()}
                            className="addCriteriaButton"
                            // disabled={true}
                        >
                            <Icon type="upload" /> Export</Button>
                    </Col>
                </Row>
                <br />
                <Form onSubmit={handleSubmit}>
                    <Row style={{ background: '#FFF2E8' }} gutter={[10, 10]}>
                        <Col span={6} lg={6} md={5} sm={24} xs={24}>
                            <Form.Item label={'Category Name'}>
                                {getFieldDecorator('catName',
                                    {
                                        initialValue: undefined,
                                        rules: [{ required: true, message: 'Category Name is required' },
                                            { min: 3, message: 'Enter more than 3 characters' },
                                            { max: 250, message: 'message max 250 characters' }
                                        ]
                                    })(
                                    <AutoComplete
                                        dataSource={[...new Set(allCatName)]}
                                        placeholder="Category Name"
                                        onSelect={(e) => handelSelectCat(e)}
                                        onChange={(e) => checkRequiredSubCat(e, 'Cat')}
                                        filterOption={(inputValue, option) =>
                                            (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6} lg={6} md={5} sm={24} xs={24}>
                            <Form.Item label={'Subcategory Name'}>
                                {getFieldDecorator('subCat',
                                    {
                                        initialValue: undefined,
                                        rules: [{ required: RequiredSubCat, message: 'Subcategory Name is required' },
                                            { min: 3, message: 'Enter more than 3 characters' },
                                            { max: 250, message: 'message max 250 characters' }
                                        ]
                                    })(
                                    <AutoComplete
                                        dataSource={subCatName}
                                        placeholder="Subcategory Name"
                                        onSelect={(e) => handelSelectSubCat(e)}
                                        onChange={(e) => checkRequiredSubCat(e, 'subCat')}
                                        filterOption={(inputValue, option) =>
                                            (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6} lg={6} md={5} sm={24} xs={24}>
                            <Form.Item label={'Item Name'}>
                                {getFieldDecorator('item',
                                    {
                                        initialValue: undefined,
                                        rules: [{ required: RequiredItem, message: 'item is required' },
                                            { min: 3, message: 'Enter more than 3 characters' },
                                            { max: 250, message: 'message max 250 characters' },
                                            { validator: validateNameUniqueness }
                                        ]
                                    })(
                                    <AutoComplete
                                        dataSource={itemName}
                                        placeholder="item Name"
                                        onChange={(e) => checkRequiredSubCat(e, 'item')}
                                        filterOption={(inputValue, option) =>
                                            (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={3} lg={3} md={3} sm={24} xs={24}>
                            <Form.Item style={{ marginTop: 40}}>
                                <Button type="default" style={{width: '-webkit-fill-available'}} htmlType="reset" onClick={() => resetFields()}>Cancel</Button>
                            </Form.Item>
                        </Col>
                        <Col span={3} lg={3} md={3} sm={24} xs={24}>
                            <Form.Item style={{ marginTop: 40 }}>
                                <Button className="addCriteriaButton" style={{width: '-webkit-fill-available'}} htmlType="submit">Submit</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <div style={{ overflow: window.innerWidth <= 768 ? 'auto' : 'none' }}>
                        <TableCategory getAllCategory={getAllCategory} handleOnClickInOtherPage={handleOnClickInOtherPage} getDataToEdit={getDataToEdit} getPage={getPage}/>
                    </div>
                </Form>
            </Card>
        </>
    )
}

const SlaCategorySettingPage = Form.create({ name: 'CategoryForm' })(CategoryForm)

export default connect(mapStateToProps, {
    addCategory, updateCategory, importExcel, getAllCategoriesWithPagination
})(SlaCategorySettingPage)
