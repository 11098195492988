import React from 'react'
import { Card, Icon, Row, Col, Button } from 'antd'
import { Link } from 'react-router-dom'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { StoreState } from '../../store'
import { connect } from 'react-redux'

const mapStateToProps = (state: StoreState) => {
    return {
        permission: state.currentUserRole
    }
}

interface Props {
}

const settingDash: React.FC<Props> = (props: Props) => {
    return (
        <div>
            <div className="container-fluid justify-content-center m-2" >
                <div className="row p-1">
                    <div className="col-12">
                        <Card className="myCardSetting">
                            <p className="main-title">Incident Settings</p>
                            <Row gutter={32} style={{padding: '30px 30px 5px'}}>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" style={{ width: '100%', textAlign: 'left'}} >
                                        <Link to="/GeneralIncidentSettingForm"> <Icon type="setting" />
                                            <span className={'menu-setting-page'}>General Incident Setting</span> </Link>
                                    </Button>
                                </Col>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" disabled={false} style={{ width: '100%', textAlign: 'left'}}>
                                        <Link to="/PrioritySetting"><Icon type="exclamation-circle" />
                                            <span className={'menu-setting-page'}>Priority</span></Link>
                                    </Button>
                                </Col>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" style={{ width: '100%', textAlign: 'left' }}>
                                        <Link to="/CategorySetting" > <Icon type="code-sandbox" />
                                            <span className={'menu-setting-page'}>Category</span></Link>
                                    </Button>
                                </Col>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" style={{ width: '100%', textAlign: 'left' }}>
                                        <Link to="/SupportTeamSettingList"> <Icon type="team" />
                                            <span className={'menu-setting-page'}>Group</span> </Link>
                                    </Button>
                                </Col>
                            </Row>
                            <Row gutter={32} style={{padding: '10px 30px 20px'}}>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" style={{ width: '100%', textAlign: 'left' }}>
                                        <Link to="/ConfigurationFiledSettingList"> <Icon type="bars" />
                                            <span className={'menu-setting-page'}>Custom Field Setting</span> </Link>
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                        <Card className="myCardSetting">
                            <p className="main-title">SLA Settings</p>
                            <Row gutter={32} style={{padding: 30}}>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" style={{ width: '100%', textAlign: 'left' }} disabled={!checkRolesState(RoleType.SlaPolicy, 'ManageSlaGlobalSettings')}>
                                        <Link to="/GlobalSetting"> <Icon type="setting" />
                                            <span className={'menu-setting-page'}>General SLA Setting</span> </Link>
                                    </Button>
                                </Col>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" style={{ width: '100%', textAlign: 'left' }} disabled={!checkRolesState(RoleType.SlaPolicy, 'ManageSlaPolicy')}>
                                        <Link to="/SlaPolicySettingList"> <Icon type="clock-circle" />
                                            <span className={'menu-setting-page'}>SLA Management</span>
                                        </Link>
                                    </Button>
                                </Col>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" style={{ width: '100%', textAlign: 'left' }} disabled={!checkRolesState(RoleType.SlaPolicy, 'ManageBusinessHours')}>
                                        <Link to="/BusinessHourSettingList"> <Icon type="calendar" />
                                            <span className={'menu-setting-page'}>Business Hours</span> </Link>
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                        <Card className="myCardSetting">
                            <p className="main-title">Security Settings</p>
                            <Row gutter={32} style={{padding: 30}}>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" style={{ width: '100%', textAlign: 'left' }} disabled={true}>
                                        <Link to="/GlobalSetting"> <Icon type="setting" />
                                            <span className={'menu-setting-page'}>General Security Setting</span> </Link>
                                    </Button>
                                </Col>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" style={{ width: '100%', textAlign: 'left' }} disabled={!checkRolesState(RoleType.Security, 'ManageRoleAndPermission')}>
                                        <Link to="/RoleAndPermissionsSettingList"> <Icon type="key" />
                                            <span className={'menu-setting-page'}>Role and Permission</span></Link>
                                    </Button>
                                </Col>
                                <Col span={6} lg={6} md={12} sm={24} xs={24}>
                                    <Button type="dashed" style={{ width: '100%', textAlign: 'left' }} disabled={!checkRolesState(RoleType.Security, 'ManageUserRole')}>
                                        <Link to="/UserSetting"> <Icon type="user" />
                                            <span className={'menu-setting-page'}>User</span> </Link>
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default connect(mapStateToProps)(settingDash)
