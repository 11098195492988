import React from 'react'
import { useDrag, useDrop, createDndContext } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export const RNDContext = createDndContext(HTML5Backend)

const type = 'DragableBodyRow'

export const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }: any) => {
    const ref = React.useRef()
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: monitor => {
            const { index: dragIndex } = monitor.getItem() || {}
            if (dragIndex === index) {
                return {}
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
            }
        },
        drop: (item:any) => {
            moveRow(item.index, index)
        }
    })
    const [, drag] = useDrag({
        item: { type, index },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    })
    drop(drag(ref))
    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            {...restProps}
        />
    )
}
