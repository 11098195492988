import { Dispatch } from 'redux'
import { axiosGet, axiosPost } from '../incident-management/rest'
import { Dashboard, DataForChart, Color, ChartType, GroupType, ColorSLA } from './model'
import {
    GetIncidentBacklogMyticketSuccessEvent, GetOpenSLAMyGroupSuccessEvent, GetOpenSLAMyticketSuccessEvent,
    GetOpenSLATotalGroupSuccessEvent, GetOpenTicketMyGroupSuccessEvent, GetOpenTicketTotalGroupSuccessEvent, GetOpenbyPriorityTicketMyGroupSuccessEvent
    , GetOpenbyPriorityTicketMyticketSuccessEvent, GetOpenbyPriorityTicketTotalGroupSuccessEvent, GetOpenbyStatusTicketMyGroupSuccessEvent, GetOpenbyStatusTicketMyticketSuccessEvent
    , GetOpenbyStatusTicketTotalGroupSuccessEvent
} from './state-event'

export const getDashboard = (Group: GroupType, created: String, Chart: ChartType, startDate, endDate) => {
    return async (dispatch: Dispatch) => {
        try {
            let Uri = ''
            if (created === 'anytime' && startDate !== undefined && endDate !== undefined) {
                Uri = `/api/dashboard/${Chart}?showBy=${Group}&startDate=${startDate}&endDate=${endDate}`
            } else {
                Uri = `/api/dashboard/${Chart}?showBy=${Group}&created=${created}`
            }
            const response = await axiosGet<Dashboard>(Uri)
            if (Group === GroupType.TotalGroup) {
                if (Chart === ChartType.OpenTicket) {
                    dispatch(GetOpenTicketTotalGroupSuccessEvent.build(response.data.data!!))
                } else if (Chart === ChartType.OpenByStatus) {
                    dispatch(GetOpenbyStatusTicketTotalGroupSuccessEvent.build(response.data.data!!))
                } else if (Chart === ChartType.OpenByPriority) {
                    dispatch(GetOpenbyPriorityTicketTotalGroupSuccessEvent.build(response.data.data!!))
                } else if (Chart === ChartType.OpenSLA) {
                    dispatch(GetOpenSLATotalGroupSuccessEvent.build(response.data.data!!))
                }
            } else if (Group === GroupType.MyGroup) {
                if (Chart === ChartType.OpenTicket) {
                    dispatch(GetOpenTicketMyGroupSuccessEvent.build(response.data.data!!))
                } else if (Chart === ChartType.OpenByStatus) {
                    dispatch(GetOpenbyStatusTicketMyGroupSuccessEvent.build(response.data.data!!))
                } else if (Chart === ChartType.OpenByPriority) {
                    dispatch(GetOpenbyPriorityTicketMyGroupSuccessEvent.build(response.data.data!!))
                } else if (Chart === ChartType.OpenSLA) {
                    dispatch(GetOpenSLAMyGroupSuccessEvent.build(response.data.data!!))
                }
            } else if (Group === GroupType.MyTicket) {
                if (Chart === ChartType.OpenByStatus) {
                    dispatch(GetOpenbyStatusTicketMyticketSuccessEvent.build(response.data.data!!))
                } else if (Chart === ChartType.OpenByPriority) {
                    dispatch(GetOpenbyPriorityTicketMyticketSuccessEvent.build(response.data.data!!))
                } else if (Chart === ChartType.OpenSLA) {
                    dispatch(GetOpenSLAMyticketSuccessEvent.build(response.data.data!!))
                }
            }
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getIncidentBacklog = (showBy: String, created?: String, startDate?: String, endDate?: String, sortBy?: String, orderBy?: String, skip?: String, limit?: String) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Dashboard>(`/api/dashboard/slaBacklog?showBy=${showBy}${created === 'anytime' ? '' : '&created=' + created}${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}${sortBy ? '&sortBy=' + sortBy : ''}${orderBy ? '&orderBy=' + orderBy : ''}${skip ? '&skip=' + skip : ''}${limit ? '&limit=' + limit : ''}`)
            dispatch(GetIncidentBacklogMyticketSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getIncidentBacklogNOSLA = (showBy: String, created?: String, startDate?: String, endDate?: String, sortBy?: String, orderBy?: String, skip?: String, limit?: String) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Dashboard>(`/api/dashboard/incidentBacklog?showBy=${showBy}&created=${created}${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}${sortBy ? '&sortBy=' + sortBy : ''}${orderBy ? '&orderBy=' + orderBy : ''}${skip ? '&skip=' + skip : ''}${limit ? '&limit=' + limit : ''}`)
            dispatch(GetIncidentBacklogMyticketSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const genReport = async (showBy: String, created?: String, startDate?: String, endDate?: String, sortBy?: String, orderBy?: String, skip?: String, limit?: String) => {
    try {
        const response = await axiosPost(`/api/dashboard/genSlaReport?showBy=${showBy}&created=${created}${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}${sortBy ? '&sortBy=' + sortBy : ''}${orderBy ? '&orderBy=' + orderBy : ''}${skip ? '&skip=' + skip : ''}${limit ? '&limit=' + limit : ''}`)
        console.log(response)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const genReportNOSLA = async (showBy: String, created?: String, startDate?: String, endDate?: String, sortBy?: String, orderBy?: String, skip?: String, limit?: String) => {
    try {
        const response = await axiosPost(`/api/dashboard/genTicketIncidentReport?showBy=${showBy}&created=${created}${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}${sortBy ? '&sortBy=' + sortBy : ''}${orderBy ? '&orderBy=' + orderBy : ''}${skip ? '&skip=' + skip : ''}${limit ? '&limit=' + limit : ''}`)
        console.log(response)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const downloadReport = (reportName) => {
    return async () => {
        try {
            const response = await axiosPost(`/api/dashboard/downloadReport?reportName=${reportName}`)
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}

export const ConvertDataForChart = (data, customColor?:String[]): DataForChart => {
    const Data: DataForChart = {
        labels: [],
        datasets: []
    }
    var value: Array<number> = []
    if (data) {
        Object.keys(data).forEach((it) => {
            Data.labels.push(it)
            value.push(data[it])
        })

        if (Object.keys(data).includes('Due Soon')) {
            Data.datasets.push({ data: value, backgroundColor: ColorSLA, hoverBackgroundColor: ColorSLA })
        } else if (Object.keys(data).includes('Low')) {
            Data.datasets.push({ data: value, backgroundColor: customColor!!, hoverBackgroundColor: customColor!! })
        } else {
            Data.datasets.push({ data: value, backgroundColor: Color, hoverBackgroundColor: Color })
        }
    }
    return Data
}
