import React from 'react'
import {StoreState} from '../../store'
import {connect} from 'react-redux'
import moment from 'moment'
import ViewSelection from './ViewSelection'
import {IncidentFilter, notifyCurrentViewCriteriaChanged} from './index'
import {
    AgentFilter,
    CategoryFilter,
    CreatedFilter,
    DueByFilter,
    PriorityFilter,
    RequesterFilter
} from './incident-filter'
import StatusFilter from './incident-filter/StatusFilter'
import {message} from 'antd'
import {Status} from '../status'
import {Category} from '../category'
import {PriorityLevel} from '../priority'
import {IncidentViewState} from './state-model'
import {TicketStatus} from '../incident'

interface StateProps {
    statuses: TicketStatus[]
    categories: Category[]
    impacts: PriorityLevel[]
    priorities: PriorityLevel[]
    incidentViewState: IncidentViewState
    criteria: IncidentFilter
    notifyCurrentViewCriteriaChanged: (criteria: IncidentFilter) => Promise<void>
}

type Props = StateProps

const IncidentFilterForm: React.FC<Props> = (props: Props) => {
    const notifyCurrentViewCriteriaChanged =
        props.notifyCurrentViewCriteriaChanged as (IncidentFilter) => Promise<undefined>

    function modifyCriteria(criteria: IncidentFilter) {
        notifyCurrentViewCriteriaChanged(criteria).catch((error) => message.error(error.toString()))
    }

    const onAgentCriteriaChanged = (value: string[]) => {
        modifyCriteria({
            ...props.criteria,
            assignedToMe: value.includes('assignedToMe'),
            unassigned: value.includes('unassigned'),
            assignedToMyTeam: value.includes('assignedToMyTeam')
        })
    }

    const onCategoryChanged = (value: string[]) => {
        const tempValue = value.join(',')
        modifyCriteria({...props.criteria, categories: tempValue})
    }

    const onDueByChanged = (value: string[]) => {
        const tempValue = value.join(',')
        modifyCriteria({...props.criteria, dueByList: tempValue})
    }

    const onRequesterChanged = (value: string | undefined) => {
        modifyCriteria({...props.criteria, requesterName: value})
    }

    const onStatusChanged = (value: string[]) => {
        const tempValue = value.length > 0 ? value.join(',') : undefined
        modifyCriteria({...props.criteria, statuses: tempValue})
    }

    const onPriorityChanged = (value: string[]) => {
        const tempValue = value.length > 0 ? value.join(',') : undefined
        modifyCriteria({...props.criteria, priorities: tempValue})
    }

    const onCreatedValueChanged = (value: string | undefined | string[]) => {
        const patch: IncidentFilter = {}
        if (typeof value === 'string' || value === undefined) {
            patch.created = value
            if (value === undefined) {
                patch.created = 'anytime'
                patch.createdStart = undefined
                patch.createdEnd = undefined
            }
        } else {
            const format = (time: string) => moment(time).format('YYYY-MM-DD')
            patch.createdStart = format(value[0])
            patch.createdEnd = format(value[1])
            patch.created = undefined
        }
        modifyCriteria({...props.criteria, ...patch})
    }

    const criteria = props.incidentViewState.currentView!!.criteria

    return (
        <>
            <ViewSelection/>
            <h3 className={'sub-title'}>Filter</h3>
            <a href={'#'} onClick={() => {
                window.location.reload()
            }}>Clear All Filter</a><br/><br/>
            <AgentFilter key={0} onChanged={onAgentCriteriaChanged} criteria={criteria}/><br/>
            <RequesterFilter key={1} onChanged={onRequesterChanged} criteria={criteria}/><br/>
            {/*<DueByFilter key={2} onChanged={onDueByChanged} criteria={criteria}/><br/>*/}
            <CreatedFilter key={3} onChanged={onCreatedValueChanged} criteria={criteria}/><br/>
            <StatusFilter key={4} statuses={props.statuses} onChanged={onStatusChanged} criteria={criteria}/><br/>
            <CategoryFilter key={5} categories={props.categories} onChanged={onCategoryChanged} criteria={criteria}/><br/>
            <PriorityFilter key={6} priorities={props.priorities} onChanged={onPriorityChanged} criteria={criteria}/>
        </>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        statuses: state.statuses,
        categories: state.categories,
        impacts: state.impacts,
        priorities: state.priorities,
        incidentViewState: state.incidentViewState,
        criteria: state.incidentViewState.currentView!!.criteria
    }
}

export default connect(mapStateToProps, {
    notifyCurrentViewCriteriaChanged
})(IncidentFilterForm)
