/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import {message, Table} from 'antd'
import MyStep from '../stepAndSignature/MyStep'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { checkIsCurrentJob, getAllJob, getCurrentJobNo, Job, updateJobStatus } from '../../Relate/Job'
import {decryptDataVspace} from '../../../common-misc'
import {getAssignmentUser} from '../service'
import {assignmentGroups} from '../model'

interface Params {
    woId: string,
    username: string,
    handleIsChangeStatus: (boolean) => void
    permission: boolean,
    roleId: number,
    isCloseAndCancel: boolean,
    isAssetNotNull: boolean
}
const mapStateToProps = (state: StoreState) => {
    return {
        job: state.job
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllJob: (woId: string) => Promise<Job[] | undefined>
}

type Props = DispatchProps & StateProps & Params

const TableJob: React.FC<Props> = (props: Props) => {
    const [data, setData] = useState<Job[] | undefined>([])
    const [isChangeStatus, setIsChangeStatus] = useState<boolean>(false)
    const [currentJobNo, setCurrentJobNo] = useState<string>('')
    const [User, setUser] = useState<assignmentGroups[]>([])
    const [assignmentGroup, setAssignmentGroup] = useState<string>('')
    const [assignedTo, setAssignedTo] = useState<string>('')
    let email: string = ''
    const dataVspace = decryptDataVspace()
    if (typeof dataVspace === 'object') {
        email = dataVspace.email
    }
    useEffect(() => {
        fetchJobData()
        fetchAssignmentUser()
    }, [])

    // useEffect(() => {
    //     console.log('use effect 2')
    //
    // }, [props.job])

    useEffect(() => {
        if (data) {
            const reduxJobsId = props.job.map(job => job.jobNo)
            const currentJobsId = data.map(job => job.jobNo)
            if (arrayEquals(reduxJobsId, currentJobsId)) {
                setData(props.job)
            }
            setCurrentJobNo(getCurrentJobNo(data!!))
        }
    }, [data])

    useEffect(() => {
        const jobData = {
            lastModifiedBy: email,
            jobStatus: ''
        }
        // find last job no
        const job = data?.find((job) => {
            if (job.jobNo === currentJobNo) {
                return job
            }
        })
        if (job && job.jobStatus === 'Created') {
            if (job.jobTopic === 'Approve') {
                jobData.jobStatus = 'Awaiting Approval'
                props.handleIsChangeStatus(true)
                updateJobStatus(job.id!!, jobData).then(() => {
                    fetchJobData()
                })
            } else {
                jobData.jobStatus = 'Open'
                props.handleIsChangeStatus(true)
                updateJobStatus(job.id!!, jobData).then(() => {
                    fetchJobData()
                })
            }
        } else if (job?.jobStatus === 'In Progress' && (data && data[data.length - 1].jobNo === job.jobNo)) {
            if (localStorage.getItem('isClose') === 'true') {
                props.handleIsChangeStatus(true)
            }
            localStorage.removeItem('isClose')
        } else {
            fetchJobData()
        }
    }, [isChangeStatus, currentJobNo])

    const arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index])
    }

    const fetchJobData = () => {
        props.getAllJob(props.woId).then(jobs => {
            setData(jobs)
        })
    }

    const fetchAssignmentUser = () => {
        getAssignmentUser().then((res) => {
            setUser(res)
        }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'id',
            width: '3%',
            key: 'id',
            // eslint-disable-next-line react/display-name
            render: (id, data, index) => {
                return (
                    <>
                        {index + 1}
                    </>
                )
            }
        },
        {
            title: 'Job ID',
            dataIndex: 'jobNo',
            key: 'jobNo',
            width: '10%'
        },
        {
            title: 'Job Topic',
            dataIndex: 'jobTopic',
            key: 'jobTopic',
            width: '20%'
        },
        {
            title: 'Job Status',
            dataIndex: 'jobStatus',
            key: 'jobStatus',
            width: '7%'
        },
        {
            title: 'Last modified date',
            dataIndex: 'lastModifiedDate',
            key: 'lastModifiedDate',
            width: '15%'
        },
        {
            title: 'Assignment group',
            dataIndex: 'supportTeam',
            key: 'supportTeam',
            width: '15%'
        },
        {
            title: 'Assigned to',
            dataIndex: 'assignee',
            key: 'assignee',
            width: '15%',
            render: (item, data) => {
                return (
                    <>
                        <span>{getAssigneeName(data)}</span>
                    </>
                )
            }
        }
    ]

    const getAssigneeName = (jobData: Job) : string | undefined => {
        const assigneeName = User.find((item) => item.name === jobData.supportTeam)?.assignees.find((ele) => ele.email === jobData.assignee)?.name
        return assigneeName
    }

    const handleChangeStatus = (change: boolean) => {
        setIsChangeStatus(change)
        setCurrentJobNo(getCurrentJobNo(data!!))
    }

    const getAssignmentGroup = (value) => {
        setAssignmentGroup(value)
    }

    // รอต่อ api Job เพื่อเงื่อนไข isSignature และ JobTopic
    const expandedRowRender = (expandedRow, index) => {
        const currentJob: boolean = checkIsCurrentJob(index, data!!, expandedRow)
        return <MyStep isDirection={true} isSignature={!(expandedRow.templateNo === 7 || expandedRow.templateNo === 6)} job={expandedRow} isCurrentJob={currentJob}
            handleChangeStatus={handleChangeStatus} woId={props.woId!! || ''} username={props.username} isStatus={isChangeStatus} roleId={props.roleId} vSpaceIncidentNo={expandedRow.ticketNo || ''}
            getDataFromMyStep={getAssignmentGroup} isAssetNotNull= {props.isAssetNotNull}
        />
    }

    return (
        <>
            <Table
                dataSource={data}
                columns={columns}
                expandedRowRender={props.permission || props.isCloseAndCancel ? undefined : expandedRowRender}
                pagination={false}
                rowKey='id'
            />
        </>
    )
}

export default connect(mapStateToProps,
    {
        getAllJob
    })(TableJob)
