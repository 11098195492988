import { Dispatch } from 'redux'
import { ResponseDetails } from '../../common-rest'
import { GetAllCategorySuccessEvent, AddCategorySuccessEvent, UpdateCategorySuccessEvent, DeleteCategorySuccessEvent, UpdateCategoryCurrentPageSuccessEvent, UpdateCategoryTotalPageSuccessEvent, AddCategoryByExcelSuccessEvent, DeleteAllCategorySuccessEvent } from './state-event'
import { Category } from './model'
import { axiosFactory, axiosDelete, axiosPatch, axiosPost, axiosGetWithPagination } from '../rest'

export const getAllCategory = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosFactory().get<ResponseDetails<Category[]>>('/api/incident/categories')
            dispatch(GetAllCategorySuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}

export async function getAllCategoryNoRedux(): Promise<Category[]> {
    try {
        const res = await axiosFactory().get<ResponseDetails<Category[]>>('/api/incident/categories')
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getCategoryById(id: string): Promise<Category> {
    try {
        const res = await axiosFactory().get<ResponseDetails<Category>>(`/api/incident/categories/${id}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const addCategory = (category: Category) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<Category>('/api/incident/categories', category)
            dispatch(AddCategorySuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const addMultipleCategory = (category: Category[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<Category[]>('/api/incident/categories/saveAll', category)
            dispatch(AddCategoryByExcelSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteCategory = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/incident/categories/${id}`)
            dispatch(DeleteCategorySuccessEvent.build(id))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const deleteMultipleCategory = (category: Category[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<Category[]>('/api/incident/categories/deleteAll', category)
            dispatch(DeleteAllCategorySuccessEvent.build(category))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const updateCategory = (patch: Category) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            // delete patch.id
            const response = await axiosPatch<Category>(`/api/incident/categories/${id}`, patch)
            dispatch(UpdateCategorySuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const getAllCategoriesWithPagination = (page: number, pageSize: number, searchField?: string, searchValue?: string, active?: boolean[], sortBy?: string, orderBy?: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGetWithPagination<Category[]>(`/api/incident/categories/getAllCategories?page=${page}&pageSize=${pageSize}&searchField=${searchField}&searchValue=${searchValue}&active=${active}&sortBy=${sortBy}&orderBy=${orderBy}`)
            dispatch(GetAllCategorySuccessEvent.build(response.data.data!!.content || []))
            dispatch(UpdateCategoryTotalPageSuccessEvent.build(response.data.data.totalElements))
            dispatch(UpdateCategoryCurrentPageSuccessEvent.build(response.data.data.number))
            return response.status
        } catch (err) {
            console.log(err.response)
            throw err
        }
    }
}

export const importExcel = (category : Category[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<Category>('/api/incident/categories/importExcel', category)
            dispatch(AddCategorySuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

// export const ConvertCategoryToCategoryForTableExpand = (category: Category[]): CategoryForTableExpand[] => {
//     let convert: CategoryForTableExpand[]
//     // eslint-disable-next-line prefer-const
//     convert = category.map((cat: Category | any) => {
//         if (cat?.subcategories) {
//             (cat?.subcategories || []).map((subCat: any) => {
//                 const convertItem: any[] = []
//                 subCat.items.forEach((item) => {
//                     if (item.name) {
//                         convertItem.push({
//                             key: Math.random(),
//                             name: item.name,
//                             createdDate: item.createdDate,
//                             lastModifiedDate: item.lastModifiedDate
//                         })
//                     }
//                 })
//                 subCat.children = convertItem
//                 subCat.key = Math.random()
//                 subCat.Action = 'Add Item'
//                 delete subCat.items
//                 return subCat
//             })
//             cat.children = cat?.subcategories ? cat?.subcategories : []
//             cat.Action = 'Add Subcategory'
//             delete cat?.subcategories
//         }
//         return cat
//     })
//     return convert
// }

// export const RollbackCategoryForTableExpandToCategory = (catTable: CategoryForTableExpand[]): Category[] => {
//     let rollbackdata: Category[]
//     // eslint-disable-next-line prefer-const
//     rollbackdata = catTable.map((cat: CategoryForTableExpand | any) => {
//         (cat.children || []).map((subCat: any) => {
//             const rollbackItem: Items[] = [];
//             (subCat.children || []).forEach((item) => {
//                 rollbackItem.push({
//                     name: item.name,
//                     createdDate: item.createdDate,
//                     lastModifiedDate: item.lastModifiedDate
//                 })
//             })
//             subCat.items = rollbackItem
//             delete subCat.Action
//             delete subCat.key
//             delete subCat.children
//             return subCat
//         })
//         cat.subcategories = cat.children
//         delete cat.Action
//         delete cat.children
//         return cat
//     })
//     return rollbackdata
// }

// export const convertCategoryToExcalV2 = (category: Category[]): CategoryForExcal[] => {
//     const excelArray: CategoryForExcal[] = []
//     category.map((cat: Category) => {
//         const excel: CategoryForExcal = {}
//         excel.id = cat.id
//         excel.createdBy = cat.createdBy
//         excel.createdByDisplay = cat.createdByDisplay
//         excel.createdDate = cat.createdDate
//         excel.lastModifiedBy = cat.lastModifiedBy
//         excel.lastModifiedByDisplay = cat.lastModifiedByDisplay
//         excel.lastModifiedDate = cat.lastModifiedDate
//         excel.name = cat.name
//         excel.active = cat.active
//         if (cat.subcategories.length === 0) {
//             excelArray.push(excel)
//         } else {
//             cat.subcategories.forEach((subcat: Subcategory, index: number) => {
//                 if (index === 0) {
//                     excel.subcategory = subcat.name
//                     excel.subcategorycreatedDate = subcat.createdDate
//                     excel.subcategorylastModifiedDate = subcat.lastModifiedDate
//                     if (subcat.items.length === 0) {
//                         excelArray.push(excel)
//                     } else {
//                         subcat.items.forEach((it, i: number) => {
//                             if (i === 0) {
//                                 excel.item = it.name!!
//                                 excel.itemcreatedDate = it.createdDate
//                                 excel.itemlastModifiedDate = it.lastModifiedDate
//                                 excelArray.push(excel)
//                             } else {
//                                 excelArray.push({
//                                     id: '',
//                                     createdBy: '',
//                                     createdDate: '',
//                                     createdByDisplay: '',
//                                     lastModifiedBy: '',
//                                     lastModifiedByDisplay: '',
//                                     lastModifiedDate: '',
//                                     name: '',
//                                     subcategory: '',
//                                     subcategorycreatedDate: '',
//                                     subcategorylastModifiedDate: '',
//                                     item: it.name,
//                                     itemcreatedDate: it.createdDate,
//                                     itemlastModifiedDate: it.lastModifiedDate
//                                 } as CategoryForExcal)
//                             }
//                         })
//                     }
//                 } else {
//                     if (subcat.items.length === 0) {
//                         excelArray.push({
//                             id: '',
//                             createdBy: '',
//                             createdDate: '',
//                             createdByDisplay: '',
//                             lastModifiedBy: '',
//                             lastModifiedByDisplay: '',
//                             lastModifiedDate: '',
//                             name: '',
//                             subcategory: subcat.name,
//                             subcategorycreatedDate: subcat.createdDate,
//                             subcategorylastModifiedDate: subcat.lastModifiedDate
//                         } as CategoryForExcal)
//                     } else {
//                         subcat.items.forEach((it, i: number) => {
//                             if (i === 0) {
//                                 excelArray.push({
//                                     id: '',
//                                     createdBy: '',
//                                     createdDate: '',
//                                     createdByDisplay: '',
//                                     lastModifiedBy: '',
//                                     lastModifiedByDisplay: '',
//                                     lastModifiedDate: '',
//                                     name: '',
//                                     subcategory: subcat.name,
//                                     subcategorycreatedDate: subcat.createdDate,
//                                     subcategorylastModifiedDate: subcat.lastModifiedDate,
//                                     item: it.name,
//                                     itemcreatedDate: it.createdDate,
//                                     itemlastModifiedDate: it.lastModifiedDate
//                                 } as CategoryForExcal)
//                             } else {
//                                 excelArray.push({
//                                     id: '',
//                                     createdBy: '',
//                                     createdDate: '',
//                                     createdByDisplay: '',
//                                     lastModifiedBy: '',
//                                     lastModifiedByDisplay: '',
//                                     lastModifiedDate: '',
//                                     name: '',
//                                     subcategory: '',
//                                     subcategorycreatedDate: '',
//                                     subcategorylastModifiedDate: '',
//                                     item: it.name,
//                                     itemcreatedDate: it.createdDate,
//                                     itemlastModifiedDate: it.lastModifiedDate
//                                 } as CategoryForExcal)
//                             }
//                         })
//                     }
//                 }
//             })
//         }
//     })
//     return excelArray
// }

const convertArrayStringToCategory = (value: string[]): Category => {
    const tempCat: Category = {
        id: Number(value[0]),
        createdBy: value[1],
        createdDate: value[2],
        lastModifiedBy: value[3],
        lastModifiedDate: value[4],
        category: value[5],
        subCategory: value[6],
        item: value[7],
        active: Boolean(value[8])
    }
    return tempCat
}

export const RollbackDataFromFileToCategoryForExcel = (data: any[]): Category[] => {
    data.shift()
    const allCat: Category[] = []
    data.forEach((it) => {
        if (it) {
            const tempCat = convertArrayStringToCategory(it)
            const check = allCat.some(it => it.category === tempCat.category && it.subCategory === tempCat.subCategory && it.item === tempCat.item)
            if (!check) {
                allCat.push(tempCat)
            }
        }
    })
    return allCat
    // let lastKeys = ''
    // let lastCateKeys = ''

    // const temp = {}
    // data.forEach((response) => {
    //     if (response[0]) {
    //         lastKeys = response[0]
    //         temp[lastKeys] = {
    //             header: response,
    //             category: {}
    //         }
    //     }

    //     if (response[9]) {
    //         lastCateKeys = response[9]
    //         temp[lastKeys].category[lastCateKeys] = []
    //     }
    //     if (temp[lastKeys].category[lastCateKeys]) {
    //         temp[lastKeys].category[lastCateKeys].push(response)
    //     }
    // })

    // const VALUE = Object.keys(temp).map((res) => {
    //     const header = temp[res].header

    //     const category = temp[res].category

    //     const _category = Object.keys(category).map(res => {
    //         const items = category[res].filter(res => res[12]).map(res => {
    //             return {
    //                 name: res[12],
    //                 createdDate: res[13],
    //                 lastModifiedDate: res[14]
    //             }
    //         })

    //         return {
    //             name: res,
    //             createdDate: category[res][0][10],
    //             lastModifiedDate: category[res][0][11],
    //             items: items
    //         }
    //     })

    //     return {
    //         id: res,
    //         createdBy: header[1],
    //         createdByDisplay: header[2],
    //         createdDate: header[3],
    //         lastModifiedBy: header[4],
    //         lastModifiedByDisplay: header[5],
    //         lastModifiedDate: header[6],
    //         name: header[7],
    //         active: header[8],
    //         subcategories: _category
    //     }
    // })

    // return VALUE as unknown as Category[]
}
