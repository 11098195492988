import { buildStateEvent, GenericStateEvent } from '../../common-redux'
import { Category } from './model'

export interface CategoryStateEvent<T> extends GenericStateEvent<T> {}
export interface CategoryManagementStateEvent<T> extends GenericStateEvent<T> {}

export const GetAllCategorySuccessEvent = {
    type: 'GetAllCategorySuccessEvent',
    build: (payload: Category[]) => buildStateEvent(GetAllCategorySuccessEvent.type, payload)
}

export const UpdateCategorySuccessEvent = {
    type: 'UpdateCategorySuccessEvent',
    build: (payload: Category) => buildStateEvent(UpdateCategorySuccessEvent.type, payload)
}

export const DeleteCategorySuccessEvent = {
    type: 'DeleteCategorySuccessEvent',
    build: (payload: string) => buildStateEvent(DeleteCategorySuccessEvent.type, payload)
}

export const DeleteAllCategorySuccessEvent = {
    type: 'DeleteAllCategorySuccessEvent',
    build: (payload: Category[]) => buildStateEvent(DeleteAllCategorySuccessEvent.type, payload)
}

export const AddCategorySuccessEvent = {
    type: 'AddCategorySuccessEvent',
    build: (payload: Category) => buildStateEvent(AddCategorySuccessEvent.type, payload)
}

export const AddCategoryByExcelSuccessEvent = {
    type: 'AddCategoryByExcelSuccessEvent',
    build: (payload: Category[]) => buildStateEvent(AddCategoryByExcelSuccessEvent.type, payload)
}

export const UpdateCategoryTotalPageSuccessEvent = {
    type: 'UpdateCategoryTotalPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdateCategoryTotalPageSuccessEvent.type, payload)
}

export const UpdateCategoryCurrentPageSuccessEvent = {
    type: 'UpdateCategoryCurrentPageSuccessEvent',
    build: (payload: number) => buildStateEvent(UpdateCategoryCurrentPageSuccessEvent.type, payload)
}
