import { WithVersion } from '../common-model'
import { SelectValue } from 'antd/es/select'
import moment from 'moment'
import 'moment-duration-format'
import crypto from 'crypto'
import {LoginInfo} from '../authorization-module/vSpace-login/model'
import * as XLSX from 'xlsx'

export const resolveOrderDirectionLabel = (orderDirection: string) => ({
    asc: 'Ascending',
    desc: 'Descending',
    DESC: 'Descending',
    ASC: 'Ascending'
})[orderDirection]

export function toDistinctArray<T>(array: T[]): T[] {
    return [...new Set(array)]
}

export function getVersion<T>(obj: any): T | undefined {
    return (obj as WithVersion<T>).version
}

export function nullOrEmpty(array: Array<any> | null | undefined): boolean {
    return (array === null || array === undefined || array!!.length === 0)
}

export function notEmpty(array: Array<any> | null | undefined): boolean {
    return !nullOrEmpty(array)
}

export function nullOrEmptyMap(map: Map<any, any> | null | undefined): boolean {
    return (map === null || map === undefined || map.size === 0)
}

export function notEmptyMap(map: Map<any, any> | null | undefined): boolean {
    return !nullOrEmptyMap(map)
}

export function nullOrEmptyObject(map: {} | null | undefined): boolean {
    return (map === null || map === undefined || Object.keys(map).length === 0)
}

export function notEmptyObject(map: Map<any, any> | null | undefined): boolean {
    return !nullOrEmptyObject(map)
}

export const SelectValueFunc = {
    toStringArray: (value: SelectValue): string[] => {
        if (value === undefined) {
            return []
        } else if (typeof value === 'string') {
            return [value as string]
        } else {
            return value as string[]
        }
    }
}

export function cloneObject<T>(object: T) {
    return JSON.parse(JSON.stringify(object)) as T
}

export function parseJavaDuration(s: string) {
    // @ts-ignore
    console.log(moment.duration(s).format({
        template: 'd [days] h [hours] m [mins] s [secs]',
        trim: 'left'
    }))
}

// export function testParseJavaDuration() {
//     parseJavaDuration('P5DT3H2M14.098S')
//     parseJavaDuration('PT3M14.098S')
//     parseJavaDuration('PT3M0.098S')
//     parseJavaDuration('PT0S')
//     parseJavaDuration('PT1H')
// }

// testParseJavaDuration()

export function SortArrayObjectByKey(property) {
    var sortOrder = 1
    if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
    }
}

export function countLines(ElementId: string): number {
    var el = document.getElementById(ElementId) as HTMLInputElement
    if (el) {
        var lines = (el.innerText as string).split('\n')
        return lines.length
    } else {
        return 0
    }
}

export function CutTextToReadMore(lineNumber:Number): Object {
    return {overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: lineNumber, WebkitBoxOrient: 'vertical'}
}

const algorithm = 'aes-256-ctr'
const secretKey = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3'
const iv = crypto.randomBytes(16)

export const encrypt = (text) => {
    const cipher = crypto.createCipheriv(algorithm, secretKey, iv)

    const encrypted = Buffer.concat([cipher.update(text), cipher.final()])

    return {
        iv: iv.toString('hex'),
        content: encrypted.toString('hex')
    }
}

export const decrypt = (hash) => {
    const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'))

    const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()])

    return decrpyted.toString()
}

export const decryptDataVspace = () => {
    const rawData = localStorage.getItem('dataVspace')
    if (rawData) {
        return JSON.parse(decrypt(JSON.parse(rawData))) as LoginInfo
    }
}

export const encodeSpecialCharacter = (c: string) => {
    let newStr = ''
    for (let i = 0; i < c.length; i++) {
        if (c[i] === '&') {
            newStr += '%26'
        } else if (c[i] === '+') {
            newStr += '%2B'
        } else if (c[i] === '#') {
            newStr += '%23'
        } else if (c[i] === '%') {
            newStr += '%25'
        } else if (c[i] === '{') {
            newStr += '%7B'
        } else if (c[i] === '}') {
            newStr += '%7D'
        } else if (c[i] === '|') {
            newStr += '%7C'
        } else if (c[i] === '^') {
            newStr += '%5E'
        } else if (c[i] === '[') {
            newStr += '%5B'
        } else if (c[i] === ']') {
            newStr += '%5D'
        } else if (c[i] === '`') {
            newStr += '%60'
        } else if (c[i] === '\\') {
            newStr += '%5C'
        } else {
            newStr += c[i]
        }
    }
    return newStr
}

export const disableByStatus = (currentStatus, setDisabledState: Function, assignee, supportTeam) => {
    if ((currentStatus === 'Resolved' || currentStatus === 'Closed') && !(assignee && supportTeam)) {
        setDisabledState(false)
    } else if (currentStatus === 'Resolved' || currentStatus === 'Closed' || currentStatus === 'Cancelled' || currentStatus === 'Pending') {
        setDisabledState(true)
    } else {
        setDisabledState(false)
    }
}

export const fixWidthExcel = (data: any[], worksheet: XLSX.WorkSheet): XLSX.ColInfo[] => {
    const colLengths = Object.keys(data[0]).map((k) => k?.toString().length)
    for (const d of data) {
        Object.values(d).forEach((element: any, index) => {
            const length = element?.toString().length
            if (colLengths[index] < length) {
                colLengths[index] = length
            }
        })
    }
    const ColInfoData = worksheet['!cols'] = colLengths.map((l) => {
        return {
            wch: l + 2
        }
    })
    return ColInfoData
}
