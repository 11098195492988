const axios = require('axios')

export const BASE_URL = process.env.REACT_APP_BASE_URL_VSPACE!!

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export async function getDataVspace(data) {
    try {
        const res = await axios.post(BASE_URL + '/apis/v1/ticket/detail', data, config)
        if (res) {
            return res.data!!
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}
