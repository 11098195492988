import React, {useEffect, useState} from 'react'
import {Collapse, Icon, Menu, Tooltip} from 'antd'
import {Category} from './model'

interface Props {
    categories: Category[]
    selectFolder: Function
}

const {Panel} = Collapse

export const CategoryList: React.FC<Props> = (props: Props) => {
    const [activeKeyPath, setActiveKeyPath] = useState<any[]>()
    const [categories, setCategories] = useState<Category[]>(props.categories)
    const [activeCategory, setActiveCategory] = useState<string[]>([])
    useEffect(() => {
        const keyPathString = localStorage.getItem('active_key_path')
        if (keyPathString) {
            const keyPath = keyPathString.split(',')
            setActiveCategory([keyPath[0]])
            setActiveKeyPath(keyPath)
        } else {
            const keyPath = [undefined, 'all']
            setActiveKeyPath(keyPath)
        }
    }, [])

    useEffect(() => {
        if (props.categories) {
            const sortCategories = props.categories.sort((a, b) => {
                if (a.categoryName === b.categoryName) {
                    return 0
                } else if (a.categoryName === 'General') {
                    return -1
                } else {
                    return a.categoryName!!.localeCompare(b.categoryName!!)
                }
            })
            setCategories([...sortCategories])
        }
    }, [props.categories])

    const handleClick = (keyPath: string[]) => {
        props.selectFolder(keyPath)
        console.log(keyPath)
        setActiveKeyPath(keyPath)
        let mockCategory = activeCategory
        if (mockCategory.includes(keyPath[0]) && keyPath.length === 1) {
            mockCategory = mockCategory.filter((categoryID) => (categoryID !== keyPath[0]))
        } else if (keyPath.length === 1) {
            mockCategory.push(keyPath[0])
        }
        setActiveCategory(mockCategory)
        localStorage.setItem('active_key_path', keyPath.join(','))
    }

    const {SubMenu} = Menu
    return (
        <Collapse defaultActiveKey={['1']}>
            <Panel header="Knowledge Category" key="1">
                <div>
                    <Menu
                        mode="inline"
                        onClick={(eventClick) => {
                            const sortKeyPath = [eventClick.keyPath[1], eventClick.keyPath[0]] // keyPathIndex 1 = categoryId, keyPathIndex 0 = folderId
                            handleClick(sortKeyPath)
                        }}
                        className="km-category-list"
                        openKeys={activeCategory}
                    >
                        <Menu.Item key='all'>
                            <span className={activeKeyPath && activeKeyPath[1] === 'all' ? 'km-active-menu' : ''}>All Knowledge</span>
                        </Menu.Item>
                        {categories.map((category) => {
                            return (
                                <SubMenu
                                    key={category.id}
                                    title={
                                        <span
                                            className={JSON.stringify(activeKeyPath) === JSON.stringify([category.id]) ? 'km-active-menu' : ''}>
                                            <Tooltip placement="bottom" title={category.categoryName}>
                                                <span>{category.categoryName}</span>
                                            </Tooltip>
                                        </span>
                                    }
                                    onTitleClick={(click) => {
                                        handleClick([click.key])
                                    }}
                                    className={activeCategory.includes(category.id || '') ? 'ant-menu-submenu-open' : ''}
                                >
                                    {category.folderList?.map((folder) => {
                                        const keyPathOfThisFolder = [category.id!!.toString(), folder.id!!.toString()]
                                        return (
                                            <Menu.Item key={folder.id}><Icon type='folder'/>
                                                <span
                                                    className={JSON.stringify(activeKeyPath) === JSON.stringify(keyPathOfThisFolder) ? 'km-active-menu' : ''}>
                                                    <Tooltip placement="bottom" title={folder.folderName}>
                                                        <span>{folder.folderName}</span>
                                                    </Tooltip>
                                                </span>
                                            </Menu.Item>
                                        )
                                    })}
                                </SubMenu>
                            )
                        })}
                    </Menu>
                </div>
            </Panel>
        </Collapse>
    )
}
